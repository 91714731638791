/* @import 'normalize.css'; */

@import 'print.css';

@import 'tables.css';

@import 'react-table.css';

@import 'prism.css';

/* @import 'tailwind.css'; */

.container {
    max-width: initial !important;
  }
  
* {
    outline: none !important;
}

.react-kanban-board {
    height: 100%;
    padding: 0px !important;
}
.react-kanban-board > div {
    height: 100%
}

.react-contexify__item__content {
    width: 100%;
}
/* .react-kanban-board div {
    width: 384px;
} */

.react-kanban-column {
    /* background-color: #217566 !important; */
    /* background-color: #8BDCCE30 !important; */
    border-radius: 5px !important;
    margin-top: 0px !important;
    height: calc(100vh - 152px) !important;
    /* overflow: auto;
    overflow-x: hidden; */
    width: 380px !important;
}
.react-kanban-column-header {
    color: black !important;
}

html {
    font-size: 62.5%;
    font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;
}

body {
    font-size: 14px;
    line-height: 1.4;
}

html, body, #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.rst__rowContents {
    background-color: transparent !important;
    border: none !important;
}

.css-mtwhol {
    text-align: right;
}
/* .rst__node {
    display: flex;
}
.rst__node .rst__lineBlock {
    flex: 0 0 auto;
}
.rst__node .rst__nodeContent {
    left: auto !important;
    top: auto !important;
    position: relative !important;
    flex: 0 1 auto;
    width: 100%;
} */
.rst__rowContents {
    min-width: auto !important;
}
.rst__rowLabel{
    width: 100%;
    padding-right: 5px !important;
}
 .rst__node {
    display: flex;
}
.rst__node .rst__lineBlock {
    flex: 0 0 auto;
}
.rst__node .rst__nodeContent {
    flex: 0 1 auto;
    left: auto !important;
    position: relative !important;
    top: auto !important;
    width: 100%;
}

#react-confirm-alert {
    position: fixed;
    z-index: 100000;
}
/* .rst__highlightTopLeftCorner, .rst__highlightBottomLeftCorner {
    display: none;
} */
/* .rst__lineBlock:not(.rst__lineHalfVerticalTop):not(.rst__lineFullVertical) {
    display: none;
} */
/* .rst__lineBlock.rst__lineHalfVerticalTop.rst__lineHalfHorizontalRight, .rst__lineBlock.rst__lineHalfHorizontalRight.rst__lineFullVertical {
    display: none;
} */
/* .rst__lineBlock + .rst__lineBlock.rst__lineHalfVerticalTop.rst__lineHalfHorizontalRight, .rst__lineBlock + .rst__lineBlock.rst__lineHalfHorizontalRight.rst__lineFullVertical {
    display: block;
}
.rst__lineBlock.rst__lineFullVertical {
    display: none;
} */

 



.rbc-events-container {
    margin-right: 0px !important;
}

#paperLicense input {
    font-size: 1em !important;
}

#paperLicense .MuiInputBase-input:focus {
    border-color: green !important;
}
#paperLicense .Mui-focused {
    border-color: green !important;
}

.MuiLinearProgress-barColorPrimary {
    background-color: #388087 !important;
}
.MuiLinearProgress-colorPrimary {
    background-color: #3e3b39 !important;
}

.css-18f0176 {
    height: 100%;
    width: 100%;
}

/* .rbc-event{
    background-color: ''
} */

.circle-picker {
    transform: scale(1.3);
    width: 213px;
}

h1, .h1 {
    font-size: 24px;
}

h2, .h2 {
    font-size: 20px;
}

h3, .h3 {
    font-size: 16px;
}

h4, .h4 {
    font-size: 15px;
}

h5, .h5 {
    font-size: 13px;
}

h6, .h6 {
    font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
    z-index: 99;
}

a[role=button] {
    text-decoration: none;
}

[role="tooltip"] {
    z-index: 999;
}

svg:not(:root) { overflow: visible; }

/* Medium Devices, Desktops Only */
/* @media only screen and (min-width: 992px) {
    ::-webkit-scrollbar {
        width: 8px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar:hover {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0.06);
    }

    ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.12);
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb:active {
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
        border-radius: 20px;
    }
} */

/* form label {
    z-index: 99;
} */
