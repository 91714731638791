/*@tailwind preflight;*/

.visible {
  visibility: visible
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.m-4 {
  margin: 0.4rem
}

.m-8 {
  margin: 0.8rem
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.ml-4 {
  margin-left: 0.4rem
}

.ml-8 {
  margin-left: 0.8rem
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.h-32 {
  height: 3.2rem
}

.w-32 {
  width: 3.2rem
}

.flex-1 {
  flex: 1 1 0%
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.resize {
  resize: both
}

.flex-col {
  flex-direction: column
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-between {
  justify-content: space-between
}

.overflow-hidden {
  overflow: hidden
}

.rounded-t-4 {
  border-top-left-radius: .4rem;
  border-top-right-radius: .4rem
}

.border {
  border-width: 1px
}

.p-0 {
  padding: 0
}

.p-16 {
  padding: 1.6rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pl-40 {
  padding-left: 4rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pl-80 {
  padding-left: 8rem
}

.font-bold {
  font-weight: 700
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(33, 150, 243, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

@media (min-width: 600px) {

  .sm\:p-24 {
    padding: 2.4rem
  }

  .sm\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }
}

@media (min-width: 1536px) {
  .\32xl\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .\32xl\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .\32xl\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .\32xl\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .\32xl\:pointer-events-none {
    pointer-events: none
  }

  .\32xl\:pointer-events-auto {
    pointer-events: auto
  }

  .\32xl\:visible {
    visibility: visible
  }

  .\32xl\:invisible {
    visibility: hidden
  }

  .\32xl\:static {
    position: static
  }

  .\32xl\:fixed {
    position: fixed
  }

  .\32xl\:absolute {
    position: absolute
  }

  .\32xl\:relative {
    position: relative
  }

  .\32xl\:sticky {
    position: -webkit-sticky;
    position: sticky
  }

  .\32xl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .\32xl\:inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  .\32xl\:inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  .\32xl\:inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem
  }

  .\32xl\:inset-4 {
    top: 0.4rem;
    right: 0.4rem;
    bottom: 0.4rem;
    left: 0.4rem
  }

  .\32xl\:inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem
  }

  .\32xl\:inset-6 {
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem
  }

  .\32xl\:inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem
  }

  .\32xl\:inset-8 {
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    left: 0.8rem
  }

  .\32xl\:inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem
  }

  .\32xl\:inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem
  }

  .\32xl\:inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem
  }

  .\32xl\:inset-12 {
    top: 1.2rem;
    right: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem
  }

  .\32xl\:inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem
  }

  .\32xl\:inset-16 {
    top: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem
  }

  .\32xl\:inset-20 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  .\32xl\:inset-24 {
    top: 2.4rem;
    right: 2.4rem;
    bottom: 2.4rem;
    left: 2.4rem
  }

  .\32xl\:inset-28 {
    top: 2.8rem;
    right: 2.8rem;
    bottom: 2.8rem;
    left: 2.8rem
  }

  .\32xl\:inset-32 {
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem
  }

  .\32xl\:inset-36 {
    top: 3.6rem;
    right: 3.6rem;
    bottom: 3.6rem;
    left: 3.6rem
  }

  .\32xl\:inset-40 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  .\32xl\:inset-44 {
    top: 4.4rem;
    right: 4.4rem;
    bottom: 4.4rem;
    left: 4.4rem
  }

  .\32xl\:inset-48 {
    top: 4.8rem;
    right: 4.8rem;
    bottom: 4.8rem;
    left: 4.8rem
  }

  .\32xl\:inset-52 {
    top: 5.2rem;
    right: 5.2rem;
    bottom: 5.2rem;
    left: 5.2rem
  }

  .\32xl\:inset-56 {
    top: 5.6rem;
    right: 5.6rem;
    bottom: 5.6rem;
    left: 5.6rem
  }

  .\32xl\:inset-60 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  .\32xl\:inset-64 {
    top: 6.4rem;
    right: 6.4rem;
    bottom: 6.4rem;
    left: 6.4rem
  }

  .\32xl\:inset-68 {
    top: 6.8rem;
    right: 6.8rem;
    bottom: 6.8rem;
    left: 6.8rem
  }

  .\32xl\:inset-72 {
    top: 7.2rem;
    right: 7.2rem;
    bottom: 7.2rem;
    left: 7.2rem
  }

  .\32xl\:inset-76 {
    top: 7.6rem;
    right: 7.6rem;
    bottom: 7.6rem;
    left: 7.6rem
  }

  .\32xl\:inset-80 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  .\32xl\:inset-84 {
    top: 8.4rem;
    right: 8.4rem;
    bottom: 8.4rem;
    left: 8.4rem
  }

  .\32xl\:inset-88 {
    top: 8.8rem;
    right: 8.8rem;
    bottom: 8.8rem;
    left: 8.8rem
  }

  .\32xl\:inset-92 {
    top: 9.2rem;
    right: 9.2rem;
    bottom: 9.2rem;
    left: 9.2rem
  }

  .\32xl\:inset-96 {
    top: 9.6rem;
    right: 9.6rem;
    bottom: 9.6rem;
    left: 9.6rem
  }

  .\32xl\:inset-128 {
    top: 12.8rem;
    right: 12.8rem;
    bottom: 12.8rem;
    left: 12.8rem
  }

  .\32xl\:inset-136 {
    top: 13.6rem;
    right: 13.6rem;
    bottom: 13.6rem;
    left: 13.6rem
  }

  .\32xl\:inset-160 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  .\32xl\:inset-192 {
    top: 19.2rem;
    right: 19.2rem;
    bottom: 19.2rem;
    left: 19.2rem
  }

  .\32xl\:inset-200 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  .\32xl\:inset-208 {
    top: 20.8rem;
    right: 20.8rem;
    bottom: 20.8rem;
    left: 20.8rem
  }

  .\32xl\:inset-216 {
    top: 21.6rem;
    right: 21.6rem;
    bottom: 21.6rem;
    left: 21.6rem
  }

  .\32xl\:inset-224 {
    top: 22.4rem;
    right: 22.4rem;
    bottom: 22.4rem;
    left: 22.4rem
  }

  .\32xl\:inset-256 {
    top: 25.6rem;
    right: 25.6rem;
    bottom: 25.6rem;
    left: 25.6rem
  }

  .\32xl\:inset-288 {
    top: 28.8rem;
    right: 28.8rem;
    bottom: 28.8rem;
    left: 28.8rem
  }

  .\32xl\:inset-320 {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  .\32xl\:inset-360 {
    top: 36rem;
    right: 36rem;
    bottom: 36rem;
    left: 36rem
  }

  .\32xl\:inset-384 {
    top: 38.4rem;
    right: 38.4rem;
    bottom: 38.4rem;
    left: 38.4rem
  }

  .\32xl\:inset-400 {
    top: 40rem;
    right: 40rem;
    bottom: 40rem;
    left: 40rem
  }

  .\32xl\:inset-512 {
    top: 51.2rem;
    right: 51.2rem;
    bottom: 51.2rem;
    left: 51.2rem
  }

  .\32xl\:inset-640 {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  .\32xl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .\32xl\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  .\32xl\:inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem
  }

  .\32xl\:inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem
  }

  .\32xl\:inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem
  }

  .\32xl\:inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem
  }

  .\32xl\:inset-xs {
    top: 32rem;
    right: 32rem;
    bottom: 32rem;
    left: 32rem
  }

  .\32xl\:inset-sm {
    top: 48rem;
    right: 48rem;
    bottom: 48rem;
    left: 48rem
  }

  .\32xl\:inset-md {
    top: 64rem;
    right: 64rem;
    bottom: 64rem;
    left: 64rem
  }

  .\32xl\:inset-lg {
    top: 80rem;
    right: 80rem;
    bottom: 80rem;
    left: 80rem
  }

  .\32xl\:inset-xl {
    top: 96rem;
    right: 96rem;
    bottom: 96rem;
    left: 96rem
  }

  .\32xl\:inset-2xl {
    top: 112rem;
    right: 112rem;
    bottom: 112rem;
    left: 112rem
  }

  .\32xl\:inset-3xl {
    top: 128rem;
    right: 128rem;
    bottom: 128rem;
    left: 128rem
  }

  .\32xl\:inset-4xl {
    top: 144rem;
    right: 144rem;
    bottom: 144rem;
    left: 144rem
  }

  .\32xl\:inset-5xl {
    top: 160rem;
    right: 160rem;
    bottom: 160rem;
    left: 160rem
  }

  .\32xl\:-inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  .\32xl\:-inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  .\32xl\:-inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem
  }

  .\32xl\:-inset-4 {
    top: -0.4rem;
    right: -0.4rem;
    bottom: -0.4rem;
    left: -0.4rem
  }

  .\32xl\:-inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem
  }

  .\32xl\:-inset-6 {
    top: -0.6rem;
    right: -0.6rem;
    bottom: -0.6rem;
    left: -0.6rem
  }

  .\32xl\:-inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem
  }

  .\32xl\:-inset-8 {
    top: -0.8rem;
    right: -0.8rem;
    bottom: -0.8rem;
    left: -0.8rem
  }

  .\32xl\:-inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem
  }

  .\32xl\:-inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem
  }

  .\32xl\:-inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem
  }

  .\32xl\:-inset-12 {
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem
  }

  .\32xl\:-inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem
  }

  .\32xl\:-inset-16 {
    top: -1.6rem;
    right: -1.6rem;
    bottom: -1.6rem;
    left: -1.6rem
  }

  .\32xl\:-inset-20 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  .\32xl\:-inset-24 {
    top: -2.4rem;
    right: -2.4rem;
    bottom: -2.4rem;
    left: -2.4rem
  }

  .\32xl\:-inset-28 {
    top: -2.8rem;
    right: -2.8rem;
    bottom: -2.8rem;
    left: -2.8rem
  }

  .\32xl\:-inset-32 {
    top: -3.2rem;
    right: -3.2rem;
    bottom: -3.2rem;
    left: -3.2rem
  }

  .\32xl\:-inset-36 {
    top: -3.6rem;
    right: -3.6rem;
    bottom: -3.6rem;
    left: -3.6rem
  }

  .\32xl\:-inset-40 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  .\32xl\:-inset-44 {
    top: -4.4rem;
    right: -4.4rem;
    bottom: -4.4rem;
    left: -4.4rem
  }

  .\32xl\:-inset-48 {
    top: -4.8rem;
    right: -4.8rem;
    bottom: -4.8rem;
    left: -4.8rem
  }

  .\32xl\:-inset-52 {
    top: -5.2rem;
    right: -5.2rem;
    bottom: -5.2rem;
    left: -5.2rem
  }

  .\32xl\:-inset-56 {
    top: -5.6rem;
    right: -5.6rem;
    bottom: -5.6rem;
    left: -5.6rem
  }

  .\32xl\:-inset-60 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  .\32xl\:-inset-64 {
    top: -6.4rem;
    right: -6.4rem;
    bottom: -6.4rem;
    left: -6.4rem
  }

  .\32xl\:-inset-68 {
    top: -6.8rem;
    right: -6.8rem;
    bottom: -6.8rem;
    left: -6.8rem
  }

  .\32xl\:-inset-72 {
    top: -7.2rem;
    right: -7.2rem;
    bottom: -7.2rem;
    left: -7.2rem
  }

  .\32xl\:-inset-76 {
    top: -7.6rem;
    right: -7.6rem;
    bottom: -7.6rem;
    left: -7.6rem
  }

  .\32xl\:-inset-80 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  .\32xl\:-inset-84 {
    top: -8.4rem;
    right: -8.4rem;
    bottom: -8.4rem;
    left: -8.4rem
  }

  .\32xl\:-inset-88 {
    top: -8.8rem;
    right: -8.8rem;
    bottom: -8.8rem;
    left: -8.8rem
  }

  .\32xl\:-inset-92 {
    top: -9.2rem;
    right: -9.2rem;
    bottom: -9.2rem;
    left: -9.2rem
  }

  .\32xl\:-inset-96 {
    top: -9.6rem;
    right: -9.6rem;
    bottom: -9.6rem;
    left: -9.6rem
  }

  .\32xl\:-inset-128 {
    top: -12.8rem;
    right: -12.8rem;
    bottom: -12.8rem;
    left: -12.8rem
  }

  .\32xl\:-inset-136 {
    top: -13.6rem;
    right: -13.6rem;
    bottom: -13.6rem;
    left: -13.6rem
  }

  .\32xl\:-inset-160 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  .\32xl\:-inset-192 {
    top: -19.2rem;
    right: -19.2rem;
    bottom: -19.2rem;
    left: -19.2rem
  }

  .\32xl\:-inset-200 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  .\32xl\:-inset-208 {
    top: -20.8rem;
    right: -20.8rem;
    bottom: -20.8rem;
    left: -20.8rem
  }

  .\32xl\:-inset-216 {
    top: -21.6rem;
    right: -21.6rem;
    bottom: -21.6rem;
    left: -21.6rem
  }

  .\32xl\:-inset-224 {
    top: -22.4rem;
    right: -22.4rem;
    bottom: -22.4rem;
    left: -22.4rem
  }

  .\32xl\:-inset-256 {
    top: -25.6rem;
    right: -25.6rem;
    bottom: -25.6rem;
    left: -25.6rem
  }

  .\32xl\:-inset-288 {
    top: -28.8rem;
    right: -28.8rem;
    bottom: -28.8rem;
    left: -28.8rem
  }

  .\32xl\:-inset-320 {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  .\32xl\:-inset-360 {
    top: -36rem;
    right: -36rem;
    bottom: -36rem;
    left: -36rem
  }

  .\32xl\:-inset-384 {
    top: -38.4rem;
    right: -38.4rem;
    bottom: -38.4rem;
    left: -38.4rem
  }

  .\32xl\:-inset-400 {
    top: -40rem;
    right: -40rem;
    bottom: -40rem;
    left: -40rem
  }

  .\32xl\:-inset-512 {
    top: -51.2rem;
    right: -51.2rem;
    bottom: -51.2rem;
    left: -51.2rem
  }

  .\32xl\:-inset-640 {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  .\32xl\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  .\32xl\:-inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem
  }

  .\32xl\:-inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem
  }

  .\32xl\:-inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem
  }

  .\32xl\:-inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem
  }

  .\32xl\:-inset-xs {
    top: -32rem;
    right: -32rem;
    bottom: -32rem;
    left: -32rem
  }

  .\32xl\:-inset-sm {
    top: -48rem;
    right: -48rem;
    bottom: -48rem;
    left: -48rem
  }

  .\32xl\:-inset-md {
    top: -64rem;
    right: -64rem;
    bottom: -64rem;
    left: -64rem
  }

  .\32xl\:-inset-lg {
    top: -80rem;
    right: -80rem;
    bottom: -80rem;
    left: -80rem
  }

  .\32xl\:-inset-xl {
    top: -96rem;
    right: -96rem;
    bottom: -96rem;
    left: -96rem
  }

  .\32xl\:-inset-2xl {
    top: -112rem;
    right: -112rem;
    bottom: -112rem;
    left: -112rem
  }

  .\32xl\:-inset-3xl {
    top: -128rem;
    right: -128rem;
    bottom: -128rem;
    left: -128rem
  }

  .\32xl\:-inset-4xl {
    top: -144rem;
    right: -144rem;
    bottom: -144rem;
    left: -144rem
  }

  .\32xl\:-inset-5xl {
    top: -160rem;
    right: -160rem;
    bottom: -160rem;
    left: -160rem
  }

  .\32xl\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  .\32xl\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  .\32xl\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  .\32xl\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  .\32xl\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  .\32xl\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  .\32xl\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  .\32xl\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  .\32xl\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  .\32xl\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  .\32xl\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  .\32xl\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  .\32xl\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  .\32xl\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  .\32xl\:inset-x-0 {
    left: 0;
    right: 0
  }

  .\32xl\:inset-x-1 {
    left: 0.25rem;
    right: 0.25rem
  }

  .\32xl\:inset-x-2 {
    left: 0.5rem;
    right: 0.5rem
  }

  .\32xl\:inset-x-3 {
    left: 0.75rem;
    right: 0.75rem
  }

  .\32xl\:inset-x-4 {
    left: 0.4rem;
    right: 0.4rem
  }

  .\32xl\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem
  }

  .\32xl\:inset-x-6 {
    left: 0.6rem;
    right: 0.6rem
  }

  .\32xl\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem
  }

  .\32xl\:inset-x-8 {
    left: 0.8rem;
    right: 0.8rem
  }

  .\32xl\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem
  }

  .\32xl\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem
  }

  .\32xl\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem
  }

  .\32xl\:inset-x-12 {
    left: 1.2rem;
    right: 1.2rem
  }

  .\32xl\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem
  }

  .\32xl\:inset-x-16 {
    left: 1.6rem;
    right: 1.6rem
  }

  .\32xl\:inset-x-20 {
    left: 2rem;
    right: 2rem
  }

  .\32xl\:inset-x-24 {
    left: 2.4rem;
    right: 2.4rem
  }

  .\32xl\:inset-x-28 {
    left: 2.8rem;
    right: 2.8rem
  }

  .\32xl\:inset-x-32 {
    left: 3.2rem;
    right: 3.2rem
  }

  .\32xl\:inset-x-36 {
    left: 3.6rem;
    right: 3.6rem
  }

  .\32xl\:inset-x-40 {
    left: 4rem;
    right: 4rem
  }

  .\32xl\:inset-x-44 {
    left: 4.4rem;
    right: 4.4rem
  }

  .\32xl\:inset-x-48 {
    left: 4.8rem;
    right: 4.8rem
  }

  .\32xl\:inset-x-52 {
    left: 5.2rem;
    right: 5.2rem
  }

  .\32xl\:inset-x-56 {
    left: 5.6rem;
    right: 5.6rem
  }

  .\32xl\:inset-x-60 {
    left: 6rem;
    right: 6rem
  }

  .\32xl\:inset-x-64 {
    left: 6.4rem;
    right: 6.4rem
  }

  .\32xl\:inset-x-68 {
    left: 6.8rem;
    right: 6.8rem
  }

  .\32xl\:inset-x-72 {
    left: 7.2rem;
    right: 7.2rem
  }

  .\32xl\:inset-x-76 {
    left: 7.6rem;
    right: 7.6rem
  }

  .\32xl\:inset-x-80 {
    left: 8rem;
    right: 8rem
  }

  .\32xl\:inset-x-84 {
    left: 8.4rem;
    right: 8.4rem
  }

  .\32xl\:inset-x-88 {
    left: 8.8rem;
    right: 8.8rem
  }

  .\32xl\:inset-x-92 {
    left: 9.2rem;
    right: 9.2rem
  }

  .\32xl\:inset-x-96 {
    left: 9.6rem;
    right: 9.6rem
  }

  .\32xl\:inset-x-128 {
    left: 12.8rem;
    right: 12.8rem
  }

  .\32xl\:inset-x-136 {
    left: 13.6rem;
    right: 13.6rem
  }

  .\32xl\:inset-x-160 {
    left: 16rem;
    right: 16rem
  }

  .\32xl\:inset-x-192 {
    left: 19.2rem;
    right: 19.2rem
  }

  .\32xl\:inset-x-200 {
    left: 20rem;
    right: 20rem
  }

  .\32xl\:inset-x-208 {
    left: 20.8rem;
    right: 20.8rem
  }

  .\32xl\:inset-x-216 {
    left: 21.6rem;
    right: 21.6rem
  }

  .\32xl\:inset-x-224 {
    left: 22.4rem;
    right: 22.4rem
  }

  .\32xl\:inset-x-256 {
    left: 25.6rem;
    right: 25.6rem
  }

  .\32xl\:inset-x-288 {
    left: 28.8rem;
    right: 28.8rem
  }

  .\32xl\:inset-x-320 {
    left: 32rem;
    right: 32rem
  }

  .\32xl\:inset-x-360 {
    left: 36rem;
    right: 36rem
  }

  .\32xl\:inset-x-384 {
    left: 38.4rem;
    right: 38.4rem
  }

  .\32xl\:inset-x-400 {
    left: 40rem;
    right: 40rem
  }

  .\32xl\:inset-x-512 {
    left: 51.2rem;
    right: 51.2rem
  }

  .\32xl\:inset-x-640 {
    left: 64rem;
    right: 64rem
  }

  .\32xl\:inset-x-auto {
    left: auto;
    right: auto
  }

  .\32xl\:inset-x-px {
    left: 1px;
    right: 1px
  }

  .\32xl\:inset-x-0\.5 {
    left: 0.125rem;
    right: 0.125rem
  }

  .\32xl\:inset-x-1\.5 {
    left: 0.375rem;
    right: 0.375rem
  }

  .\32xl\:inset-x-2\.5 {
    left: 0.625rem;
    right: 0.625rem
  }

  .\32xl\:inset-x-3\.5 {
    left: 0.875rem;
    right: 0.875rem
  }

  .\32xl\:inset-x-xs {
    left: 32rem;
    right: 32rem
  }

  .\32xl\:inset-x-sm {
    left: 48rem;
    right: 48rem
  }

  .\32xl\:inset-x-md {
    left: 64rem;
    right: 64rem
  }

  .\32xl\:inset-x-lg {
    left: 80rem;
    right: 80rem
  }

  .\32xl\:inset-x-xl {
    left: 96rem;
    right: 96rem
  }

  .\32xl\:inset-x-2xl {
    left: 112rem;
    right: 112rem
  }

  .\32xl\:inset-x-3xl {
    left: 128rem;
    right: 128rem
  }

  .\32xl\:inset-x-4xl {
    left: 144rem;
    right: 144rem
  }

  .\32xl\:inset-x-5xl {
    left: 160rem;
    right: 160rem
  }

  .\32xl\:-inset-x-1 {
    left: -0.25rem;
    right: -0.25rem
  }

  .\32xl\:-inset-x-2 {
    left: -0.5rem;
    right: -0.5rem
  }

  .\32xl\:-inset-x-3 {
    left: -0.75rem;
    right: -0.75rem
  }

  .\32xl\:-inset-x-4 {
    left: -0.4rem;
    right: -0.4rem
  }

  .\32xl\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem
  }

  .\32xl\:-inset-x-6 {
    left: -0.6rem;
    right: -0.6rem
  }

  .\32xl\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem
  }

  .\32xl\:-inset-x-8 {
    left: -0.8rem;
    right: -0.8rem
  }

  .\32xl\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem
  }

  .\32xl\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem
  }

  .\32xl\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem
  }

  .\32xl\:-inset-x-12 {
    left: -1.2rem;
    right: -1.2rem
  }

  .\32xl\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem
  }

  .\32xl\:-inset-x-16 {
    left: -1.6rem;
    right: -1.6rem
  }

  .\32xl\:-inset-x-20 {
    left: -2rem;
    right: -2rem
  }

  .\32xl\:-inset-x-24 {
    left: -2.4rem;
    right: -2.4rem
  }

  .\32xl\:-inset-x-28 {
    left: -2.8rem;
    right: -2.8rem
  }

  .\32xl\:-inset-x-32 {
    left: -3.2rem;
    right: -3.2rem
  }

  .\32xl\:-inset-x-36 {
    left: -3.6rem;
    right: -3.6rem
  }

  .\32xl\:-inset-x-40 {
    left: -4rem;
    right: -4rem
  }

  .\32xl\:-inset-x-44 {
    left: -4.4rem;
    right: -4.4rem
  }

  .\32xl\:-inset-x-48 {
    left: -4.8rem;
    right: -4.8rem
  }

  .\32xl\:-inset-x-52 {
    left: -5.2rem;
    right: -5.2rem
  }

  .\32xl\:-inset-x-56 {
    left: -5.6rem;
    right: -5.6rem
  }

  .\32xl\:-inset-x-60 {
    left: -6rem;
    right: -6rem
  }

  .\32xl\:-inset-x-64 {
    left: -6.4rem;
    right: -6.4rem
  }

  .\32xl\:-inset-x-68 {
    left: -6.8rem;
    right: -6.8rem
  }

  .\32xl\:-inset-x-72 {
    left: -7.2rem;
    right: -7.2rem
  }

  .\32xl\:-inset-x-76 {
    left: -7.6rem;
    right: -7.6rem
  }

  .\32xl\:-inset-x-80 {
    left: -8rem;
    right: -8rem
  }

  .\32xl\:-inset-x-84 {
    left: -8.4rem;
    right: -8.4rem
  }

  .\32xl\:-inset-x-88 {
    left: -8.8rem;
    right: -8.8rem
  }

  .\32xl\:-inset-x-92 {
    left: -9.2rem;
    right: -9.2rem
  }

  .\32xl\:-inset-x-96 {
    left: -9.6rem;
    right: -9.6rem
  }

  .\32xl\:-inset-x-128 {
    left: -12.8rem;
    right: -12.8rem
  }

  .\32xl\:-inset-x-136 {
    left: -13.6rem;
    right: -13.6rem
  }

  .\32xl\:-inset-x-160 {
    left: -16rem;
    right: -16rem
  }

  .\32xl\:-inset-x-192 {
    left: -19.2rem;
    right: -19.2rem
  }

  .\32xl\:-inset-x-200 {
    left: -20rem;
    right: -20rem
  }

  .\32xl\:-inset-x-208 {
    left: -20.8rem;
    right: -20.8rem
  }

  .\32xl\:-inset-x-216 {
    left: -21.6rem;
    right: -21.6rem
  }

  .\32xl\:-inset-x-224 {
    left: -22.4rem;
    right: -22.4rem
  }

  .\32xl\:-inset-x-256 {
    left: -25.6rem;
    right: -25.6rem
  }

  .\32xl\:-inset-x-288 {
    left: -28.8rem;
    right: -28.8rem
  }

  .\32xl\:-inset-x-320 {
    left: -32rem;
    right: -32rem
  }

  .\32xl\:-inset-x-360 {
    left: -36rem;
    right: -36rem
  }

  .\32xl\:-inset-x-384 {
    left: -38.4rem;
    right: -38.4rem
  }

  .\32xl\:-inset-x-400 {
    left: -40rem;
    right: -40rem
  }

  .\32xl\:-inset-x-512 {
    left: -51.2rem;
    right: -51.2rem
  }

  .\32xl\:-inset-x-640 {
    left: -64rem;
    right: -64rem
  }

  .\32xl\:-inset-x-px {
    left: -1px;
    right: -1px
  }

  .\32xl\:-inset-x-0\.5 {
    left: -0.125rem;
    right: -0.125rem
  }

  .\32xl\:-inset-x-1\.5 {
    left: -0.375rem;
    right: -0.375rem
  }

  .\32xl\:-inset-x-2\.5 {
    left: -0.625rem;
    right: -0.625rem
  }

  .\32xl\:-inset-x-3\.5 {
    left: -0.875rem;
    right: -0.875rem
  }

  .\32xl\:-inset-x-xs {
    left: -32rem;
    right: -32rem
  }

  .\32xl\:-inset-x-sm {
    left: -48rem;
    right: -48rem
  }

  .\32xl\:-inset-x-md {
    left: -64rem;
    right: -64rem
  }

  .\32xl\:-inset-x-lg {
    left: -80rem;
    right: -80rem
  }

  .\32xl\:-inset-x-xl {
    left: -96rem;
    right: -96rem
  }

  .\32xl\:-inset-x-2xl {
    left: -112rem;
    right: -112rem
  }

  .\32xl\:-inset-x-3xl {
    left: -128rem;
    right: -128rem
  }

  .\32xl\:-inset-x-4xl {
    left: -144rem;
    right: -144rem
  }

  .\32xl\:-inset-x-5xl {
    left: -160rem;
    right: -160rem
  }

  .\32xl\:inset-x-1\/2 {
    left: 50%;
    right: 50%
  }

  .\32xl\:inset-x-1\/3 {
    left: 33.333333%;
    right: 33.333333%
  }

  .\32xl\:inset-x-2\/3 {
    left: 66.666667%;
    right: 66.666667%
  }

  .\32xl\:inset-x-1\/4 {
    left: 25%;
    right: 25%
  }

  .\32xl\:inset-x-2\/4 {
    left: 50%;
    right: 50%
  }

  .\32xl\:inset-x-3\/4 {
    left: 75%;
    right: 75%
  }

  .\32xl\:inset-x-full {
    left: 100%;
    right: 100%
  }

  .\32xl\:-inset-x-1\/2 {
    left: -50%;
    right: -50%
  }

  .\32xl\:-inset-x-1\/3 {
    left: -33.333333%;
    right: -33.333333%
  }

  .\32xl\:-inset-x-2\/3 {
    left: -66.666667%;
    right: -66.666667%
  }

  .\32xl\:-inset-x-1\/4 {
    left: -25%;
    right: -25%
  }

  .\32xl\:-inset-x-2\/4 {
    left: -50%;
    right: -50%
  }

  .\32xl\:-inset-x-3\/4 {
    left: -75%;
    right: -75%
  }

  .\32xl\:-inset-x-full {
    left: -100%;
    right: -100%
  }

  .\32xl\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .\32xl\:inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem
  }

  .\32xl\:inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem
  }

  .\32xl\:inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem
  }

  .\32xl\:inset-y-4 {
    top: 0.4rem;
    bottom: 0.4rem
  }

  .\32xl\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem
  }

  .\32xl\:inset-y-6 {
    top: 0.6rem;
    bottom: 0.6rem
  }

  .\32xl\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem
  }

  .\32xl\:inset-y-8 {
    top: 0.8rem;
    bottom: 0.8rem
  }

  .\32xl\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem
  }

  .\32xl\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem
  }

  .\32xl\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem
  }

  .\32xl\:inset-y-12 {
    top: 1.2rem;
    bottom: 1.2rem
  }

  .\32xl\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem
  }

  .\32xl\:inset-y-16 {
    top: 1.6rem;
    bottom: 1.6rem
  }

  .\32xl\:inset-y-20 {
    top: 2rem;
    bottom: 2rem
  }

  .\32xl\:inset-y-24 {
    top: 2.4rem;
    bottom: 2.4rem
  }

  .\32xl\:inset-y-28 {
    top: 2.8rem;
    bottom: 2.8rem
  }

  .\32xl\:inset-y-32 {
    top: 3.2rem;
    bottom: 3.2rem
  }

  .\32xl\:inset-y-36 {
    top: 3.6rem;
    bottom: 3.6rem
  }

  .\32xl\:inset-y-40 {
    top: 4rem;
    bottom: 4rem
  }

  .\32xl\:inset-y-44 {
    top: 4.4rem;
    bottom: 4.4rem
  }

  .\32xl\:inset-y-48 {
    top: 4.8rem;
    bottom: 4.8rem
  }

  .\32xl\:inset-y-52 {
    top: 5.2rem;
    bottom: 5.2rem
  }

  .\32xl\:inset-y-56 {
    top: 5.6rem;
    bottom: 5.6rem
  }

  .\32xl\:inset-y-60 {
    top: 6rem;
    bottom: 6rem
  }

  .\32xl\:inset-y-64 {
    top: 6.4rem;
    bottom: 6.4rem
  }

  .\32xl\:inset-y-68 {
    top: 6.8rem;
    bottom: 6.8rem
  }

  .\32xl\:inset-y-72 {
    top: 7.2rem;
    bottom: 7.2rem
  }

  .\32xl\:inset-y-76 {
    top: 7.6rem;
    bottom: 7.6rem
  }

  .\32xl\:inset-y-80 {
    top: 8rem;
    bottom: 8rem
  }

  .\32xl\:inset-y-84 {
    top: 8.4rem;
    bottom: 8.4rem
  }

  .\32xl\:inset-y-88 {
    top: 8.8rem;
    bottom: 8.8rem
  }

  .\32xl\:inset-y-92 {
    top: 9.2rem;
    bottom: 9.2rem
  }

  .\32xl\:inset-y-96 {
    top: 9.6rem;
    bottom: 9.6rem
  }

  .\32xl\:inset-y-128 {
    top: 12.8rem;
    bottom: 12.8rem
  }

  .\32xl\:inset-y-136 {
    top: 13.6rem;
    bottom: 13.6rem
  }

  .\32xl\:inset-y-160 {
    top: 16rem;
    bottom: 16rem
  }

  .\32xl\:inset-y-192 {
    top: 19.2rem;
    bottom: 19.2rem
  }

  .\32xl\:inset-y-200 {
    top: 20rem;
    bottom: 20rem
  }

  .\32xl\:inset-y-208 {
    top: 20.8rem;
    bottom: 20.8rem
  }

  .\32xl\:inset-y-216 {
    top: 21.6rem;
    bottom: 21.6rem
  }

  .\32xl\:inset-y-224 {
    top: 22.4rem;
    bottom: 22.4rem
  }

  .\32xl\:inset-y-256 {
    top: 25.6rem;
    bottom: 25.6rem
  }

  .\32xl\:inset-y-288 {
    top: 28.8rem;
    bottom: 28.8rem
  }

  .\32xl\:inset-y-320 {
    top: 32rem;
    bottom: 32rem
  }

  .\32xl\:inset-y-360 {
    top: 36rem;
    bottom: 36rem
  }

  .\32xl\:inset-y-384 {
    top: 38.4rem;
    bottom: 38.4rem
  }

  .\32xl\:inset-y-400 {
    top: 40rem;
    bottom: 40rem
  }

  .\32xl\:inset-y-512 {
    top: 51.2rem;
    bottom: 51.2rem
  }

  .\32xl\:inset-y-640 {
    top: 64rem;
    bottom: 64rem
  }

  .\32xl\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .\32xl\:inset-y-px {
    top: 1px;
    bottom: 1px
  }

  .\32xl\:inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem
  }

  .\32xl\:inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem
  }

  .\32xl\:inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem
  }

  .\32xl\:inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem
  }

  .\32xl\:inset-y-xs {
    top: 32rem;
    bottom: 32rem
  }

  .\32xl\:inset-y-sm {
    top: 48rem;
    bottom: 48rem
  }

  .\32xl\:inset-y-md {
    top: 64rem;
    bottom: 64rem
  }

  .\32xl\:inset-y-lg {
    top: 80rem;
    bottom: 80rem
  }

  .\32xl\:inset-y-xl {
    top: 96rem;
    bottom: 96rem
  }

  .\32xl\:inset-y-2xl {
    top: 112rem;
    bottom: 112rem
  }

  .\32xl\:inset-y-3xl {
    top: 128rem;
    bottom: 128rem
  }

  .\32xl\:inset-y-4xl {
    top: 144rem;
    bottom: 144rem
  }

  .\32xl\:inset-y-5xl {
    top: 160rem;
    bottom: 160rem
  }

  .\32xl\:-inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem
  }

  .\32xl\:-inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem
  }

  .\32xl\:-inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem
  }

  .\32xl\:-inset-y-4 {
    top: -0.4rem;
    bottom: -0.4rem
  }

  .\32xl\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem
  }

  .\32xl\:-inset-y-6 {
    top: -0.6rem;
    bottom: -0.6rem
  }

  .\32xl\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem
  }

  .\32xl\:-inset-y-8 {
    top: -0.8rem;
    bottom: -0.8rem
  }

  .\32xl\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem
  }

  .\32xl\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem
  }

  .\32xl\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem
  }

  .\32xl\:-inset-y-12 {
    top: -1.2rem;
    bottom: -1.2rem
  }

  .\32xl\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem
  }

  .\32xl\:-inset-y-16 {
    top: -1.6rem;
    bottom: -1.6rem
  }

  .\32xl\:-inset-y-20 {
    top: -2rem;
    bottom: -2rem
  }

  .\32xl\:-inset-y-24 {
    top: -2.4rem;
    bottom: -2.4rem
  }

  .\32xl\:-inset-y-28 {
    top: -2.8rem;
    bottom: -2.8rem
  }

  .\32xl\:-inset-y-32 {
    top: -3.2rem;
    bottom: -3.2rem
  }

  .\32xl\:-inset-y-36 {
    top: -3.6rem;
    bottom: -3.6rem
  }

  .\32xl\:-inset-y-40 {
    top: -4rem;
    bottom: -4rem
  }

  .\32xl\:-inset-y-44 {
    top: -4.4rem;
    bottom: -4.4rem
  }

  .\32xl\:-inset-y-48 {
    top: -4.8rem;
    bottom: -4.8rem
  }

  .\32xl\:-inset-y-52 {
    top: -5.2rem;
    bottom: -5.2rem
  }

  .\32xl\:-inset-y-56 {
    top: -5.6rem;
    bottom: -5.6rem
  }

  .\32xl\:-inset-y-60 {
    top: -6rem;
    bottom: -6rem
  }

  .\32xl\:-inset-y-64 {
    top: -6.4rem;
    bottom: -6.4rem
  }

  .\32xl\:-inset-y-68 {
    top: -6.8rem;
    bottom: -6.8rem
  }

  .\32xl\:-inset-y-72 {
    top: -7.2rem;
    bottom: -7.2rem
  }

  .\32xl\:-inset-y-76 {
    top: -7.6rem;
    bottom: -7.6rem
  }

  .\32xl\:-inset-y-80 {
    top: -8rem;
    bottom: -8rem
  }

  .\32xl\:-inset-y-84 {
    top: -8.4rem;
    bottom: -8.4rem
  }

  .\32xl\:-inset-y-88 {
    top: -8.8rem;
    bottom: -8.8rem
  }

  .\32xl\:-inset-y-92 {
    top: -9.2rem;
    bottom: -9.2rem
  }

  .\32xl\:-inset-y-96 {
    top: -9.6rem;
    bottom: -9.6rem
  }

  .\32xl\:-inset-y-128 {
    top: -12.8rem;
    bottom: -12.8rem
  }

  .\32xl\:-inset-y-136 {
    top: -13.6rem;
    bottom: -13.6rem
  }

  .\32xl\:-inset-y-160 {
    top: -16rem;
    bottom: -16rem
  }

  .\32xl\:-inset-y-192 {
    top: -19.2rem;
    bottom: -19.2rem
  }

  .\32xl\:-inset-y-200 {
    top: -20rem;
    bottom: -20rem
  }

  .\32xl\:-inset-y-208 {
    top: -20.8rem;
    bottom: -20.8rem
  }

  .\32xl\:-inset-y-216 {
    top: -21.6rem;
    bottom: -21.6rem
  }

  .\32xl\:-inset-y-224 {
    top: -22.4rem;
    bottom: -22.4rem
  }

  .\32xl\:-inset-y-256 {
    top: -25.6rem;
    bottom: -25.6rem
  }

  .\32xl\:-inset-y-288 {
    top: -28.8rem;
    bottom: -28.8rem
  }

  .\32xl\:-inset-y-320 {
    top: -32rem;
    bottom: -32rem
  }

  .\32xl\:-inset-y-360 {
    top: -36rem;
    bottom: -36rem
  }

  .\32xl\:-inset-y-384 {
    top: -38.4rem;
    bottom: -38.4rem
  }

  .\32xl\:-inset-y-400 {
    top: -40rem;
    bottom: -40rem
  }

  .\32xl\:-inset-y-512 {
    top: -51.2rem;
    bottom: -51.2rem
  }

  .\32xl\:-inset-y-640 {
    top: -64rem;
    bottom: -64rem
  }

  .\32xl\:-inset-y-px {
    top: -1px;
    bottom: -1px
  }

  .\32xl\:-inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem
  }

  .\32xl\:-inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem
  }

  .\32xl\:-inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem
  }

  .\32xl\:-inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem
  }

  .\32xl\:-inset-y-xs {
    top: -32rem;
    bottom: -32rem
  }

  .\32xl\:-inset-y-sm {
    top: -48rem;
    bottom: -48rem
  }

  .\32xl\:-inset-y-md {
    top: -64rem;
    bottom: -64rem
  }

  .\32xl\:-inset-y-lg {
    top: -80rem;
    bottom: -80rem
  }

  .\32xl\:-inset-y-xl {
    top: -96rem;
    bottom: -96rem
  }

  .\32xl\:-inset-y-2xl {
    top: -112rem;
    bottom: -112rem
  }

  .\32xl\:-inset-y-3xl {
    top: -128rem;
    bottom: -128rem
  }

  .\32xl\:-inset-y-4xl {
    top: -144rem;
    bottom: -144rem
  }

  .\32xl\:-inset-y-5xl {
    top: -160rem;
    bottom: -160rem
  }

  .\32xl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%
  }

  .\32xl\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%
  }

  .\32xl\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%
  }

  .\32xl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%
  }

  .\32xl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%
  }

  .\32xl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%
  }

  .\32xl\:inset-y-full {
    top: 100%;
    bottom: 100%
  }

  .\32xl\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%
  }

  .\32xl\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%
  }

  .\32xl\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%
  }

  .\32xl\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%
  }

  .\32xl\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%
  }

  .\32xl\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%
  }

  .\32xl\:-inset-y-full {
    top: -100%;
    bottom: -100%
  }

  .\32xl\:top-0 {
    top: 0
  }

  .\32xl\:top-1 {
    top: 0.25rem
  }

  .\32xl\:top-2 {
    top: 0.5rem
  }

  .\32xl\:top-3 {
    top: 0.75rem
  }

  .\32xl\:top-4 {
    top: 0.4rem
  }

  .\32xl\:top-5 {
    top: 1.25rem
  }

  .\32xl\:top-6 {
    top: 0.6rem
  }

  .\32xl\:top-7 {
    top: 1.75rem
  }

  .\32xl\:top-8 {
    top: 0.8rem
  }

  .\32xl\:top-9 {
    top: 2.25rem
  }

  .\32xl\:top-10 {
    top: 2.5rem
  }

  .\32xl\:top-11 {
    top: 2.75rem
  }

  .\32xl\:top-12 {
    top: 1.2rem
  }

  .\32xl\:top-14 {
    top: 3.5rem
  }

  .\32xl\:top-16 {
    top: 1.6rem
  }

  .\32xl\:top-20 {
    top: 2rem
  }

  .\32xl\:top-24 {
    top: 2.4rem
  }

  .\32xl\:top-28 {
    top: 2.8rem
  }

  .\32xl\:top-32 {
    top: 3.2rem
  }

  .\32xl\:top-36 {
    top: 3.6rem
  }

  .\32xl\:top-40 {
    top: 4rem
  }

  .\32xl\:top-44 {
    top: 4.4rem
  }

  .\32xl\:top-48 {
    top: 4.8rem
  }

  .\32xl\:top-52 {
    top: 5.2rem
  }

  .\32xl\:top-56 {
    top: 5.6rem
  }

  .\32xl\:top-60 {
    top: 6rem
  }

  .\32xl\:top-64 {
    top: 6.4rem
  }

  .\32xl\:top-68 {
    top: 6.8rem
  }

  .\32xl\:top-72 {
    top: 7.2rem
  }

  .\32xl\:top-76 {
    top: 7.6rem
  }

  .\32xl\:top-80 {
    top: 8rem
  }

  .\32xl\:top-84 {
    top: 8.4rem
  }

  .\32xl\:top-88 {
    top: 8.8rem
  }

  .\32xl\:top-92 {
    top: 9.2rem
  }

  .\32xl\:top-96 {
    top: 9.6rem
  }

  .\32xl\:top-128 {
    top: 12.8rem
  }

  .\32xl\:top-136 {
    top: 13.6rem
  }

  .\32xl\:top-160 {
    top: 16rem
  }

  .\32xl\:top-192 {
    top: 19.2rem
  }

  .\32xl\:top-200 {
    top: 20rem
  }

  .\32xl\:top-208 {
    top: 20.8rem
  }

  .\32xl\:top-216 {
    top: 21.6rem
  }

  .\32xl\:top-224 {
    top: 22.4rem
  }

  .\32xl\:top-256 {
    top: 25.6rem
  }

  .\32xl\:top-288 {
    top: 28.8rem
  }

  .\32xl\:top-320 {
    top: 32rem
  }

  .\32xl\:top-360 {
    top: 36rem
  }

  .\32xl\:top-384 {
    top: 38.4rem
  }

  .\32xl\:top-400 {
    top: 40rem
  }

  .\32xl\:top-512 {
    top: 51.2rem
  }

  .\32xl\:top-640 {
    top: 64rem
  }

  .\32xl\:top-auto {
    top: auto
  }

  .\32xl\:top-px {
    top: 1px
  }

  .\32xl\:top-0\.5 {
    top: 0.125rem
  }

  .\32xl\:top-1\.5 {
    top: 0.375rem
  }

  .\32xl\:top-2\.5 {
    top: 0.625rem
  }

  .\32xl\:top-3\.5 {
    top: 0.875rem
  }

  .\32xl\:top-xs {
    top: 32rem
  }

  .\32xl\:top-sm {
    top: 48rem
  }

  .\32xl\:top-md {
    top: 64rem
  }

  .\32xl\:top-lg {
    top: 80rem
  }

  .\32xl\:top-xl {
    top: 96rem
  }

  .\32xl\:top-2xl {
    top: 112rem
  }

  .\32xl\:top-3xl {
    top: 128rem
  }

  .\32xl\:top-4xl {
    top: 144rem
  }

  .\32xl\:top-5xl {
    top: 160rem
  }

  .\32xl\:-top-1 {
    top: -0.25rem
  }

  .\32xl\:-top-2 {
    top: -0.5rem
  }

  .\32xl\:-top-3 {
    top: -0.75rem
  }

  .\32xl\:-top-4 {
    top: -0.4rem
  }

  .\32xl\:-top-5 {
    top: -1.25rem
  }

  .\32xl\:-top-6 {
    top: -0.6rem
  }

  .\32xl\:-top-7 {
    top: -1.75rem
  }

  .\32xl\:-top-8 {
    top: -0.8rem
  }

  .\32xl\:-top-9 {
    top: -2.25rem
  }

  .\32xl\:-top-10 {
    top: -2.5rem
  }

  .\32xl\:-top-11 {
    top: -2.75rem
  }

  .\32xl\:-top-12 {
    top: -1.2rem
  }

  .\32xl\:-top-14 {
    top: -3.5rem
  }

  .\32xl\:-top-16 {
    top: -1.6rem
  }

  .\32xl\:-top-20 {
    top: -2rem
  }

  .\32xl\:-top-24 {
    top: -2.4rem
  }

  .\32xl\:-top-28 {
    top: -2.8rem
  }

  .\32xl\:-top-32 {
    top: -3.2rem
  }

  .\32xl\:-top-36 {
    top: -3.6rem
  }

  .\32xl\:-top-40 {
    top: -4rem
  }

  .\32xl\:-top-44 {
    top: -4.4rem
  }

  .\32xl\:-top-48 {
    top: -4.8rem
  }

  .\32xl\:-top-52 {
    top: -5.2rem
  }

  .\32xl\:-top-56 {
    top: -5.6rem
  }

  .\32xl\:-top-60 {
    top: -6rem
  }

  .\32xl\:-top-64 {
    top: -6.4rem
  }

  .\32xl\:-top-68 {
    top: -6.8rem
  }

  .\32xl\:-top-72 {
    top: -7.2rem
  }

  .\32xl\:-top-76 {
    top: -7.6rem
  }

  .\32xl\:-top-80 {
    top: -8rem
  }

  .\32xl\:-top-84 {
    top: -8.4rem
  }

  .\32xl\:-top-88 {
    top: -8.8rem
  }

  .\32xl\:-top-92 {
    top: -9.2rem
  }

  .\32xl\:-top-96 {
    top: -9.6rem
  }

  .\32xl\:-top-128 {
    top: -12.8rem
  }

  .\32xl\:-top-136 {
    top: -13.6rem
  }

  .\32xl\:-top-160 {
    top: -16rem
  }

  .\32xl\:-top-192 {
    top: -19.2rem
  }

  .\32xl\:-top-200 {
    top: -20rem
  }

  .\32xl\:-top-208 {
    top: -20.8rem
  }

  .\32xl\:-top-216 {
    top: -21.6rem
  }

  .\32xl\:-top-224 {
    top: -22.4rem
  }

  .\32xl\:-top-256 {
    top: -25.6rem
  }

  .\32xl\:-top-288 {
    top: -28.8rem
  }

  .\32xl\:-top-320 {
    top: -32rem
  }

  .\32xl\:-top-360 {
    top: -36rem
  }

  .\32xl\:-top-384 {
    top: -38.4rem
  }

  .\32xl\:-top-400 {
    top: -40rem
  }

  .\32xl\:-top-512 {
    top: -51.2rem
  }

  .\32xl\:-top-640 {
    top: -64rem
  }

  .\32xl\:-top-px {
    top: -1px
  }

  .\32xl\:-top-0\.5 {
    top: -0.125rem
  }

  .\32xl\:-top-1\.5 {
    top: -0.375rem
  }

  .\32xl\:-top-2\.5 {
    top: -0.625rem
  }

  .\32xl\:-top-3\.5 {
    top: -0.875rem
  }

  .\32xl\:-top-xs {
    top: -32rem
  }

  .\32xl\:-top-sm {
    top: -48rem
  }

  .\32xl\:-top-md {
    top: -64rem
  }

  .\32xl\:-top-lg {
    top: -80rem
  }

  .\32xl\:-top-xl {
    top: -96rem
  }

  .\32xl\:-top-2xl {
    top: -112rem
  }

  .\32xl\:-top-3xl {
    top: -128rem
  }

  .\32xl\:-top-4xl {
    top: -144rem
  }

  .\32xl\:-top-5xl {
    top: -160rem
  }

  .\32xl\:top-1\/2 {
    top: 50%
  }

  .\32xl\:top-1\/3 {
    top: 33.333333%
  }

  .\32xl\:top-2\/3 {
    top: 66.666667%
  }

  .\32xl\:top-1\/4 {
    top: 25%
  }

  .\32xl\:top-2\/4 {
    top: 50%
  }

  .\32xl\:top-3\/4 {
    top: 75%
  }

  .\32xl\:top-full {
    top: 100%
  }

  .\32xl\:-top-1\/2 {
    top: -50%
  }

  .\32xl\:-top-1\/3 {
    top: -33.333333%
  }

  .\32xl\:-top-2\/3 {
    top: -66.666667%
  }

  .\32xl\:-top-1\/4 {
    top: -25%
  }

  .\32xl\:-top-2\/4 {
    top: -50%
  }

  .\32xl\:-top-3\/4 {
    top: -75%
  }

  .\32xl\:-top-full {
    top: -100%
  }

  .\32xl\:right-0 {
    right: 0
  }

  .\32xl\:right-1 {
    right: 0.25rem
  }

  .\32xl\:right-2 {
    right: 0.5rem
  }

  .\32xl\:right-3 {
    right: 0.75rem
  }

  .\32xl\:right-4 {
    right: 0.4rem
  }

  .\32xl\:right-5 {
    right: 1.25rem
  }

  .\32xl\:right-6 {
    right: 0.6rem
  }

  .\32xl\:right-7 {
    right: 1.75rem
  }

  .\32xl\:right-8 {
    right: 0.8rem
  }

  .\32xl\:right-9 {
    right: 2.25rem
  }

  .\32xl\:right-10 {
    right: 2.5rem
  }

  .\32xl\:right-11 {
    right: 2.75rem
  }

  .\32xl\:right-12 {
    right: 1.2rem
  }

  .\32xl\:right-14 {
    right: 3.5rem
  }

  .\32xl\:right-16 {
    right: 1.6rem
  }

  .\32xl\:right-20 {
    right: 2rem
  }

  .\32xl\:right-24 {
    right: 2.4rem
  }

  .\32xl\:right-28 {
    right: 2.8rem
  }

  .\32xl\:right-32 {
    right: 3.2rem
  }

  .\32xl\:right-36 {
    right: 3.6rem
  }

  .\32xl\:right-40 {
    right: 4rem
  }

  .\32xl\:right-44 {
    right: 4.4rem
  }

  .\32xl\:right-48 {
    right: 4.8rem
  }

  .\32xl\:right-52 {
    right: 5.2rem
  }

  .\32xl\:right-56 {
    right: 5.6rem
  }

  .\32xl\:right-60 {
    right: 6rem
  }

  .\32xl\:right-64 {
    right: 6.4rem
  }

  .\32xl\:right-68 {
    right: 6.8rem
  }

  .\32xl\:right-72 {
    right: 7.2rem
  }

  .\32xl\:right-76 {
    right: 7.6rem
  }

  .\32xl\:right-80 {
    right: 8rem
  }

  .\32xl\:right-84 {
    right: 8.4rem
  }

  .\32xl\:right-88 {
    right: 8.8rem
  }

  .\32xl\:right-92 {
    right: 9.2rem
  }

  .\32xl\:right-96 {
    right: 9.6rem
  }

  .\32xl\:right-128 {
    right: 12.8rem
  }

  .\32xl\:right-136 {
    right: 13.6rem
  }

  .\32xl\:right-160 {
    right: 16rem
  }

  .\32xl\:right-192 {
    right: 19.2rem
  }

  .\32xl\:right-200 {
    right: 20rem
  }

  .\32xl\:right-208 {
    right: 20.8rem
  }

  .\32xl\:right-216 {
    right: 21.6rem
  }

  .\32xl\:right-224 {
    right: 22.4rem
  }

  .\32xl\:right-256 {
    right: 25.6rem
  }

  .\32xl\:right-288 {
    right: 28.8rem
  }

  .\32xl\:right-320 {
    right: 32rem
  }

  .\32xl\:right-360 {
    right: 36rem
  }

  .\32xl\:right-384 {
    right: 38.4rem
  }

  .\32xl\:right-400 {
    right: 40rem
  }

  .\32xl\:right-512 {
    right: 51.2rem
  }

  .\32xl\:right-640 {
    right: 64rem
  }

  .\32xl\:right-auto {
    right: auto
  }

  .\32xl\:right-px {
    right: 1px
  }

  .\32xl\:right-0\.5 {
    right: 0.125rem
  }

  .\32xl\:right-1\.5 {
    right: 0.375rem
  }

  .\32xl\:right-2\.5 {
    right: 0.625rem
  }

  .\32xl\:right-3\.5 {
    right: 0.875rem
  }

  .\32xl\:right-xs {
    right: 32rem
  }

  .\32xl\:right-sm {
    right: 48rem
  }

  .\32xl\:right-md {
    right: 64rem
  }

  .\32xl\:right-lg {
    right: 80rem
  }

  .\32xl\:right-xl {
    right: 96rem
  }

  .\32xl\:right-2xl {
    right: 112rem
  }

  .\32xl\:right-3xl {
    right: 128rem
  }

  .\32xl\:right-4xl {
    right: 144rem
  }

  .\32xl\:right-5xl {
    right: 160rem
  }

  .\32xl\:-right-1 {
    right: -0.25rem
  }

  .\32xl\:-right-2 {
    right: -0.5rem
  }

  .\32xl\:-right-3 {
    right: -0.75rem
  }

  .\32xl\:-right-4 {
    right: -0.4rem
  }

  .\32xl\:-right-5 {
    right: -1.25rem
  }

  .\32xl\:-right-6 {
    right: -0.6rem
  }

  .\32xl\:-right-7 {
    right: -1.75rem
  }

  .\32xl\:-right-8 {
    right: -0.8rem
  }

  .\32xl\:-right-9 {
    right: -2.25rem
  }

  .\32xl\:-right-10 {
    right: -2.5rem
  }

  .\32xl\:-right-11 {
    right: -2.75rem
  }

  .\32xl\:-right-12 {
    right: -1.2rem
  }

  .\32xl\:-right-14 {
    right: -3.5rem
  }

  .\32xl\:-right-16 {
    right: -1.6rem
  }

  .\32xl\:-right-20 {
    right: -2rem
  }

  .\32xl\:-right-24 {
    right: -2.4rem
  }

  .\32xl\:-right-28 {
    right: -2.8rem
  }

  .\32xl\:-right-32 {
    right: -3.2rem
  }

  .\32xl\:-right-36 {
    right: -3.6rem
  }

  .\32xl\:-right-40 {
    right: -4rem
  }

  .\32xl\:-right-44 {
    right: -4.4rem
  }

  .\32xl\:-right-48 {
    right: -4.8rem
  }

  .\32xl\:-right-52 {
    right: -5.2rem
  }

  .\32xl\:-right-56 {
    right: -5.6rem
  }

  .\32xl\:-right-60 {
    right: -6rem
  }

  .\32xl\:-right-64 {
    right: -6.4rem
  }

  .\32xl\:-right-68 {
    right: -6.8rem
  }

  .\32xl\:-right-72 {
    right: -7.2rem
  }

  .\32xl\:-right-76 {
    right: -7.6rem
  }

  .\32xl\:-right-80 {
    right: -8rem
  }

  .\32xl\:-right-84 {
    right: -8.4rem
  }

  .\32xl\:-right-88 {
    right: -8.8rem
  }

  .\32xl\:-right-92 {
    right: -9.2rem
  }

  .\32xl\:-right-96 {
    right: -9.6rem
  }

  .\32xl\:-right-128 {
    right: -12.8rem
  }

  .\32xl\:-right-136 {
    right: -13.6rem
  }

  .\32xl\:-right-160 {
    right: -16rem
  }

  .\32xl\:-right-192 {
    right: -19.2rem
  }

  .\32xl\:-right-200 {
    right: -20rem
  }

  .\32xl\:-right-208 {
    right: -20.8rem
  }

  .\32xl\:-right-216 {
    right: -21.6rem
  }

  .\32xl\:-right-224 {
    right: -22.4rem
  }

  .\32xl\:-right-256 {
    right: -25.6rem
  }

  .\32xl\:-right-288 {
    right: -28.8rem
  }

  .\32xl\:-right-320 {
    right: -32rem
  }

  .\32xl\:-right-360 {
    right: -36rem
  }

  .\32xl\:-right-384 {
    right: -38.4rem
  }

  .\32xl\:-right-400 {
    right: -40rem
  }

  .\32xl\:-right-512 {
    right: -51.2rem
  }

  .\32xl\:-right-640 {
    right: -64rem
  }

  .\32xl\:-right-px {
    right: -1px
  }

  .\32xl\:-right-0\.5 {
    right: -0.125rem
  }

  .\32xl\:-right-1\.5 {
    right: -0.375rem
  }

  .\32xl\:-right-2\.5 {
    right: -0.625rem
  }

  .\32xl\:-right-3\.5 {
    right: -0.875rem
  }

  .\32xl\:-right-xs {
    right: -32rem
  }

  .\32xl\:-right-sm {
    right: -48rem
  }

  .\32xl\:-right-md {
    right: -64rem
  }

  .\32xl\:-right-lg {
    right: -80rem
  }

  .\32xl\:-right-xl {
    right: -96rem
  }

  .\32xl\:-right-2xl {
    right: -112rem
  }

  .\32xl\:-right-3xl {
    right: -128rem
  }

  .\32xl\:-right-4xl {
    right: -144rem
  }

  .\32xl\:-right-5xl {
    right: -160rem
  }

  .\32xl\:right-1\/2 {
    right: 50%
  }

  .\32xl\:right-1\/3 {
    right: 33.333333%
  }

  .\32xl\:right-2\/3 {
    right: 66.666667%
  }

  .\32xl\:right-1\/4 {
    right: 25%
  }

  .\32xl\:right-2\/4 {
    right: 50%
  }

  .\32xl\:right-3\/4 {
    right: 75%
  }

  .\32xl\:right-full {
    right: 100%
  }

  .\32xl\:-right-1\/2 {
    right: -50%
  }

  .\32xl\:-right-1\/3 {
    right: -33.333333%
  }

  .\32xl\:-right-2\/3 {
    right: -66.666667%
  }

  .\32xl\:-right-1\/4 {
    right: -25%
  }

  .\32xl\:-right-2\/4 {
    right: -50%
  }

  .\32xl\:-right-3\/4 {
    right: -75%
  }

  .\32xl\:-right-full {
    right: -100%
  }

  .\32xl\:bottom-0 {
    bottom: 0
  }

  .\32xl\:bottom-1 {
    bottom: 0.25rem
  }

  .\32xl\:bottom-2 {
    bottom: 0.5rem
  }

  .\32xl\:bottom-3 {
    bottom: 0.75rem
  }

  .\32xl\:bottom-4 {
    bottom: 0.4rem
  }

  .\32xl\:bottom-5 {
    bottom: 1.25rem
  }

  .\32xl\:bottom-6 {
    bottom: 0.6rem
  }

  .\32xl\:bottom-7 {
    bottom: 1.75rem
  }

  .\32xl\:bottom-8 {
    bottom: 0.8rem
  }

  .\32xl\:bottom-9 {
    bottom: 2.25rem
  }

  .\32xl\:bottom-10 {
    bottom: 2.5rem
  }

  .\32xl\:bottom-11 {
    bottom: 2.75rem
  }

  .\32xl\:bottom-12 {
    bottom: 1.2rem
  }

  .\32xl\:bottom-14 {
    bottom: 3.5rem
  }

  .\32xl\:bottom-16 {
    bottom: 1.6rem
  }

  .\32xl\:bottom-20 {
    bottom: 2rem
  }

  .\32xl\:bottom-24 {
    bottom: 2.4rem
  }

  .\32xl\:bottom-28 {
    bottom: 2.8rem
  }

  .\32xl\:bottom-32 {
    bottom: 3.2rem
  }

  .\32xl\:bottom-36 {
    bottom: 3.6rem
  }

  .\32xl\:bottom-40 {
    bottom: 4rem
  }

  .\32xl\:bottom-44 {
    bottom: 4.4rem
  }

  .\32xl\:bottom-48 {
    bottom: 4.8rem
  }

  .\32xl\:bottom-52 {
    bottom: 5.2rem
  }

  .\32xl\:bottom-56 {
    bottom: 5.6rem
  }

  .\32xl\:bottom-60 {
    bottom: 6rem
  }

  .\32xl\:bottom-64 {
    bottom: 6.4rem
  }

  .\32xl\:bottom-68 {
    bottom: 6.8rem
  }

  .\32xl\:bottom-72 {
    bottom: 7.2rem
  }

  .\32xl\:bottom-76 {
    bottom: 7.6rem
  }

  .\32xl\:bottom-80 {
    bottom: 8rem
  }

  .\32xl\:bottom-84 {
    bottom: 8.4rem
  }

  .\32xl\:bottom-88 {
    bottom: 8.8rem
  }

  .\32xl\:bottom-92 {
    bottom: 9.2rem
  }

  .\32xl\:bottom-96 {
    bottom: 9.6rem
  }

  .\32xl\:bottom-128 {
    bottom: 12.8rem
  }

  .\32xl\:bottom-136 {
    bottom: 13.6rem
  }

  .\32xl\:bottom-160 {
    bottom: 16rem
  }

  .\32xl\:bottom-192 {
    bottom: 19.2rem
  }

  .\32xl\:bottom-200 {
    bottom: 20rem
  }

  .\32xl\:bottom-208 {
    bottom: 20.8rem
  }

  .\32xl\:bottom-216 {
    bottom: 21.6rem
  }

  .\32xl\:bottom-224 {
    bottom: 22.4rem
  }

  .\32xl\:bottom-256 {
    bottom: 25.6rem
  }

  .\32xl\:bottom-288 {
    bottom: 28.8rem
  }

  .\32xl\:bottom-320 {
    bottom: 32rem
  }

  .\32xl\:bottom-360 {
    bottom: 36rem
  }

  .\32xl\:bottom-384 {
    bottom: 38.4rem
  }

  .\32xl\:bottom-400 {
    bottom: 40rem
  }

  .\32xl\:bottom-512 {
    bottom: 51.2rem
  }

  .\32xl\:bottom-640 {
    bottom: 64rem
  }

  .\32xl\:bottom-auto {
    bottom: auto
  }

  .\32xl\:bottom-px {
    bottom: 1px
  }

  .\32xl\:bottom-0\.5 {
    bottom: 0.125rem
  }

  .\32xl\:bottom-1\.5 {
    bottom: 0.375rem
  }

  .\32xl\:bottom-2\.5 {
    bottom: 0.625rem
  }

  .\32xl\:bottom-3\.5 {
    bottom: 0.875rem
  }

  .\32xl\:bottom-xs {
    bottom: 32rem
  }

  .\32xl\:bottom-sm {
    bottom: 48rem
  }

  .\32xl\:bottom-md {
    bottom: 64rem
  }

  .\32xl\:bottom-lg {
    bottom: 80rem
  }

  .\32xl\:bottom-xl {
    bottom: 96rem
  }

  .\32xl\:bottom-2xl {
    bottom: 112rem
  }

  .\32xl\:bottom-3xl {
    bottom: 128rem
  }

  .\32xl\:bottom-4xl {
    bottom: 144rem
  }

  .\32xl\:bottom-5xl {
    bottom: 160rem
  }

  .\32xl\:-bottom-1 {
    bottom: -0.25rem
  }

  .\32xl\:-bottom-2 {
    bottom: -0.5rem
  }

  .\32xl\:-bottom-3 {
    bottom: -0.75rem
  }

  .\32xl\:-bottom-4 {
    bottom: -0.4rem
  }

  .\32xl\:-bottom-5 {
    bottom: -1.25rem
  }

  .\32xl\:-bottom-6 {
    bottom: -0.6rem
  }

  .\32xl\:-bottom-7 {
    bottom: -1.75rem
  }

  .\32xl\:-bottom-8 {
    bottom: -0.8rem
  }

  .\32xl\:-bottom-9 {
    bottom: -2.25rem
  }

  .\32xl\:-bottom-10 {
    bottom: -2.5rem
  }

  .\32xl\:-bottom-11 {
    bottom: -2.75rem
  }

  .\32xl\:-bottom-12 {
    bottom: -1.2rem
  }

  .\32xl\:-bottom-14 {
    bottom: -3.5rem
  }

  .\32xl\:-bottom-16 {
    bottom: -1.6rem
  }

  .\32xl\:-bottom-20 {
    bottom: -2rem
  }

  .\32xl\:-bottom-24 {
    bottom: -2.4rem
  }

  .\32xl\:-bottom-28 {
    bottom: -2.8rem
  }

  .\32xl\:-bottom-32 {
    bottom: -3.2rem
  }

  .\32xl\:-bottom-36 {
    bottom: -3.6rem
  }

  .\32xl\:-bottom-40 {
    bottom: -4rem
  }

  .\32xl\:-bottom-44 {
    bottom: -4.4rem
  }

  .\32xl\:-bottom-48 {
    bottom: -4.8rem
  }

  .\32xl\:-bottom-52 {
    bottom: -5.2rem
  }

  .\32xl\:-bottom-56 {
    bottom: -5.6rem
  }

  .\32xl\:-bottom-60 {
    bottom: -6rem
  }

  .\32xl\:-bottom-64 {
    bottom: -6.4rem
  }

  .\32xl\:-bottom-68 {
    bottom: -6.8rem
  }

  .\32xl\:-bottom-72 {
    bottom: -7.2rem
  }

  .\32xl\:-bottom-76 {
    bottom: -7.6rem
  }

  .\32xl\:-bottom-80 {
    bottom: -8rem
  }

  .\32xl\:-bottom-84 {
    bottom: -8.4rem
  }

  .\32xl\:-bottom-88 {
    bottom: -8.8rem
  }

  .\32xl\:-bottom-92 {
    bottom: -9.2rem
  }

  .\32xl\:-bottom-96 {
    bottom: -9.6rem
  }

  .\32xl\:-bottom-128 {
    bottom: -12.8rem
  }

  .\32xl\:-bottom-136 {
    bottom: -13.6rem
  }

  .\32xl\:-bottom-160 {
    bottom: -16rem
  }

  .\32xl\:-bottom-192 {
    bottom: -19.2rem
  }

  .\32xl\:-bottom-200 {
    bottom: -20rem
  }

  .\32xl\:-bottom-208 {
    bottom: -20.8rem
  }

  .\32xl\:-bottom-216 {
    bottom: -21.6rem
  }

  .\32xl\:-bottom-224 {
    bottom: -22.4rem
  }

  .\32xl\:-bottom-256 {
    bottom: -25.6rem
  }

  .\32xl\:-bottom-288 {
    bottom: -28.8rem
  }

  .\32xl\:-bottom-320 {
    bottom: -32rem
  }

  .\32xl\:-bottom-360 {
    bottom: -36rem
  }

  .\32xl\:-bottom-384 {
    bottom: -38.4rem
  }

  .\32xl\:-bottom-400 {
    bottom: -40rem
  }

  .\32xl\:-bottom-512 {
    bottom: -51.2rem
  }

  .\32xl\:-bottom-640 {
    bottom: -64rem
  }

  .\32xl\:-bottom-px {
    bottom: -1px
  }

  .\32xl\:-bottom-0\.5 {
    bottom: -0.125rem
  }

  .\32xl\:-bottom-1\.5 {
    bottom: -0.375rem
  }

  .\32xl\:-bottom-2\.5 {
    bottom: -0.625rem
  }

  .\32xl\:-bottom-3\.5 {
    bottom: -0.875rem
  }

  .\32xl\:-bottom-xs {
    bottom: -32rem
  }

  .\32xl\:-bottom-sm {
    bottom: -48rem
  }

  .\32xl\:-bottom-md {
    bottom: -64rem
  }

  .\32xl\:-bottom-lg {
    bottom: -80rem
  }

  .\32xl\:-bottom-xl {
    bottom: -96rem
  }

  .\32xl\:-bottom-2xl {
    bottom: -112rem
  }

  .\32xl\:-bottom-3xl {
    bottom: -128rem
  }

  .\32xl\:-bottom-4xl {
    bottom: -144rem
  }

  .\32xl\:-bottom-5xl {
    bottom: -160rem
  }

  .\32xl\:bottom-1\/2 {
    bottom: 50%
  }

  .\32xl\:bottom-1\/3 {
    bottom: 33.333333%
  }

  .\32xl\:bottom-2\/3 {
    bottom: 66.666667%
  }

  .\32xl\:bottom-1\/4 {
    bottom: 25%
  }

  .\32xl\:bottom-2\/4 {
    bottom: 50%
  }

  .\32xl\:bottom-3\/4 {
    bottom: 75%
  }

  .\32xl\:bottom-full {
    bottom: 100%
  }

  .\32xl\:-bottom-1\/2 {
    bottom: -50%
  }

  .\32xl\:-bottom-1\/3 {
    bottom: -33.333333%
  }

  .\32xl\:-bottom-2\/3 {
    bottom: -66.666667%
  }

  .\32xl\:-bottom-1\/4 {
    bottom: -25%
  }

  .\32xl\:-bottom-2\/4 {
    bottom: -50%
  }

  .\32xl\:-bottom-3\/4 {
    bottom: -75%
  }

  .\32xl\:-bottom-full {
    bottom: -100%
  }

  .\32xl\:left-0 {
    left: 0
  }

  .\32xl\:left-1 {
    left: 0.25rem
  }

  .\32xl\:left-2 {
    left: 0.5rem
  }

  .\32xl\:left-3 {
    left: 0.75rem
  }

  .\32xl\:left-4 {
    left: 0.4rem
  }

  .\32xl\:left-5 {
    left: 1.25rem
  }

  .\32xl\:left-6 {
    left: 0.6rem
  }

  .\32xl\:left-7 {
    left: 1.75rem
  }

  .\32xl\:left-8 {
    left: 0.8rem
  }

  .\32xl\:left-9 {
    left: 2.25rem
  }

  .\32xl\:left-10 {
    left: 2.5rem
  }

  .\32xl\:left-11 {
    left: 2.75rem
  }

  .\32xl\:left-12 {
    left: 1.2rem
  }

  .\32xl\:left-14 {
    left: 3.5rem
  }

  .\32xl\:left-16 {
    left: 1.6rem
  }

  .\32xl\:left-20 {
    left: 2rem
  }

  .\32xl\:left-24 {
    left: 2.4rem
  }

  .\32xl\:left-28 {
    left: 2.8rem
  }

  .\32xl\:left-32 {
    left: 3.2rem
  }

  .\32xl\:left-36 {
    left: 3.6rem
  }

  .\32xl\:left-40 {
    left: 4rem
  }

  .\32xl\:left-44 {
    left: 4.4rem
  }

  .\32xl\:left-48 {
    left: 4.8rem
  }

  .\32xl\:left-52 {
    left: 5.2rem
  }

  .\32xl\:left-56 {
    left: 5.6rem
  }

  .\32xl\:left-60 {
    left: 6rem
  }

  .\32xl\:left-64 {
    left: 6.4rem
  }

  .\32xl\:left-68 {
    left: 6.8rem
  }

  .\32xl\:left-72 {
    left: 7.2rem
  }

  .\32xl\:left-76 {
    left: 7.6rem
  }

  .\32xl\:left-80 {
    left: 8rem
  }

  .\32xl\:left-84 {
    left: 8.4rem
  }

  .\32xl\:left-88 {
    left: 8.8rem
  }

  .\32xl\:left-92 {
    left: 9.2rem
  }

  .\32xl\:left-96 {
    left: 9.6rem
  }

  .\32xl\:left-128 {
    left: 12.8rem
  }

  .\32xl\:left-136 {
    left: 13.6rem
  }

  .\32xl\:left-160 {
    left: 16rem
  }

  .\32xl\:left-192 {
    left: 19.2rem
  }

  .\32xl\:left-200 {
    left: 20rem
  }

  .\32xl\:left-208 {
    left: 20.8rem
  }

  .\32xl\:left-216 {
    left: 21.6rem
  }

  .\32xl\:left-224 {
    left: 22.4rem
  }

  .\32xl\:left-256 {
    left: 25.6rem
  }

  .\32xl\:left-288 {
    left: 28.8rem
  }

  .\32xl\:left-320 {
    left: 32rem
  }

  .\32xl\:left-360 {
    left: 36rem
  }

  .\32xl\:left-384 {
    left: 38.4rem
  }

  .\32xl\:left-400 {
    left: 40rem
  }

  .\32xl\:left-512 {
    left: 51.2rem
  }

  .\32xl\:left-640 {
    left: 64rem
  }

  .\32xl\:left-auto {
    left: auto
  }

  .\32xl\:left-px {
    left: 1px
  }

  .\32xl\:left-0\.5 {
    left: 0.125rem
  }

  .\32xl\:left-1\.5 {
    left: 0.375rem
  }

  .\32xl\:left-2\.5 {
    left: 0.625rem
  }

  .\32xl\:left-3\.5 {
    left: 0.875rem
  }

  .\32xl\:left-xs {
    left: 32rem
  }

  .\32xl\:left-sm {
    left: 48rem
  }

  .\32xl\:left-md {
    left: 64rem
  }

  .\32xl\:left-lg {
    left: 80rem
  }

  .\32xl\:left-xl {
    left: 96rem
  }

  .\32xl\:left-2xl {
    left: 112rem
  }

  .\32xl\:left-3xl {
    left: 128rem
  }

  .\32xl\:left-4xl {
    left: 144rem
  }

  .\32xl\:left-5xl {
    left: 160rem
  }

  .\32xl\:-left-1 {
    left: -0.25rem
  }

  .\32xl\:-left-2 {
    left: -0.5rem
  }

  .\32xl\:-left-3 {
    left: -0.75rem
  }

  .\32xl\:-left-4 {
    left: -0.4rem
  }

  .\32xl\:-left-5 {
    left: -1.25rem
  }

  .\32xl\:-left-6 {
    left: -0.6rem
  }

  .\32xl\:-left-7 {
    left: -1.75rem
  }

  .\32xl\:-left-8 {
    left: -0.8rem
  }

  .\32xl\:-left-9 {
    left: -2.25rem
  }

  .\32xl\:-left-10 {
    left: -2.5rem
  }

  .\32xl\:-left-11 {
    left: -2.75rem
  }

  .\32xl\:-left-12 {
    left: -1.2rem
  }

  .\32xl\:-left-14 {
    left: -3.5rem
  }

  .\32xl\:-left-16 {
    left: -1.6rem
  }

  .\32xl\:-left-20 {
    left: -2rem
  }

  .\32xl\:-left-24 {
    left: -2.4rem
  }

  .\32xl\:-left-28 {
    left: -2.8rem
  }

  .\32xl\:-left-32 {
    left: -3.2rem
  }

  .\32xl\:-left-36 {
    left: -3.6rem
  }

  .\32xl\:-left-40 {
    left: -4rem
  }

  .\32xl\:-left-44 {
    left: -4.4rem
  }

  .\32xl\:-left-48 {
    left: -4.8rem
  }

  .\32xl\:-left-52 {
    left: -5.2rem
  }

  .\32xl\:-left-56 {
    left: -5.6rem
  }

  .\32xl\:-left-60 {
    left: -6rem
  }

  .\32xl\:-left-64 {
    left: -6.4rem
  }

  .\32xl\:-left-68 {
    left: -6.8rem
  }

  .\32xl\:-left-72 {
    left: -7.2rem
  }

  .\32xl\:-left-76 {
    left: -7.6rem
  }

  .\32xl\:-left-80 {
    left: -8rem
  }

  .\32xl\:-left-84 {
    left: -8.4rem
  }

  .\32xl\:-left-88 {
    left: -8.8rem
  }

  .\32xl\:-left-92 {
    left: -9.2rem
  }

  .\32xl\:-left-96 {
    left: -9.6rem
  }

  .\32xl\:-left-128 {
    left: -12.8rem
  }

  .\32xl\:-left-136 {
    left: -13.6rem
  }

  .\32xl\:-left-160 {
    left: -16rem
  }

  .\32xl\:-left-192 {
    left: -19.2rem
  }

  .\32xl\:-left-200 {
    left: -20rem
  }

  .\32xl\:-left-208 {
    left: -20.8rem
  }

  .\32xl\:-left-216 {
    left: -21.6rem
  }

  .\32xl\:-left-224 {
    left: -22.4rem
  }

  .\32xl\:-left-256 {
    left: -25.6rem
  }

  .\32xl\:-left-288 {
    left: -28.8rem
  }

  .\32xl\:-left-320 {
    left: -32rem
  }

  .\32xl\:-left-360 {
    left: -36rem
  }

  .\32xl\:-left-384 {
    left: -38.4rem
  }

  .\32xl\:-left-400 {
    left: -40rem
  }

  .\32xl\:-left-512 {
    left: -51.2rem
  }

  .\32xl\:-left-640 {
    left: -64rem
  }

  .\32xl\:-left-px {
    left: -1px
  }

  .\32xl\:-left-0\.5 {
    left: -0.125rem
  }

  .\32xl\:-left-1\.5 {
    left: -0.375rem
  }

  .\32xl\:-left-2\.5 {
    left: -0.625rem
  }

  .\32xl\:-left-3\.5 {
    left: -0.875rem
  }

  .\32xl\:-left-xs {
    left: -32rem
  }

  .\32xl\:-left-sm {
    left: -48rem
  }

  .\32xl\:-left-md {
    left: -64rem
  }

  .\32xl\:-left-lg {
    left: -80rem
  }

  .\32xl\:-left-xl {
    left: -96rem
  }

  .\32xl\:-left-2xl {
    left: -112rem
  }

  .\32xl\:-left-3xl {
    left: -128rem
  }

  .\32xl\:-left-4xl {
    left: -144rem
  }

  .\32xl\:-left-5xl {
    left: -160rem
  }

  .\32xl\:left-1\/2 {
    left: 50%
  }

  .\32xl\:left-1\/3 {
    left: 33.333333%
  }

  .\32xl\:left-2\/3 {
    left: 66.666667%
  }

  .\32xl\:left-1\/4 {
    left: 25%
  }

  .\32xl\:left-2\/4 {
    left: 50%
  }

  .\32xl\:left-3\/4 {
    left: 75%
  }

  .\32xl\:left-full {
    left: 100%
  }

  .\32xl\:-left-1\/2 {
    left: -50%
  }

  .\32xl\:-left-1\/3 {
    left: -33.333333%
  }

  .\32xl\:-left-2\/3 {
    left: -66.666667%
  }

  .\32xl\:-left-1\/4 {
    left: -25%
  }

  .\32xl\:-left-2\/4 {
    left: -50%
  }

  .\32xl\:-left-3\/4 {
    left: -75%
  }

  .\32xl\:-left-full {
    left: -100%
  }

  .\32xl\:isolate {
    isolation: isolate
  }

  .\32xl\:isolation-auto {
    isolation: auto
  }

  .\32xl\:z-0 {
    z-index: 0
  }

  .\32xl\:z-10 {
    z-index: 10
  }

  .\32xl\:z-20 {
    z-index: 20
  }

  .\32xl\:z-30 {
    z-index: 30
  }

  .\32xl\:z-40 {
    z-index: 40
  }

  .\32xl\:z-50 {
    z-index: 50
  }

  .\32xl\:z-99 {
    z-index: 99
  }

  .\32xl\:z-999 {
    z-index: 999
  }

  .\32xl\:z-9999 {
    z-index: 9999
  }

  .\32xl\:z-auto {
    z-index: auto
  }

  .\32xl\:order-1 {
    order: 1
  }

  .\32xl\:order-2 {
    order: 2
  }

  .\32xl\:order-3 {
    order: 3
  }

  .\32xl\:order-4 {
    order: 4
  }

  .\32xl\:order-5 {
    order: 5
  }

  .\32xl\:order-6 {
    order: 6
  }

  .\32xl\:order-7 {
    order: 7
  }

  .\32xl\:order-8 {
    order: 8
  }

  .\32xl\:order-9 {
    order: 9
  }

  .\32xl\:order-10 {
    order: 10
  }

  .\32xl\:order-11 {
    order: 11
  }

  .\32xl\:order-12 {
    order: 12
  }

  .\32xl\:order-first {
    order: -9999
  }

  .\32xl\:order-last {
    order: 9999
  }

  .\32xl\:order-none {
    order: 0
  }

  .\32xl\:col-auto {
    grid-column: auto
  }

  .\32xl\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .\32xl\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .\32xl\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .\32xl\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .\32xl\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .\32xl\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .\32xl\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .\32xl\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .\32xl\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .\32xl\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .\32xl\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .\32xl\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .\32xl\:col-span-full {
    grid-column: 1 / -1
  }

  .\32xl\:col-start-1 {
    grid-column-start: 1
  }

  .\32xl\:col-start-2 {
    grid-column-start: 2
  }

  .\32xl\:col-start-3 {
    grid-column-start: 3
  }

  .\32xl\:col-start-4 {
    grid-column-start: 4
  }

  .\32xl\:col-start-5 {
    grid-column-start: 5
  }

  .\32xl\:col-start-6 {
    grid-column-start: 6
  }

  .\32xl\:col-start-7 {
    grid-column-start: 7
  }

  .\32xl\:col-start-8 {
    grid-column-start: 8
  }

  .\32xl\:col-start-9 {
    grid-column-start: 9
  }

  .\32xl\:col-start-10 {
    grid-column-start: 10
  }

  .\32xl\:col-start-11 {
    grid-column-start: 11
  }

  .\32xl\:col-start-12 {
    grid-column-start: 12
  }

  .\32xl\:col-start-13 {
    grid-column-start: 13
  }

  .\32xl\:col-start-auto {
    grid-column-start: auto
  }

  .\32xl\:col-end-1 {
    grid-column-end: 1
  }

  .\32xl\:col-end-2 {
    grid-column-end: 2
  }

  .\32xl\:col-end-3 {
    grid-column-end: 3
  }

  .\32xl\:col-end-4 {
    grid-column-end: 4
  }

  .\32xl\:col-end-5 {
    grid-column-end: 5
  }

  .\32xl\:col-end-6 {
    grid-column-end: 6
  }

  .\32xl\:col-end-7 {
    grid-column-end: 7
  }

  .\32xl\:col-end-8 {
    grid-column-end: 8
  }

  .\32xl\:col-end-9 {
    grid-column-end: 9
  }

  .\32xl\:col-end-10 {
    grid-column-end: 10
  }

  .\32xl\:col-end-11 {
    grid-column-end: 11
  }

  .\32xl\:col-end-12 {
    grid-column-end: 12
  }

  .\32xl\:col-end-13 {
    grid-column-end: 13
  }

  .\32xl\:col-end-auto {
    grid-column-end: auto
  }

  .\32xl\:row-auto {
    grid-row: auto
  }

  .\32xl\:row-span-1 {
    grid-row: span 1 / span 1
  }

  .\32xl\:row-span-2 {
    grid-row: span 2 / span 2
  }

  .\32xl\:row-span-3 {
    grid-row: span 3 / span 3
  }

  .\32xl\:row-span-4 {
    grid-row: span 4 / span 4
  }

  .\32xl\:row-span-5 {
    grid-row: span 5 / span 5
  }

  .\32xl\:row-span-6 {
    grid-row: span 6 / span 6
  }

  .\32xl\:row-span-full {
    grid-row: 1 / -1
  }

  .\32xl\:row-start-1 {
    grid-row-start: 1
  }

  .\32xl\:row-start-2 {
    grid-row-start: 2
  }

  .\32xl\:row-start-3 {
    grid-row-start: 3
  }

  .\32xl\:row-start-4 {
    grid-row-start: 4
  }

  .\32xl\:row-start-5 {
    grid-row-start: 5
  }

  .\32xl\:row-start-6 {
    grid-row-start: 6
  }

  .\32xl\:row-start-7 {
    grid-row-start: 7
  }

  .\32xl\:row-start-auto {
    grid-row-start: auto
  }

  .\32xl\:row-end-1 {
    grid-row-end: 1
  }

  .\32xl\:row-end-2 {
    grid-row-end: 2
  }

  .\32xl\:row-end-3 {
    grid-row-end: 3
  }

  .\32xl\:row-end-4 {
    grid-row-end: 4
  }

  .\32xl\:row-end-5 {
    grid-row-end: 5
  }

  .\32xl\:row-end-6 {
    grid-row-end: 6
  }

  .\32xl\:row-end-7 {
    grid-row-end: 7
  }

  .\32xl\:row-end-auto {
    grid-row-end: auto
  }

  .\32xl\:float-right {
    float: right
  }

  .\32xl\:float-left {
    float: left
  }

  .\32xl\:float-none {
    float: none
  }

  .\32xl\:clear-left {
    clear: left
  }

  .\32xl\:clear-right {
    clear: right
  }

  .\32xl\:clear-both {
    clear: both
  }

  .\32xl\:clear-none {
    clear: none
  }

  .\32xl\:m-0 {
    margin: 0
  }

  .\32xl\:m-1 {
    margin: 0.25rem
  }

  .\32xl\:m-2 {
    margin: 0.5rem
  }

  .\32xl\:m-3 {
    margin: 0.75rem
  }

  .\32xl\:m-4 {
    margin: 0.4rem
  }

  .\32xl\:m-5 {
    margin: 1.25rem
  }

  .\32xl\:m-6 {
    margin: 0.6rem
  }

  .\32xl\:m-7 {
    margin: 1.75rem
  }

  .\32xl\:m-8 {
    margin: 0.8rem
  }

  .\32xl\:m-9 {
    margin: 2.25rem
  }

  .\32xl\:m-10 {
    margin: 2.5rem
  }

  .\32xl\:m-11 {
    margin: 2.75rem
  }

  .\32xl\:m-12 {
    margin: 1.2rem
  }

  .\32xl\:m-14 {
    margin: 3.5rem
  }

  .\32xl\:m-16 {
    margin: 1.6rem
  }

  .\32xl\:m-20 {
    margin: 2rem
  }

  .\32xl\:m-24 {
    margin: 2.4rem
  }

  .\32xl\:m-28 {
    margin: 2.8rem
  }

  .\32xl\:m-32 {
    margin: 3.2rem
  }

  .\32xl\:m-36 {
    margin: 3.6rem
  }

  .\32xl\:m-40 {
    margin: 4rem
  }

  .\32xl\:m-44 {
    margin: 4.4rem
  }

  .\32xl\:m-48 {
    margin: 4.8rem
  }

  .\32xl\:m-52 {
    margin: 5.2rem
  }

  .\32xl\:m-56 {
    margin: 5.6rem
  }

  .\32xl\:m-60 {
    margin: 6rem
  }

  .\32xl\:m-64 {
    margin: 6.4rem
  }

  .\32xl\:m-68 {
    margin: 6.8rem
  }

  .\32xl\:m-72 {
    margin: 7.2rem
  }

  .\32xl\:m-76 {
    margin: 7.6rem
  }

  .\32xl\:m-80 {
    margin: 8rem
  }

  .\32xl\:m-84 {
    margin: 8.4rem
  }

  .\32xl\:m-88 {
    margin: 8.8rem
  }

  .\32xl\:m-92 {
    margin: 9.2rem
  }

  .\32xl\:m-96 {
    margin: 9.6rem
  }

  .\32xl\:m-128 {
    margin: 12.8rem
  }

  .\32xl\:m-136 {
    margin: 13.6rem
  }

  .\32xl\:m-160 {
    margin: 16rem
  }

  .\32xl\:m-192 {
    margin: 19.2rem
  }

  .\32xl\:m-200 {
    margin: 20rem
  }

  .\32xl\:m-208 {
    margin: 20.8rem
  }

  .\32xl\:m-216 {
    margin: 21.6rem
  }

  .\32xl\:m-224 {
    margin: 22.4rem
  }

  .\32xl\:m-256 {
    margin: 25.6rem
  }

  .\32xl\:m-288 {
    margin: 28.8rem
  }

  .\32xl\:m-320 {
    margin: 32rem
  }

  .\32xl\:m-360 {
    margin: 36rem
  }

  .\32xl\:m-384 {
    margin: 38.4rem
  }

  .\32xl\:m-400 {
    margin: 40rem
  }

  .\32xl\:m-512 {
    margin: 51.2rem
  }

  .\32xl\:m-640 {
    margin: 64rem
  }

  .\32xl\:m-auto {
    margin: auto
  }

  .\32xl\:m-px {
    margin: 1px
  }

  .\32xl\:m-0\.5 {
    margin: 0.125rem
  }

  .\32xl\:m-1\.5 {
    margin: 0.375rem
  }

  .\32xl\:m-2\.5 {
    margin: 0.625rem
  }

  .\32xl\:m-3\.5 {
    margin: 0.875rem
  }

  .\32xl\:m-xs {
    margin: 32rem
  }

  .\32xl\:m-sm {
    margin: 48rem
  }

  .\32xl\:m-md {
    margin: 64rem
  }

  .\32xl\:m-lg {
    margin: 80rem
  }

  .\32xl\:m-xl {
    margin: 96rem
  }

  .\32xl\:m-2xl {
    margin: 112rem
  }

  .\32xl\:m-3xl {
    margin: 128rem
  }

  .\32xl\:m-4xl {
    margin: 144rem
  }

  .\32xl\:m-5xl {
    margin: 160rem
  }

  .\32xl\:-m-1 {
    margin: -0.25rem
  }

  .\32xl\:-m-2 {
    margin: -0.5rem
  }

  .\32xl\:-m-3 {
    margin: -0.75rem
  }

  .\32xl\:-m-4 {
    margin: -0.4rem
  }

  .\32xl\:-m-5 {
    margin: -1.25rem
  }

  .\32xl\:-m-6 {
    margin: -0.6rem
  }

  .\32xl\:-m-7 {
    margin: -1.75rem
  }

  .\32xl\:-m-8 {
    margin: -0.8rem
  }

  .\32xl\:-m-9 {
    margin: -2.25rem
  }

  .\32xl\:-m-10 {
    margin: -2.5rem
  }

  .\32xl\:-m-11 {
    margin: -2.75rem
  }

  .\32xl\:-m-12 {
    margin: -1.2rem
  }

  .\32xl\:-m-14 {
    margin: -3.5rem
  }

  .\32xl\:-m-16 {
    margin: -1.6rem
  }

  .\32xl\:-m-20 {
    margin: -2rem
  }

  .\32xl\:-m-24 {
    margin: -2.4rem
  }

  .\32xl\:-m-28 {
    margin: -2.8rem
  }

  .\32xl\:-m-32 {
    margin: -3.2rem
  }

  .\32xl\:-m-36 {
    margin: -3.6rem
  }

  .\32xl\:-m-40 {
    margin: -4rem
  }

  .\32xl\:-m-44 {
    margin: -4.4rem
  }

  .\32xl\:-m-48 {
    margin: -4.8rem
  }

  .\32xl\:-m-52 {
    margin: -5.2rem
  }

  .\32xl\:-m-56 {
    margin: -5.6rem
  }

  .\32xl\:-m-60 {
    margin: -6rem
  }

  .\32xl\:-m-64 {
    margin: -6.4rem
  }

  .\32xl\:-m-68 {
    margin: -6.8rem
  }

  .\32xl\:-m-72 {
    margin: -7.2rem
  }

  .\32xl\:-m-76 {
    margin: -7.6rem
  }

  .\32xl\:-m-80 {
    margin: -8rem
  }

  .\32xl\:-m-84 {
    margin: -8.4rem
  }

  .\32xl\:-m-88 {
    margin: -8.8rem
  }

  .\32xl\:-m-92 {
    margin: -9.2rem
  }

  .\32xl\:-m-96 {
    margin: -9.6rem
  }

  .\32xl\:-m-128 {
    margin: -12.8rem
  }

  .\32xl\:-m-136 {
    margin: -13.6rem
  }

  .\32xl\:-m-160 {
    margin: -16rem
  }

  .\32xl\:-m-192 {
    margin: -19.2rem
  }

  .\32xl\:-m-200 {
    margin: -20rem
  }

  .\32xl\:-m-208 {
    margin: -20.8rem
  }

  .\32xl\:-m-216 {
    margin: -21.6rem
  }

  .\32xl\:-m-224 {
    margin: -22.4rem
  }

  .\32xl\:-m-256 {
    margin: -25.6rem
  }

  .\32xl\:-m-288 {
    margin: -28.8rem
  }

  .\32xl\:-m-320 {
    margin: -32rem
  }

  .\32xl\:-m-360 {
    margin: -36rem
  }

  .\32xl\:-m-384 {
    margin: -38.4rem
  }

  .\32xl\:-m-400 {
    margin: -40rem
  }

  .\32xl\:-m-512 {
    margin: -51.2rem
  }

  .\32xl\:-m-640 {
    margin: -64rem
  }

  .\32xl\:-m-px {
    margin: -1px
  }

  .\32xl\:-m-0\.5 {
    margin: -0.125rem
  }

  .\32xl\:-m-1\.5 {
    margin: -0.375rem
  }

  .\32xl\:-m-2\.5 {
    margin: -0.625rem
  }

  .\32xl\:-m-3\.5 {
    margin: -0.875rem
  }

  .\32xl\:-m-xs {
    margin: -32rem
  }

  .\32xl\:-m-sm {
    margin: -48rem
  }

  .\32xl\:-m-md {
    margin: -64rem
  }

  .\32xl\:-m-lg {
    margin: -80rem
  }

  .\32xl\:-m-xl {
    margin: -96rem
  }

  .\32xl\:-m-2xl {
    margin: -112rem
  }

  .\32xl\:-m-3xl {
    margin: -128rem
  }

  .\32xl\:-m-4xl {
    margin: -144rem
  }

  .\32xl\:-m-5xl {
    margin: -160rem
  }

  .\32xl\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .\32xl\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .\32xl\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .\32xl\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  .\32xl\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  .\32xl\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  .\32xl\:mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem
  }

  .\32xl\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem
  }

  .\32xl\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  .\32xl\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem
  }

  .\32xl\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .\32xl\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem
  }

  .\32xl\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  .\32xl\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem
  }

  .\32xl\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  .\32xl\:mx-20 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .\32xl\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .\32xl\:mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem
  }

  .\32xl\:mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem
  }

  .\32xl\:mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem
  }

  .\32xl\:mx-40 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .\32xl\:mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem
  }

  .\32xl\:mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem
  }

  .\32xl\:mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem
  }

  .\32xl\:mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem
  }

  .\32xl\:mx-60 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .\32xl\:mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem
  }

  .\32xl\:mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem
  }

  .\32xl\:mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem
  }

  .\32xl\:mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem
  }

  .\32xl\:mx-80 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .\32xl\:mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem
  }

  .\32xl\:mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem
  }

  .\32xl\:mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem
  }

  .\32xl\:mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem
  }

  .\32xl\:mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem
  }

  .\32xl\:mx-136 {
    margin-left: 13.6rem;
    margin-right: 13.6rem
  }

  .\32xl\:mx-160 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .\32xl\:mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem
  }

  .\32xl\:mx-200 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  .\32xl\:mx-208 {
    margin-left: 20.8rem;
    margin-right: 20.8rem
  }

  .\32xl\:mx-216 {
    margin-left: 21.6rem;
    margin-right: 21.6rem
  }

  .\32xl\:mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem
  }

  .\32xl\:mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem
  }

  .\32xl\:mx-288 {
    margin-left: 28.8rem;
    margin-right: 28.8rem
  }

  .\32xl\:mx-320 {
    margin-left: 32rem;
    margin-right: 32rem
  }

  .\32xl\:mx-360 {
    margin-left: 36rem;
    margin-right: 36rem
  }

  .\32xl\:mx-384 {
    margin-left: 38.4rem;
    margin-right: 38.4rem
  }

  .\32xl\:mx-400 {
    margin-left: 40rem;
    margin-right: 40rem
  }

  .\32xl\:mx-512 {
    margin-left: 51.2rem;
    margin-right: 51.2rem
  }

  .\32xl\:mx-640 {
    margin-left: 64rem;
    margin-right: 64rem
  }

  .\32xl\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .\32xl\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .\32xl\:mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem
  }

  .\32xl\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem
  }

  .\32xl\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem
  }

  .\32xl\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem
  }

  .\32xl\:mx-xs {
    margin-left: 32rem;
    margin-right: 32rem
  }

  .\32xl\:mx-sm {
    margin-left: 48rem;
    margin-right: 48rem
  }

  .\32xl\:mx-md {
    margin-left: 64rem;
    margin-right: 64rem
  }

  .\32xl\:mx-lg {
    margin-left: 80rem;
    margin-right: 80rem
  }

  .\32xl\:mx-xl {
    margin-left: 96rem;
    margin-right: 96rem
  }

  .\32xl\:mx-2xl {
    margin-left: 112rem;
    margin-right: 112rem
  }

  .\32xl\:mx-3xl {
    margin-left: 128rem;
    margin-right: 128rem
  }

  .\32xl\:mx-4xl {
    margin-left: 144rem;
    margin-right: 144rem
  }

  .\32xl\:mx-5xl {
    margin-left: 160rem;
    margin-right: 160rem
  }

  .\32xl\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .\32xl\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .\32xl\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  .\32xl\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  .\32xl\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  .\32xl\:-mx-6 {
    margin-left: -0.6rem;
    margin-right: -0.6rem
  }

  .\32xl\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem
  }

  .\32xl\:-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem
  }

  .\32xl\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem
  }

  .\32xl\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .\32xl\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem
  }

  .\32xl\:-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem
  }

  .\32xl\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem
  }

  .\32xl\:-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem
  }

  .\32xl\:-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .\32xl\:-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem
  }

  .\32xl\:-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem
  }

  .\32xl\:-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem
  }

  .\32xl\:-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem
  }

  .\32xl\:-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .\32xl\:-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem
  }

  .\32xl\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  .\32xl\:-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem
  }

  .\32xl\:-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem
  }

  .\32xl\:-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .\32xl\:-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem
  }

  .\32xl\:-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem
  }

  .\32xl\:-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem
  }

  .\32xl\:-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem
  }

  .\32xl\:-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .\32xl\:-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem
  }

  .\32xl\:-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem
  }

  .\32xl\:-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem
  }

  .\32xl\:-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem
  }

  .\32xl\:-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem
  }

  .\32xl\:-mx-136 {
    margin-left: -13.6rem;
    margin-right: -13.6rem
  }

  .\32xl\:-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .\32xl\:-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem
  }

  .\32xl\:-mx-200 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  .\32xl\:-mx-208 {
    margin-left: -20.8rem;
    margin-right: -20.8rem
  }

  .\32xl\:-mx-216 {
    margin-left: -21.6rem;
    margin-right: -21.6rem
  }

  .\32xl\:-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem
  }

  .\32xl\:-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem
  }

  .\32xl\:-mx-288 {
    margin-left: -28.8rem;
    margin-right: -28.8rem
  }

  .\32xl\:-mx-320 {
    margin-left: -32rem;
    margin-right: -32rem
  }

  .\32xl\:-mx-360 {
    margin-left: -36rem;
    margin-right: -36rem
  }

  .\32xl\:-mx-384 {
    margin-left: -38.4rem;
    margin-right: -38.4rem
  }

  .\32xl\:-mx-400 {
    margin-left: -40rem;
    margin-right: -40rem
  }

  .\32xl\:-mx-512 {
    margin-left: -51.2rem;
    margin-right: -51.2rem
  }

  .\32xl\:-mx-640 {
    margin-left: -64rem;
    margin-right: -64rem
  }

  .\32xl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .\32xl\:-mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem
  }

  .\32xl\:-mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem
  }

  .\32xl\:-mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem
  }

  .\32xl\:-mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem
  }

  .\32xl\:-mx-xs {
    margin-left: -32rem;
    margin-right: -32rem
  }

  .\32xl\:-mx-sm {
    margin-left: -48rem;
    margin-right: -48rem
  }

  .\32xl\:-mx-md {
    margin-left: -64rem;
    margin-right: -64rem
  }

  .\32xl\:-mx-lg {
    margin-left: -80rem;
    margin-right: -80rem
  }

  .\32xl\:-mx-xl {
    margin-left: -96rem;
    margin-right: -96rem
  }

  .\32xl\:-mx-2xl {
    margin-left: -112rem;
    margin-right: -112rem
  }

  .\32xl\:-mx-3xl {
    margin-left: -128rem;
    margin-right: -128rem
  }

  .\32xl\:-mx-4xl {
    margin-left: -144rem;
    margin-right: -144rem
  }

  .\32xl\:-mx-5xl {
    margin-left: -160rem;
    margin-right: -160rem
  }

  .\32xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .\32xl\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .\32xl\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .\32xl\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  .\32xl\:my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem
  }

  .\32xl\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  .\32xl\:my-6 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem
  }

  .\32xl\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem
  }

  .\32xl\:my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem
  }

  .\32xl\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem
  }

  .\32xl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .\32xl\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem
  }

  .\32xl\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  .\32xl\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem
  }

  .\32xl\:my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem
  }

  .\32xl\:my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .\32xl\:my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem
  }

  .\32xl\:my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem
  }

  .\32xl\:my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem
  }

  .\32xl\:my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem
  }

  .\32xl\:my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .\32xl\:my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem
  }

  .\32xl\:my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem
  }

  .\32xl\:my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem
  }

  .\32xl\:my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem
  }

  .\32xl\:my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .\32xl\:my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem
  }

  .\32xl\:my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem
  }

  .\32xl\:my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem
  }

  .\32xl\:my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem
  }

  .\32xl\:my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .\32xl\:my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem
  }

  .\32xl\:my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem
  }

  .\32xl\:my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem
  }

  .\32xl\:my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem
  }

  .\32xl\:my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem
  }

  .\32xl\:my-136 {
    margin-top: 13.6rem;
    margin-bottom: 13.6rem
  }

  .\32xl\:my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .\32xl\:my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem
  }

  .\32xl\:my-200 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  .\32xl\:my-208 {
    margin-top: 20.8rem;
    margin-bottom: 20.8rem
  }

  .\32xl\:my-216 {
    margin-top: 21.6rem;
    margin-bottom: 21.6rem
  }

  .\32xl\:my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem
  }

  .\32xl\:my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem
  }

  .\32xl\:my-288 {
    margin-top: 28.8rem;
    margin-bottom: 28.8rem
  }

  .\32xl\:my-320 {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  .\32xl\:my-360 {
    margin-top: 36rem;
    margin-bottom: 36rem
  }

  .\32xl\:my-384 {
    margin-top: 38.4rem;
    margin-bottom: 38.4rem
  }

  .\32xl\:my-400 {
    margin-top: 40rem;
    margin-bottom: 40rem
  }

  .\32xl\:my-512 {
    margin-top: 51.2rem;
    margin-bottom: 51.2rem
  }

  .\32xl\:my-640 {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  .\32xl\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .\32xl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .\32xl\:my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem
  }

  .\32xl\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem
  }

  .\32xl\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem
  }

  .\32xl\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem
  }

  .\32xl\:my-xs {
    margin-top: 32rem;
    margin-bottom: 32rem
  }

  .\32xl\:my-sm {
    margin-top: 48rem;
    margin-bottom: 48rem
  }

  .\32xl\:my-md {
    margin-top: 64rem;
    margin-bottom: 64rem
  }

  .\32xl\:my-lg {
    margin-top: 80rem;
    margin-bottom: 80rem
  }

  .\32xl\:my-xl {
    margin-top: 96rem;
    margin-bottom: 96rem
  }

  .\32xl\:my-2xl {
    margin-top: 112rem;
    margin-bottom: 112rem
  }

  .\32xl\:my-3xl {
    margin-top: 128rem;
    margin-bottom: 128rem
  }

  .\32xl\:my-4xl {
    margin-top: 144rem;
    margin-bottom: 144rem
  }

  .\32xl\:my-5xl {
    margin-top: 160rem;
    margin-bottom: 160rem
  }

  .\32xl\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .\32xl\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .\32xl\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  .\32xl\:-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem
  }

  .\32xl\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  .\32xl\:-my-6 {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem
  }

  .\32xl\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem
  }

  .\32xl\:-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem
  }

  .\32xl\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem
  }

  .\32xl\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .\32xl\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem
  }

  .\32xl\:-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem
  }

  .\32xl\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem
  }

  .\32xl\:-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem
  }

  .\32xl\:-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .\32xl\:-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem
  }

  .\32xl\:-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem
  }

  .\32xl\:-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem
  }

  .\32xl\:-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem
  }

  .\32xl\:-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .\32xl\:-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem
  }

  .\32xl\:-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem
  }

  .\32xl\:-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem
  }

  .\32xl\:-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem
  }

  .\32xl\:-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .\32xl\:-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem
  }

  .\32xl\:-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem
  }

  .\32xl\:-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem
  }

  .\32xl\:-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem
  }

  .\32xl\:-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .\32xl\:-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem
  }

  .\32xl\:-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem
  }

  .\32xl\:-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem
  }

  .\32xl\:-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem
  }

  .\32xl\:-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem
  }

  .\32xl\:-my-136 {
    margin-top: -13.6rem;
    margin-bottom: -13.6rem
  }

  .\32xl\:-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .\32xl\:-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem
  }

  .\32xl\:-my-200 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  .\32xl\:-my-208 {
    margin-top: -20.8rem;
    margin-bottom: -20.8rem
  }

  .\32xl\:-my-216 {
    margin-top: -21.6rem;
    margin-bottom: -21.6rem
  }

  .\32xl\:-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem
  }

  .\32xl\:-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem
  }

  .\32xl\:-my-288 {
    margin-top: -28.8rem;
    margin-bottom: -28.8rem
  }

  .\32xl\:-my-320 {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  .\32xl\:-my-360 {
    margin-top: -36rem;
    margin-bottom: -36rem
  }

  .\32xl\:-my-384 {
    margin-top: -38.4rem;
    margin-bottom: -38.4rem
  }

  .\32xl\:-my-400 {
    margin-top: -40rem;
    margin-bottom: -40rem
  }

  .\32xl\:-my-512 {
    margin-top: -51.2rem;
    margin-bottom: -51.2rem
  }

  .\32xl\:-my-640 {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  .\32xl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .\32xl\:-my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem
  }

  .\32xl\:-my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem
  }

  .\32xl\:-my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem
  }

  .\32xl\:-my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem
  }

  .\32xl\:-my-xs {
    margin-top: -32rem;
    margin-bottom: -32rem
  }

  .\32xl\:-my-sm {
    margin-top: -48rem;
    margin-bottom: -48rem
  }

  .\32xl\:-my-md {
    margin-top: -64rem;
    margin-bottom: -64rem
  }

  .\32xl\:-my-lg {
    margin-top: -80rem;
    margin-bottom: -80rem
  }

  .\32xl\:-my-xl {
    margin-top: -96rem;
    margin-bottom: -96rem
  }

  .\32xl\:-my-2xl {
    margin-top: -112rem;
    margin-bottom: -112rem
  }

  .\32xl\:-my-3xl {
    margin-top: -128rem;
    margin-bottom: -128rem
  }

  .\32xl\:-my-4xl {
    margin-top: -144rem;
    margin-bottom: -144rem
  }

  .\32xl\:-my-5xl {
    margin-top: -160rem;
    margin-bottom: -160rem
  }

  .\32xl\:mt-0 {
    margin-top: 0
  }

  .\32xl\:mt-1 {
    margin-top: 0.25rem
  }

  .\32xl\:mt-2 {
    margin-top: 0.5rem
  }

  .\32xl\:mt-3 {
    margin-top: 0.75rem
  }

  .\32xl\:mt-4 {
    margin-top: 0.4rem
  }

  .\32xl\:mt-5 {
    margin-top: 1.25rem
  }

  .\32xl\:mt-6 {
    margin-top: 0.6rem
  }

  .\32xl\:mt-7 {
    margin-top: 1.75rem
  }

  .\32xl\:mt-8 {
    margin-top: 0.8rem
  }

  .\32xl\:mt-9 {
    margin-top: 2.25rem
  }

  .\32xl\:mt-10 {
    margin-top: 2.5rem
  }

  .\32xl\:mt-11 {
    margin-top: 2.75rem
  }

  .\32xl\:mt-12 {
    margin-top: 1.2rem
  }

  .\32xl\:mt-14 {
    margin-top: 3.5rem
  }

  .\32xl\:mt-16 {
    margin-top: 1.6rem
  }

  .\32xl\:mt-20 {
    margin-top: 2rem
  }

  .\32xl\:mt-24 {
    margin-top: 2.4rem
  }

  .\32xl\:mt-28 {
    margin-top: 2.8rem
  }

  .\32xl\:mt-32 {
    margin-top: 3.2rem
  }

  .\32xl\:mt-36 {
    margin-top: 3.6rem
  }

  .\32xl\:mt-40 {
    margin-top: 4rem
  }

  .\32xl\:mt-44 {
    margin-top: 4.4rem
  }

  .\32xl\:mt-48 {
    margin-top: 4.8rem
  }

  .\32xl\:mt-52 {
    margin-top: 5.2rem
  }

  .\32xl\:mt-56 {
    margin-top: 5.6rem
  }

  .\32xl\:mt-60 {
    margin-top: 6rem
  }

  .\32xl\:mt-64 {
    margin-top: 6.4rem
  }

  .\32xl\:mt-68 {
    margin-top: 6.8rem
  }

  .\32xl\:mt-72 {
    margin-top: 7.2rem
  }

  .\32xl\:mt-76 {
    margin-top: 7.6rem
  }

  .\32xl\:mt-80 {
    margin-top: 8rem
  }

  .\32xl\:mt-84 {
    margin-top: 8.4rem
  }

  .\32xl\:mt-88 {
    margin-top: 8.8rem
  }

  .\32xl\:mt-92 {
    margin-top: 9.2rem
  }

  .\32xl\:mt-96 {
    margin-top: 9.6rem
  }

  .\32xl\:mt-128 {
    margin-top: 12.8rem
  }

  .\32xl\:mt-136 {
    margin-top: 13.6rem
  }

  .\32xl\:mt-160 {
    margin-top: 16rem
  }

  .\32xl\:mt-192 {
    margin-top: 19.2rem
  }

  .\32xl\:mt-200 {
    margin-top: 20rem
  }

  .\32xl\:mt-208 {
    margin-top: 20.8rem
  }

  .\32xl\:mt-216 {
    margin-top: 21.6rem
  }

  .\32xl\:mt-224 {
    margin-top: 22.4rem
  }

  .\32xl\:mt-256 {
    margin-top: 25.6rem
  }

  .\32xl\:mt-288 {
    margin-top: 28.8rem
  }

  .\32xl\:mt-320 {
    margin-top: 32rem
  }

  .\32xl\:mt-360 {
    margin-top: 36rem
  }

  .\32xl\:mt-384 {
    margin-top: 38.4rem
  }

  .\32xl\:mt-400 {
    margin-top: 40rem
  }

  .\32xl\:mt-512 {
    margin-top: 51.2rem
  }

  .\32xl\:mt-640 {
    margin-top: 64rem
  }

  .\32xl\:mt-auto {
    margin-top: auto
  }

  .\32xl\:mt-px {
    margin-top: 1px
  }

  .\32xl\:mt-0\.5 {
    margin-top: 0.125rem
  }

  .\32xl\:mt-1\.5 {
    margin-top: 0.375rem
  }

  .\32xl\:mt-2\.5 {
    margin-top: 0.625rem
  }

  .\32xl\:mt-3\.5 {
    margin-top: 0.875rem
  }

  .\32xl\:mt-xs {
    margin-top: 32rem
  }

  .\32xl\:mt-sm {
    margin-top: 48rem
  }

  .\32xl\:mt-md {
    margin-top: 64rem
  }

  .\32xl\:mt-lg {
    margin-top: 80rem
  }

  .\32xl\:mt-xl {
    margin-top: 96rem
  }

  .\32xl\:mt-2xl {
    margin-top: 112rem
  }

  .\32xl\:mt-3xl {
    margin-top: 128rem
  }

  .\32xl\:mt-4xl {
    margin-top: 144rem
  }

  .\32xl\:mt-5xl {
    margin-top: 160rem
  }

  .\32xl\:-mt-1 {
    margin-top: -0.25rem
  }

  .\32xl\:-mt-2 {
    margin-top: -0.5rem
  }

  .\32xl\:-mt-3 {
    margin-top: -0.75rem
  }

  .\32xl\:-mt-4 {
    margin-top: -0.4rem
  }

  .\32xl\:-mt-5 {
    margin-top: -1.25rem
  }

  .\32xl\:-mt-6 {
    margin-top: -0.6rem
  }

  .\32xl\:-mt-7 {
    margin-top: -1.75rem
  }

  .\32xl\:-mt-8 {
    margin-top: -0.8rem
  }

  .\32xl\:-mt-9 {
    margin-top: -2.25rem
  }

  .\32xl\:-mt-10 {
    margin-top: -2.5rem
  }

  .\32xl\:-mt-11 {
    margin-top: -2.75rem
  }

  .\32xl\:-mt-12 {
    margin-top: -1.2rem
  }

  .\32xl\:-mt-14 {
    margin-top: -3.5rem
  }

  .\32xl\:-mt-16 {
    margin-top: -1.6rem
  }

  .\32xl\:-mt-20 {
    margin-top: -2rem
  }

  .\32xl\:-mt-24 {
    margin-top: -2.4rem
  }

  .\32xl\:-mt-28 {
    margin-top: -2.8rem
  }

  .\32xl\:-mt-32 {
    margin-top: -3.2rem
  }

  .\32xl\:-mt-36 {
    margin-top: -3.6rem
  }

  .\32xl\:-mt-40 {
    margin-top: -4rem
  }

  .\32xl\:-mt-44 {
    margin-top: -4.4rem
  }

  .\32xl\:-mt-48 {
    margin-top: -4.8rem
  }

  .\32xl\:-mt-52 {
    margin-top: -5.2rem
  }

  .\32xl\:-mt-56 {
    margin-top: -5.6rem
  }

  .\32xl\:-mt-60 {
    margin-top: -6rem
  }

  .\32xl\:-mt-64 {
    margin-top: -6.4rem
  }

  .\32xl\:-mt-68 {
    margin-top: -6.8rem
  }

  .\32xl\:-mt-72 {
    margin-top: -7.2rem
  }

  .\32xl\:-mt-76 {
    margin-top: -7.6rem
  }

  .\32xl\:-mt-80 {
    margin-top: -8rem
  }

  .\32xl\:-mt-84 {
    margin-top: -8.4rem
  }

  .\32xl\:-mt-88 {
    margin-top: -8.8rem
  }

  .\32xl\:-mt-92 {
    margin-top: -9.2rem
  }

  .\32xl\:-mt-96 {
    margin-top: -9.6rem
  }

  .\32xl\:-mt-128 {
    margin-top: -12.8rem
  }

  .\32xl\:-mt-136 {
    margin-top: -13.6rem
  }

  .\32xl\:-mt-160 {
    margin-top: -16rem
  }

  .\32xl\:-mt-192 {
    margin-top: -19.2rem
  }

  .\32xl\:-mt-200 {
    margin-top: -20rem
  }

  .\32xl\:-mt-208 {
    margin-top: -20.8rem
  }

  .\32xl\:-mt-216 {
    margin-top: -21.6rem
  }

  .\32xl\:-mt-224 {
    margin-top: -22.4rem
  }

  .\32xl\:-mt-256 {
    margin-top: -25.6rem
  }

  .\32xl\:-mt-288 {
    margin-top: -28.8rem
  }

  .\32xl\:-mt-320 {
    margin-top: -32rem
  }

  .\32xl\:-mt-360 {
    margin-top: -36rem
  }

  .\32xl\:-mt-384 {
    margin-top: -38.4rem
  }

  .\32xl\:-mt-400 {
    margin-top: -40rem
  }

  .\32xl\:-mt-512 {
    margin-top: -51.2rem
  }

  .\32xl\:-mt-640 {
    margin-top: -64rem
  }

  .\32xl\:-mt-px {
    margin-top: -1px
  }

  .\32xl\:-mt-0\.5 {
    margin-top: -0.125rem
  }

  .\32xl\:-mt-1\.5 {
    margin-top: -0.375rem
  }

  .\32xl\:-mt-2\.5 {
    margin-top: -0.625rem
  }

  .\32xl\:-mt-3\.5 {
    margin-top: -0.875rem
  }

  .\32xl\:-mt-xs {
    margin-top: -32rem
  }

  .\32xl\:-mt-sm {
    margin-top: -48rem
  }

  .\32xl\:-mt-md {
    margin-top: -64rem
  }

  .\32xl\:-mt-lg {
    margin-top: -80rem
  }

  .\32xl\:-mt-xl {
    margin-top: -96rem
  }

  .\32xl\:-mt-2xl {
    margin-top: -112rem
  }

  .\32xl\:-mt-3xl {
    margin-top: -128rem
  }

  .\32xl\:-mt-4xl {
    margin-top: -144rem
  }

  .\32xl\:-mt-5xl {
    margin-top: -160rem
  }

  .\32xl\:mr-0 {
    margin-right: 0
  }

  .\32xl\:mr-1 {
    margin-right: 0.25rem
  }

  .\32xl\:mr-2 {
    margin-right: 0.5rem
  }

  .\32xl\:mr-3 {
    margin-right: 0.75rem
  }

  .\32xl\:mr-4 {
    margin-right: 0.4rem
  }

  .\32xl\:mr-5 {
    margin-right: 1.25rem
  }

  .\32xl\:mr-6 {
    margin-right: 0.6rem
  }

  .\32xl\:mr-7 {
    margin-right: 1.75rem
  }

  .\32xl\:mr-8 {
    margin-right: 0.8rem
  }

  .\32xl\:mr-9 {
    margin-right: 2.25rem
  }

  .\32xl\:mr-10 {
    margin-right: 2.5rem
  }

  .\32xl\:mr-11 {
    margin-right: 2.75rem
  }

  .\32xl\:mr-12 {
    margin-right: 1.2rem
  }

  .\32xl\:mr-14 {
    margin-right: 3.5rem
  }

  .\32xl\:mr-16 {
    margin-right: 1.6rem
  }

  .\32xl\:mr-20 {
    margin-right: 2rem
  }

  .\32xl\:mr-24 {
    margin-right: 2.4rem
  }

  .\32xl\:mr-28 {
    margin-right: 2.8rem
  }

  .\32xl\:mr-32 {
    margin-right: 3.2rem
  }

  .\32xl\:mr-36 {
    margin-right: 3.6rem
  }

  .\32xl\:mr-40 {
    margin-right: 4rem
  }

  .\32xl\:mr-44 {
    margin-right: 4.4rem
  }

  .\32xl\:mr-48 {
    margin-right: 4.8rem
  }

  .\32xl\:mr-52 {
    margin-right: 5.2rem
  }

  .\32xl\:mr-56 {
    margin-right: 5.6rem
  }

  .\32xl\:mr-60 {
    margin-right: 6rem
  }

  .\32xl\:mr-64 {
    margin-right: 6.4rem
  }

  .\32xl\:mr-68 {
    margin-right: 6.8rem
  }

  .\32xl\:mr-72 {
    margin-right: 7.2rem
  }

  .\32xl\:mr-76 {
    margin-right: 7.6rem
  }

  .\32xl\:mr-80 {
    margin-right: 8rem
  }

  .\32xl\:mr-84 {
    margin-right: 8.4rem
  }

  .\32xl\:mr-88 {
    margin-right: 8.8rem
  }

  .\32xl\:mr-92 {
    margin-right: 9.2rem
  }

  .\32xl\:mr-96 {
    margin-right: 9.6rem
  }

  .\32xl\:mr-128 {
    margin-right: 12.8rem
  }

  .\32xl\:mr-136 {
    margin-right: 13.6rem
  }

  .\32xl\:mr-160 {
    margin-right: 16rem
  }

  .\32xl\:mr-192 {
    margin-right: 19.2rem
  }

  .\32xl\:mr-200 {
    margin-right: 20rem
  }

  .\32xl\:mr-208 {
    margin-right: 20.8rem
  }

  .\32xl\:mr-216 {
    margin-right: 21.6rem
  }

  .\32xl\:mr-224 {
    margin-right: 22.4rem
  }

  .\32xl\:mr-256 {
    margin-right: 25.6rem
  }

  .\32xl\:mr-288 {
    margin-right: 28.8rem
  }

  .\32xl\:mr-320 {
    margin-right: 32rem
  }

  .\32xl\:mr-360 {
    margin-right: 36rem
  }

  .\32xl\:mr-384 {
    margin-right: 38.4rem
  }

  .\32xl\:mr-400 {
    margin-right: 40rem
  }

  .\32xl\:mr-512 {
    margin-right: 51.2rem
  }

  .\32xl\:mr-640 {
    margin-right: 64rem
  }

  .\32xl\:mr-auto {
    margin-right: auto
  }

  .\32xl\:mr-px {
    margin-right: 1px
  }

  .\32xl\:mr-0\.5 {
    margin-right: 0.125rem
  }

  .\32xl\:mr-1\.5 {
    margin-right: 0.375rem
  }

  .\32xl\:mr-2\.5 {
    margin-right: 0.625rem
  }

  .\32xl\:mr-3\.5 {
    margin-right: 0.875rem
  }

  .\32xl\:mr-xs {
    margin-right: 32rem
  }

  .\32xl\:mr-sm {
    margin-right: 48rem
  }

  .\32xl\:mr-md {
    margin-right: 64rem
  }

  .\32xl\:mr-lg {
    margin-right: 80rem
  }

  .\32xl\:mr-xl {
    margin-right: 96rem
  }

  .\32xl\:mr-2xl {
    margin-right: 112rem
  }

  .\32xl\:mr-3xl {
    margin-right: 128rem
  }

  .\32xl\:mr-4xl {
    margin-right: 144rem
  }

  .\32xl\:mr-5xl {
    margin-right: 160rem
  }

  .\32xl\:-mr-1 {
    margin-right: -0.25rem
  }

  .\32xl\:-mr-2 {
    margin-right: -0.5rem
  }

  .\32xl\:-mr-3 {
    margin-right: -0.75rem
  }

  .\32xl\:-mr-4 {
    margin-right: -0.4rem
  }

  .\32xl\:-mr-5 {
    margin-right: -1.25rem
  }

  .\32xl\:-mr-6 {
    margin-right: -0.6rem
  }

  .\32xl\:-mr-7 {
    margin-right: -1.75rem
  }

  .\32xl\:-mr-8 {
    margin-right: -0.8rem
  }

  .\32xl\:-mr-9 {
    margin-right: -2.25rem
  }

  .\32xl\:-mr-10 {
    margin-right: -2.5rem
  }

  .\32xl\:-mr-11 {
    margin-right: -2.75rem
  }

  .\32xl\:-mr-12 {
    margin-right: -1.2rem
  }

  .\32xl\:-mr-14 {
    margin-right: -3.5rem
  }

  .\32xl\:-mr-16 {
    margin-right: -1.6rem
  }

  .\32xl\:-mr-20 {
    margin-right: -2rem
  }

  .\32xl\:-mr-24 {
    margin-right: -2.4rem
  }

  .\32xl\:-mr-28 {
    margin-right: -2.8rem
  }

  .\32xl\:-mr-32 {
    margin-right: -3.2rem
  }

  .\32xl\:-mr-36 {
    margin-right: -3.6rem
  }

  .\32xl\:-mr-40 {
    margin-right: -4rem
  }

  .\32xl\:-mr-44 {
    margin-right: -4.4rem
  }

  .\32xl\:-mr-48 {
    margin-right: -4.8rem
  }

  .\32xl\:-mr-52 {
    margin-right: -5.2rem
  }

  .\32xl\:-mr-56 {
    margin-right: -5.6rem
  }

  .\32xl\:-mr-60 {
    margin-right: -6rem
  }

  .\32xl\:-mr-64 {
    margin-right: -6.4rem
  }

  .\32xl\:-mr-68 {
    margin-right: -6.8rem
  }

  .\32xl\:-mr-72 {
    margin-right: -7.2rem
  }

  .\32xl\:-mr-76 {
    margin-right: -7.6rem
  }

  .\32xl\:-mr-80 {
    margin-right: -8rem
  }

  .\32xl\:-mr-84 {
    margin-right: -8.4rem
  }

  .\32xl\:-mr-88 {
    margin-right: -8.8rem
  }

  .\32xl\:-mr-92 {
    margin-right: -9.2rem
  }

  .\32xl\:-mr-96 {
    margin-right: -9.6rem
  }

  .\32xl\:-mr-128 {
    margin-right: -12.8rem
  }

  .\32xl\:-mr-136 {
    margin-right: -13.6rem
  }

  .\32xl\:-mr-160 {
    margin-right: -16rem
  }

  .\32xl\:-mr-192 {
    margin-right: -19.2rem
  }

  .\32xl\:-mr-200 {
    margin-right: -20rem
  }

  .\32xl\:-mr-208 {
    margin-right: -20.8rem
  }

  .\32xl\:-mr-216 {
    margin-right: -21.6rem
  }

  .\32xl\:-mr-224 {
    margin-right: -22.4rem
  }

  .\32xl\:-mr-256 {
    margin-right: -25.6rem
  }

  .\32xl\:-mr-288 {
    margin-right: -28.8rem
  }

  .\32xl\:-mr-320 {
    margin-right: -32rem
  }

  .\32xl\:-mr-360 {
    margin-right: -36rem
  }

  .\32xl\:-mr-384 {
    margin-right: -38.4rem
  }

  .\32xl\:-mr-400 {
    margin-right: -40rem
  }

  .\32xl\:-mr-512 {
    margin-right: -51.2rem
  }

  .\32xl\:-mr-640 {
    margin-right: -64rem
  }

  .\32xl\:-mr-px {
    margin-right: -1px
  }

  .\32xl\:-mr-0\.5 {
    margin-right: -0.125rem
  }

  .\32xl\:-mr-1\.5 {
    margin-right: -0.375rem
  }

  .\32xl\:-mr-2\.5 {
    margin-right: -0.625rem
  }

  .\32xl\:-mr-3\.5 {
    margin-right: -0.875rem
  }

  .\32xl\:-mr-xs {
    margin-right: -32rem
  }

  .\32xl\:-mr-sm {
    margin-right: -48rem
  }

  .\32xl\:-mr-md {
    margin-right: -64rem
  }

  .\32xl\:-mr-lg {
    margin-right: -80rem
  }

  .\32xl\:-mr-xl {
    margin-right: -96rem
  }

  .\32xl\:-mr-2xl {
    margin-right: -112rem
  }

  .\32xl\:-mr-3xl {
    margin-right: -128rem
  }

  .\32xl\:-mr-4xl {
    margin-right: -144rem
  }

  .\32xl\:-mr-5xl {
    margin-right: -160rem
  }

  .\32xl\:mb-0 {
    margin-bottom: 0
  }

  .\32xl\:mb-1 {
    margin-bottom: 0.25rem
  }

  .\32xl\:mb-2 {
    margin-bottom: 0.5rem
  }

  .\32xl\:mb-3 {
    margin-bottom: 0.75rem
  }

  .\32xl\:mb-4 {
    margin-bottom: 0.4rem
  }

  .\32xl\:mb-5 {
    margin-bottom: 1.25rem
  }

  .\32xl\:mb-6 {
    margin-bottom: 0.6rem
  }

  .\32xl\:mb-7 {
    margin-bottom: 1.75rem
  }

  .\32xl\:mb-8 {
    margin-bottom: 0.8rem
  }

  .\32xl\:mb-9 {
    margin-bottom: 2.25rem
  }

  .\32xl\:mb-10 {
    margin-bottom: 2.5rem
  }

  .\32xl\:mb-11 {
    margin-bottom: 2.75rem
  }

  .\32xl\:mb-12 {
    margin-bottom: 1.2rem
  }

  .\32xl\:mb-14 {
    margin-bottom: 3.5rem
  }

  .\32xl\:mb-16 {
    margin-bottom: 1.6rem
  }

  .\32xl\:mb-20 {
    margin-bottom: 2rem
  }

  .\32xl\:mb-24 {
    margin-bottom: 2.4rem
  }

  .\32xl\:mb-28 {
    margin-bottom: 2.8rem
  }

  .\32xl\:mb-32 {
    margin-bottom: 3.2rem
  }

  .\32xl\:mb-36 {
    margin-bottom: 3.6rem
  }

  .\32xl\:mb-40 {
    margin-bottom: 4rem
  }

  .\32xl\:mb-44 {
    margin-bottom: 4.4rem
  }

  .\32xl\:mb-48 {
    margin-bottom: 4.8rem
  }

  .\32xl\:mb-52 {
    margin-bottom: 5.2rem
  }

  .\32xl\:mb-56 {
    margin-bottom: 5.6rem
  }

  .\32xl\:mb-60 {
    margin-bottom: 6rem
  }

  .\32xl\:mb-64 {
    margin-bottom: 6.4rem
  }

  .\32xl\:mb-68 {
    margin-bottom: 6.8rem
  }

  .\32xl\:mb-72 {
    margin-bottom: 7.2rem
  }

  .\32xl\:mb-76 {
    margin-bottom: 7.6rem
  }

  .\32xl\:mb-80 {
    margin-bottom: 8rem
  }

  .\32xl\:mb-84 {
    margin-bottom: 8.4rem
  }

  .\32xl\:mb-88 {
    margin-bottom: 8.8rem
  }

  .\32xl\:mb-92 {
    margin-bottom: 9.2rem
  }

  .\32xl\:mb-96 {
    margin-bottom: 9.6rem
  }

  .\32xl\:mb-128 {
    margin-bottom: 12.8rem
  }

  .\32xl\:mb-136 {
    margin-bottom: 13.6rem
  }

  .\32xl\:mb-160 {
    margin-bottom: 16rem
  }

  .\32xl\:mb-192 {
    margin-bottom: 19.2rem
  }

  .\32xl\:mb-200 {
    margin-bottom: 20rem
  }

  .\32xl\:mb-208 {
    margin-bottom: 20.8rem
  }

  .\32xl\:mb-216 {
    margin-bottom: 21.6rem
  }

  .\32xl\:mb-224 {
    margin-bottom: 22.4rem
  }

  .\32xl\:mb-256 {
    margin-bottom: 25.6rem
  }

  .\32xl\:mb-288 {
    margin-bottom: 28.8rem
  }

  .\32xl\:mb-320 {
    margin-bottom: 32rem
  }

  .\32xl\:mb-360 {
    margin-bottom: 36rem
  }

  .\32xl\:mb-384 {
    margin-bottom: 38.4rem
  }

  .\32xl\:mb-400 {
    margin-bottom: 40rem
  }

  .\32xl\:mb-512 {
    margin-bottom: 51.2rem
  }

  .\32xl\:mb-640 {
    margin-bottom: 64rem
  }

  .\32xl\:mb-auto {
    margin-bottom: auto
  }

  .\32xl\:mb-px {
    margin-bottom: 1px
  }

  .\32xl\:mb-0\.5 {
    margin-bottom: 0.125rem
  }

  .\32xl\:mb-1\.5 {
    margin-bottom: 0.375rem
  }

  .\32xl\:mb-2\.5 {
    margin-bottom: 0.625rem
  }

  .\32xl\:mb-3\.5 {
    margin-bottom: 0.875rem
  }

  .\32xl\:mb-xs {
    margin-bottom: 32rem
  }

  .\32xl\:mb-sm {
    margin-bottom: 48rem
  }

  .\32xl\:mb-md {
    margin-bottom: 64rem
  }

  .\32xl\:mb-lg {
    margin-bottom: 80rem
  }

  .\32xl\:mb-xl {
    margin-bottom: 96rem
  }

  .\32xl\:mb-2xl {
    margin-bottom: 112rem
  }

  .\32xl\:mb-3xl {
    margin-bottom: 128rem
  }

  .\32xl\:mb-4xl {
    margin-bottom: 144rem
  }

  .\32xl\:mb-5xl {
    margin-bottom: 160rem
  }

  .\32xl\:-mb-1 {
    margin-bottom: -0.25rem
  }

  .\32xl\:-mb-2 {
    margin-bottom: -0.5rem
  }

  .\32xl\:-mb-3 {
    margin-bottom: -0.75rem
  }

  .\32xl\:-mb-4 {
    margin-bottom: -0.4rem
  }

  .\32xl\:-mb-5 {
    margin-bottom: -1.25rem
  }

  .\32xl\:-mb-6 {
    margin-bottom: -0.6rem
  }

  .\32xl\:-mb-7 {
    margin-bottom: -1.75rem
  }

  .\32xl\:-mb-8 {
    margin-bottom: -0.8rem
  }

  .\32xl\:-mb-9 {
    margin-bottom: -2.25rem
  }

  .\32xl\:-mb-10 {
    margin-bottom: -2.5rem
  }

  .\32xl\:-mb-11 {
    margin-bottom: -2.75rem
  }

  .\32xl\:-mb-12 {
    margin-bottom: -1.2rem
  }

  .\32xl\:-mb-14 {
    margin-bottom: -3.5rem
  }

  .\32xl\:-mb-16 {
    margin-bottom: -1.6rem
  }

  .\32xl\:-mb-20 {
    margin-bottom: -2rem
  }

  .\32xl\:-mb-24 {
    margin-bottom: -2.4rem
  }

  .\32xl\:-mb-28 {
    margin-bottom: -2.8rem
  }

  .\32xl\:-mb-32 {
    margin-bottom: -3.2rem
  }

  .\32xl\:-mb-36 {
    margin-bottom: -3.6rem
  }

  .\32xl\:-mb-40 {
    margin-bottom: -4rem
  }

  .\32xl\:-mb-44 {
    margin-bottom: -4.4rem
  }

  .\32xl\:-mb-48 {
    margin-bottom: -4.8rem
  }

  .\32xl\:-mb-52 {
    margin-bottom: -5.2rem
  }

  .\32xl\:-mb-56 {
    margin-bottom: -5.6rem
  }

  .\32xl\:-mb-60 {
    margin-bottom: -6rem
  }

  .\32xl\:-mb-64 {
    margin-bottom: -6.4rem
  }

  .\32xl\:-mb-68 {
    margin-bottom: -6.8rem
  }

  .\32xl\:-mb-72 {
    margin-bottom: -7.2rem
  }

  .\32xl\:-mb-76 {
    margin-bottom: -7.6rem
  }

  .\32xl\:-mb-80 {
    margin-bottom: -8rem
  }

  .\32xl\:-mb-84 {
    margin-bottom: -8.4rem
  }

  .\32xl\:-mb-88 {
    margin-bottom: -8.8rem
  }

  .\32xl\:-mb-92 {
    margin-bottom: -9.2rem
  }

  .\32xl\:-mb-96 {
    margin-bottom: -9.6rem
  }

  .\32xl\:-mb-128 {
    margin-bottom: -12.8rem
  }

  .\32xl\:-mb-136 {
    margin-bottom: -13.6rem
  }

  .\32xl\:-mb-160 {
    margin-bottom: -16rem
  }

  .\32xl\:-mb-192 {
    margin-bottom: -19.2rem
  }

  .\32xl\:-mb-200 {
    margin-bottom: -20rem
  }

  .\32xl\:-mb-208 {
    margin-bottom: -20.8rem
  }

  .\32xl\:-mb-216 {
    margin-bottom: -21.6rem
  }

  .\32xl\:-mb-224 {
    margin-bottom: -22.4rem
  }

  .\32xl\:-mb-256 {
    margin-bottom: -25.6rem
  }

  .\32xl\:-mb-288 {
    margin-bottom: -28.8rem
  }

  .\32xl\:-mb-320 {
    margin-bottom: -32rem
  }

  .\32xl\:-mb-360 {
    margin-bottom: -36rem
  }

  .\32xl\:-mb-384 {
    margin-bottom: -38.4rem
  }

  .\32xl\:-mb-400 {
    margin-bottom: -40rem
  }

  .\32xl\:-mb-512 {
    margin-bottom: -51.2rem
  }

  .\32xl\:-mb-640 {
    margin-bottom: -64rem
  }

  .\32xl\:-mb-px {
    margin-bottom: -1px
  }

  .\32xl\:-mb-0\.5 {
    margin-bottom: -0.125rem
  }

  .\32xl\:-mb-1\.5 {
    margin-bottom: -0.375rem
  }

  .\32xl\:-mb-2\.5 {
    margin-bottom: -0.625rem
  }

  .\32xl\:-mb-3\.5 {
    margin-bottom: -0.875rem
  }

  .\32xl\:-mb-xs {
    margin-bottom: -32rem
  }

  .\32xl\:-mb-sm {
    margin-bottom: -48rem
  }

  .\32xl\:-mb-md {
    margin-bottom: -64rem
  }

  .\32xl\:-mb-lg {
    margin-bottom: -80rem
  }

  .\32xl\:-mb-xl {
    margin-bottom: -96rem
  }

  .\32xl\:-mb-2xl {
    margin-bottom: -112rem
  }

  .\32xl\:-mb-3xl {
    margin-bottom: -128rem
  }

  .\32xl\:-mb-4xl {
    margin-bottom: -144rem
  }

  .\32xl\:-mb-5xl {
    margin-bottom: -160rem
  }

  .\32xl\:ml-0 {
    margin-left: 0
  }

  .\32xl\:ml-1 {
    margin-left: 0.25rem
  }

  .\32xl\:ml-2 {
    margin-left: 0.5rem
  }

  .\32xl\:ml-3 {
    margin-left: 0.75rem
  }

  .\32xl\:ml-4 {
    margin-left: 0.4rem
  }

  .\32xl\:ml-5 {
    margin-left: 1.25rem
  }

  .\32xl\:ml-6 {
    margin-left: 0.6rem
  }

  .\32xl\:ml-7 {
    margin-left: 1.75rem
  }

  .\32xl\:ml-8 {
    margin-left: 0.8rem
  }

  .\32xl\:ml-9 {
    margin-left: 2.25rem
  }

  .\32xl\:ml-10 {
    margin-left: 2.5rem
  }

  .\32xl\:ml-11 {
    margin-left: 2.75rem
  }

  .\32xl\:ml-12 {
    margin-left: 1.2rem
  }

  .\32xl\:ml-14 {
    margin-left: 3.5rem
  }

  .\32xl\:ml-16 {
    margin-left: 1.6rem
  }

  .\32xl\:ml-20 {
    margin-left: 2rem
  }

  .\32xl\:ml-24 {
    margin-left: 2.4rem
  }

  .\32xl\:ml-28 {
    margin-left: 2.8rem
  }

  .\32xl\:ml-32 {
    margin-left: 3.2rem
  }

  .\32xl\:ml-36 {
    margin-left: 3.6rem
  }

  .\32xl\:ml-40 {
    margin-left: 4rem
  }

  .\32xl\:ml-44 {
    margin-left: 4.4rem
  }

  .\32xl\:ml-48 {
    margin-left: 4.8rem
  }

  .\32xl\:ml-52 {
    margin-left: 5.2rem
  }

  .\32xl\:ml-56 {
    margin-left: 5.6rem
  }

  .\32xl\:ml-60 {
    margin-left: 6rem
  }

  .\32xl\:ml-64 {
    margin-left: 6.4rem
  }

  .\32xl\:ml-68 {
    margin-left: 6.8rem
  }

  .\32xl\:ml-72 {
    margin-left: 7.2rem
  }

  .\32xl\:ml-76 {
    margin-left: 7.6rem
  }

  .\32xl\:ml-80 {
    margin-left: 8rem
  }

  .\32xl\:ml-84 {
    margin-left: 8.4rem
  }

  .\32xl\:ml-88 {
    margin-left: 8.8rem
  }

  .\32xl\:ml-92 {
    margin-left: 9.2rem
  }

  .\32xl\:ml-96 {
    margin-left: 9.6rem
  }

  .\32xl\:ml-128 {
    margin-left: 12.8rem
  }

  .\32xl\:ml-136 {
    margin-left: 13.6rem
  }

  .\32xl\:ml-160 {
    margin-left: 16rem
  }

  .\32xl\:ml-192 {
    margin-left: 19.2rem
  }

  .\32xl\:ml-200 {
    margin-left: 20rem
  }

  .\32xl\:ml-208 {
    margin-left: 20.8rem
  }

  .\32xl\:ml-216 {
    margin-left: 21.6rem
  }

  .\32xl\:ml-224 {
    margin-left: 22.4rem
  }

  .\32xl\:ml-256 {
    margin-left: 25.6rem
  }

  .\32xl\:ml-288 {
    margin-left: 28.8rem
  }

  .\32xl\:ml-320 {
    margin-left: 32rem
  }

  .\32xl\:ml-360 {
    margin-left: 36rem
  }

  .\32xl\:ml-384 {
    margin-left: 38.4rem
  }

  .\32xl\:ml-400 {
    margin-left: 40rem
  }

  .\32xl\:ml-512 {
    margin-left: 51.2rem
  }

  .\32xl\:ml-640 {
    margin-left: 64rem
  }

  .\32xl\:ml-auto {
    margin-left: auto
  }

  .\32xl\:ml-px {
    margin-left: 1px
  }

  .\32xl\:ml-0\.5 {
    margin-left: 0.125rem
  }

  .\32xl\:ml-1\.5 {
    margin-left: 0.375rem
  }

  .\32xl\:ml-2\.5 {
    margin-left: 0.625rem
  }

  .\32xl\:ml-3\.5 {
    margin-left: 0.875rem
  }

  .\32xl\:ml-xs {
    margin-left: 32rem
  }

  .\32xl\:ml-sm {
    margin-left: 48rem
  }

  .\32xl\:ml-md {
    margin-left: 64rem
  }

  .\32xl\:ml-lg {
    margin-left: 80rem
  }

  .\32xl\:ml-xl {
    margin-left: 96rem
  }

  .\32xl\:ml-2xl {
    margin-left: 112rem
  }

  .\32xl\:ml-3xl {
    margin-left: 128rem
  }

  .\32xl\:ml-4xl {
    margin-left: 144rem
  }

  .\32xl\:ml-5xl {
    margin-left: 160rem
  }

  .\32xl\:-ml-1 {
    margin-left: -0.25rem
  }

  .\32xl\:-ml-2 {
    margin-left: -0.5rem
  }

  .\32xl\:-ml-3 {
    margin-left: -0.75rem
  }

  .\32xl\:-ml-4 {
    margin-left: -0.4rem
  }

  .\32xl\:-ml-5 {
    margin-left: -1.25rem
  }

  .\32xl\:-ml-6 {
    margin-left: -0.6rem
  }

  .\32xl\:-ml-7 {
    margin-left: -1.75rem
  }

  .\32xl\:-ml-8 {
    margin-left: -0.8rem
  }

  .\32xl\:-ml-9 {
    margin-left: -2.25rem
  }

  .\32xl\:-ml-10 {
    margin-left: -2.5rem
  }

  .\32xl\:-ml-11 {
    margin-left: -2.75rem
  }

  .\32xl\:-ml-12 {
    margin-left: -1.2rem
  }

  .\32xl\:-ml-14 {
    margin-left: -3.5rem
  }

  .\32xl\:-ml-16 {
    margin-left: -1.6rem
  }

  .\32xl\:-ml-20 {
    margin-left: -2rem
  }

  .\32xl\:-ml-24 {
    margin-left: -2.4rem
  }

  .\32xl\:-ml-28 {
    margin-left: -2.8rem
  }

  .\32xl\:-ml-32 {
    margin-left: -3.2rem
  }

  .\32xl\:-ml-36 {
    margin-left: -3.6rem
  }

  .\32xl\:-ml-40 {
    margin-left: -4rem
  }

  .\32xl\:-ml-44 {
    margin-left: -4.4rem
  }

  .\32xl\:-ml-48 {
    margin-left: -4.8rem
  }

  .\32xl\:-ml-52 {
    margin-left: -5.2rem
  }

  .\32xl\:-ml-56 {
    margin-left: -5.6rem
  }

  .\32xl\:-ml-60 {
    margin-left: -6rem
  }

  .\32xl\:-ml-64 {
    margin-left: -6.4rem
  }

  .\32xl\:-ml-68 {
    margin-left: -6.8rem
  }

  .\32xl\:-ml-72 {
    margin-left: -7.2rem
  }

  .\32xl\:-ml-76 {
    margin-left: -7.6rem
  }

  .\32xl\:-ml-80 {
    margin-left: -8rem
  }

  .\32xl\:-ml-84 {
    margin-left: -8.4rem
  }

  .\32xl\:-ml-88 {
    margin-left: -8.8rem
  }

  .\32xl\:-ml-92 {
    margin-left: -9.2rem
  }

  .\32xl\:-ml-96 {
    margin-left: -9.6rem
  }

  .\32xl\:-ml-128 {
    margin-left: -12.8rem
  }

  .\32xl\:-ml-136 {
    margin-left: -13.6rem
  }

  .\32xl\:-ml-160 {
    margin-left: -16rem
  }

  .\32xl\:-ml-192 {
    margin-left: -19.2rem
  }

  .\32xl\:-ml-200 {
    margin-left: -20rem
  }

  .\32xl\:-ml-208 {
    margin-left: -20.8rem
  }

  .\32xl\:-ml-216 {
    margin-left: -21.6rem
  }

  .\32xl\:-ml-224 {
    margin-left: -22.4rem
  }

  .\32xl\:-ml-256 {
    margin-left: -25.6rem
  }

  .\32xl\:-ml-288 {
    margin-left: -28.8rem
  }

  .\32xl\:-ml-320 {
    margin-left: -32rem
  }

  .\32xl\:-ml-360 {
    margin-left: -36rem
  }

  .\32xl\:-ml-384 {
    margin-left: -38.4rem
  }

  .\32xl\:-ml-400 {
    margin-left: -40rem
  }

  .\32xl\:-ml-512 {
    margin-left: -51.2rem
  }

  .\32xl\:-ml-640 {
    margin-left: -64rem
  }

  .\32xl\:-ml-px {
    margin-left: -1px
  }

  .\32xl\:-ml-0\.5 {
    margin-left: -0.125rem
  }

  .\32xl\:-ml-1\.5 {
    margin-left: -0.375rem
  }

  .\32xl\:-ml-2\.5 {
    margin-left: -0.625rem
  }

  .\32xl\:-ml-3\.5 {
    margin-left: -0.875rem
  }

  .\32xl\:-ml-xs {
    margin-left: -32rem
  }

  .\32xl\:-ml-sm {
    margin-left: -48rem
  }

  .\32xl\:-ml-md {
    margin-left: -64rem
  }

  .\32xl\:-ml-lg {
    margin-left: -80rem
  }

  .\32xl\:-ml-xl {
    margin-left: -96rem
  }

  .\32xl\:-ml-2xl {
    margin-left: -112rem
  }

  .\32xl\:-ml-3xl {
    margin-left: -128rem
  }

  .\32xl\:-ml-4xl {
    margin-left: -144rem
  }

  .\32xl\:-ml-5xl {
    margin-left: -160rem
  }

  .\32xl\:box-border {
    box-sizing: border-box
  }

  .\32xl\:box-content {
    box-sizing: content-box
  }

  .\32xl\:block {
    display: block
  }

  .\32xl\:inline-block {
    display: inline-block
  }

  .\32xl\:inline {
    display: inline
  }

  .\32xl\:flex {
    display: flex
  }

  .\32xl\:inline-flex {
    display: inline-flex
  }

  .\32xl\:table {
    display: table
  }

  .\32xl\:inline-table {
    display: inline-table
  }

  .\32xl\:table-caption {
    display: table-caption
  }

  .\32xl\:table-cell {
    display: table-cell
  }

  .\32xl\:table-column {
    display: table-column
  }

  .\32xl\:table-column-group {
    display: table-column-group
  }

  .\32xl\:table-footer-group {
    display: table-footer-group
  }

  .\32xl\:table-header-group {
    display: table-header-group
  }

  .\32xl\:table-row-group {
    display: table-row-group
  }

  .\32xl\:table-row {
    display: table-row
  }

  .\32xl\:flow-root {
    display: flow-root
  }

  .\32xl\:grid {
    display: grid
  }

  .\32xl\:inline-grid {
    display: inline-grid
  }

  .\32xl\:contents {
    display: contents
  }

  .\32xl\:list-item {
    display: list-item
  }

  .\32xl\:hidden {
    display: none
  }

  .\32xl\:hover\:block:hover {
    display: block
  }

  .\32xl\:hover\:inline-block:hover {
    display: inline-block
  }

  .\32xl\:hover\:inline:hover {
    display: inline
  }

  .\32xl\:hover\:flex:hover {
    display: flex
  }

  .\32xl\:hover\:inline-flex:hover {
    display: inline-flex
  }

  .\32xl\:hover\:table:hover {
    display: table
  }

  .\32xl\:hover\:inline-table:hover {
    display: inline-table
  }

  .\32xl\:hover\:table-caption:hover {
    display: table-caption
  }

  .\32xl\:hover\:table-cell:hover {
    display: table-cell
  }

  .\32xl\:hover\:table-column:hover {
    display: table-column
  }

  .\32xl\:hover\:table-column-group:hover {
    display: table-column-group
  }

  .\32xl\:hover\:table-footer-group:hover {
    display: table-footer-group
  }

  .\32xl\:hover\:table-header-group:hover {
    display: table-header-group
  }

  .\32xl\:hover\:table-row-group:hover {
    display: table-row-group
  }

  .\32xl\:hover\:table-row:hover {
    display: table-row
  }

  .\32xl\:hover\:flow-root:hover {
    display: flow-root
  }

  .\32xl\:hover\:grid:hover {
    display: grid
  }

  .\32xl\:hover\:inline-grid:hover {
    display: inline-grid
  }

  .\32xl\:hover\:contents:hover {
    display: contents
  }

  .\32xl\:hover\:list-item:hover {
    display: list-item
  }

  .\32xl\:hover\:hidden:hover {
    display: none
  }

  .\32xl\:focus\:block:focus {
    display: block
  }

  .\32xl\:focus\:inline-block:focus {
    display: inline-block
  }

  .\32xl\:focus\:inline:focus {
    display: inline
  }

  .\32xl\:focus\:flex:focus {
    display: flex
  }

  .\32xl\:focus\:inline-flex:focus {
    display: inline-flex
  }

  .\32xl\:focus\:table:focus {
    display: table
  }

  .\32xl\:focus\:inline-table:focus {
    display: inline-table
  }

  .\32xl\:focus\:table-caption:focus {
    display: table-caption
  }

  .\32xl\:focus\:table-cell:focus {
    display: table-cell
  }

  .\32xl\:focus\:table-column:focus {
    display: table-column
  }

  .\32xl\:focus\:table-column-group:focus {
    display: table-column-group
  }

  .\32xl\:focus\:table-footer-group:focus {
    display: table-footer-group
  }

  .\32xl\:focus\:table-header-group:focus {
    display: table-header-group
  }

  .\32xl\:focus\:table-row-group:focus {
    display: table-row-group
  }

  .\32xl\:focus\:table-row:focus {
    display: table-row
  }

  .\32xl\:focus\:flow-root:focus {
    display: flow-root
  }

  .\32xl\:focus\:grid:focus {
    display: grid
  }

  .\32xl\:focus\:inline-grid:focus {
    display: inline-grid
  }

  .\32xl\:focus\:contents:focus {
    display: contents
  }

  .\32xl\:focus\:list-item:focus {
    display: list-item
  }

  .\32xl\:focus\:hidden:focus {
    display: none
  }

  .\32xl\:h-0 {
    height: 0
  }

  .\32xl\:h-1 {
    height: 0.25rem
  }

  .\32xl\:h-2 {
    height: 0.5rem
  }

  .\32xl\:h-3 {
    height: 0.75rem
  }

  .\32xl\:h-4 {
    height: 0.4rem
  }

  .\32xl\:h-5 {
    height: 1.25rem
  }

  .\32xl\:h-6 {
    height: 0.6rem
  }

  .\32xl\:h-7 {
    height: 1.75rem
  }

  .\32xl\:h-8 {
    height: 0.8rem
  }

  .\32xl\:h-9 {
    height: 2.25rem
  }

  .\32xl\:h-10 {
    height: 2.5rem
  }

  .\32xl\:h-11 {
    height: 2.75rem
  }

  .\32xl\:h-12 {
    height: 1.2rem
  }

  .\32xl\:h-14 {
    height: 3.5rem
  }

  .\32xl\:h-16 {
    height: 1.6rem
  }

  .\32xl\:h-20 {
    height: 2rem
  }

  .\32xl\:h-24 {
    height: 2.4rem
  }

  .\32xl\:h-28 {
    height: 2.8rem
  }

  .\32xl\:h-32 {
    height: 3.2rem
  }

  .\32xl\:h-36 {
    height: 3.6rem
  }

  .\32xl\:h-40 {
    height: 4rem
  }

  .\32xl\:h-44 {
    height: 4.4rem
  }

  .\32xl\:h-48 {
    height: 4.8rem
  }

  .\32xl\:h-52 {
    height: 5.2rem
  }

  .\32xl\:h-56 {
    height: 5.6rem
  }

  .\32xl\:h-60 {
    height: 6rem
  }

  .\32xl\:h-64 {
    height: 6.4rem
  }

  .\32xl\:h-68 {
    height: 6.8rem
  }

  .\32xl\:h-72 {
    height: 7.2rem
  }

  .\32xl\:h-76 {
    height: 7.6rem
  }

  .\32xl\:h-80 {
    height: 8rem
  }

  .\32xl\:h-84 {
    height: 8.4rem
  }

  .\32xl\:h-88 {
    height: 8.8rem
  }

  .\32xl\:h-92 {
    height: 9.2rem
  }

  .\32xl\:h-96 {
    height: 9.6rem
  }

  .\32xl\:h-128 {
    height: 12.8rem
  }

  .\32xl\:h-136 {
    height: 13.6rem
  }

  .\32xl\:h-160 {
    height: 16rem
  }

  .\32xl\:h-192 {
    height: 19.2rem
  }

  .\32xl\:h-200 {
    height: 20rem
  }

  .\32xl\:h-208 {
    height: 20.8rem
  }

  .\32xl\:h-216 {
    height: 21.6rem
  }

  .\32xl\:h-224 {
    height: 22.4rem
  }

  .\32xl\:h-256 {
    height: 25.6rem
  }

  .\32xl\:h-288 {
    height: 28.8rem
  }

  .\32xl\:h-320 {
    height: 32rem
  }

  .\32xl\:h-360 {
    height: 36rem
  }

  .\32xl\:h-384 {
    height: 38.4rem
  }

  .\32xl\:h-400 {
    height: 40rem
  }

  .\32xl\:h-512 {
    height: 51.2rem
  }

  .\32xl\:h-640 {
    height: 64rem
  }

  .\32xl\:h-auto {
    height: auto
  }

  .\32xl\:h-px {
    height: 1px
  }

  .\32xl\:h-0\.5 {
    height: 0.125rem
  }

  .\32xl\:h-1\.5 {
    height: 0.375rem
  }

  .\32xl\:h-2\.5 {
    height: 0.625rem
  }

  .\32xl\:h-3\.5 {
    height: 0.875rem
  }

  .\32xl\:h-xs {
    height: 32rem
  }

  .\32xl\:h-sm {
    height: 48rem
  }

  .\32xl\:h-md {
    height: 64rem
  }

  .\32xl\:h-lg {
    height: 80rem
  }

  .\32xl\:h-xl {
    height: 96rem
  }

  .\32xl\:h-2xl {
    height: 112rem
  }

  .\32xl\:h-3xl {
    height: 128rem
  }

  .\32xl\:h-4xl {
    height: 144rem
  }

  .\32xl\:h-5xl {
    height: 160rem
  }

  .\32xl\:h-1\/2 {
    height: 50%
  }

  .\32xl\:h-1\/3 {
    height: 33.333333%
  }

  .\32xl\:h-2\/3 {
    height: 66.666667%
  }

  .\32xl\:h-1\/4 {
    height: 25%
  }

  .\32xl\:h-2\/4 {
    height: 50%
  }

  .\32xl\:h-3\/4 {
    height: 75%
  }

  .\32xl\:h-1\/5 {
    height: 20%
  }

  .\32xl\:h-2\/5 {
    height: 40%
  }

  .\32xl\:h-3\/5 {
    height: 60%
  }

  .\32xl\:h-4\/5 {
    height: 80%
  }

  .\32xl\:h-1\/6 {
    height: 16.666667%
  }

  .\32xl\:h-2\/6 {
    height: 33.333333%
  }

  .\32xl\:h-3\/6 {
    height: 50%
  }

  .\32xl\:h-4\/6 {
    height: 66.666667%
  }

  .\32xl\:h-5\/6 {
    height: 83.333333%
  }

  .\32xl\:h-full {
    height: 100%
  }

  .\32xl\:h-screen {
    height: 100vh
  }

  .\32xl\:max-h-0 {
    max-height: 0
  }

  .\32xl\:max-h-1 {
    max-height: 0.25rem
  }

  .\32xl\:max-h-2 {
    max-height: 0.5rem
  }

  .\32xl\:max-h-3 {
    max-height: 0.75rem
  }

  .\32xl\:max-h-4 {
    max-height: 0.4rem
  }

  .\32xl\:max-h-5 {
    max-height: 1.25rem
  }

  .\32xl\:max-h-6 {
    max-height: 0.6rem
  }

  .\32xl\:max-h-7 {
    max-height: 1.75rem
  }

  .\32xl\:max-h-8 {
    max-height: 0.8rem
  }

  .\32xl\:max-h-9 {
    max-height: 2.25rem
  }

  .\32xl\:max-h-10 {
    max-height: 2.5rem
  }

  .\32xl\:max-h-11 {
    max-height: 2.75rem
  }

  .\32xl\:max-h-12 {
    max-height: 1.2rem
  }

  .\32xl\:max-h-14 {
    max-height: 3.5rem
  }

  .\32xl\:max-h-16 {
    max-height: 1.6rem
  }

  .\32xl\:max-h-20 {
    max-height: 2rem
  }

  .\32xl\:max-h-24 {
    max-height: 2.4rem
  }

  .\32xl\:max-h-28 {
    max-height: 2.8rem
  }

  .\32xl\:max-h-32 {
    max-height: 3.2rem
  }

  .\32xl\:max-h-36 {
    max-height: 3.6rem
  }

  .\32xl\:max-h-40 {
    max-height: 4rem
  }

  .\32xl\:max-h-44 {
    max-height: 4.4rem
  }

  .\32xl\:max-h-48 {
    max-height: 4.8rem
  }

  .\32xl\:max-h-52 {
    max-height: 5.2rem
  }

  .\32xl\:max-h-56 {
    max-height: 5.6rem
  }

  .\32xl\:max-h-60 {
    max-height: 6rem
  }

  .\32xl\:max-h-64 {
    max-height: 6.4rem
  }

  .\32xl\:max-h-68 {
    max-height: 6.8rem
  }

  .\32xl\:max-h-72 {
    max-height: 7.2rem
  }

  .\32xl\:max-h-76 {
    max-height: 7.6rem
  }

  .\32xl\:max-h-80 {
    max-height: 8rem
  }

  .\32xl\:max-h-84 {
    max-height: 8.4rem
  }

  .\32xl\:max-h-88 {
    max-height: 8.8rem
  }

  .\32xl\:max-h-92 {
    max-height: 9.2rem
  }

  .\32xl\:max-h-96 {
    max-height: 9.6rem
  }

  .\32xl\:max-h-128 {
    max-height: 12.8rem
  }

  .\32xl\:max-h-136 {
    max-height: 13.6rem
  }

  .\32xl\:max-h-160 {
    max-height: 16rem
  }

  .\32xl\:max-h-192 {
    max-height: 19.2rem
  }

  .\32xl\:max-h-200 {
    max-height: 20rem
  }

  .\32xl\:max-h-208 {
    max-height: 20.8rem
  }

  .\32xl\:max-h-216 {
    max-height: 21.6rem
  }

  .\32xl\:max-h-224 {
    max-height: 22.4rem
  }

  .\32xl\:max-h-256 {
    max-height: 25.6rem
  }

  .\32xl\:max-h-288 {
    max-height: 28.8rem
  }

  .\32xl\:max-h-320 {
    max-height: 32rem
  }

  .\32xl\:max-h-360 {
    max-height: 36rem
  }

  .\32xl\:max-h-384 {
    max-height: 38.4rem
  }

  .\32xl\:max-h-400 {
    max-height: 40rem
  }

  .\32xl\:max-h-512 {
    max-height: 51.2rem
  }

  .\32xl\:max-h-640 {
    max-height: 64rem
  }

  .\32xl\:max-h-px {
    max-height: 1px
  }

  .\32xl\:max-h-0\.5 {
    max-height: 0.125rem
  }

  .\32xl\:max-h-1\.5 {
    max-height: 0.375rem
  }

  .\32xl\:max-h-2\.5 {
    max-height: 0.625rem
  }

  .\32xl\:max-h-3\.5 {
    max-height: 0.875rem
  }

  .\32xl\:max-h-xs {
    max-height: 32rem
  }

  .\32xl\:max-h-sm {
    max-height: 48rem
  }

  .\32xl\:max-h-md {
    max-height: 64rem
  }

  .\32xl\:max-h-lg {
    max-height: 80rem
  }

  .\32xl\:max-h-xl {
    max-height: 96rem
  }

  .\32xl\:max-h-2xl {
    max-height: 112rem
  }

  .\32xl\:max-h-3xl {
    max-height: 128rem
  }

  .\32xl\:max-h-4xl {
    max-height: 144rem
  }

  .\32xl\:max-h-5xl {
    max-height: 160rem
  }

  .\32xl\:max-h-full {
    max-height: 100%
  }

  .\32xl\:max-h-screen {
    max-height: 100vh
  }

  .\32xl\:max-h-auto {
    max-height: auto
  }

  .\32xl\:min-h-0 {
    min-height: 0
  }

  .\32xl\:min-h-1 {
    min-height: 0.25rem
  }

  .\32xl\:min-h-2 {
    min-height: 0.5rem
  }

  .\32xl\:min-h-3 {
    min-height: 0.75rem
  }

  .\32xl\:min-h-4 {
    min-height: 0.4rem
  }

  .\32xl\:min-h-5 {
    min-height: 1.25rem
  }

  .\32xl\:min-h-6 {
    min-height: 0.6rem
  }

  .\32xl\:min-h-7 {
    min-height: 1.75rem
  }

  .\32xl\:min-h-8 {
    min-height: 0.8rem
  }

  .\32xl\:min-h-9 {
    min-height: 2.25rem
  }

  .\32xl\:min-h-10 {
    min-height: 2.5rem
  }

  .\32xl\:min-h-11 {
    min-height: 2.75rem
  }

  .\32xl\:min-h-12 {
    min-height: 1.2rem
  }

  .\32xl\:min-h-14 {
    min-height: 3.5rem
  }

  .\32xl\:min-h-16 {
    min-height: 1.6rem
  }

  .\32xl\:min-h-20 {
    min-height: 2rem
  }

  .\32xl\:min-h-24 {
    min-height: 2.4rem
  }

  .\32xl\:min-h-28 {
    min-height: 2.8rem
  }

  .\32xl\:min-h-32 {
    min-height: 3.2rem
  }

  .\32xl\:min-h-36 {
    min-height: 3.6rem
  }

  .\32xl\:min-h-40 {
    min-height: 4rem
  }

  .\32xl\:min-h-44 {
    min-height: 4.4rem
  }

  .\32xl\:min-h-48 {
    min-height: 4.8rem
  }

  .\32xl\:min-h-52 {
    min-height: 5.2rem
  }

  .\32xl\:min-h-56 {
    min-height: 5.6rem
  }

  .\32xl\:min-h-60 {
    min-height: 6rem
  }

  .\32xl\:min-h-64 {
    min-height: 6.4rem
  }

  .\32xl\:min-h-68 {
    min-height: 6.8rem
  }

  .\32xl\:min-h-72 {
    min-height: 7.2rem
  }

  .\32xl\:min-h-76 {
    min-height: 7.6rem
  }

  .\32xl\:min-h-80 {
    min-height: 8rem
  }

  .\32xl\:min-h-84 {
    min-height: 8.4rem
  }

  .\32xl\:min-h-88 {
    min-height: 8.8rem
  }

  .\32xl\:min-h-92 {
    min-height: 9.2rem
  }

  .\32xl\:min-h-96 {
    min-height: 9.6rem
  }

  .\32xl\:min-h-128 {
    min-height: 12.8rem
  }

  .\32xl\:min-h-136 {
    min-height: 13.6rem
  }

  .\32xl\:min-h-160 {
    min-height: 16rem
  }

  .\32xl\:min-h-192 {
    min-height: 19.2rem
  }

  .\32xl\:min-h-200 {
    min-height: 20rem
  }

  .\32xl\:min-h-208 {
    min-height: 20.8rem
  }

  .\32xl\:min-h-216 {
    min-height: 21.6rem
  }

  .\32xl\:min-h-224 {
    min-height: 22.4rem
  }

  .\32xl\:min-h-256 {
    min-height: 25.6rem
  }

  .\32xl\:min-h-288 {
    min-height: 28.8rem
  }

  .\32xl\:min-h-320 {
    min-height: 32rem
  }

  .\32xl\:min-h-360 {
    min-height: 36rem
  }

  .\32xl\:min-h-384 {
    min-height: 38.4rem
  }

  .\32xl\:min-h-400 {
    min-height: 40rem
  }

  .\32xl\:min-h-512 {
    min-height: 51.2rem
  }

  .\32xl\:min-h-640 {
    min-height: 64rem
  }

  .\32xl\:min-h-full {
    min-height: 100%
  }

  .\32xl\:min-h-screen {
    min-height: 100vh
  }

  .\32xl\:min-h-auto {
    min-height: auto
  }

  .\32xl\:min-h-px {
    min-height: 1px
  }

  .\32xl\:min-h-0\.5 {
    min-height: 0.125rem
  }

  .\32xl\:min-h-1\.5 {
    min-height: 0.375rem
  }

  .\32xl\:min-h-2\.5 {
    min-height: 0.625rem
  }

  .\32xl\:min-h-3\.5 {
    min-height: 0.875rem
  }

  .\32xl\:min-h-xs {
    min-height: 32rem
  }

  .\32xl\:min-h-sm {
    min-height: 48rem
  }

  .\32xl\:min-h-md {
    min-height: 64rem
  }

  .\32xl\:min-h-lg {
    min-height: 80rem
  }

  .\32xl\:min-h-xl {
    min-height: 96rem
  }

  .\32xl\:min-h-2xl {
    min-height: 112rem
  }

  .\32xl\:min-h-3xl {
    min-height: 128rem
  }

  .\32xl\:min-h-4xl {
    min-height: 144rem
  }

  .\32xl\:min-h-5xl {
    min-height: 160rem
  }

  .\32xl\:w-0 {
    width: 0
  }

  .\32xl\:w-1 {
    width: 0.25rem
  }

  .\32xl\:w-2 {
    width: 0.5rem
  }

  .\32xl\:w-3 {
    width: 0.75rem
  }

  .\32xl\:w-4 {
    width: 0.4rem
  }

  .\32xl\:w-5 {
    width: 1.25rem
  }

  .\32xl\:w-6 {
    width: 0.6rem
  }

  .\32xl\:w-7 {
    width: 1.75rem
  }

  .\32xl\:w-8 {
    width: 0.8rem
  }

  .\32xl\:w-9 {
    width: 2.25rem
  }

  .\32xl\:w-10 {
    width: 2.5rem
  }

  .\32xl\:w-11 {
    width: 2.75rem
  }

  .\32xl\:w-12 {
    width: 1.2rem
  }

  .\32xl\:w-14 {
    width: 3.5rem
  }

  .\32xl\:w-16 {
    width: 1.6rem
  }

  .\32xl\:w-20 {
    width: 2rem
  }

  .\32xl\:w-24 {
    width: 2.4rem
  }

  .\32xl\:w-28 {
    width: 2.8rem
  }

  .\32xl\:w-32 {
    width: 3.2rem
  }

  .\32xl\:w-36 {
    width: 3.6rem
  }

  .\32xl\:w-40 {
    width: 4rem
  }

  .\32xl\:w-44 {
    width: 4.4rem
  }

  .\32xl\:w-48 {
    width: 4.8rem
  }

  .\32xl\:w-52 {
    width: 5.2rem
  }

  .\32xl\:w-56 {
    width: 5.6rem
  }

  .\32xl\:w-60 {
    width: 6rem
  }

  .\32xl\:w-64 {
    width: 6.4rem
  }

  .\32xl\:w-68 {
    width: 6.8rem
  }

  .\32xl\:w-72 {
    width: 7.2rem
  }

  .\32xl\:w-76 {
    width: 7.6rem
  }

  .\32xl\:w-80 {
    width: 8rem
  }

  .\32xl\:w-84 {
    width: 8.4rem
  }

  .\32xl\:w-88 {
    width: 8.8rem
  }

  .\32xl\:w-92 {
    width: 9.2rem
  }

  .\32xl\:w-96 {
    width: 9.6rem
  }

  .\32xl\:w-128 {
    width: 12.8rem
  }

  .\32xl\:w-136 {
    width: 13.6rem
  }

  .\32xl\:w-160 {
    width: 16rem
  }

  .\32xl\:w-192 {
    width: 19.2rem
  }

  .\32xl\:w-200 {
    width: 20rem
  }

  .\32xl\:w-208 {
    width: 20.8rem
  }

  .\32xl\:w-216 {
    width: 21.6rem
  }

  .\32xl\:w-224 {
    width: 22.4rem
  }

  .\32xl\:w-256 {
    width: 25.6rem
  }

  .\32xl\:w-288 {
    width: 28.8rem
  }

  .\32xl\:w-320 {
    width: 32rem
  }

  .\32xl\:w-360 {
    width: 36rem
  }

  .\32xl\:w-384 {
    width: 38.4rem
  }

  .\32xl\:w-400 {
    width: 40rem
  }

  .\32xl\:w-512 {
    width: 51.2rem
  }

  .\32xl\:w-640 {
    width: 64rem
  }

  .\32xl\:w-auto {
    width: auto
  }

  .\32xl\:w-px {
    width: 1px
  }

  .\32xl\:w-0\.5 {
    width: 0.125rem
  }

  .\32xl\:w-1\.5 {
    width: 0.375rem
  }

  .\32xl\:w-2\.5 {
    width: 0.625rem
  }

  .\32xl\:w-3\.5 {
    width: 0.875rem
  }

  .\32xl\:w-xs {
    width: 32rem
  }

  .\32xl\:w-sm {
    width: 48rem
  }

  .\32xl\:w-md {
    width: 64rem
  }

  .\32xl\:w-lg {
    width: 80rem
  }

  .\32xl\:w-xl {
    width: 96rem
  }

  .\32xl\:w-2xl {
    width: 112rem
  }

  .\32xl\:w-3xl {
    width: 128rem
  }

  .\32xl\:w-4xl {
    width: 144rem
  }

  .\32xl\:w-5xl {
    width: 160rem
  }

  .\32xl\:w-1\/2 {
    width: 50%
  }

  .\32xl\:w-1\/3 {
    width: 33.333333%
  }

  .\32xl\:w-2\/3 {
    width: 66.666667%
  }

  .\32xl\:w-1\/4 {
    width: 25%
  }

  .\32xl\:w-2\/4 {
    width: 50%
  }

  .\32xl\:w-3\/4 {
    width: 75%
  }

  .\32xl\:w-1\/5 {
    width: 20%
  }

  .\32xl\:w-2\/5 {
    width: 40%
  }

  .\32xl\:w-3\/5 {
    width: 60%
  }

  .\32xl\:w-4\/5 {
    width: 80%
  }

  .\32xl\:w-1\/6 {
    width: 16.666667%
  }

  .\32xl\:w-2\/6 {
    width: 33.333333%
  }

  .\32xl\:w-3\/6 {
    width: 50%
  }

  .\32xl\:w-4\/6 {
    width: 66.666667%
  }

  .\32xl\:w-5\/6 {
    width: 83.333333%
  }

  .\32xl\:w-1\/12 {
    width: 8.333333%
  }

  .\32xl\:w-2\/12 {
    width: 16.666667%
  }

  .\32xl\:w-3\/12 {
    width: 25%
  }

  .\32xl\:w-4\/12 {
    width: 33.333333%
  }

  .\32xl\:w-5\/12 {
    width: 41.666667%
  }

  .\32xl\:w-6\/12 {
    width: 50%
  }

  .\32xl\:w-7\/12 {
    width: 58.333333%
  }

  .\32xl\:w-8\/12 {
    width: 66.666667%
  }

  .\32xl\:w-9\/12 {
    width: 75%
  }

  .\32xl\:w-10\/12 {
    width: 83.333333%
  }

  .\32xl\:w-11\/12 {
    width: 91.666667%
  }

  .\32xl\:w-full {
    width: 100%
  }

  .\32xl\:w-screen {
    width: 100vw
  }

  .\32xl\:w-min {
    width: -webkit-min-content;
    width: min-content
  }

  .\32xl\:w-max {
    width: -webkit-max-content;
    width: max-content
  }

  .\32xl\:min-w-0 {
    min-width: 0
  }

  .\32xl\:min-w-1 {
    min-width: 0.25rem
  }

  .\32xl\:min-w-2 {
    min-width: 0.5rem
  }

  .\32xl\:min-w-3 {
    min-width: 0.75rem
  }

  .\32xl\:min-w-4 {
    min-width: 0.4rem
  }

  .\32xl\:min-w-5 {
    min-width: 1.25rem
  }

  .\32xl\:min-w-6 {
    min-width: 0.6rem
  }

  .\32xl\:min-w-7 {
    min-width: 1.75rem
  }

  .\32xl\:min-w-8 {
    min-width: 0.8rem
  }

  .\32xl\:min-w-9 {
    min-width: 2.25rem
  }

  .\32xl\:min-w-10 {
    min-width: 2.5rem
  }

  .\32xl\:min-w-11 {
    min-width: 2.75rem
  }

  .\32xl\:min-w-12 {
    min-width: 1.2rem
  }

  .\32xl\:min-w-14 {
    min-width: 3.5rem
  }

  .\32xl\:min-w-16 {
    min-width: 1.6rem
  }

  .\32xl\:min-w-20 {
    min-width: 2rem
  }

  .\32xl\:min-w-24 {
    min-width: 2.4rem
  }

  .\32xl\:min-w-28 {
    min-width: 2.8rem
  }

  .\32xl\:min-w-32 {
    min-width: 3.2rem
  }

  .\32xl\:min-w-36 {
    min-width: 3.6rem
  }

  .\32xl\:min-w-40 {
    min-width: 4rem
  }

  .\32xl\:min-w-44 {
    min-width: 4.4rem
  }

  .\32xl\:min-w-48 {
    min-width: 4.8rem
  }

  .\32xl\:min-w-52 {
    min-width: 5.2rem
  }

  .\32xl\:min-w-56 {
    min-width: 5.6rem
  }

  .\32xl\:min-w-60 {
    min-width: 6rem
  }

  .\32xl\:min-w-64 {
    min-width: 6.4rem
  }

  .\32xl\:min-w-68 {
    min-width: 6.8rem
  }

  .\32xl\:min-w-72 {
    min-width: 7.2rem
  }

  .\32xl\:min-w-76 {
    min-width: 7.6rem
  }

  .\32xl\:min-w-80 {
    min-width: 8rem
  }

  .\32xl\:min-w-84 {
    min-width: 8.4rem
  }

  .\32xl\:min-w-88 {
    min-width: 8.8rem
  }

  .\32xl\:min-w-92 {
    min-width: 9.2rem
  }

  .\32xl\:min-w-96 {
    min-width: 9.6rem
  }

  .\32xl\:min-w-128 {
    min-width: 12.8rem
  }

  .\32xl\:min-w-136 {
    min-width: 13.6rem
  }

  .\32xl\:min-w-160 {
    min-width: 16rem
  }

  .\32xl\:min-w-192 {
    min-width: 19.2rem
  }

  .\32xl\:min-w-200 {
    min-width: 20rem
  }

  .\32xl\:min-w-208 {
    min-width: 20.8rem
  }

  .\32xl\:min-w-216 {
    min-width: 21.6rem
  }

  .\32xl\:min-w-224 {
    min-width: 22.4rem
  }

  .\32xl\:min-w-256 {
    min-width: 25.6rem
  }

  .\32xl\:min-w-288 {
    min-width: 28.8rem
  }

  .\32xl\:min-w-320 {
    min-width: 32rem
  }

  .\32xl\:min-w-360 {
    min-width: 36rem
  }

  .\32xl\:min-w-384 {
    min-width: 38.4rem
  }

  .\32xl\:min-w-400 {
    min-width: 40rem
  }

  .\32xl\:min-w-512 {
    min-width: 51.2rem
  }

  .\32xl\:min-w-640 {
    min-width: 64rem
  }

  .\32xl\:min-w-full {
    min-width: 100%
  }

  .\32xl\:min-w-min {
    min-width: -webkit-min-content;
    min-width: min-content
  }

  .\32xl\:min-w-max {
    min-width: -webkit-max-content;
    min-width: max-content
  }

  .\32xl\:min-w-px {
    min-width: 1px
  }

  .\32xl\:min-w-0\.5 {
    min-width: 0.125rem
  }

  .\32xl\:min-w-1\.5 {
    min-width: 0.375rem
  }

  .\32xl\:min-w-2\.5 {
    min-width: 0.625rem
  }

  .\32xl\:min-w-3\.5 {
    min-width: 0.875rem
  }

  .\32xl\:min-w-xs {
    min-width: 32rem
  }

  .\32xl\:min-w-sm {
    min-width: 48rem
  }

  .\32xl\:min-w-md {
    min-width: 64rem
  }

  .\32xl\:min-w-lg {
    min-width: 80rem
  }

  .\32xl\:min-w-xl {
    min-width: 96rem
  }

  .\32xl\:min-w-2xl {
    min-width: 112rem
  }

  .\32xl\:min-w-3xl {
    min-width: 128rem
  }

  .\32xl\:min-w-4xl {
    min-width: 144rem
  }

  .\32xl\:min-w-5xl {
    min-width: 160rem
  }

  .\32xl\:min-w-screen {
    min-width: 100vw
  }

  .\32xl\:max-w-0 {
    max-width: 0
  }

  .\32xl\:max-w-1 {
    max-width: 0.25rem
  }

  .\32xl\:max-w-2 {
    max-width: 0.5rem
  }

  .\32xl\:max-w-3 {
    max-width: 0.75rem
  }

  .\32xl\:max-w-4 {
    max-width: 0.4rem
  }

  .\32xl\:max-w-5 {
    max-width: 1.25rem
  }

  .\32xl\:max-w-6 {
    max-width: 0.6rem
  }

  .\32xl\:max-w-7 {
    max-width: 1.75rem
  }

  .\32xl\:max-w-8 {
    max-width: 0.8rem
  }

  .\32xl\:max-w-9 {
    max-width: 2.25rem
  }

  .\32xl\:max-w-10 {
    max-width: 2.5rem
  }

  .\32xl\:max-w-11 {
    max-width: 2.75rem
  }

  .\32xl\:max-w-12 {
    max-width: 1.2rem
  }

  .\32xl\:max-w-14 {
    max-width: 3.5rem
  }

  .\32xl\:max-w-16 {
    max-width: 1.6rem
  }

  .\32xl\:max-w-20 {
    max-width: 2rem
  }

  .\32xl\:max-w-24 {
    max-width: 2.4rem
  }

  .\32xl\:max-w-28 {
    max-width: 2.8rem
  }

  .\32xl\:max-w-32 {
    max-width: 3.2rem
  }

  .\32xl\:max-w-36 {
    max-width: 3.6rem
  }

  .\32xl\:max-w-40 {
    max-width: 4rem
  }

  .\32xl\:max-w-44 {
    max-width: 4.4rem
  }

  .\32xl\:max-w-48 {
    max-width: 4.8rem
  }

  .\32xl\:max-w-52 {
    max-width: 5.2rem
  }

  .\32xl\:max-w-56 {
    max-width: 5.6rem
  }

  .\32xl\:max-w-60 {
    max-width: 6rem
  }

  .\32xl\:max-w-64 {
    max-width: 6.4rem
  }

  .\32xl\:max-w-68 {
    max-width: 6.8rem
  }

  .\32xl\:max-w-72 {
    max-width: 7.2rem
  }

  .\32xl\:max-w-76 {
    max-width: 7.6rem
  }

  .\32xl\:max-w-80 {
    max-width: 8rem
  }

  .\32xl\:max-w-84 {
    max-width: 8.4rem
  }

  .\32xl\:max-w-88 {
    max-width: 8.8rem
  }

  .\32xl\:max-w-92 {
    max-width: 9.2rem
  }

  .\32xl\:max-w-96 {
    max-width: 9.6rem
  }

  .\32xl\:max-w-128 {
    max-width: 12.8rem
  }

  .\32xl\:max-w-136 {
    max-width: 13.6rem
  }

  .\32xl\:max-w-160 {
    max-width: 16rem
  }

  .\32xl\:max-w-192 {
    max-width: 19.2rem
  }

  .\32xl\:max-w-200 {
    max-width: 20rem
  }

  .\32xl\:max-w-208 {
    max-width: 20.8rem
  }

  .\32xl\:max-w-216 {
    max-width: 21.6rem
  }

  .\32xl\:max-w-224 {
    max-width: 22.4rem
  }

  .\32xl\:max-w-256 {
    max-width: 25.6rem
  }

  .\32xl\:max-w-288 {
    max-width: 28.8rem
  }

  .\32xl\:max-w-320 {
    max-width: 32rem
  }

  .\32xl\:max-w-360 {
    max-width: 36rem
  }

  .\32xl\:max-w-384 {
    max-width: 38.4rem
  }

  .\32xl\:max-w-400 {
    max-width: 40rem
  }

  .\32xl\:max-w-512 {
    max-width: 51.2rem
  }

  .\32xl\:max-w-640 {
    max-width: 64rem
  }

  .\32xl\:max-w-none {
    max-width: none
  }

  .\32xl\:max-w-xs {
    max-width: 32rem
  }

  .\32xl\:max-w-sm {
    max-width: 48rem
  }

  .\32xl\:max-w-md {
    max-width: 64rem
  }

  .\32xl\:max-w-lg {
    max-width: 80rem
  }

  .\32xl\:max-w-xl {
    max-width: 96rem
  }

  .\32xl\:max-w-2xl {
    max-width: 112rem
  }

  .\32xl\:max-w-3xl {
    max-width: 128rem
  }

  .\32xl\:max-w-4xl {
    max-width: 144rem
  }

  .\32xl\:max-w-5xl {
    max-width: 160rem
  }

  .\32xl\:max-w-6xl {
    max-width: 72rem
  }

  .\32xl\:max-w-7xl {
    max-width: 80rem
  }

  .\32xl\:max-w-full {
    max-width: 100%
  }

  .\32xl\:max-w-min {
    max-width: -webkit-min-content;
    max-width: min-content
  }

  .\32xl\:max-w-max {
    max-width: -webkit-max-content;
    max-width: max-content
  }

  .\32xl\:max-w-prose {
    max-width: 65ch
  }

  .\32xl\:max-w-screen-sm {
    max-width: 600px
  }

  .\32xl\:max-w-screen-md {
    max-width: 960px
  }

  .\32xl\:max-w-screen-lg {
    max-width: 1280px
  }

  .\32xl\:max-w-screen-xl {
    max-width: 1920px
  }

  .\32xl\:max-w-screen-2xl {
    max-width: 1536px
  }

  .\32xl\:max-w-px {
    max-width: 1px
  }

  .\32xl\:max-w-0\.5 {
    max-width: 0.125rem
  }

  .\32xl\:max-w-1\.5 {
    max-width: 0.375rem
  }

  .\32xl\:max-w-2\.5 {
    max-width: 0.625rem
  }

  .\32xl\:max-w-3\.5 {
    max-width: 0.875rem
  }

  .\32xl\:max-w-screen {
    max-width: 100vw
  }

  .\32xl\:flex-1 {
    flex: 1 1 0%
  }

  .\32xl\:flex-auto {
    flex: 1 1 auto
  }

  .\32xl\:flex-initial {
    flex: 0 1 auto
  }

  .\32xl\:flex-none {
    flex: none
  }

  .\32xl\:flex-shrink-0 {
    flex-shrink: 0
  }

  .\32xl\:flex-shrink {
    flex-shrink: 1
  }

  .\32xl\:flex-shrink-default {
    flex-shrink: 1
  }

  .\32xl\:flex-grow-0 {
    flex-grow: 0
  }

  .\32xl\:flex-grow {
    flex-grow: 1
  }

  .\32xl\:flex-grow-default {
    flex-grow: 1
  }

  .\32xl\:table-auto {
    table-layout: auto
  }

  .\32xl\:table-fixed {
    table-layout: fixed
  }

  .\32xl\:border-collapse {
    border-collapse: collapse
  }

  .\32xl\:border-separate {
    border-collapse: separate
  }

  .\32xl\:origin-center {
    transform-origin: center
  }

  .\32xl\:origin-top {
    transform-origin: top
  }

  .\32xl\:origin-top-right {
    transform-origin: top right
  }

  .\32xl\:origin-right {
    transform-origin: right
  }

  .\32xl\:origin-bottom-right {
    transform-origin: bottom right
  }

  .\32xl\:origin-bottom {
    transform-origin: bottom
  }

  .\32xl\:origin-bottom-left {
    transform-origin: bottom left
  }

  .\32xl\:origin-left {
    transform-origin: left
  }

  .\32xl\:origin-top-left {
    transform-origin: top left
  }

  .\32xl\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .\32xl\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .\32xl\:transform-none {
    transform: none
  }

  .\32xl\:translate-x-0 {
    --tw-translate-x: 0
  }

  .\32xl\:translate-x-1 {
    --tw-translate-x: 0.25rem
  }

  .\32xl\:translate-x-2 {
    --tw-translate-x: 0.5rem
  }

  .\32xl\:translate-x-3 {
    --tw-translate-x: 0.75rem
  }

  .\32xl\:translate-x-4 {
    --tw-translate-x: 0.4rem
  }

  .\32xl\:translate-x-5 {
    --tw-translate-x: 1.25rem
  }

  .\32xl\:translate-x-6 {
    --tw-translate-x: 0.6rem
  }

  .\32xl\:translate-x-7 {
    --tw-translate-x: 1.75rem
  }

  .\32xl\:translate-x-8 {
    --tw-translate-x: 0.8rem
  }

  .\32xl\:translate-x-9 {
    --tw-translate-x: 2.25rem
  }

  .\32xl\:translate-x-10 {
    --tw-translate-x: 2.5rem
  }

  .\32xl\:translate-x-11 {
    --tw-translate-x: 2.75rem
  }

  .\32xl\:translate-x-12 {
    --tw-translate-x: 1.2rem
  }

  .\32xl\:translate-x-14 {
    --tw-translate-x: 3.5rem
  }

  .\32xl\:translate-x-16 {
    --tw-translate-x: 1.6rem
  }

  .\32xl\:translate-x-20 {
    --tw-translate-x: 2rem
  }

  .\32xl\:translate-x-24 {
    --tw-translate-x: 2.4rem
  }

  .\32xl\:translate-x-28 {
    --tw-translate-x: 2.8rem
  }

  .\32xl\:translate-x-32 {
    --tw-translate-x: 3.2rem
  }

  .\32xl\:translate-x-36 {
    --tw-translate-x: 3.6rem
  }

  .\32xl\:translate-x-40 {
    --tw-translate-x: 4rem
  }

  .\32xl\:translate-x-44 {
    --tw-translate-x: 4.4rem
  }

  .\32xl\:translate-x-48 {
    --tw-translate-x: 4.8rem
  }

  .\32xl\:translate-x-52 {
    --tw-translate-x: 5.2rem
  }

  .\32xl\:translate-x-56 {
    --tw-translate-x: 5.6rem
  }

  .\32xl\:translate-x-60 {
    --tw-translate-x: 6rem
  }

  .\32xl\:translate-x-64 {
    --tw-translate-x: 6.4rem
  }

  .\32xl\:translate-x-68 {
    --tw-translate-x: 6.8rem
  }

  .\32xl\:translate-x-72 {
    --tw-translate-x: 7.2rem
  }

  .\32xl\:translate-x-76 {
    --tw-translate-x: 7.6rem
  }

  .\32xl\:translate-x-80 {
    --tw-translate-x: 8rem
  }

  .\32xl\:translate-x-84 {
    --tw-translate-x: 8.4rem
  }

  .\32xl\:translate-x-88 {
    --tw-translate-x: 8.8rem
  }

  .\32xl\:translate-x-92 {
    --tw-translate-x: 9.2rem
  }

  .\32xl\:translate-x-96 {
    --tw-translate-x: 9.6rem
  }

  .\32xl\:translate-x-128 {
    --tw-translate-x: 12.8rem
  }

  .\32xl\:translate-x-136 {
    --tw-translate-x: 13.6rem
  }

  .\32xl\:translate-x-160 {
    --tw-translate-x: 16rem
  }

  .\32xl\:translate-x-192 {
    --tw-translate-x: 19.2rem
  }

  .\32xl\:translate-x-200 {
    --tw-translate-x: 20rem
  }

  .\32xl\:translate-x-208 {
    --tw-translate-x: 20.8rem
  }

  .\32xl\:translate-x-216 {
    --tw-translate-x: 21.6rem
  }

  .\32xl\:translate-x-224 {
    --tw-translate-x: 22.4rem
  }

  .\32xl\:translate-x-256 {
    --tw-translate-x: 25.6rem
  }

  .\32xl\:translate-x-288 {
    --tw-translate-x: 28.8rem
  }

  .\32xl\:translate-x-320 {
    --tw-translate-x: 32rem
  }

  .\32xl\:translate-x-360 {
    --tw-translate-x: 36rem
  }

  .\32xl\:translate-x-384 {
    --tw-translate-x: 38.4rem
  }

  .\32xl\:translate-x-400 {
    --tw-translate-x: 40rem
  }

  .\32xl\:translate-x-512 {
    --tw-translate-x: 51.2rem
  }

  .\32xl\:translate-x-640 {
    --tw-translate-x: 64rem
  }

  .\32xl\:translate-x-px {
    --tw-translate-x: 1px
  }

  .\32xl\:translate-x-0\.5 {
    --tw-translate-x: 0.125rem
  }

  .\32xl\:translate-x-1\.5 {
    --tw-translate-x: 0.375rem
  }

  .\32xl\:translate-x-2\.5 {
    --tw-translate-x: 0.625rem
  }

  .\32xl\:translate-x-3\.5 {
    --tw-translate-x: 0.875rem
  }

  .\32xl\:translate-x-xs {
    --tw-translate-x: 32rem
  }

  .\32xl\:translate-x-sm {
    --tw-translate-x: 48rem
  }

  .\32xl\:translate-x-md {
    --tw-translate-x: 64rem
  }

  .\32xl\:translate-x-lg {
    --tw-translate-x: 80rem
  }

  .\32xl\:translate-x-xl {
    --tw-translate-x: 96rem
  }

  .\32xl\:translate-x-2xl {
    --tw-translate-x: 112rem
  }

  .\32xl\:translate-x-3xl {
    --tw-translate-x: 128rem
  }

  .\32xl\:translate-x-4xl {
    --tw-translate-x: 144rem
  }

  .\32xl\:translate-x-5xl {
    --tw-translate-x: 160rem
  }

  .\32xl\:-translate-x-1 {
    --tw-translate-x: -0.25rem
  }

  .\32xl\:-translate-x-2 {
    --tw-translate-x: -0.5rem
  }

  .\32xl\:-translate-x-3 {
    --tw-translate-x: -0.75rem
  }

  .\32xl\:-translate-x-4 {
    --tw-translate-x: -0.4rem
  }

  .\32xl\:-translate-x-5 {
    --tw-translate-x: -1.25rem
  }

  .\32xl\:-translate-x-6 {
    --tw-translate-x: -0.6rem
  }

  .\32xl\:-translate-x-7 {
    --tw-translate-x: -1.75rem
  }

  .\32xl\:-translate-x-8 {
    --tw-translate-x: -0.8rem
  }

  .\32xl\:-translate-x-9 {
    --tw-translate-x: -2.25rem
  }

  .\32xl\:-translate-x-10 {
    --tw-translate-x: -2.5rem
  }

  .\32xl\:-translate-x-11 {
    --tw-translate-x: -2.75rem
  }

  .\32xl\:-translate-x-12 {
    --tw-translate-x: -1.2rem
  }

  .\32xl\:-translate-x-14 {
    --tw-translate-x: -3.5rem
  }

  .\32xl\:-translate-x-16 {
    --tw-translate-x: -1.6rem
  }

  .\32xl\:-translate-x-20 {
    --tw-translate-x: -2rem
  }

  .\32xl\:-translate-x-24 {
    --tw-translate-x: -2.4rem
  }

  .\32xl\:-translate-x-28 {
    --tw-translate-x: -2.8rem
  }

  .\32xl\:-translate-x-32 {
    --tw-translate-x: -3.2rem
  }

  .\32xl\:-translate-x-36 {
    --tw-translate-x: -3.6rem
  }

  .\32xl\:-translate-x-40 {
    --tw-translate-x: -4rem
  }

  .\32xl\:-translate-x-44 {
    --tw-translate-x: -4.4rem
  }

  .\32xl\:-translate-x-48 {
    --tw-translate-x: -4.8rem
  }

  .\32xl\:-translate-x-52 {
    --tw-translate-x: -5.2rem
  }

  .\32xl\:-translate-x-56 {
    --tw-translate-x: -5.6rem
  }

  .\32xl\:-translate-x-60 {
    --tw-translate-x: -6rem
  }

  .\32xl\:-translate-x-64 {
    --tw-translate-x: -6.4rem
  }

  .\32xl\:-translate-x-68 {
    --tw-translate-x: -6.8rem
  }

  .\32xl\:-translate-x-72 {
    --tw-translate-x: -7.2rem
  }

  .\32xl\:-translate-x-76 {
    --tw-translate-x: -7.6rem
  }

  .\32xl\:-translate-x-80 {
    --tw-translate-x: -8rem
  }

  .\32xl\:-translate-x-84 {
    --tw-translate-x: -8.4rem
  }

  .\32xl\:-translate-x-88 {
    --tw-translate-x: -8.8rem
  }

  .\32xl\:-translate-x-92 {
    --tw-translate-x: -9.2rem
  }

  .\32xl\:-translate-x-96 {
    --tw-translate-x: -9.6rem
  }

  .\32xl\:-translate-x-128 {
    --tw-translate-x: -12.8rem
  }

  .\32xl\:-translate-x-136 {
    --tw-translate-x: -13.6rem
  }

  .\32xl\:-translate-x-160 {
    --tw-translate-x: -16rem
  }

  .\32xl\:-translate-x-192 {
    --tw-translate-x: -19.2rem
  }

  .\32xl\:-translate-x-200 {
    --tw-translate-x: -20rem
  }

  .\32xl\:-translate-x-208 {
    --tw-translate-x: -20.8rem
  }

  .\32xl\:-translate-x-216 {
    --tw-translate-x: -21.6rem
  }

  .\32xl\:-translate-x-224 {
    --tw-translate-x: -22.4rem
  }

  .\32xl\:-translate-x-256 {
    --tw-translate-x: -25.6rem
  }

  .\32xl\:-translate-x-288 {
    --tw-translate-x: -28.8rem
  }

  .\32xl\:-translate-x-320 {
    --tw-translate-x: -32rem
  }

  .\32xl\:-translate-x-360 {
    --tw-translate-x: -36rem
  }

  .\32xl\:-translate-x-384 {
    --tw-translate-x: -38.4rem
  }

  .\32xl\:-translate-x-400 {
    --tw-translate-x: -40rem
  }

  .\32xl\:-translate-x-512 {
    --tw-translate-x: -51.2rem
  }

  .\32xl\:-translate-x-640 {
    --tw-translate-x: -64rem
  }

  .\32xl\:-translate-x-px {
    --tw-translate-x: -1px
  }

  .\32xl\:-translate-x-0\.5 {
    --tw-translate-x: -0.125rem
  }

  .\32xl\:-translate-x-1\.5 {
    --tw-translate-x: -0.375rem
  }

  .\32xl\:-translate-x-2\.5 {
    --tw-translate-x: -0.625rem
  }

  .\32xl\:-translate-x-3\.5 {
    --tw-translate-x: -0.875rem
  }

  .\32xl\:-translate-x-xs {
    --tw-translate-x: -32rem
  }

  .\32xl\:-translate-x-sm {
    --tw-translate-x: -48rem
  }

  .\32xl\:-translate-x-md {
    --tw-translate-x: -64rem
  }

  .\32xl\:-translate-x-lg {
    --tw-translate-x: -80rem
  }

  .\32xl\:-translate-x-xl {
    --tw-translate-x: -96rem
  }

  .\32xl\:-translate-x-2xl {
    --tw-translate-x: -112rem
  }

  .\32xl\:-translate-x-3xl {
    --tw-translate-x: -128rem
  }

  .\32xl\:-translate-x-4xl {
    --tw-translate-x: -144rem
  }

  .\32xl\:-translate-x-5xl {
    --tw-translate-x: -160rem
  }

  .\32xl\:translate-x-1\/2 {
    --tw-translate-x: 50%
  }

  .\32xl\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%
  }

  .\32xl\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%
  }

  .\32xl\:translate-x-1\/4 {
    --tw-translate-x: 25%
  }

  .\32xl\:translate-x-2\/4 {
    --tw-translate-x: 50%
  }

  .\32xl\:translate-x-3\/4 {
    --tw-translate-x: 75%
  }

  .\32xl\:translate-x-full {
    --tw-translate-x: 100%
  }

  .\32xl\:-translate-x-1\/2 {
    --tw-translate-x: -50%
  }

  .\32xl\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%
  }

  .\32xl\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%
  }

  .\32xl\:-translate-x-1\/4 {
    --tw-translate-x: -25%
  }

  .\32xl\:-translate-x-2\/4 {
    --tw-translate-x: -50%
  }

  .\32xl\:-translate-x-3\/4 {
    --tw-translate-x: -75%
  }

  .\32xl\:-translate-x-full {
    --tw-translate-x: -100%
  }

  .\32xl\:translate-y-0 {
    --tw-translate-y: 0
  }

  .\32xl\:translate-y-1 {
    --tw-translate-y: 0.25rem
  }

  .\32xl\:translate-y-2 {
    --tw-translate-y: 0.5rem
  }

  .\32xl\:translate-y-3 {
    --tw-translate-y: 0.75rem
  }

  .\32xl\:translate-y-4 {
    --tw-translate-y: 0.4rem
  }

  .\32xl\:translate-y-5 {
    --tw-translate-y: 1.25rem
  }

  .\32xl\:translate-y-6 {
    --tw-translate-y: 0.6rem
  }

  .\32xl\:translate-y-7 {
    --tw-translate-y: 1.75rem
  }

  .\32xl\:translate-y-8 {
    --tw-translate-y: 0.8rem
  }

  .\32xl\:translate-y-9 {
    --tw-translate-y: 2.25rem
  }

  .\32xl\:translate-y-10 {
    --tw-translate-y: 2.5rem
  }

  .\32xl\:translate-y-11 {
    --tw-translate-y: 2.75rem
  }

  .\32xl\:translate-y-12 {
    --tw-translate-y: 1.2rem
  }

  .\32xl\:translate-y-14 {
    --tw-translate-y: 3.5rem
  }

  .\32xl\:translate-y-16 {
    --tw-translate-y: 1.6rem
  }

  .\32xl\:translate-y-20 {
    --tw-translate-y: 2rem
  }

  .\32xl\:translate-y-24 {
    --tw-translate-y: 2.4rem
  }

  .\32xl\:translate-y-28 {
    --tw-translate-y: 2.8rem
  }

  .\32xl\:translate-y-32 {
    --tw-translate-y: 3.2rem
  }

  .\32xl\:translate-y-36 {
    --tw-translate-y: 3.6rem
  }

  .\32xl\:translate-y-40 {
    --tw-translate-y: 4rem
  }

  .\32xl\:translate-y-44 {
    --tw-translate-y: 4.4rem
  }

  .\32xl\:translate-y-48 {
    --tw-translate-y: 4.8rem
  }

  .\32xl\:translate-y-52 {
    --tw-translate-y: 5.2rem
  }

  .\32xl\:translate-y-56 {
    --tw-translate-y: 5.6rem
  }

  .\32xl\:translate-y-60 {
    --tw-translate-y: 6rem
  }

  .\32xl\:translate-y-64 {
    --tw-translate-y: 6.4rem
  }

  .\32xl\:translate-y-68 {
    --tw-translate-y: 6.8rem
  }

  .\32xl\:translate-y-72 {
    --tw-translate-y: 7.2rem
  }

  .\32xl\:translate-y-76 {
    --tw-translate-y: 7.6rem
  }

  .\32xl\:translate-y-80 {
    --tw-translate-y: 8rem
  }

  .\32xl\:translate-y-84 {
    --tw-translate-y: 8.4rem
  }

  .\32xl\:translate-y-88 {
    --tw-translate-y: 8.8rem
  }

  .\32xl\:translate-y-92 {
    --tw-translate-y: 9.2rem
  }

  .\32xl\:translate-y-96 {
    --tw-translate-y: 9.6rem
  }

  .\32xl\:translate-y-128 {
    --tw-translate-y: 12.8rem
  }

  .\32xl\:translate-y-136 {
    --tw-translate-y: 13.6rem
  }

  .\32xl\:translate-y-160 {
    --tw-translate-y: 16rem
  }

  .\32xl\:translate-y-192 {
    --tw-translate-y: 19.2rem
  }

  .\32xl\:translate-y-200 {
    --tw-translate-y: 20rem
  }

  .\32xl\:translate-y-208 {
    --tw-translate-y: 20.8rem
  }

  .\32xl\:translate-y-216 {
    --tw-translate-y: 21.6rem
  }

  .\32xl\:translate-y-224 {
    --tw-translate-y: 22.4rem
  }

  .\32xl\:translate-y-256 {
    --tw-translate-y: 25.6rem
  }

  .\32xl\:translate-y-288 {
    --tw-translate-y: 28.8rem
  }

  .\32xl\:translate-y-320 {
    --tw-translate-y: 32rem
  }

  .\32xl\:translate-y-360 {
    --tw-translate-y: 36rem
  }

  .\32xl\:translate-y-384 {
    --tw-translate-y: 38.4rem
  }

  .\32xl\:translate-y-400 {
    --tw-translate-y: 40rem
  }

  .\32xl\:translate-y-512 {
    --tw-translate-y: 51.2rem
  }

  .\32xl\:translate-y-640 {
    --tw-translate-y: 64rem
  }

  .\32xl\:translate-y-px {
    --tw-translate-y: 1px
  }

  .\32xl\:translate-y-0\.5 {
    --tw-translate-y: 0.125rem
  }

  .\32xl\:translate-y-1\.5 {
    --tw-translate-y: 0.375rem
  }

  .\32xl\:translate-y-2\.5 {
    --tw-translate-y: 0.625rem
  }

  .\32xl\:translate-y-3\.5 {
    --tw-translate-y: 0.875rem
  }

  .\32xl\:translate-y-xs {
    --tw-translate-y: 32rem
  }

  .\32xl\:translate-y-sm {
    --tw-translate-y: 48rem
  }

  .\32xl\:translate-y-md {
    --tw-translate-y: 64rem
  }

  .\32xl\:translate-y-lg {
    --tw-translate-y: 80rem
  }

  .\32xl\:translate-y-xl {
    --tw-translate-y: 96rem
  }

  .\32xl\:translate-y-2xl {
    --tw-translate-y: 112rem
  }

  .\32xl\:translate-y-3xl {
    --tw-translate-y: 128rem
  }

  .\32xl\:translate-y-4xl {
    --tw-translate-y: 144rem
  }

  .\32xl\:translate-y-5xl {
    --tw-translate-y: 160rem
  }

  .\32xl\:-translate-y-1 {
    --tw-translate-y: -0.25rem
  }

  .\32xl\:-translate-y-2 {
    --tw-translate-y: -0.5rem
  }

  .\32xl\:-translate-y-3 {
    --tw-translate-y: -0.75rem
  }

  .\32xl\:-translate-y-4 {
    --tw-translate-y: -0.4rem
  }

  .\32xl\:-translate-y-5 {
    --tw-translate-y: -1.25rem
  }

  .\32xl\:-translate-y-6 {
    --tw-translate-y: -0.6rem
  }

  .\32xl\:-translate-y-7 {
    --tw-translate-y: -1.75rem
  }

  .\32xl\:-translate-y-8 {
    --tw-translate-y: -0.8rem
  }

  .\32xl\:-translate-y-9 {
    --tw-translate-y: -2.25rem
  }

  .\32xl\:-translate-y-10 {
    --tw-translate-y: -2.5rem
  }

  .\32xl\:-translate-y-11 {
    --tw-translate-y: -2.75rem
  }

  .\32xl\:-translate-y-12 {
    --tw-translate-y: -1.2rem
  }

  .\32xl\:-translate-y-14 {
    --tw-translate-y: -3.5rem
  }

  .\32xl\:-translate-y-16 {
    --tw-translate-y: -1.6rem
  }

  .\32xl\:-translate-y-20 {
    --tw-translate-y: -2rem
  }

  .\32xl\:-translate-y-24 {
    --tw-translate-y: -2.4rem
  }

  .\32xl\:-translate-y-28 {
    --tw-translate-y: -2.8rem
  }

  .\32xl\:-translate-y-32 {
    --tw-translate-y: -3.2rem
  }

  .\32xl\:-translate-y-36 {
    --tw-translate-y: -3.6rem
  }

  .\32xl\:-translate-y-40 {
    --tw-translate-y: -4rem
  }

  .\32xl\:-translate-y-44 {
    --tw-translate-y: -4.4rem
  }

  .\32xl\:-translate-y-48 {
    --tw-translate-y: -4.8rem
  }

  .\32xl\:-translate-y-52 {
    --tw-translate-y: -5.2rem
  }

  .\32xl\:-translate-y-56 {
    --tw-translate-y: -5.6rem
  }

  .\32xl\:-translate-y-60 {
    --tw-translate-y: -6rem
  }

  .\32xl\:-translate-y-64 {
    --tw-translate-y: -6.4rem
  }

  .\32xl\:-translate-y-68 {
    --tw-translate-y: -6.8rem
  }

  .\32xl\:-translate-y-72 {
    --tw-translate-y: -7.2rem
  }

  .\32xl\:-translate-y-76 {
    --tw-translate-y: -7.6rem
  }

  .\32xl\:-translate-y-80 {
    --tw-translate-y: -8rem
  }

  .\32xl\:-translate-y-84 {
    --tw-translate-y: -8.4rem
  }

  .\32xl\:-translate-y-88 {
    --tw-translate-y: -8.8rem
  }

  .\32xl\:-translate-y-92 {
    --tw-translate-y: -9.2rem
  }

  .\32xl\:-translate-y-96 {
    --tw-translate-y: -9.6rem
  }

  .\32xl\:-translate-y-128 {
    --tw-translate-y: -12.8rem
  }

  .\32xl\:-translate-y-136 {
    --tw-translate-y: -13.6rem
  }

  .\32xl\:-translate-y-160 {
    --tw-translate-y: -16rem
  }

  .\32xl\:-translate-y-192 {
    --tw-translate-y: -19.2rem
  }

  .\32xl\:-translate-y-200 {
    --tw-translate-y: -20rem
  }

  .\32xl\:-translate-y-208 {
    --tw-translate-y: -20.8rem
  }

  .\32xl\:-translate-y-216 {
    --tw-translate-y: -21.6rem
  }

  .\32xl\:-translate-y-224 {
    --tw-translate-y: -22.4rem
  }

  .\32xl\:-translate-y-256 {
    --tw-translate-y: -25.6rem
  }

  .\32xl\:-translate-y-288 {
    --tw-translate-y: -28.8rem
  }

  .\32xl\:-translate-y-320 {
    --tw-translate-y: -32rem
  }

  .\32xl\:-translate-y-360 {
    --tw-translate-y: -36rem
  }

  .\32xl\:-translate-y-384 {
    --tw-translate-y: -38.4rem
  }

  .\32xl\:-translate-y-400 {
    --tw-translate-y: -40rem
  }

  .\32xl\:-translate-y-512 {
    --tw-translate-y: -51.2rem
  }

  .\32xl\:-translate-y-640 {
    --tw-translate-y: -64rem
  }

  .\32xl\:-translate-y-px {
    --tw-translate-y: -1px
  }

  .\32xl\:-translate-y-0\.5 {
    --tw-translate-y: -0.125rem
  }

  .\32xl\:-translate-y-1\.5 {
    --tw-translate-y: -0.375rem
  }

  .\32xl\:-translate-y-2\.5 {
    --tw-translate-y: -0.625rem
  }

  .\32xl\:-translate-y-3\.5 {
    --tw-translate-y: -0.875rem
  }

  .\32xl\:-translate-y-xs {
    --tw-translate-y: -32rem
  }

  .\32xl\:-translate-y-sm {
    --tw-translate-y: -48rem
  }

  .\32xl\:-translate-y-md {
    --tw-translate-y: -64rem
  }

  .\32xl\:-translate-y-lg {
    --tw-translate-y: -80rem
  }

  .\32xl\:-translate-y-xl {
    --tw-translate-y: -96rem
  }

  .\32xl\:-translate-y-2xl {
    --tw-translate-y: -112rem
  }

  .\32xl\:-translate-y-3xl {
    --tw-translate-y: -128rem
  }

  .\32xl\:-translate-y-4xl {
    --tw-translate-y: -144rem
  }

  .\32xl\:-translate-y-5xl {
    --tw-translate-y: -160rem
  }

  .\32xl\:translate-y-1\/2 {
    --tw-translate-y: 50%
  }

  .\32xl\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%
  }

  .\32xl\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%
  }

  .\32xl\:translate-y-1\/4 {
    --tw-translate-y: 25%
  }

  .\32xl\:translate-y-2\/4 {
    --tw-translate-y: 50%
  }

  .\32xl\:translate-y-3\/4 {
    --tw-translate-y: 75%
  }

  .\32xl\:translate-y-full {
    --tw-translate-y: 100%
  }

  .\32xl\:-translate-y-1\/2 {
    --tw-translate-y: -50%
  }

  .\32xl\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%
  }

  .\32xl\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%
  }

  .\32xl\:-translate-y-1\/4 {
    --tw-translate-y: -25%
  }

  .\32xl\:-translate-y-2\/4 {
    --tw-translate-y: -50%
  }

  .\32xl\:-translate-y-3\/4 {
    --tw-translate-y: -75%
  }

  .\32xl\:-translate-y-full {
    --tw-translate-y: -100%
  }

  .\32xl\:hover\:translate-x-0:hover {
    --tw-translate-x: 0
  }

  .\32xl\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem
  }

  .\32xl\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem
  }

  .\32xl\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem
  }

  .\32xl\:hover\:translate-x-4:hover {
    --tw-translate-x: 0.4rem
  }

  .\32xl\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem
  }

  .\32xl\:hover\:translate-x-6:hover {
    --tw-translate-x: 0.6rem
  }

  .\32xl\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem
  }

  .\32xl\:hover\:translate-x-8:hover {
    --tw-translate-x: 0.8rem
  }

  .\32xl\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem
  }

  .\32xl\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem
  }

  .\32xl\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem
  }

  .\32xl\:hover\:translate-x-12:hover {
    --tw-translate-x: 1.2rem
  }

  .\32xl\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem
  }

  .\32xl\:hover\:translate-x-16:hover {
    --tw-translate-x: 1.6rem
  }

  .\32xl\:hover\:translate-x-20:hover {
    --tw-translate-x: 2rem
  }

  .\32xl\:hover\:translate-x-24:hover {
    --tw-translate-x: 2.4rem
  }

  .\32xl\:hover\:translate-x-28:hover {
    --tw-translate-x: 2.8rem
  }

  .\32xl\:hover\:translate-x-32:hover {
    --tw-translate-x: 3.2rem
  }

  .\32xl\:hover\:translate-x-36:hover {
    --tw-translate-x: 3.6rem
  }

  .\32xl\:hover\:translate-x-40:hover {
    --tw-translate-x: 4rem
  }

  .\32xl\:hover\:translate-x-44:hover {
    --tw-translate-x: 4.4rem
  }

  .\32xl\:hover\:translate-x-48:hover {
    --tw-translate-x: 4.8rem
  }

  .\32xl\:hover\:translate-x-52:hover {
    --tw-translate-x: 5.2rem
  }

  .\32xl\:hover\:translate-x-56:hover {
    --tw-translate-x: 5.6rem
  }

  .\32xl\:hover\:translate-x-60:hover {
    --tw-translate-x: 6rem
  }

  .\32xl\:hover\:translate-x-64:hover {
    --tw-translate-x: 6.4rem
  }

  .\32xl\:hover\:translate-x-68:hover {
    --tw-translate-x: 6.8rem
  }

  .\32xl\:hover\:translate-x-72:hover {
    --tw-translate-x: 7.2rem
  }

  .\32xl\:hover\:translate-x-76:hover {
    --tw-translate-x: 7.6rem
  }

  .\32xl\:hover\:translate-x-80:hover {
    --tw-translate-x: 8rem
  }

  .\32xl\:hover\:translate-x-84:hover {
    --tw-translate-x: 8.4rem
  }

  .\32xl\:hover\:translate-x-88:hover {
    --tw-translate-x: 8.8rem
  }

  .\32xl\:hover\:translate-x-92:hover {
    --tw-translate-x: 9.2rem
  }

  .\32xl\:hover\:translate-x-96:hover {
    --tw-translate-x: 9.6rem
  }

  .\32xl\:hover\:translate-x-128:hover {
    --tw-translate-x: 12.8rem
  }

  .\32xl\:hover\:translate-x-136:hover {
    --tw-translate-x: 13.6rem
  }

  .\32xl\:hover\:translate-x-160:hover {
    --tw-translate-x: 16rem
  }

  .\32xl\:hover\:translate-x-192:hover {
    --tw-translate-x: 19.2rem
  }

  .\32xl\:hover\:translate-x-200:hover {
    --tw-translate-x: 20rem
  }

  .\32xl\:hover\:translate-x-208:hover {
    --tw-translate-x: 20.8rem
  }

  .\32xl\:hover\:translate-x-216:hover {
    --tw-translate-x: 21.6rem
  }

  .\32xl\:hover\:translate-x-224:hover {
    --tw-translate-x: 22.4rem
  }

  .\32xl\:hover\:translate-x-256:hover {
    --tw-translate-x: 25.6rem
  }

  .\32xl\:hover\:translate-x-288:hover {
    --tw-translate-x: 28.8rem
  }

  .\32xl\:hover\:translate-x-320:hover {
    --tw-translate-x: 32rem
  }

  .\32xl\:hover\:translate-x-360:hover {
    --tw-translate-x: 36rem
  }

  .\32xl\:hover\:translate-x-384:hover {
    --tw-translate-x: 38.4rem
  }

  .\32xl\:hover\:translate-x-400:hover {
    --tw-translate-x: 40rem
  }

  .\32xl\:hover\:translate-x-512:hover {
    --tw-translate-x: 51.2rem
  }

  .\32xl\:hover\:translate-x-640:hover {
    --tw-translate-x: 64rem
  }

  .\32xl\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px
  }

  .\32xl\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem
  }

  .\32xl\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem
  }

  .\32xl\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem
  }

  .\32xl\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem
  }

  .\32xl\:hover\:translate-x-xs:hover {
    --tw-translate-x: 32rem
  }

  .\32xl\:hover\:translate-x-sm:hover {
    --tw-translate-x: 48rem
  }

  .\32xl\:hover\:translate-x-md:hover {
    --tw-translate-x: 64rem
  }

  .\32xl\:hover\:translate-x-lg:hover {
    --tw-translate-x: 80rem
  }

  .\32xl\:hover\:translate-x-xl:hover {
    --tw-translate-x: 96rem
  }

  .\32xl\:hover\:translate-x-2xl:hover {
    --tw-translate-x: 112rem
  }

  .\32xl\:hover\:translate-x-3xl:hover {
    --tw-translate-x: 128rem
  }

  .\32xl\:hover\:translate-x-4xl:hover {
    --tw-translate-x: 144rem
  }

  .\32xl\:hover\:translate-x-5xl:hover {
    --tw-translate-x: 160rem
  }

  .\32xl\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem
  }

  .\32xl\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem
  }

  .\32xl\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem
  }

  .\32xl\:hover\:-translate-x-4:hover {
    --tw-translate-x: -0.4rem
  }

  .\32xl\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem
  }

  .\32xl\:hover\:-translate-x-6:hover {
    --tw-translate-x: -0.6rem
  }

  .\32xl\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem
  }

  .\32xl\:hover\:-translate-x-8:hover {
    --tw-translate-x: -0.8rem
  }

  .\32xl\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem
  }

  .\32xl\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem
  }

  .\32xl\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem
  }

  .\32xl\:hover\:-translate-x-12:hover {
    --tw-translate-x: -1.2rem
  }

  .\32xl\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem
  }

  .\32xl\:hover\:-translate-x-16:hover {
    --tw-translate-x: -1.6rem
  }

  .\32xl\:hover\:-translate-x-20:hover {
    --tw-translate-x: -2rem
  }

  .\32xl\:hover\:-translate-x-24:hover {
    --tw-translate-x: -2.4rem
  }

  .\32xl\:hover\:-translate-x-28:hover {
    --tw-translate-x: -2.8rem
  }

  .\32xl\:hover\:-translate-x-32:hover {
    --tw-translate-x: -3.2rem
  }

  .\32xl\:hover\:-translate-x-36:hover {
    --tw-translate-x: -3.6rem
  }

  .\32xl\:hover\:-translate-x-40:hover {
    --tw-translate-x: -4rem
  }

  .\32xl\:hover\:-translate-x-44:hover {
    --tw-translate-x: -4.4rem
  }

  .\32xl\:hover\:-translate-x-48:hover {
    --tw-translate-x: -4.8rem
  }

  .\32xl\:hover\:-translate-x-52:hover {
    --tw-translate-x: -5.2rem
  }

  .\32xl\:hover\:-translate-x-56:hover {
    --tw-translate-x: -5.6rem
  }

  .\32xl\:hover\:-translate-x-60:hover {
    --tw-translate-x: -6rem
  }

  .\32xl\:hover\:-translate-x-64:hover {
    --tw-translate-x: -6.4rem
  }

  .\32xl\:hover\:-translate-x-68:hover {
    --tw-translate-x: -6.8rem
  }

  .\32xl\:hover\:-translate-x-72:hover {
    --tw-translate-x: -7.2rem
  }

  .\32xl\:hover\:-translate-x-76:hover {
    --tw-translate-x: -7.6rem
  }

  .\32xl\:hover\:-translate-x-80:hover {
    --tw-translate-x: -8rem
  }

  .\32xl\:hover\:-translate-x-84:hover {
    --tw-translate-x: -8.4rem
  }

  .\32xl\:hover\:-translate-x-88:hover {
    --tw-translate-x: -8.8rem
  }

  .\32xl\:hover\:-translate-x-92:hover {
    --tw-translate-x: -9.2rem
  }

  .\32xl\:hover\:-translate-x-96:hover {
    --tw-translate-x: -9.6rem
  }

  .\32xl\:hover\:-translate-x-128:hover {
    --tw-translate-x: -12.8rem
  }

  .\32xl\:hover\:-translate-x-136:hover {
    --tw-translate-x: -13.6rem
  }

  .\32xl\:hover\:-translate-x-160:hover {
    --tw-translate-x: -16rem
  }

  .\32xl\:hover\:-translate-x-192:hover {
    --tw-translate-x: -19.2rem
  }

  .\32xl\:hover\:-translate-x-200:hover {
    --tw-translate-x: -20rem
  }

  .\32xl\:hover\:-translate-x-208:hover {
    --tw-translate-x: -20.8rem
  }

  .\32xl\:hover\:-translate-x-216:hover {
    --tw-translate-x: -21.6rem
  }

  .\32xl\:hover\:-translate-x-224:hover {
    --tw-translate-x: -22.4rem
  }

  .\32xl\:hover\:-translate-x-256:hover {
    --tw-translate-x: -25.6rem
  }

  .\32xl\:hover\:-translate-x-288:hover {
    --tw-translate-x: -28.8rem
  }

  .\32xl\:hover\:-translate-x-320:hover {
    --tw-translate-x: -32rem
  }

  .\32xl\:hover\:-translate-x-360:hover {
    --tw-translate-x: -36rem
  }

  .\32xl\:hover\:-translate-x-384:hover {
    --tw-translate-x: -38.4rem
  }

  .\32xl\:hover\:-translate-x-400:hover {
    --tw-translate-x: -40rem
  }

  .\32xl\:hover\:-translate-x-512:hover {
    --tw-translate-x: -51.2rem
  }

  .\32xl\:hover\:-translate-x-640:hover {
    --tw-translate-x: -64rem
  }

  .\32xl\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px
  }

  .\32xl\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem
  }

  .\32xl\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem
  }

  .\32xl\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem
  }

  .\32xl\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem
  }

  .\32xl\:hover\:-translate-x-xs:hover {
    --tw-translate-x: -32rem
  }

  .\32xl\:hover\:-translate-x-sm:hover {
    --tw-translate-x: -48rem
  }

  .\32xl\:hover\:-translate-x-md:hover {
    --tw-translate-x: -64rem
  }

  .\32xl\:hover\:-translate-x-lg:hover {
    --tw-translate-x: -80rem
  }

  .\32xl\:hover\:-translate-x-xl:hover {
    --tw-translate-x: -96rem
  }

  .\32xl\:hover\:-translate-x-2xl:hover {
    --tw-translate-x: -112rem
  }

  .\32xl\:hover\:-translate-x-3xl:hover {
    --tw-translate-x: -128rem
  }

  .\32xl\:hover\:-translate-x-4xl:hover {
    --tw-translate-x: -144rem
  }

  .\32xl\:hover\:-translate-x-5xl:hover {
    --tw-translate-x: -160rem
  }

  .\32xl\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%
  }

  .\32xl\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%
  }

  .\32xl\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%
  }

  .\32xl\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%
  }

  .\32xl\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%
  }

  .\32xl\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%
  }

  .\32xl\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%
  }

  .\32xl\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%
  }

  .\32xl\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%
  }

  .\32xl\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%
  }

  .\32xl\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%
  }

  .\32xl\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%
  }

  .\32xl\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%
  }

  .\32xl\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%
  }

  .\32xl\:hover\:translate-y-0:hover {
    --tw-translate-y: 0
  }

  .\32xl\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem
  }

  .\32xl\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem
  }

  .\32xl\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem
  }

  .\32xl\:hover\:translate-y-4:hover {
    --tw-translate-y: 0.4rem
  }

  .\32xl\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem
  }

  .\32xl\:hover\:translate-y-6:hover {
    --tw-translate-y: 0.6rem
  }

  .\32xl\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem
  }

  .\32xl\:hover\:translate-y-8:hover {
    --tw-translate-y: 0.8rem
  }

  .\32xl\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem
  }

  .\32xl\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem
  }

  .\32xl\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem
  }

  .\32xl\:hover\:translate-y-12:hover {
    --tw-translate-y: 1.2rem
  }

  .\32xl\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem
  }

  .\32xl\:hover\:translate-y-16:hover {
    --tw-translate-y: 1.6rem
  }

  .\32xl\:hover\:translate-y-20:hover {
    --tw-translate-y: 2rem
  }

  .\32xl\:hover\:translate-y-24:hover {
    --tw-translate-y: 2.4rem
  }

  .\32xl\:hover\:translate-y-28:hover {
    --tw-translate-y: 2.8rem
  }

  .\32xl\:hover\:translate-y-32:hover {
    --tw-translate-y: 3.2rem
  }

  .\32xl\:hover\:translate-y-36:hover {
    --tw-translate-y: 3.6rem
  }

  .\32xl\:hover\:translate-y-40:hover {
    --tw-translate-y: 4rem
  }

  .\32xl\:hover\:translate-y-44:hover {
    --tw-translate-y: 4.4rem
  }

  .\32xl\:hover\:translate-y-48:hover {
    --tw-translate-y: 4.8rem
  }

  .\32xl\:hover\:translate-y-52:hover {
    --tw-translate-y: 5.2rem
  }

  .\32xl\:hover\:translate-y-56:hover {
    --tw-translate-y: 5.6rem
  }

  .\32xl\:hover\:translate-y-60:hover {
    --tw-translate-y: 6rem
  }

  .\32xl\:hover\:translate-y-64:hover {
    --tw-translate-y: 6.4rem
  }

  .\32xl\:hover\:translate-y-68:hover {
    --tw-translate-y: 6.8rem
  }

  .\32xl\:hover\:translate-y-72:hover {
    --tw-translate-y: 7.2rem
  }

  .\32xl\:hover\:translate-y-76:hover {
    --tw-translate-y: 7.6rem
  }

  .\32xl\:hover\:translate-y-80:hover {
    --tw-translate-y: 8rem
  }

  .\32xl\:hover\:translate-y-84:hover {
    --tw-translate-y: 8.4rem
  }

  .\32xl\:hover\:translate-y-88:hover {
    --tw-translate-y: 8.8rem
  }

  .\32xl\:hover\:translate-y-92:hover {
    --tw-translate-y: 9.2rem
  }

  .\32xl\:hover\:translate-y-96:hover {
    --tw-translate-y: 9.6rem
  }

  .\32xl\:hover\:translate-y-128:hover {
    --tw-translate-y: 12.8rem
  }

  .\32xl\:hover\:translate-y-136:hover {
    --tw-translate-y: 13.6rem
  }

  .\32xl\:hover\:translate-y-160:hover {
    --tw-translate-y: 16rem
  }

  .\32xl\:hover\:translate-y-192:hover {
    --tw-translate-y: 19.2rem
  }

  .\32xl\:hover\:translate-y-200:hover {
    --tw-translate-y: 20rem
  }

  .\32xl\:hover\:translate-y-208:hover {
    --tw-translate-y: 20.8rem
  }

  .\32xl\:hover\:translate-y-216:hover {
    --tw-translate-y: 21.6rem
  }

  .\32xl\:hover\:translate-y-224:hover {
    --tw-translate-y: 22.4rem
  }

  .\32xl\:hover\:translate-y-256:hover {
    --tw-translate-y: 25.6rem
  }

  .\32xl\:hover\:translate-y-288:hover {
    --tw-translate-y: 28.8rem
  }

  .\32xl\:hover\:translate-y-320:hover {
    --tw-translate-y: 32rem
  }

  .\32xl\:hover\:translate-y-360:hover {
    --tw-translate-y: 36rem
  }

  .\32xl\:hover\:translate-y-384:hover {
    --tw-translate-y: 38.4rem
  }

  .\32xl\:hover\:translate-y-400:hover {
    --tw-translate-y: 40rem
  }

  .\32xl\:hover\:translate-y-512:hover {
    --tw-translate-y: 51.2rem
  }

  .\32xl\:hover\:translate-y-640:hover {
    --tw-translate-y: 64rem
  }

  .\32xl\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px
  }

  .\32xl\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem
  }

  .\32xl\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem
  }

  .\32xl\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem
  }

  .\32xl\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem
  }

  .\32xl\:hover\:translate-y-xs:hover {
    --tw-translate-y: 32rem
  }

  .\32xl\:hover\:translate-y-sm:hover {
    --tw-translate-y: 48rem
  }

  .\32xl\:hover\:translate-y-md:hover {
    --tw-translate-y: 64rem
  }

  .\32xl\:hover\:translate-y-lg:hover {
    --tw-translate-y: 80rem
  }

  .\32xl\:hover\:translate-y-xl:hover {
    --tw-translate-y: 96rem
  }

  .\32xl\:hover\:translate-y-2xl:hover {
    --tw-translate-y: 112rem
  }

  .\32xl\:hover\:translate-y-3xl:hover {
    --tw-translate-y: 128rem
  }

  .\32xl\:hover\:translate-y-4xl:hover {
    --tw-translate-y: 144rem
  }

  .\32xl\:hover\:translate-y-5xl:hover {
    --tw-translate-y: 160rem
  }

  .\32xl\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem
  }

  .\32xl\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem
  }

  .\32xl\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem
  }

  .\32xl\:hover\:-translate-y-4:hover {
    --tw-translate-y: -0.4rem
  }

  .\32xl\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem
  }

  .\32xl\:hover\:-translate-y-6:hover {
    --tw-translate-y: -0.6rem
  }

  .\32xl\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem
  }

  .\32xl\:hover\:-translate-y-8:hover {
    --tw-translate-y: -0.8rem
  }

  .\32xl\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem
  }

  .\32xl\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem
  }

  .\32xl\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem
  }

  .\32xl\:hover\:-translate-y-12:hover {
    --tw-translate-y: -1.2rem
  }

  .\32xl\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem
  }

  .\32xl\:hover\:-translate-y-16:hover {
    --tw-translate-y: -1.6rem
  }

  .\32xl\:hover\:-translate-y-20:hover {
    --tw-translate-y: -2rem
  }

  .\32xl\:hover\:-translate-y-24:hover {
    --tw-translate-y: -2.4rem
  }

  .\32xl\:hover\:-translate-y-28:hover {
    --tw-translate-y: -2.8rem
  }

  .\32xl\:hover\:-translate-y-32:hover {
    --tw-translate-y: -3.2rem
  }

  .\32xl\:hover\:-translate-y-36:hover {
    --tw-translate-y: -3.6rem
  }

  .\32xl\:hover\:-translate-y-40:hover {
    --tw-translate-y: -4rem
  }

  .\32xl\:hover\:-translate-y-44:hover {
    --tw-translate-y: -4.4rem
  }

  .\32xl\:hover\:-translate-y-48:hover {
    --tw-translate-y: -4.8rem
  }

  .\32xl\:hover\:-translate-y-52:hover {
    --tw-translate-y: -5.2rem
  }

  .\32xl\:hover\:-translate-y-56:hover {
    --tw-translate-y: -5.6rem
  }

  .\32xl\:hover\:-translate-y-60:hover {
    --tw-translate-y: -6rem
  }

  .\32xl\:hover\:-translate-y-64:hover {
    --tw-translate-y: -6.4rem
  }

  .\32xl\:hover\:-translate-y-68:hover {
    --tw-translate-y: -6.8rem
  }

  .\32xl\:hover\:-translate-y-72:hover {
    --tw-translate-y: -7.2rem
  }

  .\32xl\:hover\:-translate-y-76:hover {
    --tw-translate-y: -7.6rem
  }

  .\32xl\:hover\:-translate-y-80:hover {
    --tw-translate-y: -8rem
  }

  .\32xl\:hover\:-translate-y-84:hover {
    --tw-translate-y: -8.4rem
  }

  .\32xl\:hover\:-translate-y-88:hover {
    --tw-translate-y: -8.8rem
  }

  .\32xl\:hover\:-translate-y-92:hover {
    --tw-translate-y: -9.2rem
  }

  .\32xl\:hover\:-translate-y-96:hover {
    --tw-translate-y: -9.6rem
  }

  .\32xl\:hover\:-translate-y-128:hover {
    --tw-translate-y: -12.8rem
  }

  .\32xl\:hover\:-translate-y-136:hover {
    --tw-translate-y: -13.6rem
  }

  .\32xl\:hover\:-translate-y-160:hover {
    --tw-translate-y: -16rem
  }

  .\32xl\:hover\:-translate-y-192:hover {
    --tw-translate-y: -19.2rem
  }

  .\32xl\:hover\:-translate-y-200:hover {
    --tw-translate-y: -20rem
  }

  .\32xl\:hover\:-translate-y-208:hover {
    --tw-translate-y: -20.8rem
  }

  .\32xl\:hover\:-translate-y-216:hover {
    --tw-translate-y: -21.6rem
  }

  .\32xl\:hover\:-translate-y-224:hover {
    --tw-translate-y: -22.4rem
  }

  .\32xl\:hover\:-translate-y-256:hover {
    --tw-translate-y: -25.6rem
  }

  .\32xl\:hover\:-translate-y-288:hover {
    --tw-translate-y: -28.8rem
  }

  .\32xl\:hover\:-translate-y-320:hover {
    --tw-translate-y: -32rem
  }

  .\32xl\:hover\:-translate-y-360:hover {
    --tw-translate-y: -36rem
  }

  .\32xl\:hover\:-translate-y-384:hover {
    --tw-translate-y: -38.4rem
  }

  .\32xl\:hover\:-translate-y-400:hover {
    --tw-translate-y: -40rem
  }

  .\32xl\:hover\:-translate-y-512:hover {
    --tw-translate-y: -51.2rem
  }

  .\32xl\:hover\:-translate-y-640:hover {
    --tw-translate-y: -64rem
  }

  .\32xl\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px
  }

  .\32xl\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem
  }

  .\32xl\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem
  }

  .\32xl\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem
  }

  .\32xl\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem
  }

  .\32xl\:hover\:-translate-y-xs:hover {
    --tw-translate-y: -32rem
  }

  .\32xl\:hover\:-translate-y-sm:hover {
    --tw-translate-y: -48rem
  }

  .\32xl\:hover\:-translate-y-md:hover {
    --tw-translate-y: -64rem
  }

  .\32xl\:hover\:-translate-y-lg:hover {
    --tw-translate-y: -80rem
  }

  .\32xl\:hover\:-translate-y-xl:hover {
    --tw-translate-y: -96rem
  }

  .\32xl\:hover\:-translate-y-2xl:hover {
    --tw-translate-y: -112rem
  }

  .\32xl\:hover\:-translate-y-3xl:hover {
    --tw-translate-y: -128rem
  }

  .\32xl\:hover\:-translate-y-4xl:hover {
    --tw-translate-y: -144rem
  }

  .\32xl\:hover\:-translate-y-5xl:hover {
    --tw-translate-y: -160rem
  }

  .\32xl\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%
  }

  .\32xl\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%
  }

  .\32xl\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%
  }

  .\32xl\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%
  }

  .\32xl\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%
  }

  .\32xl\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%
  }

  .\32xl\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%
  }

  .\32xl\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%
  }

  .\32xl\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%
  }

  .\32xl\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%
  }

  .\32xl\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%
  }

  .\32xl\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%
  }

  .\32xl\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%
  }

  .\32xl\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%
  }

  .\32xl\:focus\:translate-x-0:focus {
    --tw-translate-x: 0
  }

  .\32xl\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem
  }

  .\32xl\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem
  }

  .\32xl\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem
  }

  .\32xl\:focus\:translate-x-4:focus {
    --tw-translate-x: 0.4rem
  }

  .\32xl\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem
  }

  .\32xl\:focus\:translate-x-6:focus {
    --tw-translate-x: 0.6rem
  }

  .\32xl\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem
  }

  .\32xl\:focus\:translate-x-8:focus {
    --tw-translate-x: 0.8rem
  }

  .\32xl\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem
  }

  .\32xl\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem
  }

  .\32xl\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem
  }

  .\32xl\:focus\:translate-x-12:focus {
    --tw-translate-x: 1.2rem
  }

  .\32xl\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem
  }

  .\32xl\:focus\:translate-x-16:focus {
    --tw-translate-x: 1.6rem
  }

  .\32xl\:focus\:translate-x-20:focus {
    --tw-translate-x: 2rem
  }

  .\32xl\:focus\:translate-x-24:focus {
    --tw-translate-x: 2.4rem
  }

  .\32xl\:focus\:translate-x-28:focus {
    --tw-translate-x: 2.8rem
  }

  .\32xl\:focus\:translate-x-32:focus {
    --tw-translate-x: 3.2rem
  }

  .\32xl\:focus\:translate-x-36:focus {
    --tw-translate-x: 3.6rem
  }

  .\32xl\:focus\:translate-x-40:focus {
    --tw-translate-x: 4rem
  }

  .\32xl\:focus\:translate-x-44:focus {
    --tw-translate-x: 4.4rem
  }

  .\32xl\:focus\:translate-x-48:focus {
    --tw-translate-x: 4.8rem
  }

  .\32xl\:focus\:translate-x-52:focus {
    --tw-translate-x: 5.2rem
  }

  .\32xl\:focus\:translate-x-56:focus {
    --tw-translate-x: 5.6rem
  }

  .\32xl\:focus\:translate-x-60:focus {
    --tw-translate-x: 6rem
  }

  .\32xl\:focus\:translate-x-64:focus {
    --tw-translate-x: 6.4rem
  }

  .\32xl\:focus\:translate-x-68:focus {
    --tw-translate-x: 6.8rem
  }

  .\32xl\:focus\:translate-x-72:focus {
    --tw-translate-x: 7.2rem
  }

  .\32xl\:focus\:translate-x-76:focus {
    --tw-translate-x: 7.6rem
  }

  .\32xl\:focus\:translate-x-80:focus {
    --tw-translate-x: 8rem
  }

  .\32xl\:focus\:translate-x-84:focus {
    --tw-translate-x: 8.4rem
  }

  .\32xl\:focus\:translate-x-88:focus {
    --tw-translate-x: 8.8rem
  }

  .\32xl\:focus\:translate-x-92:focus {
    --tw-translate-x: 9.2rem
  }

  .\32xl\:focus\:translate-x-96:focus {
    --tw-translate-x: 9.6rem
  }

  .\32xl\:focus\:translate-x-128:focus {
    --tw-translate-x: 12.8rem
  }

  .\32xl\:focus\:translate-x-136:focus {
    --tw-translate-x: 13.6rem
  }

  .\32xl\:focus\:translate-x-160:focus {
    --tw-translate-x: 16rem
  }

  .\32xl\:focus\:translate-x-192:focus {
    --tw-translate-x: 19.2rem
  }

  .\32xl\:focus\:translate-x-200:focus {
    --tw-translate-x: 20rem
  }

  .\32xl\:focus\:translate-x-208:focus {
    --tw-translate-x: 20.8rem
  }

  .\32xl\:focus\:translate-x-216:focus {
    --tw-translate-x: 21.6rem
  }

  .\32xl\:focus\:translate-x-224:focus {
    --tw-translate-x: 22.4rem
  }

  .\32xl\:focus\:translate-x-256:focus {
    --tw-translate-x: 25.6rem
  }

  .\32xl\:focus\:translate-x-288:focus {
    --tw-translate-x: 28.8rem
  }

  .\32xl\:focus\:translate-x-320:focus {
    --tw-translate-x: 32rem
  }

  .\32xl\:focus\:translate-x-360:focus {
    --tw-translate-x: 36rem
  }

  .\32xl\:focus\:translate-x-384:focus {
    --tw-translate-x: 38.4rem
  }

  .\32xl\:focus\:translate-x-400:focus {
    --tw-translate-x: 40rem
  }

  .\32xl\:focus\:translate-x-512:focus {
    --tw-translate-x: 51.2rem
  }

  .\32xl\:focus\:translate-x-640:focus {
    --tw-translate-x: 64rem
  }

  .\32xl\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px
  }

  .\32xl\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem
  }

  .\32xl\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem
  }

  .\32xl\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem
  }

  .\32xl\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem
  }

  .\32xl\:focus\:translate-x-xs:focus {
    --tw-translate-x: 32rem
  }

  .\32xl\:focus\:translate-x-sm:focus {
    --tw-translate-x: 48rem
  }

  .\32xl\:focus\:translate-x-md:focus {
    --tw-translate-x: 64rem
  }

  .\32xl\:focus\:translate-x-lg:focus {
    --tw-translate-x: 80rem
  }

  .\32xl\:focus\:translate-x-xl:focus {
    --tw-translate-x: 96rem
  }

  .\32xl\:focus\:translate-x-2xl:focus {
    --tw-translate-x: 112rem
  }

  .\32xl\:focus\:translate-x-3xl:focus {
    --tw-translate-x: 128rem
  }

  .\32xl\:focus\:translate-x-4xl:focus {
    --tw-translate-x: 144rem
  }

  .\32xl\:focus\:translate-x-5xl:focus {
    --tw-translate-x: 160rem
  }

  .\32xl\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem
  }

  .\32xl\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem
  }

  .\32xl\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem
  }

  .\32xl\:focus\:-translate-x-4:focus {
    --tw-translate-x: -0.4rem
  }

  .\32xl\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem
  }

  .\32xl\:focus\:-translate-x-6:focus {
    --tw-translate-x: -0.6rem
  }

  .\32xl\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem
  }

  .\32xl\:focus\:-translate-x-8:focus {
    --tw-translate-x: -0.8rem
  }

  .\32xl\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem
  }

  .\32xl\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem
  }

  .\32xl\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem
  }

  .\32xl\:focus\:-translate-x-12:focus {
    --tw-translate-x: -1.2rem
  }

  .\32xl\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem
  }

  .\32xl\:focus\:-translate-x-16:focus {
    --tw-translate-x: -1.6rem
  }

  .\32xl\:focus\:-translate-x-20:focus {
    --tw-translate-x: -2rem
  }

  .\32xl\:focus\:-translate-x-24:focus {
    --tw-translate-x: -2.4rem
  }

  .\32xl\:focus\:-translate-x-28:focus {
    --tw-translate-x: -2.8rem
  }

  .\32xl\:focus\:-translate-x-32:focus {
    --tw-translate-x: -3.2rem
  }

  .\32xl\:focus\:-translate-x-36:focus {
    --tw-translate-x: -3.6rem
  }

  .\32xl\:focus\:-translate-x-40:focus {
    --tw-translate-x: -4rem
  }

  .\32xl\:focus\:-translate-x-44:focus {
    --tw-translate-x: -4.4rem
  }

  .\32xl\:focus\:-translate-x-48:focus {
    --tw-translate-x: -4.8rem
  }

  .\32xl\:focus\:-translate-x-52:focus {
    --tw-translate-x: -5.2rem
  }

  .\32xl\:focus\:-translate-x-56:focus {
    --tw-translate-x: -5.6rem
  }

  .\32xl\:focus\:-translate-x-60:focus {
    --tw-translate-x: -6rem
  }

  .\32xl\:focus\:-translate-x-64:focus {
    --tw-translate-x: -6.4rem
  }

  .\32xl\:focus\:-translate-x-68:focus {
    --tw-translate-x: -6.8rem
  }

  .\32xl\:focus\:-translate-x-72:focus {
    --tw-translate-x: -7.2rem
  }

  .\32xl\:focus\:-translate-x-76:focus {
    --tw-translate-x: -7.6rem
  }

  .\32xl\:focus\:-translate-x-80:focus {
    --tw-translate-x: -8rem
  }

  .\32xl\:focus\:-translate-x-84:focus {
    --tw-translate-x: -8.4rem
  }

  .\32xl\:focus\:-translate-x-88:focus {
    --tw-translate-x: -8.8rem
  }

  .\32xl\:focus\:-translate-x-92:focus {
    --tw-translate-x: -9.2rem
  }

  .\32xl\:focus\:-translate-x-96:focus {
    --tw-translate-x: -9.6rem
  }

  .\32xl\:focus\:-translate-x-128:focus {
    --tw-translate-x: -12.8rem
  }

  .\32xl\:focus\:-translate-x-136:focus {
    --tw-translate-x: -13.6rem
  }

  .\32xl\:focus\:-translate-x-160:focus {
    --tw-translate-x: -16rem
  }

  .\32xl\:focus\:-translate-x-192:focus {
    --tw-translate-x: -19.2rem
  }

  .\32xl\:focus\:-translate-x-200:focus {
    --tw-translate-x: -20rem
  }

  .\32xl\:focus\:-translate-x-208:focus {
    --tw-translate-x: -20.8rem
  }

  .\32xl\:focus\:-translate-x-216:focus {
    --tw-translate-x: -21.6rem
  }

  .\32xl\:focus\:-translate-x-224:focus {
    --tw-translate-x: -22.4rem
  }

  .\32xl\:focus\:-translate-x-256:focus {
    --tw-translate-x: -25.6rem
  }

  .\32xl\:focus\:-translate-x-288:focus {
    --tw-translate-x: -28.8rem
  }

  .\32xl\:focus\:-translate-x-320:focus {
    --tw-translate-x: -32rem
  }

  .\32xl\:focus\:-translate-x-360:focus {
    --tw-translate-x: -36rem
  }

  .\32xl\:focus\:-translate-x-384:focus {
    --tw-translate-x: -38.4rem
  }

  .\32xl\:focus\:-translate-x-400:focus {
    --tw-translate-x: -40rem
  }

  .\32xl\:focus\:-translate-x-512:focus {
    --tw-translate-x: -51.2rem
  }

  .\32xl\:focus\:-translate-x-640:focus {
    --tw-translate-x: -64rem
  }

  .\32xl\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px
  }

  .\32xl\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem
  }

  .\32xl\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem
  }

  .\32xl\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem
  }

  .\32xl\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem
  }

  .\32xl\:focus\:-translate-x-xs:focus {
    --tw-translate-x: -32rem
  }

  .\32xl\:focus\:-translate-x-sm:focus {
    --tw-translate-x: -48rem
  }

  .\32xl\:focus\:-translate-x-md:focus {
    --tw-translate-x: -64rem
  }

  .\32xl\:focus\:-translate-x-lg:focus {
    --tw-translate-x: -80rem
  }

  .\32xl\:focus\:-translate-x-xl:focus {
    --tw-translate-x: -96rem
  }

  .\32xl\:focus\:-translate-x-2xl:focus {
    --tw-translate-x: -112rem
  }

  .\32xl\:focus\:-translate-x-3xl:focus {
    --tw-translate-x: -128rem
  }

  .\32xl\:focus\:-translate-x-4xl:focus {
    --tw-translate-x: -144rem
  }

  .\32xl\:focus\:-translate-x-5xl:focus {
    --tw-translate-x: -160rem
  }

  .\32xl\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%
  }

  .\32xl\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%
  }

  .\32xl\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%
  }

  .\32xl\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%
  }

  .\32xl\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%
  }

  .\32xl\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%
  }

  .\32xl\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%
  }

  .\32xl\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%
  }

  .\32xl\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%
  }

  .\32xl\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%
  }

  .\32xl\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%
  }

  .\32xl\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%
  }

  .\32xl\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%
  }

  .\32xl\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%
  }

  .\32xl\:focus\:translate-y-0:focus {
    --tw-translate-y: 0
  }

  .\32xl\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem
  }

  .\32xl\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem
  }

  .\32xl\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem
  }

  .\32xl\:focus\:translate-y-4:focus {
    --tw-translate-y: 0.4rem
  }

  .\32xl\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem
  }

  .\32xl\:focus\:translate-y-6:focus {
    --tw-translate-y: 0.6rem
  }

  .\32xl\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem
  }

  .\32xl\:focus\:translate-y-8:focus {
    --tw-translate-y: 0.8rem
  }

  .\32xl\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem
  }

  .\32xl\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem
  }

  .\32xl\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem
  }

  .\32xl\:focus\:translate-y-12:focus {
    --tw-translate-y: 1.2rem
  }

  .\32xl\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem
  }

  .\32xl\:focus\:translate-y-16:focus {
    --tw-translate-y: 1.6rem
  }

  .\32xl\:focus\:translate-y-20:focus {
    --tw-translate-y: 2rem
  }

  .\32xl\:focus\:translate-y-24:focus {
    --tw-translate-y: 2.4rem
  }

  .\32xl\:focus\:translate-y-28:focus {
    --tw-translate-y: 2.8rem
  }

  .\32xl\:focus\:translate-y-32:focus {
    --tw-translate-y: 3.2rem
  }

  .\32xl\:focus\:translate-y-36:focus {
    --tw-translate-y: 3.6rem
  }

  .\32xl\:focus\:translate-y-40:focus {
    --tw-translate-y: 4rem
  }

  .\32xl\:focus\:translate-y-44:focus {
    --tw-translate-y: 4.4rem
  }

  .\32xl\:focus\:translate-y-48:focus {
    --tw-translate-y: 4.8rem
  }

  .\32xl\:focus\:translate-y-52:focus {
    --tw-translate-y: 5.2rem
  }

  .\32xl\:focus\:translate-y-56:focus {
    --tw-translate-y: 5.6rem
  }

  .\32xl\:focus\:translate-y-60:focus {
    --tw-translate-y: 6rem
  }

  .\32xl\:focus\:translate-y-64:focus {
    --tw-translate-y: 6.4rem
  }

  .\32xl\:focus\:translate-y-68:focus {
    --tw-translate-y: 6.8rem
  }

  .\32xl\:focus\:translate-y-72:focus {
    --tw-translate-y: 7.2rem
  }

  .\32xl\:focus\:translate-y-76:focus {
    --tw-translate-y: 7.6rem
  }

  .\32xl\:focus\:translate-y-80:focus {
    --tw-translate-y: 8rem
  }

  .\32xl\:focus\:translate-y-84:focus {
    --tw-translate-y: 8.4rem
  }

  .\32xl\:focus\:translate-y-88:focus {
    --tw-translate-y: 8.8rem
  }

  .\32xl\:focus\:translate-y-92:focus {
    --tw-translate-y: 9.2rem
  }

  .\32xl\:focus\:translate-y-96:focus {
    --tw-translate-y: 9.6rem
  }

  .\32xl\:focus\:translate-y-128:focus {
    --tw-translate-y: 12.8rem
  }

  .\32xl\:focus\:translate-y-136:focus {
    --tw-translate-y: 13.6rem
  }

  .\32xl\:focus\:translate-y-160:focus {
    --tw-translate-y: 16rem
  }

  .\32xl\:focus\:translate-y-192:focus {
    --tw-translate-y: 19.2rem
  }

  .\32xl\:focus\:translate-y-200:focus {
    --tw-translate-y: 20rem
  }

  .\32xl\:focus\:translate-y-208:focus {
    --tw-translate-y: 20.8rem
  }

  .\32xl\:focus\:translate-y-216:focus {
    --tw-translate-y: 21.6rem
  }

  .\32xl\:focus\:translate-y-224:focus {
    --tw-translate-y: 22.4rem
  }

  .\32xl\:focus\:translate-y-256:focus {
    --tw-translate-y: 25.6rem
  }

  .\32xl\:focus\:translate-y-288:focus {
    --tw-translate-y: 28.8rem
  }

  .\32xl\:focus\:translate-y-320:focus {
    --tw-translate-y: 32rem
  }

  .\32xl\:focus\:translate-y-360:focus {
    --tw-translate-y: 36rem
  }

  .\32xl\:focus\:translate-y-384:focus {
    --tw-translate-y: 38.4rem
  }

  .\32xl\:focus\:translate-y-400:focus {
    --tw-translate-y: 40rem
  }

  .\32xl\:focus\:translate-y-512:focus {
    --tw-translate-y: 51.2rem
  }

  .\32xl\:focus\:translate-y-640:focus {
    --tw-translate-y: 64rem
  }

  .\32xl\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px
  }

  .\32xl\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem
  }

  .\32xl\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem
  }

  .\32xl\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem
  }

  .\32xl\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem
  }

  .\32xl\:focus\:translate-y-xs:focus {
    --tw-translate-y: 32rem
  }

  .\32xl\:focus\:translate-y-sm:focus {
    --tw-translate-y: 48rem
  }

  .\32xl\:focus\:translate-y-md:focus {
    --tw-translate-y: 64rem
  }

  .\32xl\:focus\:translate-y-lg:focus {
    --tw-translate-y: 80rem
  }

  .\32xl\:focus\:translate-y-xl:focus {
    --tw-translate-y: 96rem
  }

  .\32xl\:focus\:translate-y-2xl:focus {
    --tw-translate-y: 112rem
  }

  .\32xl\:focus\:translate-y-3xl:focus {
    --tw-translate-y: 128rem
  }

  .\32xl\:focus\:translate-y-4xl:focus {
    --tw-translate-y: 144rem
  }

  .\32xl\:focus\:translate-y-5xl:focus {
    --tw-translate-y: 160rem
  }

  .\32xl\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem
  }

  .\32xl\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem
  }

  .\32xl\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem
  }

  .\32xl\:focus\:-translate-y-4:focus {
    --tw-translate-y: -0.4rem
  }

  .\32xl\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem
  }

  .\32xl\:focus\:-translate-y-6:focus {
    --tw-translate-y: -0.6rem
  }

  .\32xl\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem
  }

  .\32xl\:focus\:-translate-y-8:focus {
    --tw-translate-y: -0.8rem
  }

  .\32xl\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem
  }

  .\32xl\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem
  }

  .\32xl\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem
  }

  .\32xl\:focus\:-translate-y-12:focus {
    --tw-translate-y: -1.2rem
  }

  .\32xl\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem
  }

  .\32xl\:focus\:-translate-y-16:focus {
    --tw-translate-y: -1.6rem
  }

  .\32xl\:focus\:-translate-y-20:focus {
    --tw-translate-y: -2rem
  }

  .\32xl\:focus\:-translate-y-24:focus {
    --tw-translate-y: -2.4rem
  }

  .\32xl\:focus\:-translate-y-28:focus {
    --tw-translate-y: -2.8rem
  }

  .\32xl\:focus\:-translate-y-32:focus {
    --tw-translate-y: -3.2rem
  }

  .\32xl\:focus\:-translate-y-36:focus {
    --tw-translate-y: -3.6rem
  }

  .\32xl\:focus\:-translate-y-40:focus {
    --tw-translate-y: -4rem
  }

  .\32xl\:focus\:-translate-y-44:focus {
    --tw-translate-y: -4.4rem
  }

  .\32xl\:focus\:-translate-y-48:focus {
    --tw-translate-y: -4.8rem
  }

  .\32xl\:focus\:-translate-y-52:focus {
    --tw-translate-y: -5.2rem
  }

  .\32xl\:focus\:-translate-y-56:focus {
    --tw-translate-y: -5.6rem
  }

  .\32xl\:focus\:-translate-y-60:focus {
    --tw-translate-y: -6rem
  }

  .\32xl\:focus\:-translate-y-64:focus {
    --tw-translate-y: -6.4rem
  }

  .\32xl\:focus\:-translate-y-68:focus {
    --tw-translate-y: -6.8rem
  }

  .\32xl\:focus\:-translate-y-72:focus {
    --tw-translate-y: -7.2rem
  }

  .\32xl\:focus\:-translate-y-76:focus {
    --tw-translate-y: -7.6rem
  }

  .\32xl\:focus\:-translate-y-80:focus {
    --tw-translate-y: -8rem
  }

  .\32xl\:focus\:-translate-y-84:focus {
    --tw-translate-y: -8.4rem
  }

  .\32xl\:focus\:-translate-y-88:focus {
    --tw-translate-y: -8.8rem
  }

  .\32xl\:focus\:-translate-y-92:focus {
    --tw-translate-y: -9.2rem
  }

  .\32xl\:focus\:-translate-y-96:focus {
    --tw-translate-y: -9.6rem
  }

  .\32xl\:focus\:-translate-y-128:focus {
    --tw-translate-y: -12.8rem
  }

  .\32xl\:focus\:-translate-y-136:focus {
    --tw-translate-y: -13.6rem
  }

  .\32xl\:focus\:-translate-y-160:focus {
    --tw-translate-y: -16rem
  }

  .\32xl\:focus\:-translate-y-192:focus {
    --tw-translate-y: -19.2rem
  }

  .\32xl\:focus\:-translate-y-200:focus {
    --tw-translate-y: -20rem
  }

  .\32xl\:focus\:-translate-y-208:focus {
    --tw-translate-y: -20.8rem
  }

  .\32xl\:focus\:-translate-y-216:focus {
    --tw-translate-y: -21.6rem
  }

  .\32xl\:focus\:-translate-y-224:focus {
    --tw-translate-y: -22.4rem
  }

  .\32xl\:focus\:-translate-y-256:focus {
    --tw-translate-y: -25.6rem
  }

  .\32xl\:focus\:-translate-y-288:focus {
    --tw-translate-y: -28.8rem
  }

  .\32xl\:focus\:-translate-y-320:focus {
    --tw-translate-y: -32rem
  }

  .\32xl\:focus\:-translate-y-360:focus {
    --tw-translate-y: -36rem
  }

  .\32xl\:focus\:-translate-y-384:focus {
    --tw-translate-y: -38.4rem
  }

  .\32xl\:focus\:-translate-y-400:focus {
    --tw-translate-y: -40rem
  }

  .\32xl\:focus\:-translate-y-512:focus {
    --tw-translate-y: -51.2rem
  }

  .\32xl\:focus\:-translate-y-640:focus {
    --tw-translate-y: -64rem
  }

  .\32xl\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px
  }

  .\32xl\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem
  }

  .\32xl\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem
  }

  .\32xl\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem
  }

  .\32xl\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem
  }

  .\32xl\:focus\:-translate-y-xs:focus {
    --tw-translate-y: -32rem
  }

  .\32xl\:focus\:-translate-y-sm:focus {
    --tw-translate-y: -48rem
  }

  .\32xl\:focus\:-translate-y-md:focus {
    --tw-translate-y: -64rem
  }

  .\32xl\:focus\:-translate-y-lg:focus {
    --tw-translate-y: -80rem
  }

  .\32xl\:focus\:-translate-y-xl:focus {
    --tw-translate-y: -96rem
  }

  .\32xl\:focus\:-translate-y-2xl:focus {
    --tw-translate-y: -112rem
  }

  .\32xl\:focus\:-translate-y-3xl:focus {
    --tw-translate-y: -128rem
  }

  .\32xl\:focus\:-translate-y-4xl:focus {
    --tw-translate-y: -144rem
  }

  .\32xl\:focus\:-translate-y-5xl:focus {
    --tw-translate-y: -160rem
  }

  .\32xl\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%
  }

  .\32xl\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%
  }

  .\32xl\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%
  }

  .\32xl\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%
  }

  .\32xl\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%
  }

  .\32xl\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%
  }

  .\32xl\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%
  }

  .\32xl\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%
  }

  .\32xl\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%
  }

  .\32xl\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%
  }

  .\32xl\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%
  }

  .\32xl\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%
  }

  .\32xl\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%
  }

  .\32xl\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%
  }

  .\32xl\:rotate-0 {
    --tw-rotate: 0deg
  }

  .\32xl\:rotate-1 {
    --tw-rotate: 1deg
  }

  .\32xl\:rotate-2 {
    --tw-rotate: 2deg
  }

  .\32xl\:rotate-3 {
    --tw-rotate: 3deg
  }

  .\32xl\:rotate-6 {
    --tw-rotate: 6deg
  }

  .\32xl\:rotate-12 {
    --tw-rotate: 12deg
  }

  .\32xl\:rotate-45 {
    --tw-rotate: 45deg
  }

  .\32xl\:rotate-90 {
    --tw-rotate: 90deg
  }

  .\32xl\:rotate-180 {
    --tw-rotate: 180deg
  }

  .\32xl\:-rotate-180 {
    --tw-rotate: -180deg
  }

  .\32xl\:-rotate-90 {
    --tw-rotate: -90deg
  }

  .\32xl\:-rotate-45 {
    --tw-rotate: -45deg
  }

  .\32xl\:-rotate-12 {
    --tw-rotate: -12deg
  }

  .\32xl\:-rotate-6 {
    --tw-rotate: -6deg
  }

  .\32xl\:-rotate-3 {
    --tw-rotate: -3deg
  }

  .\32xl\:-rotate-2 {
    --tw-rotate: -2deg
  }

  .\32xl\:-rotate-1 {
    --tw-rotate: -1deg
  }

  .\32xl\:hover\:rotate-0:hover {
    --tw-rotate: 0deg
  }

  .\32xl\:hover\:rotate-1:hover {
    --tw-rotate: 1deg
  }

  .\32xl\:hover\:rotate-2:hover {
    --tw-rotate: 2deg
  }

  .\32xl\:hover\:rotate-3:hover {
    --tw-rotate: 3deg
  }

  .\32xl\:hover\:rotate-6:hover {
    --tw-rotate: 6deg
  }

  .\32xl\:hover\:rotate-12:hover {
    --tw-rotate: 12deg
  }

  .\32xl\:hover\:rotate-45:hover {
    --tw-rotate: 45deg
  }

  .\32xl\:hover\:rotate-90:hover {
    --tw-rotate: 90deg
  }

  .\32xl\:hover\:rotate-180:hover {
    --tw-rotate: 180deg
  }

  .\32xl\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg
  }

  .\32xl\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg
  }

  .\32xl\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg
  }

  .\32xl\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg
  }

  .\32xl\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg
  }

  .\32xl\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg
  }

  .\32xl\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg
  }

  .\32xl\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg
  }

  .\32xl\:focus\:rotate-0:focus {
    --tw-rotate: 0deg
  }

  .\32xl\:focus\:rotate-1:focus {
    --tw-rotate: 1deg
  }

  .\32xl\:focus\:rotate-2:focus {
    --tw-rotate: 2deg
  }

  .\32xl\:focus\:rotate-3:focus {
    --tw-rotate: 3deg
  }

  .\32xl\:focus\:rotate-6:focus {
    --tw-rotate: 6deg
  }

  .\32xl\:focus\:rotate-12:focus {
    --tw-rotate: 12deg
  }

  .\32xl\:focus\:rotate-45:focus {
    --tw-rotate: 45deg
  }

  .\32xl\:focus\:rotate-90:focus {
    --tw-rotate: 90deg
  }

  .\32xl\:focus\:rotate-180:focus {
    --tw-rotate: 180deg
  }

  .\32xl\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg
  }

  .\32xl\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg
  }

  .\32xl\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg
  }

  .\32xl\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg
  }

  .\32xl\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg
  }

  .\32xl\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg
  }

  .\32xl\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg
  }

  .\32xl\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg
  }

  .\32xl\:skew-x-0 {
    --tw-skew-x: 0deg
  }

  .\32xl\:skew-x-1 {
    --tw-skew-x: 1deg
  }

  .\32xl\:skew-x-2 {
    --tw-skew-x: 2deg
  }

  .\32xl\:skew-x-3 {
    --tw-skew-x: 3deg
  }

  .\32xl\:skew-x-6 {
    --tw-skew-x: 6deg
  }

  .\32xl\:skew-x-12 {
    --tw-skew-x: 12deg
  }

  .\32xl\:-skew-x-12 {
    --tw-skew-x: -12deg
  }

  .\32xl\:-skew-x-6 {
    --tw-skew-x: -6deg
  }

  .\32xl\:-skew-x-3 {
    --tw-skew-x: -3deg
  }

  .\32xl\:-skew-x-2 {
    --tw-skew-x: -2deg
  }

  .\32xl\:-skew-x-1 {
    --tw-skew-x: -1deg
  }

  .\32xl\:skew-y-0 {
    --tw-skew-y: 0deg
  }

  .\32xl\:skew-y-1 {
    --tw-skew-y: 1deg
  }

  .\32xl\:skew-y-2 {
    --tw-skew-y: 2deg
  }

  .\32xl\:skew-y-3 {
    --tw-skew-y: 3deg
  }

  .\32xl\:skew-y-6 {
    --tw-skew-y: 6deg
  }

  .\32xl\:skew-y-12 {
    --tw-skew-y: 12deg
  }

  .\32xl\:-skew-y-12 {
    --tw-skew-y: -12deg
  }

  .\32xl\:-skew-y-6 {
    --tw-skew-y: -6deg
  }

  .\32xl\:-skew-y-3 {
    --tw-skew-y: -3deg
  }

  .\32xl\:-skew-y-2 {
    --tw-skew-y: -2deg
  }

  .\32xl\:-skew-y-1 {
    --tw-skew-y: -1deg
  }

  .\32xl\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg
  }

  .\32xl\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg
  }

  .\32xl\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg
  }

  .\32xl\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg
  }

  .\32xl\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg
  }

  .\32xl\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg
  }

  .\32xl\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg
  }

  .\32xl\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg
  }

  .\32xl\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg
  }

  .\32xl\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg
  }

  .\32xl\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg
  }

  .\32xl\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg
  }

  .\32xl\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg
  }

  .\32xl\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg
  }

  .\32xl\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg
  }

  .\32xl\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg
  }

  .\32xl\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg
  }

  .\32xl\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg
  }

  .\32xl\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg
  }

  .\32xl\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg
  }

  .\32xl\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg
  }

  .\32xl\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg
  }

  .\32xl\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg
  }

  .\32xl\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg
  }

  .\32xl\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg
  }

  .\32xl\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg
  }

  .\32xl\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg
  }

  .\32xl\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg
  }

  .\32xl\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg
  }

  .\32xl\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg
  }

  .\32xl\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg
  }

  .\32xl\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg
  }

  .\32xl\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg
  }

  .\32xl\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg
  }

  .\32xl\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg
  }

  .\32xl\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg
  }

  .\32xl\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg
  }

  .\32xl\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg
  }

  .\32xl\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg
  }

  .\32xl\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg
  }

  .\32xl\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg
  }

  .\32xl\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg
  }

  .\32xl\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg
  }

  .\32xl\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg
  }

  .\32xl\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .\32xl\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .\32xl\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .\32xl\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .\32xl\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .\32xl\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .\32xl\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .\32xl\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .\32xl\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .\32xl\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .\32xl\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .\32xl\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .\32xl\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .\32xl\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .\32xl\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .\32xl\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .\32xl\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .\32xl\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .\32xl\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .\32xl\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .\32xl\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  .\32xl\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  .\32xl\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  .\32xl\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  .\32xl\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  .\32xl\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  .\32xl\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  .\32xl\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  .\32xl\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  .\32xl\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  .\32xl\:scale-x-0 {
    --tw-scale-x: 0
  }

  .\32xl\:scale-x-50 {
    --tw-scale-x: .5
  }

  .\32xl\:scale-x-75 {
    --tw-scale-x: .75
  }

  .\32xl\:scale-x-90 {
    --tw-scale-x: .9
  }

  .\32xl\:scale-x-95 {
    --tw-scale-x: .95
  }

  .\32xl\:scale-x-100 {
    --tw-scale-x: 1
  }

  .\32xl\:scale-x-105 {
    --tw-scale-x: 1.05
  }

  .\32xl\:scale-x-110 {
    --tw-scale-x: 1.1
  }

  .\32xl\:scale-x-125 {
    --tw-scale-x: 1.25
  }

  .\32xl\:scale-x-150 {
    --tw-scale-x: 1.5
  }

  .\32xl\:scale-y-0 {
    --tw-scale-y: 0
  }

  .\32xl\:scale-y-50 {
    --tw-scale-y: .5
  }

  .\32xl\:scale-y-75 {
    --tw-scale-y: .75
  }

  .\32xl\:scale-y-90 {
    --tw-scale-y: .9
  }

  .\32xl\:scale-y-95 {
    --tw-scale-y: .95
  }

  .\32xl\:scale-y-100 {
    --tw-scale-y: 1
  }

  .\32xl\:scale-y-105 {
    --tw-scale-y: 1.05
  }

  .\32xl\:scale-y-110 {
    --tw-scale-y: 1.1
  }

  .\32xl\:scale-y-125 {
    --tw-scale-y: 1.25
  }

  .\32xl\:scale-y-150 {
    --tw-scale-y: 1.5
  }

  .\32xl\:hover\:scale-x-0:hover {
    --tw-scale-x: 0
  }

  .\32xl\:hover\:scale-x-50:hover {
    --tw-scale-x: .5
  }

  .\32xl\:hover\:scale-x-75:hover {
    --tw-scale-x: .75
  }

  .\32xl\:hover\:scale-x-90:hover {
    --tw-scale-x: .9
  }

  .\32xl\:hover\:scale-x-95:hover {
    --tw-scale-x: .95
  }

  .\32xl\:hover\:scale-x-100:hover {
    --tw-scale-x: 1
  }

  .\32xl\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05
  }

  .\32xl\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1
  }

  .\32xl\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25
  }

  .\32xl\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5
  }

  .\32xl\:hover\:scale-y-0:hover {
    --tw-scale-y: 0
  }

  .\32xl\:hover\:scale-y-50:hover {
    --tw-scale-y: .5
  }

  .\32xl\:hover\:scale-y-75:hover {
    --tw-scale-y: .75
  }

  .\32xl\:hover\:scale-y-90:hover {
    --tw-scale-y: .9
  }

  .\32xl\:hover\:scale-y-95:hover {
    --tw-scale-y: .95
  }

  .\32xl\:hover\:scale-y-100:hover {
    --tw-scale-y: 1
  }

  .\32xl\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05
  }

  .\32xl\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1
  }

  .\32xl\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25
  }

  .\32xl\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5
  }

  .\32xl\:focus\:scale-x-0:focus {
    --tw-scale-x: 0
  }

  .\32xl\:focus\:scale-x-50:focus {
    --tw-scale-x: .5
  }

  .\32xl\:focus\:scale-x-75:focus {
    --tw-scale-x: .75
  }

  .\32xl\:focus\:scale-x-90:focus {
    --tw-scale-x: .9
  }

  .\32xl\:focus\:scale-x-95:focus {
    --tw-scale-x: .95
  }

  .\32xl\:focus\:scale-x-100:focus {
    --tw-scale-x: 1
  }

  .\32xl\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05
  }

  .\32xl\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1
  }

  .\32xl\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25
  }

  .\32xl\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5
  }

  .\32xl\:focus\:scale-y-0:focus {
    --tw-scale-y: 0
  }

  .\32xl\:focus\:scale-y-50:focus {
    --tw-scale-y: .5
  }

  .\32xl\:focus\:scale-y-75:focus {
    --tw-scale-y: .75
  }

  .\32xl\:focus\:scale-y-90:focus {
    --tw-scale-y: .9
  }

  .\32xl\:focus\:scale-y-95:focus {
    --tw-scale-y: .95
  }

  .\32xl\:focus\:scale-y-100:focus {
    --tw-scale-y: 1
  }

  .\32xl\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05
  }

  .\32xl\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1
  }

  .\32xl\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25
  }

  .\32xl\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5
  }

  .\32xl\:animate-none {
    animation: none
  }

  .\32xl\:animate-spin {
    animation: spin 1s linear infinite
  }

  .\32xl\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .\32xl\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .\32xl\:animate-bounce {
    animation: bounce 1s infinite
  }

  .\32xl\:cursor-auto {
    cursor: auto
  }

  .\32xl\:cursor-default {
    cursor: default
  }

  .\32xl\:cursor-pointer {
    cursor: pointer
  }

  .\32xl\:cursor-wait {
    cursor: wait
  }

  .\32xl\:cursor-text {
    cursor: text
  }

  .\32xl\:cursor-move {
    cursor: move
  }

  .\32xl\:cursor-help {
    cursor: help
  }

  .\32xl\:cursor-not-allowed {
    cursor: not-allowed
  }

  .\32xl\:select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none
  }

  .\32xl\:select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text
  }

  .\32xl\:select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all
  }

  .\32xl\:select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto
  }

  .\32xl\:resize-none {
    resize: none
  }

  .\32xl\:resize-y {
    resize: vertical
  }

  .\32xl\:resize-x {
    resize: horizontal
  }

  .\32xl\:resize {
    resize: both
  }

  .\32xl\:list-inside {
    list-style-position: inside
  }

  .\32xl\:list-outside {
    list-style-position: outside
  }

  .\32xl\:list-none {
    list-style-type: none
  }

  .\32xl\:list-disc {
    list-style-type: disc
  }

  .\32xl\:list-decimal {
    list-style-type: decimal
  }

  .\32xl\:appearance-none {
    -webkit-appearance: none;
            appearance: none
  }

  .\32xl\:auto-cols-auto {
    grid-auto-columns: auto
  }

  .\32xl\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content
  }

  .\32xl\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content
  }

  .\32xl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr)
  }

  .\32xl\:grid-flow-row {
    grid-auto-flow: row
  }

  .\32xl\:grid-flow-col {
    grid-auto-flow: column
  }

  .\32xl\:grid-flow-row-dense {
    grid-auto-flow: row dense
  }

  .\32xl\:grid-flow-col-dense {
    grid-auto-flow: column dense
  }

  .\32xl\:auto-rows-auto {
    grid-auto-rows: auto
  }

  .\32xl\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content
  }

  .\32xl\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content
  }

  .\32xl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr)
  }

  .\32xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .\32xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .\32xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .\32xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .\32xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .\32xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .\32xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .\32xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .\32xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .\32xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .\32xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .\32xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .\32xl\:grid-cols-none {
    grid-template-columns: none
  }

  .\32xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .\32xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .\32xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .\32xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .\32xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .\32xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .\32xl\:grid-rows-none {
    grid-template-rows: none
  }

  .\32xl\:flex-row {
    flex-direction: row
  }

  .\32xl\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .\32xl\:flex-col {
    flex-direction: column
  }

  .\32xl\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .\32xl\:flex-wrap {
    flex-wrap: wrap
  }

  .\32xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .\32xl\:flex-nowrap {
    flex-wrap: nowrap
  }

  .\32xl\:place-content-center {
    place-content: center
  }

  .\32xl\:place-content-start {
    place-content: start
  }

  .\32xl\:place-content-end {
    place-content: end
  }

  .\32xl\:place-content-between {
    place-content: space-between
  }

  .\32xl\:place-content-around {
    place-content: space-around
  }

  .\32xl\:place-content-evenly {
    place-content: space-evenly
  }

  .\32xl\:place-content-stretch {
    place-content: stretch
  }

  .\32xl\:place-items-start {
    place-items: start
  }

  .\32xl\:place-items-end {
    place-items: end
  }

  .\32xl\:place-items-center {
    place-items: center
  }

  .\32xl\:place-items-stretch {
    place-items: stretch
  }

  .\32xl\:content-center {
    align-content: center
  }

  .\32xl\:content-start {
    align-content: flex-start
  }

  .\32xl\:content-end {
    align-content: flex-end
  }

  .\32xl\:content-between {
    align-content: space-between
  }

  .\32xl\:content-around {
    align-content: space-around
  }

  .\32xl\:content-evenly {
    align-content: space-evenly
  }

  .\32xl\:items-start {
    align-items: flex-start
  }

  .\32xl\:items-end {
    align-items: flex-end
  }

  .\32xl\:items-center {
    align-items: center
  }

  .\32xl\:items-baseline {
    align-items: baseline
  }

  .\32xl\:items-stretch {
    align-items: stretch
  }

  .\32xl\:justify-start {
    justify-content: flex-start
  }

  .\32xl\:justify-end {
    justify-content: flex-end
  }

  .\32xl\:justify-center {
    justify-content: center
  }

  .\32xl\:justify-between {
    justify-content: space-between
  }

  .\32xl\:justify-around {
    justify-content: space-around
  }

  .\32xl\:justify-evenly {
    justify-content: space-evenly
  }

  .\32xl\:justify-items-start {
    justify-items: start
  }

  .\32xl\:justify-items-end {
    justify-items: end
  }

  .\32xl\:justify-items-center {
    justify-items: center
  }

  .\32xl\:justify-items-stretch {
    justify-items: stretch
  }

  .\32xl\:gap-0 {
    gap: 0
  }

  .\32xl\:gap-1 {
    gap: 0.25rem
  }

  .\32xl\:gap-2 {
    gap: 0.5rem
  }

  .\32xl\:gap-3 {
    gap: 0.75rem
  }

  .\32xl\:gap-4 {
    gap: 0.4rem
  }

  .\32xl\:gap-5 {
    gap: 1.25rem
  }

  .\32xl\:gap-6 {
    gap: 0.6rem
  }

  .\32xl\:gap-7 {
    gap: 1.75rem
  }

  .\32xl\:gap-8 {
    gap: 0.8rem
  }

  .\32xl\:gap-9 {
    gap: 2.25rem
  }

  .\32xl\:gap-10 {
    gap: 2.5rem
  }

  .\32xl\:gap-11 {
    gap: 2.75rem
  }

  .\32xl\:gap-12 {
    gap: 1.2rem
  }

  .\32xl\:gap-14 {
    gap: 3.5rem
  }

  .\32xl\:gap-16 {
    gap: 1.6rem
  }

  .\32xl\:gap-20 {
    gap: 2rem
  }

  .\32xl\:gap-24 {
    gap: 2.4rem
  }

  .\32xl\:gap-28 {
    gap: 2.8rem
  }

  .\32xl\:gap-32 {
    gap: 3.2rem
  }

  .\32xl\:gap-36 {
    gap: 3.6rem
  }

  .\32xl\:gap-40 {
    gap: 4rem
  }

  .\32xl\:gap-44 {
    gap: 4.4rem
  }

  .\32xl\:gap-48 {
    gap: 4.8rem
  }

  .\32xl\:gap-52 {
    gap: 5.2rem
  }

  .\32xl\:gap-56 {
    gap: 5.6rem
  }

  .\32xl\:gap-60 {
    gap: 6rem
  }

  .\32xl\:gap-64 {
    gap: 6.4rem
  }

  .\32xl\:gap-68 {
    gap: 6.8rem
  }

  .\32xl\:gap-72 {
    gap: 7.2rem
  }

  .\32xl\:gap-76 {
    gap: 7.6rem
  }

  .\32xl\:gap-80 {
    gap: 8rem
  }

  .\32xl\:gap-84 {
    gap: 8.4rem
  }

  .\32xl\:gap-88 {
    gap: 8.8rem
  }

  .\32xl\:gap-92 {
    gap: 9.2rem
  }

  .\32xl\:gap-96 {
    gap: 9.6rem
  }

  .\32xl\:gap-128 {
    gap: 12.8rem
  }

  .\32xl\:gap-136 {
    gap: 13.6rem
  }

  .\32xl\:gap-160 {
    gap: 16rem
  }

  .\32xl\:gap-192 {
    gap: 19.2rem
  }

  .\32xl\:gap-200 {
    gap: 20rem
  }

  .\32xl\:gap-208 {
    gap: 20.8rem
  }

  .\32xl\:gap-216 {
    gap: 21.6rem
  }

  .\32xl\:gap-224 {
    gap: 22.4rem
  }

  .\32xl\:gap-256 {
    gap: 25.6rem
  }

  .\32xl\:gap-288 {
    gap: 28.8rem
  }

  .\32xl\:gap-320 {
    gap: 32rem
  }

  .\32xl\:gap-360 {
    gap: 36rem
  }

  .\32xl\:gap-384 {
    gap: 38.4rem
  }

  .\32xl\:gap-400 {
    gap: 40rem
  }

  .\32xl\:gap-512 {
    gap: 51.2rem
  }

  .\32xl\:gap-640 {
    gap: 64rem
  }

  .\32xl\:gap-px {
    gap: 1px
  }

  .\32xl\:gap-0\.5 {
    gap: 0.125rem
  }

  .\32xl\:gap-1\.5 {
    gap: 0.375rem
  }

  .\32xl\:gap-2\.5 {
    gap: 0.625rem
  }

  .\32xl\:gap-3\.5 {
    gap: 0.875rem
  }

  .\32xl\:gap-xs {
    gap: 32rem
  }

  .\32xl\:gap-sm {
    gap: 48rem
  }

  .\32xl\:gap-md {
    gap: 64rem
  }

  .\32xl\:gap-lg {
    gap: 80rem
  }

  .\32xl\:gap-xl {
    gap: 96rem
  }

  .\32xl\:gap-2xl {
    gap: 112rem
  }

  .\32xl\:gap-3xl {
    gap: 128rem
  }

  .\32xl\:gap-4xl {
    gap: 144rem
  }

  .\32xl\:gap-5xl {
    gap: 160rem
  }

  .\32xl\:gap-x-0 {
    column-gap: 0
  }

  .\32xl\:gap-x-1 {
    column-gap: 0.25rem
  }

  .\32xl\:gap-x-2 {
    column-gap: 0.5rem
  }

  .\32xl\:gap-x-3 {
    column-gap: 0.75rem
  }

  .\32xl\:gap-x-4 {
    column-gap: 0.4rem
  }

  .\32xl\:gap-x-5 {
    column-gap: 1.25rem
  }

  .\32xl\:gap-x-6 {
    column-gap: 0.6rem
  }

  .\32xl\:gap-x-7 {
    column-gap: 1.75rem
  }

  .\32xl\:gap-x-8 {
    column-gap: 0.8rem
  }

  .\32xl\:gap-x-9 {
    column-gap: 2.25rem
  }

  .\32xl\:gap-x-10 {
    column-gap: 2.5rem
  }

  .\32xl\:gap-x-11 {
    column-gap: 2.75rem
  }

  .\32xl\:gap-x-12 {
    column-gap: 1.2rem
  }

  .\32xl\:gap-x-14 {
    column-gap: 3.5rem
  }

  .\32xl\:gap-x-16 {
    column-gap: 1.6rem
  }

  .\32xl\:gap-x-20 {
    column-gap: 2rem
  }

  .\32xl\:gap-x-24 {
    column-gap: 2.4rem
  }

  .\32xl\:gap-x-28 {
    column-gap: 2.8rem
  }

  .\32xl\:gap-x-32 {
    column-gap: 3.2rem
  }

  .\32xl\:gap-x-36 {
    column-gap: 3.6rem
  }

  .\32xl\:gap-x-40 {
    column-gap: 4rem
  }

  .\32xl\:gap-x-44 {
    column-gap: 4.4rem
  }

  .\32xl\:gap-x-48 {
    column-gap: 4.8rem
  }

  .\32xl\:gap-x-52 {
    column-gap: 5.2rem
  }

  .\32xl\:gap-x-56 {
    column-gap: 5.6rem
  }

  .\32xl\:gap-x-60 {
    column-gap: 6rem
  }

  .\32xl\:gap-x-64 {
    column-gap: 6.4rem
  }

  .\32xl\:gap-x-68 {
    column-gap: 6.8rem
  }

  .\32xl\:gap-x-72 {
    column-gap: 7.2rem
  }

  .\32xl\:gap-x-76 {
    column-gap: 7.6rem
  }

  .\32xl\:gap-x-80 {
    column-gap: 8rem
  }

  .\32xl\:gap-x-84 {
    column-gap: 8.4rem
  }

  .\32xl\:gap-x-88 {
    column-gap: 8.8rem
  }

  .\32xl\:gap-x-92 {
    column-gap: 9.2rem
  }

  .\32xl\:gap-x-96 {
    column-gap: 9.6rem
  }

  .\32xl\:gap-x-128 {
    column-gap: 12.8rem
  }

  .\32xl\:gap-x-136 {
    column-gap: 13.6rem
  }

  .\32xl\:gap-x-160 {
    column-gap: 16rem
  }

  .\32xl\:gap-x-192 {
    column-gap: 19.2rem
  }

  .\32xl\:gap-x-200 {
    column-gap: 20rem
  }

  .\32xl\:gap-x-208 {
    column-gap: 20.8rem
  }

  .\32xl\:gap-x-216 {
    column-gap: 21.6rem
  }

  .\32xl\:gap-x-224 {
    column-gap: 22.4rem
  }

  .\32xl\:gap-x-256 {
    column-gap: 25.6rem
  }

  .\32xl\:gap-x-288 {
    column-gap: 28.8rem
  }

  .\32xl\:gap-x-320 {
    column-gap: 32rem
  }

  .\32xl\:gap-x-360 {
    column-gap: 36rem
  }

  .\32xl\:gap-x-384 {
    column-gap: 38.4rem
  }

  .\32xl\:gap-x-400 {
    column-gap: 40rem
  }

  .\32xl\:gap-x-512 {
    column-gap: 51.2rem
  }

  .\32xl\:gap-x-640 {
    column-gap: 64rem
  }

  .\32xl\:gap-x-px {
    column-gap: 1px
  }

  .\32xl\:gap-x-0\.5 {
    column-gap: 0.125rem
  }

  .\32xl\:gap-x-1\.5 {
    column-gap: 0.375rem
  }

  .\32xl\:gap-x-2\.5 {
    column-gap: 0.625rem
  }

  .\32xl\:gap-x-3\.5 {
    column-gap: 0.875rem
  }

  .\32xl\:gap-x-xs {
    column-gap: 32rem
  }

  .\32xl\:gap-x-sm {
    column-gap: 48rem
  }

  .\32xl\:gap-x-md {
    column-gap: 64rem
  }

  .\32xl\:gap-x-lg {
    column-gap: 80rem
  }

  .\32xl\:gap-x-xl {
    column-gap: 96rem
  }

  .\32xl\:gap-x-2xl {
    column-gap: 112rem
  }

  .\32xl\:gap-x-3xl {
    column-gap: 128rem
  }

  .\32xl\:gap-x-4xl {
    column-gap: 144rem
  }

  .\32xl\:gap-x-5xl {
    column-gap: 160rem
  }

  .\32xl\:gap-y-0 {
    row-gap: 0
  }

  .\32xl\:gap-y-1 {
    row-gap: 0.25rem
  }

  .\32xl\:gap-y-2 {
    row-gap: 0.5rem
  }

  .\32xl\:gap-y-3 {
    row-gap: 0.75rem
  }

  .\32xl\:gap-y-4 {
    row-gap: 0.4rem
  }

  .\32xl\:gap-y-5 {
    row-gap: 1.25rem
  }

  .\32xl\:gap-y-6 {
    row-gap: 0.6rem
  }

  .\32xl\:gap-y-7 {
    row-gap: 1.75rem
  }

  .\32xl\:gap-y-8 {
    row-gap: 0.8rem
  }

  .\32xl\:gap-y-9 {
    row-gap: 2.25rem
  }

  .\32xl\:gap-y-10 {
    row-gap: 2.5rem
  }

  .\32xl\:gap-y-11 {
    row-gap: 2.75rem
  }

  .\32xl\:gap-y-12 {
    row-gap: 1.2rem
  }

  .\32xl\:gap-y-14 {
    row-gap: 3.5rem
  }

  .\32xl\:gap-y-16 {
    row-gap: 1.6rem
  }

  .\32xl\:gap-y-20 {
    row-gap: 2rem
  }

  .\32xl\:gap-y-24 {
    row-gap: 2.4rem
  }

  .\32xl\:gap-y-28 {
    row-gap: 2.8rem
  }

  .\32xl\:gap-y-32 {
    row-gap: 3.2rem
  }

  .\32xl\:gap-y-36 {
    row-gap: 3.6rem
  }

  .\32xl\:gap-y-40 {
    row-gap: 4rem
  }

  .\32xl\:gap-y-44 {
    row-gap: 4.4rem
  }

  .\32xl\:gap-y-48 {
    row-gap: 4.8rem
  }

  .\32xl\:gap-y-52 {
    row-gap: 5.2rem
  }

  .\32xl\:gap-y-56 {
    row-gap: 5.6rem
  }

  .\32xl\:gap-y-60 {
    row-gap: 6rem
  }

  .\32xl\:gap-y-64 {
    row-gap: 6.4rem
  }

  .\32xl\:gap-y-68 {
    row-gap: 6.8rem
  }

  .\32xl\:gap-y-72 {
    row-gap: 7.2rem
  }

  .\32xl\:gap-y-76 {
    row-gap: 7.6rem
  }

  .\32xl\:gap-y-80 {
    row-gap: 8rem
  }

  .\32xl\:gap-y-84 {
    row-gap: 8.4rem
  }

  .\32xl\:gap-y-88 {
    row-gap: 8.8rem
  }

  .\32xl\:gap-y-92 {
    row-gap: 9.2rem
  }

  .\32xl\:gap-y-96 {
    row-gap: 9.6rem
  }

  .\32xl\:gap-y-128 {
    row-gap: 12.8rem
  }

  .\32xl\:gap-y-136 {
    row-gap: 13.6rem
  }

  .\32xl\:gap-y-160 {
    row-gap: 16rem
  }

  .\32xl\:gap-y-192 {
    row-gap: 19.2rem
  }

  .\32xl\:gap-y-200 {
    row-gap: 20rem
  }

  .\32xl\:gap-y-208 {
    row-gap: 20.8rem
  }

  .\32xl\:gap-y-216 {
    row-gap: 21.6rem
  }

  .\32xl\:gap-y-224 {
    row-gap: 22.4rem
  }

  .\32xl\:gap-y-256 {
    row-gap: 25.6rem
  }

  .\32xl\:gap-y-288 {
    row-gap: 28.8rem
  }

  .\32xl\:gap-y-320 {
    row-gap: 32rem
  }

  .\32xl\:gap-y-360 {
    row-gap: 36rem
  }

  .\32xl\:gap-y-384 {
    row-gap: 38.4rem
  }

  .\32xl\:gap-y-400 {
    row-gap: 40rem
  }

  .\32xl\:gap-y-512 {
    row-gap: 51.2rem
  }

  .\32xl\:gap-y-640 {
    row-gap: 64rem
  }

  .\32xl\:gap-y-px {
    row-gap: 1px
  }

  .\32xl\:gap-y-0\.5 {
    row-gap: 0.125rem
  }

  .\32xl\:gap-y-1\.5 {
    row-gap: 0.375rem
  }

  .\32xl\:gap-y-2\.5 {
    row-gap: 0.625rem
  }

  .\32xl\:gap-y-3\.5 {
    row-gap: 0.875rem
  }

  .\32xl\:gap-y-xs {
    row-gap: 32rem
  }

  .\32xl\:gap-y-sm {
    row-gap: 48rem
  }

  .\32xl\:gap-y-md {
    row-gap: 64rem
  }

  .\32xl\:gap-y-lg {
    row-gap: 80rem
  }

  .\32xl\:gap-y-xl {
    row-gap: 96rem
  }

  .\32xl\:gap-y-2xl {
    row-gap: 112rem
  }

  .\32xl\:gap-y-3xl {
    row-gap: 128rem
  }

  .\32xl\:gap-y-4xl {
    row-gap: 144rem
  }

  .\32xl\:gap-y-5xl {
    row-gap: 160rem
  }

  .\32xl\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.4rem * var(--tw-space-x-reverse));
    margin-left: calc(0.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.6rem * var(--tw-space-x-reverse));
    margin-left: calc(0.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.8rem * var(--tw-space-x-reverse));
    margin-left: calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.2rem * var(--tw-space-x-reverse));
    margin-left: calc(1.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.4rem * var(--tw-space-x-reverse));
    margin-left: calc(2.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.8rem * var(--tw-space-x-reverse));
    margin-left: calc(2.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.2rem * var(--tw-space-x-reverse));
    margin-left: calc(3.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.6rem * var(--tw-space-x-reverse));
    margin-left: calc(3.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4.4rem * var(--tw-space-x-reverse));
    margin-left: calc(4.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4.8rem * var(--tw-space-x-reverse));
    margin-left: calc(4.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5.2rem * var(--tw-space-x-reverse));
    margin-left: calc(5.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5.6rem * var(--tw-space-x-reverse));
    margin-left: calc(5.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6.4rem * var(--tw-space-x-reverse));
    margin-left: calc(6.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6.8rem * var(--tw-space-x-reverse));
    margin-left: calc(6.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7.2rem * var(--tw-space-x-reverse));
    margin-left: calc(7.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7.6rem * var(--tw-space-x-reverse));
    margin-left: calc(7.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8.4rem * var(--tw-space-x-reverse));
    margin-left: calc(8.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8.8rem * var(--tw-space-x-reverse));
    margin-left: calc(8.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9.2rem * var(--tw-space-x-reverse));
    margin-left: calc(9.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9.6rem * var(--tw-space-x-reverse));
    margin-left: calc(9.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12.8rem * var(--tw-space-x-reverse));
    margin-left: calc(12.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13.6rem * var(--tw-space-x-reverse));
    margin-left: calc(13.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(19.2rem * var(--tw-space-x-reverse));
    margin-left: calc(19.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20.8rem * var(--tw-space-x-reverse));
    margin-left: calc(20.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(21.6rem * var(--tw-space-x-reverse));
    margin-left: calc(21.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(22.4rem * var(--tw-space-x-reverse));
    margin-left: calc(22.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(25.6rem * var(--tw-space-x-reverse));
    margin-left: calc(25.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(28.8rem * var(--tw-space-x-reverse));
    margin-left: calc(28.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(32rem * var(--tw-space-x-reverse));
    margin-left: calc(32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(36rem * var(--tw-space-x-reverse));
    margin-left: calc(36rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(38.4rem * var(--tw-space-x-reverse));
    margin-left: calc(38.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(40rem * var(--tw-space-x-reverse));
    margin-left: calc(40rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(51.2rem * var(--tw-space-x-reverse));
    margin-left: calc(51.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(64rem * var(--tw-space-x-reverse));
    margin-left: calc(64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(32rem * var(--tw-space-x-reverse));
    margin-left: calc(32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(48rem * var(--tw-space-x-reverse));
    margin-left: calc(48rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(64rem * var(--tw-space-x-reverse));
    margin-left: calc(64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(80rem * var(--tw-space-x-reverse));
    margin-left: calc(80rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(96rem * var(--tw-space-x-reverse));
    margin-left: calc(96rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(112rem * var(--tw-space-x-reverse));
    margin-left: calc(112rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(128rem * var(--tw-space-x-reverse));
    margin-left: calc(128rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(144rem * var(--tw-space-x-reverse));
    margin-left: calc(144rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-x-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(160rem * var(--tw-space-x-reverse));
    margin-left: calc(160rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-4.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-5.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-5.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-6.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-6.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-7.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-7.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-8.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-9.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-9.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-12.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-13.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-19.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-19.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-20.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-21.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-21.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-22.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-22.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-25.6rem * var(--tw-space-x-reverse));
    margin-left: calc(-25.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-28.8rem * var(--tw-space-x-reverse));
    margin-left: calc(-28.8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-32rem * var(--tw-space-x-reverse));
    margin-left: calc(-32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-36rem * var(--tw-space-x-reverse));
    margin-left: calc(-36rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-38.4rem * var(--tw-space-x-reverse));
    margin-left: calc(-38.4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-40rem * var(--tw-space-x-reverse));
    margin-left: calc(-40rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-51.2rem * var(--tw-space-x-reverse));
    margin-left: calc(-51.2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-64rem * var(--tw-space-x-reverse));
    margin-left: calc(-64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-32rem * var(--tw-space-x-reverse));
    margin-left: calc(-32rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-48rem * var(--tw-space-x-reverse));
    margin-left: calc(-48rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-64rem * var(--tw-space-x-reverse));
    margin-left: calc(-64rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-80rem * var(--tw-space-x-reverse));
    margin-left: calc(-80rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-96rem * var(--tw-space-x-reverse));
    margin-left: calc(-96rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-112rem * var(--tw-space-x-reverse));
    margin-left: calc(-112rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-128rem * var(--tw-space-x-reverse));
    margin-left: calc(-128rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-144rem * var(--tw-space-x-reverse));
    margin-left: calc(-144rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:-space-x-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-160rem * var(--tw-space-x-reverse));
    margin-left: calc(-160rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .\32xl\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4.4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5.2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6.4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7.2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8.4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9.2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(19.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(19.2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(21.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(21.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(22.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(22.4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(25.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(25.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(28.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(28.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(36rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(36rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(38.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(38.4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(40rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(40rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(51.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(51.2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(64rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(48rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(48rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(64rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(80rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(80rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(96rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(96rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(112rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(112rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(128rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(128rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(144rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(144rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(160rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(160rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4.4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5.2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6.4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-68 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7.2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-76 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-84 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8.4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-88 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-92 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9.2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-128 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-136 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-160 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-192 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-19.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-19.2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-208 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-216 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-21.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-21.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-224 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-22.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-22.4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-256 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-25.6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-25.6rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-288 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-28.8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-28.8rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-320 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-32rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-360 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-36rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-36rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-384 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-38.4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-38.4rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-40rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-40rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-512 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-51.2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-51.2rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-640 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-64rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-xs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-32rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-32rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-48rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-48rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-md > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-64rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-64rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-lg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-80rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-80rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-96rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-96rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-2xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-112rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-112rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-3xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-128rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-128rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-4xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-144rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-144rem * var(--tw-space-y-reverse))
  }

  .\32xl\:-space-y-5xl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-160rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-160rem * var(--tw-space-y-reverse))
  }

  .\32xl\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1
  }

  .\32xl\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1
  }

  .\32xl\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .\32xl\:divide-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .\32xl\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .\32xl\:divide-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(3px * var(--tw-divide-x-reverse));
    border-left-width: calc(3px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .\32xl\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .\32xl\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .\32xl\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .\32xl\:divide-x-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .\32xl\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse))
  }

  .\32xl\:divide-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  .\32xl\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse))
  }

  .\32xl\:divide-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(3px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(3px * var(--tw-divide-y-reverse))
  }

  .\32xl\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse))
  }

  .\32xl\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse))
  }

  .\32xl\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  .\32xl\:divide-y-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  .\32xl\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1
  }

  .\32xl\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1
  }

  .\32xl\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid
  }

  .\32xl\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed
  }

  .\32xl\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted
  }

  .\32xl\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double
  }

  .\32xl\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none
  }

  .\32xl\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent
  }

  .\32xl\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor
  }

  .\32xl\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-divide-opacity))
  }

  .\32xl\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-divide-opacity))
  }

  .\32xl\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-divide-opacity))
  }

  .\32xl\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-divide-opacity))
  }

  .\32xl\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-divide-opacity))
  }

  .\32xl\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-divide-opacity))
  }

  .\32xl\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .\32xl\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-divide-opacity))
  }

  .\32xl\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .\32xl\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-divide-opacity))
  }

  .\32xl\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity))
  }

  .\32xl\:divide-gray-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .\32xl\:divide-gray-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-divide-opacity))
  }

  .\32xl\:divide-gray-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-divide-opacity))
  }

  .\32xl\:divide-gray-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-divide-opacity))
  }

  .\32xl\:divide-gray-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .\32xl\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-divide-opacity))
  }

  .\32xl\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-divide-opacity))
  }

  .\32xl\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-divide-opacity))
  }

  .\32xl\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-divide-opacity))
  }

  .\32xl\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-divide-opacity))
  }

  .\32xl\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-divide-opacity))
  }

  .\32xl\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-divide-opacity))
  }

  .\32xl\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-divide-opacity))
  }

  .\32xl\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-divide-opacity))
  }

  .\32xl\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-divide-opacity))
  }

  .\32xl\:divide-red-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-divide-opacity))
  }

  .\32xl\:divide-red-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-divide-opacity))
  }

  .\32xl\:divide-red-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-divide-opacity))
  }

  .\32xl\:divide-red-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-divide-opacity))
  }

  .\32xl\:divide-red-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-divide-opacity))
  }

  .\32xl\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-divide-opacity))
  }

  .\32xl\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-divide-opacity))
  }

  .\32xl\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-divide-opacity))
  }

  .\32xl\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-divide-opacity))
  }

  .\32xl\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-divide-opacity))
  }

  .\32xl\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-divide-opacity))
  }

  .\32xl\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-divide-opacity))
  }

  .\32xl\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-divide-opacity))
  }

  .\32xl\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-divide-opacity))
  }

  .\32xl\:divide-yellow-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-divide-opacity))
  }

  .\32xl\:divide-yellow-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-divide-opacity))
  }

  .\32xl\:divide-yellow-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-yellow-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-yellow-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-divide-opacity))
  }

  .\32xl\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-divide-opacity))
  }

  .\32xl\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-divide-opacity))
  }

  .\32xl\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-divide-opacity))
  }

  .\32xl\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-divide-opacity))
  }

  .\32xl\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-divide-opacity))
  }

  .\32xl\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-divide-opacity))
  }

  .\32xl\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-divide-opacity))
  }

  .\32xl\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-divide-opacity))
  }

  .\32xl\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-divide-opacity))
  }

  .\32xl\:divide-green-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-divide-opacity))
  }

  .\32xl\:divide-green-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-divide-opacity))
  }

  .\32xl\:divide-green-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-divide-opacity))
  }

  .\32xl\:divide-green-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-divide-opacity))
  }

  .\32xl\:divide-green-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-divide-opacity))
  }

  .\32xl\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-divide-opacity))
  }

  .\32xl\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-divide-opacity))
  }

  .\32xl\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-divide-opacity))
  }

  .\32xl\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-divide-opacity))
  }

  .\32xl\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-divide-opacity))
  }

  .\32xl\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-divide-opacity))
  }

  .\32xl\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-divide-opacity))
  }

  .\32xl\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-divide-opacity))
  }

  .\32xl\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-divide-opacity))
  }

  .\32xl\:divide-indigo-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-divide-opacity))
  }

  .\32xl\:divide-indigo-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-indigo-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-divide-opacity))
  }

  .\32xl\:divide-indigo-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-divide-opacity))
  }

  .\32xl\:divide-indigo-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-divide-opacity))
  }

  .\32xl\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-divide-opacity))
  }

  .\32xl\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-divide-opacity))
  }

  .\32xl\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-divide-opacity))
  }

  .\32xl\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-divide-opacity))
  }

  .\32xl\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-divide-opacity))
  }

  .\32xl\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-divide-opacity))
  }

  .\32xl\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-divide-opacity))
  }

  .\32xl\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-divide-opacity))
  }

  .\32xl\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-divide-opacity))
  }

  .\32xl\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-divide-opacity))
  }

  .\32xl\:divide-purple-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-divide-opacity))
  }

  .\32xl\:divide-purple-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-divide-opacity))
  }

  .\32xl\:divide-purple-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-divide-opacity))
  }

  .\32xl\:divide-purple-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-divide-opacity))
  }

  .\32xl\:divide-purple-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-divide-opacity))
  }

  .\32xl\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-divide-opacity))
  }

  .\32xl\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-divide-opacity))
  }

  .\32xl\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-divide-opacity))
  }

  .\32xl\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-divide-opacity))
  }

  .\32xl\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-divide-opacity))
  }

  .\32xl\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-divide-opacity))
  }

  .\32xl\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-divide-opacity))
  }

  .\32xl\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-divide-opacity))
  }

  .\32xl\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-divide-opacity))
  }

  .\32xl\:divide-pink-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-divide-opacity))
  }

  .\32xl\:divide-pink-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-divide-opacity))
  }

  .\32xl\:divide-pink-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-divide-opacity))
  }

  .\32xl\:divide-pink-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-divide-opacity))
  }

  .\32xl\:divide-pink-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-divide-opacity))
  }

  .\32xl\:divide-grey-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-divide-opacity))
  }

  .\32xl\:divide-grey-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-divide-opacity))
  }

  .\32xl\:divide-grey-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-divide-opacity))
  }

  .\32xl\:divide-grey-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-divide-opacity))
  }

  .\32xl\:divide-grey-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-divide-opacity))
  }

  .\32xl\:divide-grey-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .\32xl\:divide-grey-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-divide-opacity))
  }

  .\32xl\:divide-grey-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .\32xl\:divide-grey-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-divide-opacity))
  }

  .\32xl\:divide-grey-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity))
  }

  .\32xl\:divide-grey-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-divide-opacity))
  }

  .\32xl\:divide-grey-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-divide-opacity))
  }

  .\32xl\:divide-grey-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-divide-opacity))
  }

  .\32xl\:divide-grey-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-divide-opacity))
  }

  .\32xl\:divide-grey-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-divide-opacity))
  }

  .\32xl\:divide-orange-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-divide-opacity))
  }

  .\32xl\:divide-orange-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-divide-opacity))
  }

  .\32xl\:divide-orange-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-divide-opacity))
  }

  .\32xl\:divide-orange-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-divide-opacity))
  }

  .\32xl\:divide-orange-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-divide-opacity))
  }

  .\32xl\:divide-orange-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-orange-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-orange-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-orange-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-orange-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-orange-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-orange-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-divide-opacity))
  }

  .\32xl\:divide-orange-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-divide-opacity))
  }

  .\32xl\:divide-orange-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-orange-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-orange-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-orange-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-orange-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-orange-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-orange-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-orange-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-orange-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-orange-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-orange-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-orange-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-orange-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-orange-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-orange-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-orange-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-orange-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-green-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-green-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-green-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-green-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-green-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-divide-opacity))
  }

  .\32xl\:divide-teal-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-divide-opacity))
  }

  .\32xl\:divide-teal-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-divide-opacity))
  }

  .\32xl\:divide-teal-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-divide-opacity))
  }

  .\32xl\:divide-teal-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-divide-opacity))
  }

  .\32xl\:divide-teal-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-divide-opacity))
  }

  .\32xl\:divide-teal-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-divide-opacity))
  }

  .\32xl\:divide-teal-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-divide-opacity))
  }

  .\32xl\:divide-teal-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-divide-opacity))
  }

  .\32xl\:divide-teal-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-divide-opacity))
  }

  .\32xl\:divide-teal-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-divide-opacity))
  }

  .\32xl\:divide-teal-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-divide-opacity))
  }

  .\32xl\:divide-teal-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-divide-opacity))
  }

  .\32xl\:divide-teal-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-divide-opacity))
  }

  .\32xl\:divide-teal-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-divide-opacity))
  }

  .\32xl\:divide-teal-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-blue-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-blue-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-blue-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-blue-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-light-blue-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-purple-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-purple-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-purple-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-purple-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-deep-purple-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-divide-opacity))
  }

  .\32xl\:divide-lime-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-divide-opacity))
  }

  .\32xl\:divide-lime-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-divide-opacity))
  }

  .\32xl\:divide-lime-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-divide-opacity))
  }

  .\32xl\:divide-lime-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-divide-opacity))
  }

  .\32xl\:divide-lime-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-divide-opacity))
  }

  .\32xl\:divide-lime-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-divide-opacity))
  }

  .\32xl\:divide-lime-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-divide-opacity))
  }

  .\32xl\:divide-lime-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-divide-opacity))
  }

  .\32xl\:divide-lime-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-divide-opacity))
  }

  .\32xl\:divide-lime-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-divide-opacity))
  }

  .\32xl\:divide-lime-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-divide-opacity))
  }

  .\32xl\:divide-lime-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-divide-opacity))
  }

  .\32xl\:divide-lime-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-divide-opacity))
  }

  .\32xl\:divide-lime-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-lime-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-amber-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-divide-opacity))
  }

  .\32xl\:divide-amber-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-divide-opacity))
  }

  .\32xl\:divide-amber-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-divide-opacity))
  }

  .\32xl\:divide-amber-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-divide-opacity))
  }

  .\32xl\:divide-amber-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-divide-opacity))
  }

  .\32xl\:divide-amber-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-divide-opacity))
  }

  .\32xl\:divide-amber-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-amber-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-amber-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-amber-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-amber-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-divide-opacity))
  }

  .\32xl\:divide-amber-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-divide-opacity))
  }

  .\32xl\:divide-amber-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-divide-opacity))
  }

  .\32xl\:divide-amber-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-amber-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-divide-opacity))
  }

  .\32xl\:divide-brown-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-divide-opacity))
  }

  .\32xl\:divide-brown-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-divide-opacity))
  }

  .\32xl\:divide-brown-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-divide-opacity))
  }

  .\32xl\:divide-brown-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-divide-opacity))
  }

  .\32xl\:divide-brown-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-divide-opacity))
  }

  .\32xl\:divide-brown-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-divide-opacity))
  }

  .\32xl\:divide-brown-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-divide-opacity))
  }

  .\32xl\:divide-brown-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-divide-opacity))
  }

  .\32xl\:divide-brown-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-divide-opacity))
  }

  .\32xl\:divide-brown-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-divide-opacity))
  }

  .\32xl\:divide-brown-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-divide-opacity))
  }

  .\32xl\:divide-brown-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-divide-opacity))
  }

  .\32xl\:divide-brown-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-divide-opacity))
  }

  .\32xl\:divide-brown-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-divide-opacity))
  }

  .\32xl\:divide-brown-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-gray-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-gray-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-gray-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-gray-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-divide-opacity))
  }

  .\32xl\:divide-blue-gray-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-divide-opacity))
  }

  .\32xl\:divide-cyan-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-divide-opacity))
  }

  .\32xl\:divide-cyan-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-divide-opacity))
  }

  .\32xl\:divide-cyan-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-divide-opacity))
  }

  .\32xl\:divide-cyan-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-divide-opacity))
  }

  .\32xl\:divide-cyan-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-divide-opacity))
  }

  .\32xl\:divide-cyan-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-divide-opacity))
  }

  .\32xl\:divide-cyan-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-divide-opacity))
  }

  .\32xl\:divide-cyan-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-divide-opacity))
  }

  .\32xl\:divide-cyan-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-divide-opacity))
  }

  .\32xl\:divide-cyan-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-divide-opacity))
  }

  .\32xl\:divide-cyan-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-divide-opacity))
  }

  .\32xl\:divide-cyan-A100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-cyan-A200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-cyan-A400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-divide-opacity))
  }

  .\32xl\:divide-cyan-A700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-divide-opacity))
  }

  .\32xl\:divide-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-divide-opacity))
  }

  .\32xl\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0
  }

  .\32xl\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05
  }

  .\32xl\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1
  }

  .\32xl\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2
  }

  .\32xl\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25
  }

  .\32xl\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3
  }

  .\32xl\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4
  }

  .\32xl\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5
  }

  .\32xl\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6
  }

  .\32xl\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7
  }

  .\32xl\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75
  }

  .\32xl\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8
  }

  .\32xl\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9
  }

  .\32xl\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95
  }

  .\32xl\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1
  }

  .\32xl\:place-self-auto {
    place-self: auto
  }

  .\32xl\:place-self-start {
    place-self: start
  }

  .\32xl\:place-self-end {
    place-self: end
  }

  .\32xl\:place-self-center {
    place-self: center
  }

  .\32xl\:place-self-stretch {
    place-self: stretch
  }

  .\32xl\:self-auto {
    align-self: auto
  }

  .\32xl\:self-start {
    align-self: flex-start
  }

  .\32xl\:self-end {
    align-self: flex-end
  }

  .\32xl\:self-center {
    align-self: center
  }

  .\32xl\:self-stretch {
    align-self: stretch
  }

  .\32xl\:self-baseline {
    align-self: baseline
  }

  .\32xl\:justify-self-auto {
    justify-self: auto
  }

  .\32xl\:justify-self-start {
    justify-self: start
  }

  .\32xl\:justify-self-end {
    justify-self: end
  }

  .\32xl\:justify-self-center {
    justify-self: center
  }

  .\32xl\:justify-self-stretch {
    justify-self: stretch
  }

  .\32xl\:overflow-auto {
    overflow: auto
  }

  .\32xl\:overflow-hidden {
    overflow: hidden
  }

  .\32xl\:overflow-visible {
    overflow: visible
  }

  .\32xl\:overflow-scroll {
    overflow: scroll
  }

  .\32xl\:overflow-x-auto {
    overflow-x: auto
  }

  .\32xl\:overflow-y-auto {
    overflow-y: auto
  }

  .\32xl\:overflow-x-hidden {
    overflow-x: hidden
  }

  .\32xl\:overflow-y-hidden {
    overflow-y: hidden
  }

  .\32xl\:overflow-x-visible {
    overflow-x: visible
  }

  .\32xl\:overflow-y-visible {
    overflow-y: visible
  }

  .\32xl\:overflow-x-scroll {
    overflow-x: scroll
  }

  .\32xl\:overflow-y-scroll {
    overflow-y: scroll
  }

  .\32xl\:overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto
  }

  .\32xl\:overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain
  }

  .\32xl\:overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none
  }

  .\32xl\:overscroll-y-auto {
    overscroll-behavior-y: auto
  }

  .\32xl\:overscroll-y-contain {
    overscroll-behavior-y: contain
  }

  .\32xl\:overscroll-y-none {
    overscroll-behavior-y: none
  }

  .\32xl\:overscroll-x-auto {
    overscroll-behavior-x: auto
  }

  .\32xl\:overscroll-x-contain {
    overscroll-behavior-x: contain
  }

  .\32xl\:overscroll-x-none {
    overscroll-behavior-x: none
  }

  .\32xl\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .\32xl\:overflow-ellipsis {
    text-overflow: ellipsis
  }

  .\32xl\:overflow-clip {
    text-overflow: clip
  }

  .\32xl\:whitespace-normal {
    white-space: normal
  }

  .\32xl\:whitespace-nowrap {
    white-space: nowrap
  }

  .\32xl\:whitespace-pre {
    white-space: pre
  }

  .\32xl\:whitespace-pre-line {
    white-space: pre-line
  }

  .\32xl\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .\32xl\:break-normal {
    overflow-wrap: normal;
    word-break: normal
  }

  .\32xl\:break-words {
    overflow-wrap: break-word
  }

  .\32xl\:break-all {
    word-break: break-all
  }

  .\32xl\:rounded-2 {
    border-radius: .2rem
  }

  .\32xl\:rounded-4 {
    border-radius: .4rem
  }

  .\32xl\:rounded-6 {
    border-radius: .6rem
  }

  .\32xl\:rounded-8 {
    border-radius: .8rem
  }

  .\32xl\:rounded-12 {
    border-radius: 1.2rem
  }

  .\32xl\:rounded-16 {
    border-radius: 1.6rem
  }

  .\32xl\:rounded-20 {
    border-radius: 2rem
  }

  .\32xl\:rounded-24 {
    border-radius: 2.4rem
  }

  .\32xl\:rounded-28 {
    border-radius: 2.8rem
  }

  .\32xl\:rounded-32 {
    border-radius: 3.2rem
  }

  .\32xl\:rounded-none {
    border-radius: 0
  }

  .\32xl\:rounded-sm {
    border-radius: .2rem
  }

  .\32xl\:rounded {
    border-radius: 0.25rem
  }

  .\32xl\:rounded-md {
    border-radius: 0.375rem
  }

  .\32xl\:rounded-lg {
    border-radius: .8rem
  }

  .\32xl\:rounded-xl {
    border-radius: 0.75rem
  }

  .\32xl\:rounded-2xl {
    border-radius: 1rem
  }

  .\32xl\:rounded-3xl {
    border-radius: 1.5rem
  }

  .\32xl\:rounded-full {
    border-radius: 9999px
  }

  .\32xl\:rounded-default {
    border-radius: .4rem
  }

  .\32xl\:rounded-t-2 {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem
  }

  .\32xl\:rounded-t-4 {
    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem
  }

  .\32xl\:rounded-t-6 {
    border-top-left-radius: .6rem;
    border-top-right-radius: .6rem
  }

  .\32xl\:rounded-t-8 {
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem
  }

  .\32xl\:rounded-t-12 {
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem
  }

  .\32xl\:rounded-t-16 {
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem
  }

  .\32xl\:rounded-t-20 {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem
  }

  .\32xl\:rounded-t-24 {
    border-top-left-radius: 2.4rem;
    border-top-right-radius: 2.4rem
  }

  .\32xl\:rounded-t-28 {
    border-top-left-radius: 2.8rem;
    border-top-right-radius: 2.8rem
  }

  .\32xl\:rounded-t-32 {
    border-top-left-radius: 3.2rem;
    border-top-right-radius: 3.2rem
  }

  .\32xl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .\32xl\:rounded-t-sm {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem
  }

  .\32xl\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  .\32xl\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem
  }

  .\32xl\:rounded-t-lg {
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem
  }

  .\32xl\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem
  }

  .\32xl\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
  }

  .\32xl\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem
  }

  .\32xl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .\32xl\:rounded-t-default {
    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem
  }

  .\32xl\:rounded-r-2 {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
  }

  .\32xl\:rounded-r-4 {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem
  }

  .\32xl\:rounded-r-6 {
    border-top-right-radius: .6rem;
    border-bottom-right-radius: .6rem
  }

  .\32xl\:rounded-r-8 {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem
  }

  .\32xl\:rounded-r-12 {
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem
  }

  .\32xl\:rounded-r-16 {
    border-top-right-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem
  }

  .\32xl\:rounded-r-20 {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem
  }

  .\32xl\:rounded-r-24 {
    border-top-right-radius: 2.4rem;
    border-bottom-right-radius: 2.4rem
  }

  .\32xl\:rounded-r-28 {
    border-top-right-radius: 2.8rem;
    border-bottom-right-radius: 2.8rem
  }

  .\32xl\:rounded-r-32 {
    border-top-right-radius: 3.2rem;
    border-bottom-right-radius: 3.2rem
  }

  .\32xl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .\32xl\:rounded-r-sm {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
  }

  .\32xl\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  .\32xl\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem
  }

  .\32xl\:rounded-r-lg {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem
  }

  .\32xl\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem
  }

  .\32xl\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
  }

  .\32xl\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
  }

  .\32xl\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .\32xl\:rounded-r-default {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem
  }

  .\32xl\:rounded-b-2 {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .\32xl\:rounded-b-4 {
    border-bottom-right-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .\32xl\:rounded-b-6 {
    border-bottom-right-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .\32xl\:rounded-b-8 {
    border-bottom-right-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .\32xl\:rounded-b-12 {
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .\32xl\:rounded-b-16 {
    border-bottom-right-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .\32xl\:rounded-b-20 {
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem
  }

  .\32xl\:rounded-b-24 {
    border-bottom-right-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .\32xl\:rounded-b-28 {
    border-bottom-right-radius: 2.8rem;
    border-bottom-left-radius: 2.8rem
  }

  .\32xl\:rounded-b-32 {
    border-bottom-right-radius: 3.2rem;
    border-bottom-left-radius: 3.2rem
  }

  .\32xl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .\32xl\:rounded-b-sm {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .\32xl\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .\32xl\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .\32xl\:rounded-b-lg {
    border-bottom-right-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .\32xl\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .\32xl\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .\32xl\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .\32xl\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .\32xl\:rounded-b-default {
    border-bottom-right-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .\32xl\:rounded-l-2 {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .\32xl\:rounded-l-4 {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .\32xl\:rounded-l-6 {
    border-top-left-radius: .6rem;
    border-bottom-left-radius: .6rem
  }

  .\32xl\:rounded-l-8 {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .\32xl\:rounded-l-12 {
    border-top-left-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem
  }

  .\32xl\:rounded-l-16 {
    border-top-left-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem
  }

  .\32xl\:rounded-l-20 {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem
  }

  .\32xl\:rounded-l-24 {
    border-top-left-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem
  }

  .\32xl\:rounded-l-28 {
    border-top-left-radius: 2.8rem;
    border-bottom-left-radius: 2.8rem
  }

  .\32xl\:rounded-l-32 {
    border-top-left-radius: 3.2rem;
    border-bottom-left-radius: 3.2rem
  }

  .\32xl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .\32xl\:rounded-l-sm {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .\32xl\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .\32xl\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .\32xl\:rounded-l-lg {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem
  }

  .\32xl\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .\32xl\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .\32xl\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .\32xl\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .\32xl\:rounded-l-default {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem
  }

  .\32xl\:rounded-tl-2 {
    border-top-left-radius: .2rem
  }

  .\32xl\:rounded-tl-4 {
    border-top-left-radius: .4rem
  }

  .\32xl\:rounded-tl-6 {
    border-top-left-radius: .6rem
  }

  .\32xl\:rounded-tl-8 {
    border-top-left-radius: .8rem
  }

  .\32xl\:rounded-tl-12 {
    border-top-left-radius: 1.2rem
  }

  .\32xl\:rounded-tl-16 {
    border-top-left-radius: 1.6rem
  }

  .\32xl\:rounded-tl-20 {
    border-top-left-radius: 2rem
  }

  .\32xl\:rounded-tl-24 {
    border-top-left-radius: 2.4rem
  }

  .\32xl\:rounded-tl-28 {
    border-top-left-radius: 2.8rem
  }

  .\32xl\:rounded-tl-32 {
    border-top-left-radius: 3.2rem
  }

  .\32xl\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .\32xl\:rounded-tl-sm {
    border-top-left-radius: .2rem
  }

  .\32xl\:rounded-tl {
    border-top-left-radius: 0.25rem
  }

  .\32xl\:rounded-tl-md {
    border-top-left-radius: 0.375rem
  }

  .\32xl\:rounded-tl-lg {
    border-top-left-radius: .8rem
  }

  .\32xl\:rounded-tl-xl {
    border-top-left-radius: 0.75rem
  }

  .\32xl\:rounded-tl-2xl {
    border-top-left-radius: 1rem
  }

  .\32xl\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem
  }

  .\32xl\:rounded-tl-full {
    border-top-left-radius: 9999px
  }

  .\32xl\:rounded-tl-default {
    border-top-left-radius: .4rem
  }

  .\32xl\:rounded-tr-2 {
    border-top-right-radius: .2rem
  }

  .\32xl\:rounded-tr-4 {
    border-top-right-radius: .4rem
  }

  .\32xl\:rounded-tr-6 {
    border-top-right-radius: .6rem
  }

  .\32xl\:rounded-tr-8 {
    border-top-right-radius: .8rem
  }

  .\32xl\:rounded-tr-12 {
    border-top-right-radius: 1.2rem
  }

  .\32xl\:rounded-tr-16 {
    border-top-right-radius: 1.6rem
  }

  .\32xl\:rounded-tr-20 {
    border-top-right-radius: 2rem
  }

  .\32xl\:rounded-tr-24 {
    border-top-right-radius: 2.4rem
  }

  .\32xl\:rounded-tr-28 {
    border-top-right-radius: 2.8rem
  }

  .\32xl\:rounded-tr-32 {
    border-top-right-radius: 3.2rem
  }

  .\32xl\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .\32xl\:rounded-tr-sm {
    border-top-right-radius: .2rem
  }

  .\32xl\:rounded-tr {
    border-top-right-radius: 0.25rem
  }

  .\32xl\:rounded-tr-md {
    border-top-right-radius: 0.375rem
  }

  .\32xl\:rounded-tr-lg {
    border-top-right-radius: .8rem
  }

  .\32xl\:rounded-tr-xl {
    border-top-right-radius: 0.75rem
  }

  .\32xl\:rounded-tr-2xl {
    border-top-right-radius: 1rem
  }

  .\32xl\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem
  }

  .\32xl\:rounded-tr-full {
    border-top-right-radius: 9999px
  }

  .\32xl\:rounded-tr-default {
    border-top-right-radius: .4rem
  }

  .\32xl\:rounded-br-2 {
    border-bottom-right-radius: .2rem
  }

  .\32xl\:rounded-br-4 {
    border-bottom-right-radius: .4rem
  }

  .\32xl\:rounded-br-6 {
    border-bottom-right-radius: .6rem
  }

  .\32xl\:rounded-br-8 {
    border-bottom-right-radius: .8rem
  }

  .\32xl\:rounded-br-12 {
    border-bottom-right-radius: 1.2rem
  }

  .\32xl\:rounded-br-16 {
    border-bottom-right-radius: 1.6rem
  }

  .\32xl\:rounded-br-20 {
    border-bottom-right-radius: 2rem
  }

  .\32xl\:rounded-br-24 {
    border-bottom-right-radius: 2.4rem
  }

  .\32xl\:rounded-br-28 {
    border-bottom-right-radius: 2.8rem
  }

  .\32xl\:rounded-br-32 {
    border-bottom-right-radius: 3.2rem
  }

  .\32xl\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .\32xl\:rounded-br-sm {
    border-bottom-right-radius: .2rem
  }

  .\32xl\:rounded-br {
    border-bottom-right-radius: 0.25rem
  }

  .\32xl\:rounded-br-md {
    border-bottom-right-radius: 0.375rem
  }

  .\32xl\:rounded-br-lg {
    border-bottom-right-radius: .8rem
  }

  .\32xl\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem
  }

  .\32xl\:rounded-br-2xl {
    border-bottom-right-radius: 1rem
  }

  .\32xl\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem
  }

  .\32xl\:rounded-br-full {
    border-bottom-right-radius: 9999px
  }

  .\32xl\:rounded-br-default {
    border-bottom-right-radius: .4rem
  }

  .\32xl\:rounded-bl-2 {
    border-bottom-left-radius: .2rem
  }

  .\32xl\:rounded-bl-4 {
    border-bottom-left-radius: .4rem
  }

  .\32xl\:rounded-bl-6 {
    border-bottom-left-radius: .6rem
  }

  .\32xl\:rounded-bl-8 {
    border-bottom-left-radius: .8rem
  }

  .\32xl\:rounded-bl-12 {
    border-bottom-left-radius: 1.2rem
  }

  .\32xl\:rounded-bl-16 {
    border-bottom-left-radius: 1.6rem
  }

  .\32xl\:rounded-bl-20 {
    border-bottom-left-radius: 2rem
  }

  .\32xl\:rounded-bl-24 {
    border-bottom-left-radius: 2.4rem
  }

  .\32xl\:rounded-bl-28 {
    border-bottom-left-radius: 2.8rem
  }

  .\32xl\:rounded-bl-32 {
    border-bottom-left-radius: 3.2rem
  }

  .\32xl\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .\32xl\:rounded-bl-sm {
    border-bottom-left-radius: .2rem
  }

  .\32xl\:rounded-bl {
    border-bottom-left-radius: 0.25rem
  }

  .\32xl\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem
  }

  .\32xl\:rounded-bl-lg {
    border-bottom-left-radius: .8rem
  }

  .\32xl\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem
  }

  .\32xl\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem
  }

  .\32xl\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem
  }

  .\32xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px
  }

  .\32xl\:rounded-bl-default {
    border-bottom-left-radius: .4rem
  }

  .\32xl\:border-0 {
    border-width: 0
  }

  .\32xl\:border-1 {
    border-width: 1px
  }

  .\32xl\:border-2 {
    border-width: 2px
  }

  .\32xl\:border-3 {
    border-width: 3px
  }

  .\32xl\:border-4 {
    border-width: 4px
  }

  .\32xl\:border-8 {
    border-width: 8px
  }

  .\32xl\:border {
    border-width: 1px
  }

  .\32xl\:border-default {
    border-width: 1px
  }

  .\32xl\:border-t-0 {
    border-top-width: 0
  }

  .\32xl\:border-t-1 {
    border-top-width: 1px
  }

  .\32xl\:border-t-2 {
    border-top-width: 2px
  }

  .\32xl\:border-t-3 {
    border-top-width: 3px
  }

  .\32xl\:border-t-4 {
    border-top-width: 4px
  }

  .\32xl\:border-t-8 {
    border-top-width: 8px
  }

  .\32xl\:border-t {
    border-top-width: 1px
  }

  .\32xl\:border-t-default {
    border-top-width: 1px
  }

  .\32xl\:border-r-0 {
    border-right-width: 0
  }

  .\32xl\:border-r-1 {
    border-right-width: 1px
  }

  .\32xl\:border-r-2 {
    border-right-width: 2px
  }

  .\32xl\:border-r-3 {
    border-right-width: 3px
  }

  .\32xl\:border-r-4 {
    border-right-width: 4px
  }

  .\32xl\:border-r-8 {
    border-right-width: 8px
  }

  .\32xl\:border-r {
    border-right-width: 1px
  }

  .\32xl\:border-r-default {
    border-right-width: 1px
  }

  .\32xl\:border-b-0 {
    border-bottom-width: 0
  }

  .\32xl\:border-b-1 {
    border-bottom-width: 1px
  }

  .\32xl\:border-b-2 {
    border-bottom-width: 2px
  }

  .\32xl\:border-b-3 {
    border-bottom-width: 3px
  }

  .\32xl\:border-b-4 {
    border-bottom-width: 4px
  }

  .\32xl\:border-b-8 {
    border-bottom-width: 8px
  }

  .\32xl\:border-b {
    border-bottom-width: 1px
  }

  .\32xl\:border-b-default {
    border-bottom-width: 1px
  }

  .\32xl\:border-l-0 {
    border-left-width: 0
  }

  .\32xl\:border-l-1 {
    border-left-width: 1px
  }

  .\32xl\:border-l-2 {
    border-left-width: 2px
  }

  .\32xl\:border-l-3 {
    border-left-width: 3px
  }

  .\32xl\:border-l-4 {
    border-left-width: 4px
  }

  .\32xl\:border-l-8 {
    border-left-width: 8px
  }

  .\32xl\:border-l {
    border-left-width: 1px
  }

  .\32xl\:border-l-default {
    border-left-width: 1px
  }

  .\32xl\:border-solid {
    border-style: solid
  }

  .\32xl\:border-dashed {
    border-style: dashed
  }

  .\32xl\:border-dotted {
    border-style: dotted
  }

  .\32xl\:border-double {
    border-style: double
  }

  .\32xl\:border-none {
    border-style: none
  }

  .\32xl\:border-transparent {
    border-color: transparent
  }

  .\32xl\:border-current {
    border-color: currentColor
  }

  .\32xl\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .\32xl\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .\32xl\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .\32xl\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .\32xl\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .\32xl\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .\32xl\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .\32xl\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .\32xl\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .\32xl\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .\32xl\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .\32xl\:border-gray-default {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .\32xl\:border-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .\32xl\:border-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .\32xl\:border-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .\32xl\:border-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .\32xl\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .\32xl\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .\32xl\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .\32xl\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .\32xl\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .\32xl\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .\32xl\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .\32xl\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .\32xl\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .\32xl\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .\32xl\:border-red-default {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .\32xl\:border-red-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .\32xl\:border-red-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .\32xl\:border-red-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .\32xl\:border-red-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .\32xl\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .\32xl\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .\32xl\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .\32xl\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .\32xl\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .\32xl\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .\32xl\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .\32xl\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .\32xl\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .\32xl\:border-yellow-default {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .\32xl\:border-yellow-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .\32xl\:border-yellow-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-yellow-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-yellow-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .\32xl\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .\32xl\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .\32xl\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .\32xl\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .\32xl\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .\32xl\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .\32xl\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .\32xl\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .\32xl\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .\32xl\:border-green-default {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .\32xl\:border-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .\32xl\:border-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .\32xl\:border-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .\32xl\:border-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-default {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .\32xl\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .\32xl\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .\32xl\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .\32xl\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .\32xl\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .\32xl\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .\32xl\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .\32xl\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .\32xl\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .\32xl\:border-indigo-default {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .\32xl\:border-indigo-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-indigo-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .\32xl\:border-indigo-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .\32xl\:border-indigo-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .\32xl\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .\32xl\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .\32xl\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .\32xl\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .\32xl\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .\32xl\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .\32xl\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .\32xl\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .\32xl\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .\32xl\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .\32xl\:border-purple-default {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .\32xl\:border-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .\32xl\:border-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .\32xl\:border-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .\32xl\:border-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .\32xl\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .\32xl\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .\32xl\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .\32xl\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .\32xl\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .\32xl\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .\32xl\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .\32xl\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .\32xl\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .\32xl\:border-pink-default {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .\32xl\:border-pink-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .\32xl\:border-pink-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .\32xl\:border-pink-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .\32xl\:border-pink-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .\32xl\:border-grey-50 {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .\32xl\:border-grey-100 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .\32xl\:border-grey-200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .\32xl\:border-grey-300 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .\32xl\:border-grey-400 {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .\32xl\:border-grey-500 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .\32xl\:border-grey-600 {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .\32xl\:border-grey-700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .\32xl\:border-grey-800 {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .\32xl\:border-grey-900 {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .\32xl\:border-grey-default {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .\32xl\:border-grey-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .\32xl\:border-grey-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .\32xl\:border-grey-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .\32xl\:border-grey-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .\32xl\:border-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .\32xl\:border-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .\32xl\:border-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .\32xl\:border-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .\32xl\:border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .\32xl\:border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-orange-default {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .\32xl\:border-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .\32xl\:border-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-orange-default {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-orange-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-orange-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-orange-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-orange-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-light-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .\32xl\:border-light-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .\32xl\:border-light-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .\32xl\:border-light-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .\32xl\:border-light-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .\32xl\:border-light-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .\32xl\:border-light-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .\32xl\:border-light-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .\32xl\:border-light-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .\32xl\:border-light-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .\32xl\:border-light-green-default {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .\32xl\:border-light-green-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .\32xl\:border-light-green-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .\32xl\:border-light-green-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .\32xl\:border-light-green-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .\32xl\:border-teal-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .\32xl\:border-teal-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .\32xl\:border-teal-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .\32xl\:border-teal-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .\32xl\:border-teal-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .\32xl\:border-teal-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .\32xl\:border-teal-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .\32xl\:border-teal-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .\32xl\:border-teal-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .\32xl\:border-teal-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .\32xl\:border-teal-default {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .\32xl\:border-teal-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .\32xl\:border-teal-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .\32xl\:border-teal-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .\32xl\:border-teal-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .\32xl\:border-light-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .\32xl\:border-light-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .\32xl\:border-light-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .\32xl\:border-light-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .\32xl\:border-light-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .\32xl\:border-light-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .\32xl\:border-light-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .\32xl\:border-light-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .\32xl\:border-light-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .\32xl\:border-light-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .\32xl\:border-light-blue-default {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .\32xl\:border-light-blue-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-light-blue-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-light-blue-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-light-blue-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-purple-default {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-purple-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-purple-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-purple-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-deep-purple-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .\32xl\:border-lime-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .\32xl\:border-lime-100 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .\32xl\:border-lime-200 {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .\32xl\:border-lime-300 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .\32xl\:border-lime-400 {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .\32xl\:border-lime-500 {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .\32xl\:border-lime-600 {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .\32xl\:border-lime-700 {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .\32xl\:border-lime-800 {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .\32xl\:border-lime-900 {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .\32xl\:border-lime-default {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .\32xl\:border-lime-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .\32xl\:border-lime-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .\32xl\:border-lime-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-lime-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-amber-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .\32xl\:border-amber-100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .\32xl\:border-amber-200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .\32xl\:border-amber-300 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .\32xl\:border-amber-400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .\32xl\:border-amber-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .\32xl\:border-amber-600 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-amber-800 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-amber-900 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-amber-default {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .\32xl\:border-amber-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .\32xl\:border-amber-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .\32xl\:border-amber-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-amber-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .\32xl\:border-brown-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .\32xl\:border-brown-100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .\32xl\:border-brown-200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .\32xl\:border-brown-300 {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .\32xl\:border-brown-400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .\32xl\:border-brown-500 {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .\32xl\:border-brown-600 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .\32xl\:border-brown-700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .\32xl\:border-brown-800 {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .\32xl\:border-brown-900 {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .\32xl\:border-brown-default {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .\32xl\:border-brown-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .\32xl\:border-brown-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .\32xl\:border-brown-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .\32xl\:border-brown-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-gray-default {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-gray-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-gray-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-gray-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .\32xl\:border-blue-gray-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .\32xl\:border-cyan-50 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .\32xl\:border-cyan-100 {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .\32xl\:border-cyan-200 {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .\32xl\:border-cyan-300 {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .\32xl\:border-cyan-400 {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .\32xl\:border-cyan-500 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .\32xl\:border-cyan-600 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .\32xl\:border-cyan-700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .\32xl\:border-cyan-800 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .\32xl\:border-cyan-900 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .\32xl\:border-cyan-default {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .\32xl\:border-cyan-A100 {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-cyan-A200 {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-cyan-A400 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .\32xl\:border-cyan-A700 {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .\32xl\:border-default {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-transparent:hover {
    border-color: transparent
  }

  .\32xl\:hover\:border-current:hover {
    border-color: currentColor
  }

  .\32xl\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-gray-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-gray-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-gray-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-gray-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-gray-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-red-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-red-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-red-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-red-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-red-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-yellow-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-yellow-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-yellow-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-yellow-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-yellow-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-green-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-green-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-green-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-green-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-green-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-indigo-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-indigo-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-indigo-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-indigo-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-indigo-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-purple-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-purple-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-purple-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-purple-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-purple-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-pink-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-pink-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-pink-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-pink-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-pink-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-grey-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-grey-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-grey-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-grey-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-grey-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-grey-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-grey-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-grey-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-grey-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-grey-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-grey-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-grey-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-grey-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-grey-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-grey-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-orange-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-orange-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-orange-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-orange-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-orange-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-orange-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-orange-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-orange-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-orange-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-orange-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-orange-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-orange-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-orange-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-orange-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-orange-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-orange-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-orange-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-orange-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-orange-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-orange-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-orange-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-orange-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-orange-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-orange-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-orange-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-orange-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-orange-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-orange-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-orange-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-orange-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-green-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-green-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-green-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-green-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-green-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-teal-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-teal-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-teal-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-teal-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-teal-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-teal-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-teal-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-teal-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-teal-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-teal-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-teal-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-teal-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-teal-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-teal-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-teal-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-blue-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-blue-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-blue-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-blue-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-light-blue-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-purple-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-purple-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-purple-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-purple-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-deep-purple-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-lime-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-lime-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-lime-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-lime-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-lime-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-lime-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-lime-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-lime-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-lime-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-lime-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-lime-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-lime-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-lime-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-lime-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-lime-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-amber-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-amber-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-amber-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-amber-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-amber-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-amber-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-amber-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-amber-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-amber-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-amber-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-amber-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-amber-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-amber-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-amber-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-amber-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-brown-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-brown-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-brown-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-brown-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-brown-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-brown-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-brown-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-brown-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-brown-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-brown-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-brown-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-brown-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-brown-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-brown-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-brown-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-gray-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-gray-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-gray-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-gray-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-blue-gray-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-cyan-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-cyan-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-cyan-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-cyan-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-cyan-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-cyan-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-cyan-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-cyan-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-cyan-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-cyan-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-cyan-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-cyan-A100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-cyan-A200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-cyan-A400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-cyan-A700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .\32xl\:hover\:border-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-transparent:focus {
    border-color: transparent
  }

  .\32xl\:focus\:border-current:focus {
    border-color: currentColor
  }

  .\32xl\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(34, 41, 47, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-gray-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-gray-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-gray-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-gray-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-gray-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 238, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 205, 210, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 154, 154, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 115, 115, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 83, 80, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 57, 53, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(211, 47, 47, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(198, 40, 40, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 28, 28, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-red-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 67, 54, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-red-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 128, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-red-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 82, 82, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-red-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 23, 68, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-red-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 253, 231, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 249, 196, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 245, 157, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 241, 118, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 238, 88, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 216, 53, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 192, 45, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 37, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 127, 23, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-yellow-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 235, 59, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-yellow-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 141, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-yellow-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-yellow-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 234, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-yellow-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 214, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(232, 245, 233, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(200, 230, 201, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 214, 167, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 199, 132, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(102, 187, 106, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 160, 71, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(56, 142, 60, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(46, 125, 50, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 94, 32, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-green-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 175, 80, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-green-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 246, 202, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-green-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(105, 240, 174, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-green-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 230, 118, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-green-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 200, 83, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(227, 242, 253, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(187, 222, 251, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(144, 202, 249, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 181, 246, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 165, 245, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 136, 229, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(25, 118, 210, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(21, 101, 192, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(13, 71, 161, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 150, 243, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(130, 177, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(68, 138, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 121, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 98, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(232, 234, 246, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 202, 233, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(159, 168, 218, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 134, 203, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(92, 107, 192, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(57, 73, 171, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 63, 159, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(40, 53, 147, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(26, 35, 126, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-indigo-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(63, 81, 181, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-indigo-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(140, 158, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-indigo-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(83, 109, 254, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-indigo-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(61, 90, 254, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-indigo-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 79, 254, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 229, 245, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(225, 190, 231, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(206, 147, 216, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(186, 104, 200, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(171, 71, 188, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(142, 36, 170, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(123, 31, 162, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(106, 27, 154, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(74, 20, 140, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-purple-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 39, 176, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-purple-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(234, 128, 252, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-purple-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 64, 251, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-purple-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 0, 249, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-purple-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 0, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 228, 236, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 187, 208, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 143, 177, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(240, 98, 146, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 64, 122, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(216, 27, 96, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(194, 24, 91, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(173, 20, 87, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(136, 14, 79, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-pink-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 30, 99, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-pink-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 128, 171, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-pink-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 64, 129, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-pink-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 0, 87, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-pink-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 17, 98, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-grey-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(250, 250, 250, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-grey-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 245, 245, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-grey-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 238, 238, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-grey-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-grey-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(189, 189, 189, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-grey-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-grey-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(117, 117, 117, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-grey-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-grey-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(66, 66, 66, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-grey-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-grey-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 158, 158, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-grey-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(213, 213, 213, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-grey-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 170, 170, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-grey-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(48, 48, 48, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-grey-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(97, 97, 97, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-orange-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 243, 224, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-orange-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 178, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-orange-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 128, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-orange-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 183, 77, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-orange-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 167, 38, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-orange-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-orange-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 140, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-orange-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 124, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-orange-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 108, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-orange-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 81, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-orange-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 152, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-orange-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 209, 128, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-orange-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 64, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-orange-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 145, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-orange-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 109, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-orange-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 233, 231, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-orange-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 204, 188, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-orange-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 145, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-orange-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 138, 101, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-orange-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 112, 67, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-orange-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-orange-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 81, 30, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-orange-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 74, 25, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-orange-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(216, 67, 21, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-orange-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 54, 12, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-orange-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 87, 34, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-orange-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 158, 128, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-orange-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 110, 64, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-orange-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 61, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-orange-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 44, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(241, 248, 233, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 237, 200, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(197, 225, 165, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(174, 213, 129, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 204, 101, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 179, 66, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(104, 159, 56, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(85, 139, 47, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(51, 105, 30, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-green-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 195, 74, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-green-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(204, 255, 144, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-green-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 255, 89, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-green-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(118, 255, 3, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-green-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 221, 23, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-teal-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 242, 241, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-teal-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 223, 219, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-teal-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 203, 196, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-teal-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(77, 182, 172, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-teal-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 166, 154, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-teal-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-teal-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 137, 123, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-teal-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 121, 107, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-teal-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 105, 92, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-teal-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 77, 64, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-teal-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 150, 136, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-teal-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 255, 235, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-teal-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(100, 255, 218, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-teal-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 233, 182, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-teal-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 191, 165, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(225, 245, 254, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 229, 252, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 212, 250, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 195, 247, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(41, 182, 246, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 155, 229, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(2, 136, 209, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(2, 119, 189, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(1, 87, 155, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-blue-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(3, 169, 244, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-blue-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 216, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-blue-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(64, 196, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-blue-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 176, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-light-blue-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 145, 234, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 231, 246, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 196, 233, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 157, 219, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(149, 117, 205, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(126, 87, 194, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(94, 53, 177, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(81, 45, 168, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 39, 160, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 27, 146, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-purple-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(103, 58, 183, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-purple-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(179, 136, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-purple-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 77, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-purple-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(101, 31, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-deep-purple-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(98, 0, 234, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-lime-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 251, 231, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-lime-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(240, 244, 195, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-lime-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 238, 156, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-lime-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 231, 117, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-lime-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(212, 225, 87, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-lime-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-lime-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(192, 202, 51, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-lime-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(175, 180, 43, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-lime-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(158, 157, 36, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-lime-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(130, 119, 23, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-lime-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(205, 220, 57, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-lime-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 255, 129, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-lime-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 255, 65, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-lime-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(198, 255, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-lime-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(174, 234, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-amber-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 248, 225, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-amber-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 236, 179, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-amber-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 224, 130, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-amber-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 213, 79, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-amber-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 202, 40, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-amber-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-amber-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 179, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-amber-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 160, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-amber-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 143, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-amber-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 111, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-amber-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 193, 7, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-amber-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 229, 127, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-amber-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 215, 64, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-amber-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 196, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-amber-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 171, 0, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-brown-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 235, 233, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-brown-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-brown-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-brown-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(161, 136, 127, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-brown-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-brown-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-brown-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 76, 65, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-brown-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-brown-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(78, 52, 46, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-brown-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(62, 39, 35, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-brown-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(121, 85, 72, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-brown-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(215, 204, 200, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-brown-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(188, 170, 164, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-brown-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(141, 110, 99, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-brown-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(93, 64, 55, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 239, 241, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(144, 164, 174, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(84, 110, 122, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 71, 79, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 50, 56, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-gray-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 125, 139, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-gray-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(207, 216, 220, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-gray-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(176, 190, 197, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-gray-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 144, 156, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-blue-gray-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(69, 90, 100, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-cyan-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 247, 250, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-cyan-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(178, 235, 242, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-cyan-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(128, 222, 234, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-cyan-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(77, 208, 225, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-cyan-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 198, 218, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-cyan-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-cyan-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 172, 193, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-cyan-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 151, 167, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-cyan-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 131, 143, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-cyan-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 96, 100, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-cyan-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 188, 212, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-cyan-A100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(132, 255, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-cyan-A200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(24, 255, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-cyan-A400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 229, 255, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-cyan-A700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 184, 212, var(--tw-border-opacity))
  }

  .\32xl\:focus\:border-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 224, 224, var(--tw-border-opacity))
  }

  .\32xl\:border-opacity-0 {
    --tw-border-opacity: 0
  }

  .\32xl\:border-opacity-5 {
    --tw-border-opacity: 0.05
  }

  .\32xl\:border-opacity-10 {
    --tw-border-opacity: 0.1
  }

  .\32xl\:border-opacity-20 {
    --tw-border-opacity: 0.2
  }

  .\32xl\:border-opacity-25 {
    --tw-border-opacity: 0.25
  }

  .\32xl\:border-opacity-30 {
    --tw-border-opacity: 0.3
  }

  .\32xl\:border-opacity-40 {
    --tw-border-opacity: 0.4
  }

  .\32xl\:border-opacity-50 {
    --tw-border-opacity: 0.5
  }

  .\32xl\:border-opacity-60 {
    --tw-border-opacity: 0.6
  }

  .\32xl\:border-opacity-70 {
    --tw-border-opacity: 0.7
  }

  .\32xl\:border-opacity-75 {
    --tw-border-opacity: 0.75
  }

  .\32xl\:border-opacity-80 {
    --tw-border-opacity: 0.8
  }

  .\32xl\:border-opacity-90 {
    --tw-border-opacity: 0.9
  }

  .\32xl\:border-opacity-95 {
    --tw-border-opacity: 0.95
  }

  .\32xl\:border-opacity-100 {
    --tw-border-opacity: 1
  }

  .group:hover .\32xl\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0
  }

  .group:hover .\32xl\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05
  }

  .group:hover .\32xl\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1
  }

  .group:hover .\32xl\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2
  }

  .group:hover .\32xl\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25
  }

  .group:hover .\32xl\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3
  }

  .group:hover .\32xl\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4
  }

  .group:hover .\32xl\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5
  }

  .group:hover .\32xl\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6
  }

  .group:hover .\32xl\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7
  }

  .group:hover .\32xl\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75
  }

  .group:hover .\32xl\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8
  }

  .group:hover .\32xl\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9
  }

  .group:hover .\32xl\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95
  }

  .group:hover .\32xl\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1
  }

  .\32xl\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0
  }

  .\32xl\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05
  }

  .\32xl\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1
  }

  .\32xl\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2
  }

  .\32xl\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25
  }

  .\32xl\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3
  }

  .\32xl\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4
  }

  .\32xl\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5
  }

  .\32xl\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6
  }

  .\32xl\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7
  }

  .\32xl\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75
  }

  .\32xl\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8
  }

  .\32xl\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9
  }

  .\32xl\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95
  }

  .\32xl\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1
  }

  .\32xl\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0
  }

  .\32xl\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05
  }

  .\32xl\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1
  }

  .\32xl\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2
  }

  .\32xl\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25
  }

  .\32xl\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3
  }

  .\32xl\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4
  }

  .\32xl\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5
  }

  .\32xl\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6
  }

  .\32xl\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7
  }

  .\32xl\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75
  }

  .\32xl\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8
  }

  .\32xl\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9
  }

  .\32xl\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95
  }

  .\32xl\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1
  }

  .\32xl\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0
  }

  .\32xl\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05
  }

  .\32xl\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1
  }

  .\32xl\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2
  }

  .\32xl\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25
  }

  .\32xl\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3
  }

  .\32xl\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4
  }

  .\32xl\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5
  }

  .\32xl\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6
  }

  .\32xl\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7
  }

  .\32xl\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75
  }

  .\32xl\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8
  }

  .\32xl\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9
  }

  .\32xl\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95
  }

  .\32xl\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1
  }

  .\32xl\:bg-transparent {
    background-color: transparent
  }

  .\32xl\:bg-current {
    background-color: currentColor
  }

  .\32xl\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .\32xl\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .\32xl\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .\32xl\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .\32xl\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .\32xl\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .\32xl\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .\32xl\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .\32xl\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .\32xl\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .\32xl\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .\32xl\:bg-gray-default {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .\32xl\:bg-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .\32xl\:bg-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .\32xl\:bg-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .\32xl\:bg-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .\32xl\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .\32xl\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .\32xl\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .\32xl\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .\32xl\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .\32xl\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .\32xl\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .\32xl\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .\32xl\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .\32xl\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .\32xl\:bg-red-default {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .\32xl\:bg-red-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .\32xl\:bg-red-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .\32xl\:bg-red-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .\32xl\:bg-red-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .\32xl\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .\32xl\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .\32xl\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .\32xl\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .\32xl\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .\32xl\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .\32xl\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .\32xl\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .\32xl\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .\32xl\:bg-yellow-default {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .\32xl\:bg-yellow-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .\32xl\:bg-yellow-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-yellow-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-yellow-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .\32xl\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .\32xl\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .\32xl\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .\32xl\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .\32xl\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .\32xl\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .\32xl\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .\32xl\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .\32xl\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .\32xl\:bg-green-default {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .\32xl\:bg-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .\32xl\:bg-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .\32xl\:bg-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .\32xl\:bg-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-default {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .\32xl\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .\32xl\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .\32xl\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .\32xl\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .\32xl\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .\32xl\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .\32xl\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .\32xl\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .\32xl\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .\32xl\:bg-indigo-default {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .\32xl\:bg-indigo-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-indigo-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .\32xl\:bg-indigo-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .\32xl\:bg-indigo-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .\32xl\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .\32xl\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .\32xl\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .\32xl\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .\32xl\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .\32xl\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .\32xl\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .\32xl\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .\32xl\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .\32xl\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .\32xl\:bg-purple-default {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .\32xl\:bg-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .\32xl\:bg-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .\32xl\:bg-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .\32xl\:bg-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .\32xl\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .\32xl\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .\32xl\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .\32xl\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .\32xl\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .\32xl\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .\32xl\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .\32xl\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .\32xl\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .\32xl\:bg-pink-default {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .\32xl\:bg-pink-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .\32xl\:bg-pink-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .\32xl\:bg-pink-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .\32xl\:bg-pink-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .\32xl\:bg-grey-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .\32xl\:bg-grey-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .\32xl\:bg-grey-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .\32xl\:bg-grey-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .\32xl\:bg-grey-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .\32xl\:bg-grey-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .\32xl\:bg-grey-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .\32xl\:bg-grey-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .\32xl\:bg-grey-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .\32xl\:bg-grey-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .\32xl\:bg-grey-default {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .\32xl\:bg-grey-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .\32xl\:bg-grey-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .\32xl\:bg-grey-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .\32xl\:bg-grey-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .\32xl\:bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .\32xl\:bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .\32xl\:bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .\32xl\:bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .\32xl\:bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .\32xl\:bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-orange-default {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .\32xl\:bg-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .\32xl\:bg-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-orange-default {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-orange-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-orange-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-orange-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-orange-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-green-default {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-green-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-green-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-green-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-green-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .\32xl\:bg-teal-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .\32xl\:bg-teal-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .\32xl\:bg-teal-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .\32xl\:bg-teal-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .\32xl\:bg-teal-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .\32xl\:bg-teal-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .\32xl\:bg-teal-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .\32xl\:bg-teal-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .\32xl\:bg-teal-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .\32xl\:bg-teal-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .\32xl\:bg-teal-default {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .\32xl\:bg-teal-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .\32xl\:bg-teal-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .\32xl\:bg-teal-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .\32xl\:bg-teal-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-blue-default {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-blue-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-blue-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-blue-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-light-blue-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-purple-default {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-purple-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-purple-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-purple-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-deep-purple-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .\32xl\:bg-lime-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .\32xl\:bg-lime-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .\32xl\:bg-lime-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .\32xl\:bg-lime-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .\32xl\:bg-lime-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .\32xl\:bg-lime-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .\32xl\:bg-lime-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .\32xl\:bg-lime-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .\32xl\:bg-lime-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .\32xl\:bg-lime-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .\32xl\:bg-lime-default {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .\32xl\:bg-lime-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .\32xl\:bg-lime-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .\32xl\:bg-lime-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-lime-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-amber-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .\32xl\:bg-amber-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .\32xl\:bg-amber-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .\32xl\:bg-amber-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .\32xl\:bg-amber-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .\32xl\:bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .\32xl\:bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-amber-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-amber-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-amber-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-amber-default {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .\32xl\:bg-amber-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .\32xl\:bg-amber-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .\32xl\:bg-amber-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-amber-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .\32xl\:bg-brown-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .\32xl\:bg-brown-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .\32xl\:bg-brown-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .\32xl\:bg-brown-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .\32xl\:bg-brown-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .\32xl\:bg-brown-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .\32xl\:bg-brown-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .\32xl\:bg-brown-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .\32xl\:bg-brown-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .\32xl\:bg-brown-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .\32xl\:bg-brown-default {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .\32xl\:bg-brown-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .\32xl\:bg-brown-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .\32xl\:bg-brown-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .\32xl\:bg-brown-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-gray-default {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-gray-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-gray-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-gray-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .\32xl\:bg-blue-gray-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .\32xl\:bg-cyan-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .\32xl\:bg-cyan-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .\32xl\:bg-cyan-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .\32xl\:bg-cyan-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .\32xl\:bg-cyan-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .\32xl\:bg-cyan-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .\32xl\:bg-cyan-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .\32xl\:bg-cyan-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .\32xl\:bg-cyan-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .\32xl\:bg-cyan-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .\32xl\:bg-cyan-default {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .\32xl\:bg-cyan-A100 {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-cyan-A200 {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-cyan-A400 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .\32xl\:bg-cyan-A700 {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-transparent:hover {
    background-color: transparent
  }

  .\32xl\:hover\:bg-current:hover {
    background-color: currentColor
  }

  .\32xl\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-gray-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-gray-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-gray-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-gray-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-gray-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-red-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-red-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-red-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-red-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-red-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-yellow-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-yellow-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-yellow-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-yellow-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-yellow-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-green-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-green-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-green-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-green-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-green-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-indigo-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-indigo-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-indigo-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-indigo-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-indigo-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-purple-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-purple-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-purple-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-purple-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-purple-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-pink-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-pink-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-pink-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-pink-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-pink-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-grey-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-grey-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-grey-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-grey-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-grey-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-grey-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-grey-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-grey-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-grey-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-grey-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-grey-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-grey-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-grey-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-grey-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-grey-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-orange-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-orange-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-orange-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-orange-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-orange-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-orange-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-orange-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-orange-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-orange-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-orange-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-orange-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-orange-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-orange-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-orange-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-orange-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-orange-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-orange-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-orange-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-orange-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-orange-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-orange-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-orange-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-orange-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-orange-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-orange-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-orange-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-orange-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-orange-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-orange-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-orange-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-green-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-green-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-green-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-green-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-green-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-teal-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-teal-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-teal-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-teal-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-teal-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-teal-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-teal-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-teal-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-teal-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-teal-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-teal-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-teal-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-teal-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-teal-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-teal-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-blue-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-blue-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-blue-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-blue-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-light-blue-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-purple-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-purple-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-purple-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-purple-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-deep-purple-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-lime-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-lime-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-lime-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-lime-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-lime-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-lime-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-lime-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-lime-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-lime-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-lime-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-lime-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-lime-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-lime-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-lime-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-lime-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-amber-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-amber-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-amber-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-amber-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-amber-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-amber-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-amber-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-amber-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-amber-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-amber-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-amber-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-amber-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-amber-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-amber-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-amber-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-brown-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-brown-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-brown-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-brown-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-brown-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-brown-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-brown-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-brown-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-brown-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-brown-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-brown-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-brown-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-brown-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-brown-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-brown-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-gray-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-gray-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-gray-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-gray-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-blue-gray-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-cyan-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-cyan-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-cyan-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-cyan-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-cyan-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-cyan-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-cyan-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-cyan-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-cyan-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-cyan-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-cyan-default:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-cyan-A100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-cyan-A200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-cyan-A400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .\32xl\:hover\:bg-cyan-A700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-transparent:focus {
    background-color: transparent
  }

  .\32xl\:focus\:bg-current:focus {
    background-color: currentColor
  }

  .\32xl\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(34, 41, 47, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-gray-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-gray-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-gray-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-gray-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-gray-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 238, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 205, 210, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 154, 154, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 115, 115, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 83, 80, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 57, 53, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(211, 47, 47, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 40, 40, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 28, 28, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-red-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-red-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 128, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-red-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 82, 82, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-red-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 23, 68, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-red-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 253, 231, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 249, 196, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 245, 157, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 118, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 238, 88, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 216, 53, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 192, 45, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 37, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 127, 23, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-yellow-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 235, 59, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-yellow-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 141, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-yellow-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-yellow-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 234, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-yellow-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 214, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 245, 233, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(200, 230, 201, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 214, 167, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 199, 132, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(102, 187, 106, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 160, 71, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 142, 60, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 125, 50, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 94, 32, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-green-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 175, 80, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-green-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 246, 202, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-green-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 240, 174, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-green-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 230, 118, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-green-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 200, 83, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(187, 222, 251, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 202, 249, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 181, 246, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 165, 245, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 136, 229, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(25, 118, 210, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(21, 101, 192, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(13, 71, 161, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 150, 243, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 177, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 138, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 121, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 98, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 234, 246, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 202, 233, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 168, 218, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 134, 203, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(92, 107, 192, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 73, 171, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 63, 159, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(40, 53, 147, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 35, 126, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-indigo-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 81, 181, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-indigo-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(140, 158, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-indigo-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(83, 109, 254, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-indigo-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(61, 90, 254, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-indigo-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 79, 254, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 229, 245, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 190, 231, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(206, 147, 216, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(186, 104, 200, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(171, 71, 188, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 36, 170, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(123, 31, 162, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(106, 27, 154, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(74, 20, 140, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-purple-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 39, 176, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-purple-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 128, 252, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-purple-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 64, 251, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-purple-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 0, 249, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-purple-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 0, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 228, 236, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 187, 208, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 143, 177, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 98, 146, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 64, 122, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 27, 96, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 24, 91, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(173, 20, 87, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 14, 79, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-pink-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 30, 99, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-pink-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 128, 171, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-pink-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 64, 129, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-pink-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 0, 87, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-pink-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 17, 98, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-grey-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-grey-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-grey-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 238, 238, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-grey-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 224, 224, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-grey-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(189, 189, 189, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-grey-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-grey-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(117, 117, 117, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-grey-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-grey-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 66, 66, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-grey-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-grey-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 158, 158, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-grey-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(213, 213, 213, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-grey-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 170, 170, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-grey-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(48, 48, 48, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-grey-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 97, 97, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-orange-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 243, 224, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-orange-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 178, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-orange-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 128, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-orange-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 183, 77, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-orange-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 167, 38, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-orange-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-orange-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 140, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-orange-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 124, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-orange-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 108, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-orange-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 81, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-orange-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 152, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-orange-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 209, 128, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-orange-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 64, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-orange-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 145, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-orange-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 109, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-orange-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 233, 231, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-orange-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 204, 188, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-orange-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 145, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-orange-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 138, 101, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-orange-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 112, 67, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-orange-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-orange-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 81, 30, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-orange-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 74, 25, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-orange-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(216, 67, 21, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-orange-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 54, 12, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-orange-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 87, 34, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-orange-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 158, 128, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-orange-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 110, 64, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-orange-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 61, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-orange-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 44, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 248, 233, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 237, 200, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 225, 165, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 213, 129, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 204, 101, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 179, 66, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(104, 159, 56, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(85, 139, 47, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 105, 30, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-green-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 195, 74, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-green-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 255, 144, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-green-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 255, 89, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-green-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 255, 3, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-green-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 221, 23, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-teal-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 242, 241, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-teal-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 223, 219, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-teal-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 203, 196, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-teal-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 182, 172, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-teal-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 166, 154, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-teal-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-teal-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 137, 123, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-teal-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 121, 107, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-teal-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 105, 92, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-teal-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 77, 64, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-teal-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 150, 136, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-teal-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 255, 235, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-teal-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 255, 218, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-teal-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 233, 182, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-teal-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 191, 165, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 245, 254, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 229, 252, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 212, 250, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 195, 247, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 182, 246, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 155, 229, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 136, 209, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(2, 119, 189, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(1, 87, 155, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-blue-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 169, 244, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-blue-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 216, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-blue-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 196, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-blue-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 176, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-light-blue-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 145, 234, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 231, 246, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 196, 233, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 157, 219, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(149, 117, 205, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(126, 87, 194, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(94, 53, 177, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(81, 45, 168, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 39, 160, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 27, 146, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-purple-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(103, 58, 183, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-purple-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(179, 136, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-purple-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 77, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-purple-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 31, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-deep-purple-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(98, 0, 234, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-lime-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 251, 231, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-lime-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 244, 195, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-lime-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 238, 156, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-lime-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 231, 117, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-lime-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 225, 87, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-lime-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-lime-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(192, 202, 51, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-lime-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(175, 180, 43, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-lime-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(158, 157, 36, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-lime-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 119, 23, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-lime-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(205, 220, 57, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-lime-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 255, 129, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-lime-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 255, 65, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-lime-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(198, 255, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-lime-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(174, 234, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-amber-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 248, 225, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-amber-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 236, 179, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-amber-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 224, 130, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-amber-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 213, 79, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-amber-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 202, 40, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-amber-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-amber-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 179, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-amber-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 160, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-amber-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 143, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-amber-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 111, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-amber-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-amber-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 229, 127, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-amber-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 215, 64, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-amber-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 196, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-amber-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 171, 0, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-brown-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 235, 233, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-brown-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-brown-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-brown-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 136, 127, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-brown-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-brown-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-brown-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 76, 65, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-brown-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-brown-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 52, 46, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-brown-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(62, 39, 35, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-brown-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(121, 85, 72, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-brown-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(215, 204, 200, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-brown-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(188, 170, 164, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-brown-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(141, 110, 99, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-brown-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(93, 64, 55, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 239, 241, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 164, 174, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(84, 110, 122, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 71, 79, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 50, 56, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-gray-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 125, 139, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-gray-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 216, 220, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-gray-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(176, 190, 197, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-gray-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 144, 156, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-blue-gray-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(69, 90, 100, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-cyan-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 247, 250, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-cyan-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(178, 235, 242, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-cyan-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(128, 222, 234, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-cyan-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(77, 208, 225, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-cyan-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 198, 218, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-cyan-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-cyan-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 193, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-cyan-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 151, 167, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-cyan-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 131, 143, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-cyan-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 96, 100, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-cyan-default:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 188, 212, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-cyan-A100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(132, 255, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-cyan-A200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(24, 255, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-cyan-A400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 229, 255, var(--tw-bg-opacity))
  }

  .\32xl\:focus\:bg-cyan-A700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 184, 212, var(--tw-bg-opacity))
  }

  .\32xl\:bg-opacity-0 {
    --tw-bg-opacity: 0
  }

  .\32xl\:bg-opacity-5 {
    --tw-bg-opacity: 0.05
  }

  .\32xl\:bg-opacity-10 {
    --tw-bg-opacity: 0.1
  }

  .\32xl\:bg-opacity-20 {
    --tw-bg-opacity: 0.2
  }

  .\32xl\:bg-opacity-25 {
    --tw-bg-opacity: 0.25
  }

  .\32xl\:bg-opacity-30 {
    --tw-bg-opacity: 0.3
  }

  .\32xl\:bg-opacity-40 {
    --tw-bg-opacity: 0.4
  }

  .\32xl\:bg-opacity-50 {
    --tw-bg-opacity: 0.5
  }

  .\32xl\:bg-opacity-60 {
    --tw-bg-opacity: 0.6
  }

  .\32xl\:bg-opacity-70 {
    --tw-bg-opacity: 0.7
  }

  .\32xl\:bg-opacity-75 {
    --tw-bg-opacity: 0.75
  }

  .\32xl\:bg-opacity-80 {
    --tw-bg-opacity: 0.8
  }

  .\32xl\:bg-opacity-90 {
    --tw-bg-opacity: 0.9
  }

  .\32xl\:bg-opacity-95 {
    --tw-bg-opacity: 0.95
  }

  .\32xl\:bg-opacity-100 {
    --tw-bg-opacity: 1
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1
  }

  .\32xl\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0
  }

  .\32xl\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05
  }

  .\32xl\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1
  }

  .\32xl\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2
  }

  .\32xl\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25
  }

  .\32xl\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3
  }

  .\32xl\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4
  }

  .\32xl\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5
  }

  .\32xl\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6
  }

  .\32xl\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7
  }

  .\32xl\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75
  }

  .\32xl\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8
  }

  .\32xl\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9
  }

  .\32xl\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95
  }

  .\32xl\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1
  }

  .\32xl\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0
  }

  .\32xl\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05
  }

  .\32xl\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1
  }

  .\32xl\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2
  }

  .\32xl\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25
  }

  .\32xl\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3
  }

  .\32xl\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4
  }

  .\32xl\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5
  }

  .\32xl\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6
  }

  .\32xl\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7
  }

  .\32xl\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75
  }

  .\32xl\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8
  }

  .\32xl\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9
  }

  .\32xl\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95
  }

  .\32xl\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1
  }

  .\32xl\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0
  }

  .\32xl\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05
  }

  .\32xl\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1
  }

  .\32xl\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2
  }

  .\32xl\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25
  }

  .\32xl\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3
  }

  .\32xl\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4
  }

  .\32xl\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5
  }

  .\32xl\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6
  }

  .\32xl\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7
  }

  .\32xl\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75
  }

  .\32xl\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8
  }

  .\32xl\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9
  }

  .\32xl\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95
  }

  .\32xl\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1
  }

  .\32xl\:bg-none {
    background-image: none
  }

  .\32xl\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops))
  }

  .\32xl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops))
  }

  .\32xl\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
  }

  .\32xl\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops))
  }

  .\32xl\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
  }

  .\32xl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops))
  }

  .\32xl\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops))
  }

  .\32xl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops))
  }

  .\32xl\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .\32xl\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .\32xl\:from-black {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .\32xl\:from-white {
    --tw-gradient-from: #FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .\32xl\:from-gray-50 {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .\32xl\:from-gray-100 {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .\32xl\:from-gray-200 {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .\32xl\:from-gray-300 {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .\32xl\:from-gray-400 {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .\32xl\:from-gray-500 {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:from-gray-600 {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .\32xl\:from-gray-700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:from-gray-800 {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .\32xl\:from-gray-900 {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .\32xl\:from-gray-default {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:from-gray-A100 {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .\32xl\:from-gray-A200 {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .\32xl\:from-gray-A400 {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .\32xl\:from-gray-A700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:from-red-50 {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .\32xl\:from-red-100 {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .\32xl\:from-red-200 {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .\32xl\:from-red-300 {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .\32xl\:from-red-400 {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .\32xl\:from-red-500 {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .\32xl\:from-red-600 {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .\32xl\:from-red-700 {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .\32xl\:from-red-800 {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .\32xl\:from-red-900 {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .\32xl\:from-red-default {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .\32xl\:from-red-A100 {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .\32xl\:from-red-A200 {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .\32xl\:from-red-A400 {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .\32xl\:from-red-A700 {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .\32xl\:from-yellow-50 {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .\32xl\:from-yellow-100 {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .\32xl\:from-yellow-200 {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .\32xl\:from-yellow-300 {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .\32xl\:from-yellow-400 {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .\32xl\:from-yellow-500 {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .\32xl\:from-yellow-600 {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .\32xl\:from-yellow-700 {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .\32xl\:from-yellow-800 {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .\32xl\:from-yellow-900 {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .\32xl\:from-yellow-default {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .\32xl\:from-yellow-A100 {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .\32xl\:from-yellow-A200 {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .\32xl\:from-yellow-A400 {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .\32xl\:from-yellow-A700 {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .\32xl\:from-green-50 {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .\32xl\:from-green-100 {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .\32xl\:from-green-200 {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .\32xl\:from-green-300 {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .\32xl\:from-green-400 {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .\32xl\:from-green-500 {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .\32xl\:from-green-600 {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .\32xl\:from-green-700 {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .\32xl\:from-green-800 {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .\32xl\:from-green-900 {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .\32xl\:from-green-default {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .\32xl\:from-green-A100 {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .\32xl\:from-green-A200 {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .\32xl\:from-green-A400 {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .\32xl\:from-green-A700 {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .\32xl\:from-blue-50 {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .\32xl\:from-blue-100 {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .\32xl\:from-blue-200 {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .\32xl\:from-blue-300 {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .\32xl\:from-blue-400 {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .\32xl\:from-blue-500 {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .\32xl\:from-blue-600 {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .\32xl\:from-blue-700 {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .\32xl\:from-blue-800 {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .\32xl\:from-blue-900 {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .\32xl\:from-blue-default {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .\32xl\:from-blue-A100 {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .\32xl\:from-blue-A200 {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .\32xl\:from-blue-A400 {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .\32xl\:from-blue-A700 {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .\32xl\:from-indigo-50 {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .\32xl\:from-indigo-100 {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .\32xl\:from-indigo-200 {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .\32xl\:from-indigo-300 {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .\32xl\:from-indigo-400 {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .\32xl\:from-indigo-500 {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .\32xl\:from-indigo-600 {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .\32xl\:from-indigo-700 {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .\32xl\:from-indigo-800 {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .\32xl\:from-indigo-900 {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .\32xl\:from-indigo-default {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .\32xl\:from-indigo-A100 {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .\32xl\:from-indigo-A200 {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .\32xl\:from-indigo-A400 {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .\32xl\:from-indigo-A700 {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .\32xl\:from-purple-50 {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .\32xl\:from-purple-100 {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .\32xl\:from-purple-200 {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .\32xl\:from-purple-300 {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .\32xl\:from-purple-400 {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .\32xl\:from-purple-500 {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .\32xl\:from-purple-600 {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .\32xl\:from-purple-700 {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .\32xl\:from-purple-800 {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .\32xl\:from-purple-900 {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .\32xl\:from-purple-default {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .\32xl\:from-purple-A100 {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .\32xl\:from-purple-A200 {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .\32xl\:from-purple-A400 {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .\32xl\:from-purple-A700 {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .\32xl\:from-pink-50 {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .\32xl\:from-pink-100 {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .\32xl\:from-pink-200 {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .\32xl\:from-pink-300 {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .\32xl\:from-pink-400 {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .\32xl\:from-pink-500 {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .\32xl\:from-pink-600 {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .\32xl\:from-pink-700 {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .\32xl\:from-pink-800 {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .\32xl\:from-pink-900 {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .\32xl\:from-pink-default {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .\32xl\:from-pink-A100 {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .\32xl\:from-pink-A200 {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .\32xl\:from-pink-A400 {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .\32xl\:from-pink-A700 {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .\32xl\:from-grey-50 {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .\32xl\:from-grey-100 {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .\32xl\:from-grey-200 {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .\32xl\:from-grey-300 {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .\32xl\:from-grey-400 {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .\32xl\:from-grey-500 {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:from-grey-600 {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .\32xl\:from-grey-700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:from-grey-800 {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .\32xl\:from-grey-900 {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .\32xl\:from-grey-default {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:from-grey-A100 {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .\32xl\:from-grey-A200 {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .\32xl\:from-grey-A400 {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .\32xl\:from-grey-A700 {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:from-orange-50 {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .\32xl\:from-orange-100 {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .\32xl\:from-orange-200 {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .\32xl\:from-orange-300 {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .\32xl\:from-orange-400 {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .\32xl\:from-orange-500 {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .\32xl\:from-orange-600 {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .\32xl\:from-orange-700 {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .\32xl\:from-orange-800 {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .\32xl\:from-orange-900 {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .\32xl\:from-orange-default {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .\32xl\:from-orange-A100 {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .\32xl\:from-orange-A200 {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .\32xl\:from-orange-A400 {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .\32xl\:from-orange-A700 {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .\32xl\:from-deep-orange-50 {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .\32xl\:from-deep-orange-100 {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .\32xl\:from-deep-orange-200 {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .\32xl\:from-deep-orange-300 {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .\32xl\:from-deep-orange-400 {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .\32xl\:from-deep-orange-500 {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .\32xl\:from-deep-orange-600 {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .\32xl\:from-deep-orange-700 {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .\32xl\:from-deep-orange-800 {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .\32xl\:from-deep-orange-900 {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .\32xl\:from-deep-orange-default {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .\32xl\:from-deep-orange-A100 {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .\32xl\:from-deep-orange-A200 {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .\32xl\:from-deep-orange-A400 {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .\32xl\:from-deep-orange-A700 {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .\32xl\:from-light-green-50 {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .\32xl\:from-light-green-100 {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .\32xl\:from-light-green-200 {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .\32xl\:from-light-green-300 {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .\32xl\:from-light-green-400 {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .\32xl\:from-light-green-500 {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .\32xl\:from-light-green-600 {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .\32xl\:from-light-green-700 {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .\32xl\:from-light-green-800 {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .\32xl\:from-light-green-900 {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .\32xl\:from-light-green-default {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .\32xl\:from-light-green-A100 {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .\32xl\:from-light-green-A200 {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .\32xl\:from-light-green-A400 {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .\32xl\:from-light-green-A700 {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .\32xl\:from-teal-50 {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .\32xl\:from-teal-100 {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .\32xl\:from-teal-200 {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .\32xl\:from-teal-300 {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .\32xl\:from-teal-400 {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .\32xl\:from-teal-500 {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .\32xl\:from-teal-600 {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .\32xl\:from-teal-700 {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .\32xl\:from-teal-800 {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .\32xl\:from-teal-900 {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .\32xl\:from-teal-default {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .\32xl\:from-teal-A100 {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .\32xl\:from-teal-A200 {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .\32xl\:from-teal-A400 {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .\32xl\:from-teal-A700 {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .\32xl\:from-light-blue-50 {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .\32xl\:from-light-blue-100 {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .\32xl\:from-light-blue-200 {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .\32xl\:from-light-blue-300 {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .\32xl\:from-light-blue-400 {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .\32xl\:from-light-blue-500 {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .\32xl\:from-light-blue-600 {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .\32xl\:from-light-blue-700 {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .\32xl\:from-light-blue-800 {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .\32xl\:from-light-blue-900 {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .\32xl\:from-light-blue-default {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .\32xl\:from-light-blue-A100 {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .\32xl\:from-light-blue-A200 {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .\32xl\:from-light-blue-A400 {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .\32xl\:from-light-blue-A700 {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .\32xl\:from-deep-purple-50 {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .\32xl\:from-deep-purple-100 {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .\32xl\:from-deep-purple-200 {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .\32xl\:from-deep-purple-300 {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .\32xl\:from-deep-purple-400 {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .\32xl\:from-deep-purple-500 {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .\32xl\:from-deep-purple-600 {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .\32xl\:from-deep-purple-700 {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .\32xl\:from-deep-purple-800 {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .\32xl\:from-deep-purple-900 {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .\32xl\:from-deep-purple-default {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .\32xl\:from-deep-purple-A100 {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .\32xl\:from-deep-purple-A200 {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .\32xl\:from-deep-purple-A400 {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .\32xl\:from-deep-purple-A700 {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .\32xl\:from-lime-50 {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .\32xl\:from-lime-100 {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .\32xl\:from-lime-200 {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .\32xl\:from-lime-300 {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .\32xl\:from-lime-400 {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .\32xl\:from-lime-500 {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .\32xl\:from-lime-600 {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .\32xl\:from-lime-700 {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .\32xl\:from-lime-800 {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .\32xl\:from-lime-900 {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .\32xl\:from-lime-default {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .\32xl\:from-lime-A100 {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .\32xl\:from-lime-A200 {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .\32xl\:from-lime-A400 {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .\32xl\:from-lime-A700 {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .\32xl\:from-amber-50 {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .\32xl\:from-amber-100 {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .\32xl\:from-amber-200 {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .\32xl\:from-amber-300 {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .\32xl\:from-amber-400 {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .\32xl\:from-amber-500 {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .\32xl\:from-amber-600 {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .\32xl\:from-amber-700 {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .\32xl\:from-amber-800 {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .\32xl\:from-amber-900 {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .\32xl\:from-amber-default {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .\32xl\:from-amber-A100 {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .\32xl\:from-amber-A200 {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .\32xl\:from-amber-A400 {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .\32xl\:from-amber-A700 {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .\32xl\:from-brown-50 {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .\32xl\:from-brown-100 {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .\32xl\:from-brown-200 {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .\32xl\:from-brown-300 {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .\32xl\:from-brown-400 {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .\32xl\:from-brown-500 {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .\32xl\:from-brown-600 {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .\32xl\:from-brown-700 {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .\32xl\:from-brown-800 {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .\32xl\:from-brown-900 {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .\32xl\:from-brown-default {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .\32xl\:from-brown-A100 {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .\32xl\:from-brown-A200 {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .\32xl\:from-brown-A400 {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .\32xl\:from-brown-A700 {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .\32xl\:from-blue-gray-50 {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .\32xl\:from-blue-gray-100 {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .\32xl\:from-blue-gray-200 {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .\32xl\:from-blue-gray-300 {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .\32xl\:from-blue-gray-400 {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .\32xl\:from-blue-gray-500 {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .\32xl\:from-blue-gray-600 {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .\32xl\:from-blue-gray-700 {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .\32xl\:from-blue-gray-800 {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .\32xl\:from-blue-gray-900 {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .\32xl\:from-blue-gray-default {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .\32xl\:from-blue-gray-A100 {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .\32xl\:from-blue-gray-A200 {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .\32xl\:from-blue-gray-A400 {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .\32xl\:from-blue-gray-A700 {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .\32xl\:from-cyan-50 {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .\32xl\:from-cyan-100 {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .\32xl\:from-cyan-200 {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .\32xl\:from-cyan-300 {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .\32xl\:from-cyan-400 {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .\32xl\:from-cyan-500 {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .\32xl\:from-cyan-600 {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .\32xl\:from-cyan-700 {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .\32xl\:from-cyan-800 {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .\32xl\:from-cyan-900 {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .\32xl\:from-cyan-default {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .\32xl\:from-cyan-A100 {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .\32xl\:from-cyan-A200 {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .\32xl\:from-cyan-A400 {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .\32xl\:from-cyan-A700 {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .\32xl\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .\32xl\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .\32xl\:hover\:from-black:hover {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .\32xl\:hover\:from-white:hover {
    --tw-gradient-from: #FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .\32xl\:hover\:from-gray-50:hover {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .\32xl\:hover\:from-gray-100:hover {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .\32xl\:hover\:from-gray-200:hover {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .\32xl\:hover\:from-gray-300:hover {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .\32xl\:hover\:from-gray-400:hover {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .\32xl\:hover\:from-gray-500:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:hover\:from-gray-600:hover {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .\32xl\:hover\:from-gray-700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:hover\:from-gray-800:hover {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .\32xl\:hover\:from-gray-900:hover {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .\32xl\:hover\:from-gray-default:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:hover\:from-gray-A100:hover {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .\32xl\:hover\:from-gray-A200:hover {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .\32xl\:hover\:from-gray-A400:hover {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .\32xl\:hover\:from-gray-A700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:hover\:from-red-50:hover {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .\32xl\:hover\:from-red-100:hover {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .\32xl\:hover\:from-red-200:hover {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .\32xl\:hover\:from-red-300:hover {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .\32xl\:hover\:from-red-400:hover {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .\32xl\:hover\:from-red-500:hover {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .\32xl\:hover\:from-red-600:hover {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .\32xl\:hover\:from-red-700:hover {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .\32xl\:hover\:from-red-800:hover {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .\32xl\:hover\:from-red-900:hover {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .\32xl\:hover\:from-red-default:hover {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .\32xl\:hover\:from-red-A100:hover {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .\32xl\:hover\:from-red-A200:hover {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .\32xl\:hover\:from-red-A400:hover {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .\32xl\:hover\:from-red-A700:hover {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .\32xl\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .\32xl\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .\32xl\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .\32xl\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .\32xl\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .\32xl\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .\32xl\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .\32xl\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .\32xl\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .\32xl\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .\32xl\:hover\:from-yellow-default:hover {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .\32xl\:hover\:from-yellow-A100:hover {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .\32xl\:hover\:from-yellow-A200:hover {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .\32xl\:hover\:from-yellow-A400:hover {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .\32xl\:hover\:from-yellow-A700:hover {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .\32xl\:hover\:from-green-50:hover {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .\32xl\:hover\:from-green-100:hover {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .\32xl\:hover\:from-green-200:hover {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .\32xl\:hover\:from-green-300:hover {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .\32xl\:hover\:from-green-400:hover {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .\32xl\:hover\:from-green-500:hover {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .\32xl\:hover\:from-green-600:hover {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .\32xl\:hover\:from-green-700:hover {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .\32xl\:hover\:from-green-800:hover {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .\32xl\:hover\:from-green-900:hover {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .\32xl\:hover\:from-green-default:hover {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .\32xl\:hover\:from-green-A100:hover {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .\32xl\:hover\:from-green-A200:hover {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .\32xl\:hover\:from-green-A400:hover {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .\32xl\:hover\:from-green-A700:hover {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .\32xl\:hover\:from-blue-50:hover {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .\32xl\:hover\:from-blue-100:hover {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .\32xl\:hover\:from-blue-200:hover {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .\32xl\:hover\:from-blue-300:hover {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .\32xl\:hover\:from-blue-400:hover {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .\32xl\:hover\:from-blue-500:hover {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .\32xl\:hover\:from-blue-600:hover {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .\32xl\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .\32xl\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .\32xl\:hover\:from-blue-900:hover {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .\32xl\:hover\:from-blue-default:hover {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .\32xl\:hover\:from-blue-A100:hover {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .\32xl\:hover\:from-blue-A200:hover {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .\32xl\:hover\:from-blue-A400:hover {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .\32xl\:hover\:from-blue-A700:hover {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .\32xl\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .\32xl\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .\32xl\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .\32xl\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .\32xl\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .\32xl\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .\32xl\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .\32xl\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .\32xl\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .\32xl\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .\32xl\:hover\:from-indigo-default:hover {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .\32xl\:hover\:from-indigo-A100:hover {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .\32xl\:hover\:from-indigo-A200:hover {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .\32xl\:hover\:from-indigo-A400:hover {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .\32xl\:hover\:from-indigo-A700:hover {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .\32xl\:hover\:from-purple-50:hover {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .\32xl\:hover\:from-purple-100:hover {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .\32xl\:hover\:from-purple-200:hover {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .\32xl\:hover\:from-purple-300:hover {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .\32xl\:hover\:from-purple-400:hover {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .\32xl\:hover\:from-purple-500:hover {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .\32xl\:hover\:from-purple-600:hover {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .\32xl\:hover\:from-purple-700:hover {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .\32xl\:hover\:from-purple-800:hover {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .\32xl\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .\32xl\:hover\:from-purple-default:hover {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .\32xl\:hover\:from-purple-A100:hover {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .\32xl\:hover\:from-purple-A200:hover {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .\32xl\:hover\:from-purple-A400:hover {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .\32xl\:hover\:from-purple-A700:hover {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .\32xl\:hover\:from-pink-50:hover {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .\32xl\:hover\:from-pink-100:hover {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .\32xl\:hover\:from-pink-200:hover {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .\32xl\:hover\:from-pink-300:hover {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .\32xl\:hover\:from-pink-400:hover {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .\32xl\:hover\:from-pink-500:hover {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .\32xl\:hover\:from-pink-600:hover {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .\32xl\:hover\:from-pink-700:hover {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .\32xl\:hover\:from-pink-800:hover {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .\32xl\:hover\:from-pink-900:hover {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .\32xl\:hover\:from-pink-default:hover {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .\32xl\:hover\:from-pink-A100:hover {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .\32xl\:hover\:from-pink-A200:hover {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .\32xl\:hover\:from-pink-A400:hover {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .\32xl\:hover\:from-pink-A700:hover {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .\32xl\:hover\:from-grey-50:hover {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .\32xl\:hover\:from-grey-100:hover {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .\32xl\:hover\:from-grey-200:hover {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .\32xl\:hover\:from-grey-300:hover {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .\32xl\:hover\:from-grey-400:hover {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .\32xl\:hover\:from-grey-500:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:hover\:from-grey-600:hover {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .\32xl\:hover\:from-grey-700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:hover\:from-grey-800:hover {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .\32xl\:hover\:from-grey-900:hover {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .\32xl\:hover\:from-grey-default:hover {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:hover\:from-grey-A100:hover {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .\32xl\:hover\:from-grey-A200:hover {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .\32xl\:hover\:from-grey-A400:hover {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .\32xl\:hover\:from-grey-A700:hover {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:hover\:from-orange-50:hover {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .\32xl\:hover\:from-orange-100:hover {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .\32xl\:hover\:from-orange-200:hover {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .\32xl\:hover\:from-orange-300:hover {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .\32xl\:hover\:from-orange-400:hover {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .\32xl\:hover\:from-orange-500:hover {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .\32xl\:hover\:from-orange-600:hover {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .\32xl\:hover\:from-orange-700:hover {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .\32xl\:hover\:from-orange-800:hover {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .\32xl\:hover\:from-orange-900:hover {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .\32xl\:hover\:from-orange-default:hover {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .\32xl\:hover\:from-orange-A100:hover {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .\32xl\:hover\:from-orange-A200:hover {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .\32xl\:hover\:from-orange-A400:hover {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .\32xl\:hover\:from-orange-A700:hover {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .\32xl\:hover\:from-deep-orange-50:hover {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .\32xl\:hover\:from-deep-orange-100:hover {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .\32xl\:hover\:from-deep-orange-200:hover {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .\32xl\:hover\:from-deep-orange-300:hover {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .\32xl\:hover\:from-deep-orange-400:hover {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .\32xl\:hover\:from-deep-orange-500:hover {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .\32xl\:hover\:from-deep-orange-600:hover {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .\32xl\:hover\:from-deep-orange-700:hover {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .\32xl\:hover\:from-deep-orange-800:hover {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .\32xl\:hover\:from-deep-orange-900:hover {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .\32xl\:hover\:from-deep-orange-default:hover {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .\32xl\:hover\:from-deep-orange-A100:hover {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .\32xl\:hover\:from-deep-orange-A200:hover {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .\32xl\:hover\:from-deep-orange-A400:hover {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .\32xl\:hover\:from-deep-orange-A700:hover {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .\32xl\:hover\:from-light-green-50:hover {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .\32xl\:hover\:from-light-green-100:hover {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .\32xl\:hover\:from-light-green-200:hover {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .\32xl\:hover\:from-light-green-300:hover {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .\32xl\:hover\:from-light-green-400:hover {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .\32xl\:hover\:from-light-green-500:hover {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .\32xl\:hover\:from-light-green-600:hover {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .\32xl\:hover\:from-light-green-700:hover {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .\32xl\:hover\:from-light-green-800:hover {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .\32xl\:hover\:from-light-green-900:hover {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .\32xl\:hover\:from-light-green-default:hover {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .\32xl\:hover\:from-light-green-A100:hover {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .\32xl\:hover\:from-light-green-A200:hover {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .\32xl\:hover\:from-light-green-A400:hover {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .\32xl\:hover\:from-light-green-A700:hover {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .\32xl\:hover\:from-teal-50:hover {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .\32xl\:hover\:from-teal-100:hover {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .\32xl\:hover\:from-teal-200:hover {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .\32xl\:hover\:from-teal-300:hover {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .\32xl\:hover\:from-teal-400:hover {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .\32xl\:hover\:from-teal-500:hover {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .\32xl\:hover\:from-teal-600:hover {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .\32xl\:hover\:from-teal-700:hover {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .\32xl\:hover\:from-teal-800:hover {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .\32xl\:hover\:from-teal-900:hover {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .\32xl\:hover\:from-teal-default:hover {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .\32xl\:hover\:from-teal-A100:hover {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .\32xl\:hover\:from-teal-A200:hover {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .\32xl\:hover\:from-teal-A400:hover {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .\32xl\:hover\:from-teal-A700:hover {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .\32xl\:hover\:from-light-blue-50:hover {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .\32xl\:hover\:from-light-blue-100:hover {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .\32xl\:hover\:from-light-blue-200:hover {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .\32xl\:hover\:from-light-blue-300:hover {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .\32xl\:hover\:from-light-blue-400:hover {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .\32xl\:hover\:from-light-blue-500:hover {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .\32xl\:hover\:from-light-blue-600:hover {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .\32xl\:hover\:from-light-blue-700:hover {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .\32xl\:hover\:from-light-blue-800:hover {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .\32xl\:hover\:from-light-blue-900:hover {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .\32xl\:hover\:from-light-blue-default:hover {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .\32xl\:hover\:from-light-blue-A100:hover {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .\32xl\:hover\:from-light-blue-A200:hover {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .\32xl\:hover\:from-light-blue-A400:hover {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .\32xl\:hover\:from-light-blue-A700:hover {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .\32xl\:hover\:from-deep-purple-50:hover {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .\32xl\:hover\:from-deep-purple-100:hover {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .\32xl\:hover\:from-deep-purple-200:hover {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .\32xl\:hover\:from-deep-purple-300:hover {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .\32xl\:hover\:from-deep-purple-400:hover {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .\32xl\:hover\:from-deep-purple-500:hover {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .\32xl\:hover\:from-deep-purple-600:hover {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .\32xl\:hover\:from-deep-purple-700:hover {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .\32xl\:hover\:from-deep-purple-800:hover {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .\32xl\:hover\:from-deep-purple-900:hover {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .\32xl\:hover\:from-deep-purple-default:hover {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .\32xl\:hover\:from-deep-purple-A100:hover {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .\32xl\:hover\:from-deep-purple-A200:hover {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .\32xl\:hover\:from-deep-purple-A400:hover {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .\32xl\:hover\:from-deep-purple-A700:hover {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .\32xl\:hover\:from-lime-50:hover {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .\32xl\:hover\:from-lime-100:hover {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .\32xl\:hover\:from-lime-200:hover {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .\32xl\:hover\:from-lime-300:hover {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .\32xl\:hover\:from-lime-400:hover {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .\32xl\:hover\:from-lime-500:hover {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .\32xl\:hover\:from-lime-600:hover {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .\32xl\:hover\:from-lime-700:hover {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .\32xl\:hover\:from-lime-800:hover {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .\32xl\:hover\:from-lime-900:hover {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .\32xl\:hover\:from-lime-default:hover {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .\32xl\:hover\:from-lime-A100:hover {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .\32xl\:hover\:from-lime-A200:hover {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .\32xl\:hover\:from-lime-A400:hover {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .\32xl\:hover\:from-lime-A700:hover {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .\32xl\:hover\:from-amber-50:hover {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .\32xl\:hover\:from-amber-100:hover {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .\32xl\:hover\:from-amber-200:hover {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .\32xl\:hover\:from-amber-300:hover {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .\32xl\:hover\:from-amber-400:hover {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .\32xl\:hover\:from-amber-500:hover {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .\32xl\:hover\:from-amber-600:hover {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .\32xl\:hover\:from-amber-700:hover {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .\32xl\:hover\:from-amber-800:hover {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .\32xl\:hover\:from-amber-900:hover {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .\32xl\:hover\:from-amber-default:hover {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .\32xl\:hover\:from-amber-A100:hover {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .\32xl\:hover\:from-amber-A200:hover {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .\32xl\:hover\:from-amber-A400:hover {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .\32xl\:hover\:from-amber-A700:hover {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .\32xl\:hover\:from-brown-50:hover {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .\32xl\:hover\:from-brown-100:hover {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .\32xl\:hover\:from-brown-200:hover {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .\32xl\:hover\:from-brown-300:hover {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .\32xl\:hover\:from-brown-400:hover {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .\32xl\:hover\:from-brown-500:hover {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .\32xl\:hover\:from-brown-600:hover {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .\32xl\:hover\:from-brown-700:hover {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .\32xl\:hover\:from-brown-800:hover {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .\32xl\:hover\:from-brown-900:hover {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .\32xl\:hover\:from-brown-default:hover {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .\32xl\:hover\:from-brown-A100:hover {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .\32xl\:hover\:from-brown-A200:hover {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .\32xl\:hover\:from-brown-A400:hover {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .\32xl\:hover\:from-brown-A700:hover {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .\32xl\:hover\:from-blue-gray-50:hover {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .\32xl\:hover\:from-blue-gray-100:hover {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .\32xl\:hover\:from-blue-gray-200:hover {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .\32xl\:hover\:from-blue-gray-300:hover {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .\32xl\:hover\:from-blue-gray-400:hover {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .\32xl\:hover\:from-blue-gray-500:hover {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .\32xl\:hover\:from-blue-gray-600:hover {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .\32xl\:hover\:from-blue-gray-700:hover {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .\32xl\:hover\:from-blue-gray-800:hover {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .\32xl\:hover\:from-blue-gray-900:hover {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .\32xl\:hover\:from-blue-gray-default:hover {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .\32xl\:hover\:from-blue-gray-A100:hover {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .\32xl\:hover\:from-blue-gray-A200:hover {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .\32xl\:hover\:from-blue-gray-A400:hover {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .\32xl\:hover\:from-blue-gray-A700:hover {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .\32xl\:hover\:from-cyan-50:hover {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .\32xl\:hover\:from-cyan-100:hover {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .\32xl\:hover\:from-cyan-200:hover {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .\32xl\:hover\:from-cyan-300:hover {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .\32xl\:hover\:from-cyan-400:hover {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .\32xl\:hover\:from-cyan-500:hover {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .\32xl\:hover\:from-cyan-600:hover {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .\32xl\:hover\:from-cyan-700:hover {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .\32xl\:hover\:from-cyan-800:hover {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .\32xl\:hover\:from-cyan-900:hover {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .\32xl\:hover\:from-cyan-default:hover {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .\32xl\:hover\:from-cyan-A100:hover {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .\32xl\:hover\:from-cyan-A200:hover {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .\32xl\:hover\:from-cyan-A400:hover {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .\32xl\:hover\:from-cyan-A700:hover {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .\32xl\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .\32xl\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .\32xl\:focus\:from-black:focus {
    --tw-gradient-from: #22292F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .\32xl\:focus\:from-white:focus {
    --tw-gradient-from: #FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .\32xl\:focus\:from-gray-50:focus {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .\32xl\:focus\:from-gray-100:focus {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .\32xl\:focus\:from-gray-200:focus {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .\32xl\:focus\:from-gray-300:focus {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .\32xl\:focus\:from-gray-400:focus {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .\32xl\:focus\:from-gray-500:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:focus\:from-gray-600:focus {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .\32xl\:focus\:from-gray-700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:focus\:from-gray-800:focus {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .\32xl\:focus\:from-gray-900:focus {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .\32xl\:focus\:from-gray-default:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:focus\:from-gray-A100:focus {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .\32xl\:focus\:from-gray-A200:focus {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .\32xl\:focus\:from-gray-A400:focus {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .\32xl\:focus\:from-gray-A700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:focus\:from-red-50:focus {
    --tw-gradient-from: #FFEBEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .\32xl\:focus\:from-red-100:focus {
    --tw-gradient-from: #FFCDD2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .\32xl\:focus\:from-red-200:focus {
    --tw-gradient-from: #EF9A9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .\32xl\:focus\:from-red-300:focus {
    --tw-gradient-from: #E57373;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .\32xl\:focus\:from-red-400:focus {
    --tw-gradient-from: #EF5350;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .\32xl\:focus\:from-red-500:focus {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .\32xl\:focus\:from-red-600:focus {
    --tw-gradient-from: #E53935;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .\32xl\:focus\:from-red-700:focus {
    --tw-gradient-from: #D32F2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .\32xl\:focus\:from-red-800:focus {
    --tw-gradient-from: #C62828;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .\32xl\:focus\:from-red-900:focus {
    --tw-gradient-from: #B71C1C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .\32xl\:focus\:from-red-default:focus {
    --tw-gradient-from: #F44336;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .\32xl\:focus\:from-red-A100:focus {
    --tw-gradient-from: #FF8A80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .\32xl\:focus\:from-red-A200:focus {
    --tw-gradient-from: #FF5252;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .\32xl\:focus\:from-red-A400:focus {
    --tw-gradient-from: #FF1744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .\32xl\:focus\:from-red-A700:focus {
    --tw-gradient-from: #D50000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .\32xl\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #FFFDE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .\32xl\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #FFF9C4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .\32xl\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #FFF59D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .\32xl\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #FFF176;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .\32xl\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #FFEE58;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .\32xl\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .\32xl\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #FDD835;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .\32xl\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #FBC02D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .\32xl\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #F9A825;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .\32xl\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #F57F17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .\32xl\:focus\:from-yellow-default:focus {
    --tw-gradient-from: #FFEB3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .\32xl\:focus\:from-yellow-A100:focus {
    --tw-gradient-from: #FFFF8D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .\32xl\:focus\:from-yellow-A200:focus {
    --tw-gradient-from: #FFFF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .\32xl\:focus\:from-yellow-A400:focus {
    --tw-gradient-from: #FFEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .\32xl\:focus\:from-yellow-A700:focus {
    --tw-gradient-from: #FFD600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .\32xl\:focus\:from-green-50:focus {
    --tw-gradient-from: #E8F5E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .\32xl\:focus\:from-green-100:focus {
    --tw-gradient-from: #C8E6C9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .\32xl\:focus\:from-green-200:focus {
    --tw-gradient-from: #A5D6A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .\32xl\:focus\:from-green-300:focus {
    --tw-gradient-from: #81C784;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .\32xl\:focus\:from-green-400:focus {
    --tw-gradient-from: #66BB6A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .\32xl\:focus\:from-green-500:focus {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .\32xl\:focus\:from-green-600:focus {
    --tw-gradient-from: #43A047;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .\32xl\:focus\:from-green-700:focus {
    --tw-gradient-from: #388E3C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .\32xl\:focus\:from-green-800:focus {
    --tw-gradient-from: #2E7D32;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .\32xl\:focus\:from-green-900:focus {
    --tw-gradient-from: #1B5E20;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .\32xl\:focus\:from-green-default:focus {
    --tw-gradient-from: #4CAF50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .\32xl\:focus\:from-green-A100:focus {
    --tw-gradient-from: #B9F6CA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .\32xl\:focus\:from-green-A200:focus {
    --tw-gradient-from: #69F0AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .\32xl\:focus\:from-green-A400:focus {
    --tw-gradient-from: #00E676;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .\32xl\:focus\:from-green-A700:focus {
    --tw-gradient-from: #00C853;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .\32xl\:focus\:from-blue-50:focus {
    --tw-gradient-from: #E3F2FD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .\32xl\:focus\:from-blue-100:focus {
    --tw-gradient-from: #BBDEFB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .\32xl\:focus\:from-blue-200:focus {
    --tw-gradient-from: #90CAF9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .\32xl\:focus\:from-blue-300:focus {
    --tw-gradient-from: #64B5F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .\32xl\:focus\:from-blue-400:focus {
    --tw-gradient-from: #42A5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .\32xl\:focus\:from-blue-500:focus {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .\32xl\:focus\:from-blue-600:focus {
    --tw-gradient-from: #1E88E5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .\32xl\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1976D2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .\32xl\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1565C0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .\32xl\:focus\:from-blue-900:focus {
    --tw-gradient-from: #0D47A1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .\32xl\:focus\:from-blue-default:focus {
    --tw-gradient-from: #2196F3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .\32xl\:focus\:from-blue-A100:focus {
    --tw-gradient-from: #82B1FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .\32xl\:focus\:from-blue-A200:focus {
    --tw-gradient-from: #448AFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .\32xl\:focus\:from-blue-A400:focus {
    --tw-gradient-from: #2979FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .\32xl\:focus\:from-blue-A700:focus {
    --tw-gradient-from: #2962FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .\32xl\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #E8EAF6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .\32xl\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #C5CAE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .\32xl\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #9FA8DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .\32xl\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #7986CB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .\32xl\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #5C6BC0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .\32xl\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .\32xl\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #3949AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .\32xl\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #303F9F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .\32xl\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #283593;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .\32xl\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #1A237E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .\32xl\:focus\:from-indigo-default:focus {
    --tw-gradient-from: #3F51B5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .\32xl\:focus\:from-indigo-A100:focus {
    --tw-gradient-from: #8C9EFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .\32xl\:focus\:from-indigo-A200:focus {
    --tw-gradient-from: #536DFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .\32xl\:focus\:from-indigo-A400:focus {
    --tw-gradient-from: #3D5AFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .\32xl\:focus\:from-indigo-A700:focus {
    --tw-gradient-from: #304FFE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .\32xl\:focus\:from-purple-50:focus {
    --tw-gradient-from: #F3E5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .\32xl\:focus\:from-purple-100:focus {
    --tw-gradient-from: #E1BEE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .\32xl\:focus\:from-purple-200:focus {
    --tw-gradient-from: #CE93D8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .\32xl\:focus\:from-purple-300:focus {
    --tw-gradient-from: #BA68C8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .\32xl\:focus\:from-purple-400:focus {
    --tw-gradient-from: #AB47BC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .\32xl\:focus\:from-purple-500:focus {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .\32xl\:focus\:from-purple-600:focus {
    --tw-gradient-from: #8E24AA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .\32xl\:focus\:from-purple-700:focus {
    --tw-gradient-from: #7B1FA2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .\32xl\:focus\:from-purple-800:focus {
    --tw-gradient-from: #6A1B9A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .\32xl\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4A148C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .\32xl\:focus\:from-purple-default:focus {
    --tw-gradient-from: #9C27B0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .\32xl\:focus\:from-purple-A100:focus {
    --tw-gradient-from: #EA80FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .\32xl\:focus\:from-purple-A200:focus {
    --tw-gradient-from: #E040FB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .\32xl\:focus\:from-purple-A400:focus {
    --tw-gradient-from: #D500F9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .\32xl\:focus\:from-purple-A700:focus {
    --tw-gradient-from: #AA00FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .\32xl\:focus\:from-pink-50:focus {
    --tw-gradient-from: #FCE4EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .\32xl\:focus\:from-pink-100:focus {
    --tw-gradient-from: #F8BBD0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .\32xl\:focus\:from-pink-200:focus {
    --tw-gradient-from: #F48FB1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .\32xl\:focus\:from-pink-300:focus {
    --tw-gradient-from: #F06292;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .\32xl\:focus\:from-pink-400:focus {
    --tw-gradient-from: #EC407A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .\32xl\:focus\:from-pink-500:focus {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .\32xl\:focus\:from-pink-600:focus {
    --tw-gradient-from: #D81B60;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .\32xl\:focus\:from-pink-700:focus {
    --tw-gradient-from: #C2185B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .\32xl\:focus\:from-pink-800:focus {
    --tw-gradient-from: #AD1457;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .\32xl\:focus\:from-pink-900:focus {
    --tw-gradient-from: #880E4F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .\32xl\:focus\:from-pink-default:focus {
    --tw-gradient-from: #E91E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .\32xl\:focus\:from-pink-A100:focus {
    --tw-gradient-from: #FF80AB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .\32xl\:focus\:from-pink-A200:focus {
    --tw-gradient-from: #FF4081;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .\32xl\:focus\:from-pink-A400:focus {
    --tw-gradient-from: #F50057;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .\32xl\:focus\:from-pink-A700:focus {
    --tw-gradient-from: #C51162;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .\32xl\:focus\:from-grey-50:focus {
    --tw-gradient-from: #FAFAFA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .\32xl\:focus\:from-grey-100:focus {
    --tw-gradient-from: #F5F5F5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .\32xl\:focus\:from-grey-200:focus {
    --tw-gradient-from: #EEEEEE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .\32xl\:focus\:from-grey-300:focus {
    --tw-gradient-from: #E0E0E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .\32xl\:focus\:from-grey-400:focus {
    --tw-gradient-from: #BDBDBD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .\32xl\:focus\:from-grey-500:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:focus\:from-grey-600:focus {
    --tw-gradient-from: #757575;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .\32xl\:focus\:from-grey-700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:focus\:from-grey-800:focus {
    --tw-gradient-from: #424242;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .\32xl\:focus\:from-grey-900:focus {
    --tw-gradient-from: #212121;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .\32xl\:focus\:from-grey-default:focus {
    --tw-gradient-from: #9E9E9E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:focus\:from-grey-A100:focus {
    --tw-gradient-from: #D5D5D5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .\32xl\:focus\:from-grey-A200:focus {
    --tw-gradient-from: #AAAAAA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .\32xl\:focus\:from-grey-A400:focus {
    --tw-gradient-from: #303030;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .\32xl\:focus\:from-grey-A700:focus {
    --tw-gradient-from: #616161;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:focus\:from-orange-50:focus {
    --tw-gradient-from: #FFF3E0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .\32xl\:focus\:from-orange-100:focus {
    --tw-gradient-from: #FFE0B2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .\32xl\:focus\:from-orange-200:focus {
    --tw-gradient-from: #FFCC80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .\32xl\:focus\:from-orange-300:focus {
    --tw-gradient-from: #FFB74D;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .\32xl\:focus\:from-orange-400:focus {
    --tw-gradient-from: #FFA726;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .\32xl\:focus\:from-orange-500:focus {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .\32xl\:focus\:from-orange-600:focus {
    --tw-gradient-from: #FB8C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .\32xl\:focus\:from-orange-700:focus {
    --tw-gradient-from: #F57C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .\32xl\:focus\:from-orange-800:focus {
    --tw-gradient-from: #EF6C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .\32xl\:focus\:from-orange-900:focus {
    --tw-gradient-from: #E65100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .\32xl\:focus\:from-orange-default:focus {
    --tw-gradient-from: #FF9800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .\32xl\:focus\:from-orange-A100:focus {
    --tw-gradient-from: #FFD180;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .\32xl\:focus\:from-orange-A200:focus {
    --tw-gradient-from: #FFAB40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .\32xl\:focus\:from-orange-A400:focus {
    --tw-gradient-from: #FF9100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .\32xl\:focus\:from-orange-A700:focus {
    --tw-gradient-from: #FF6D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .\32xl\:focus\:from-deep-orange-50:focus {
    --tw-gradient-from: #FBE9E7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .\32xl\:focus\:from-deep-orange-100:focus {
    --tw-gradient-from: #FFCCBC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .\32xl\:focus\:from-deep-orange-200:focus {
    --tw-gradient-from: #FFAB91;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .\32xl\:focus\:from-deep-orange-300:focus {
    --tw-gradient-from: #FF8A65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .\32xl\:focus\:from-deep-orange-400:focus {
    --tw-gradient-from: #FF7043;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .\32xl\:focus\:from-deep-orange-500:focus {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .\32xl\:focus\:from-deep-orange-600:focus {
    --tw-gradient-from: #F4511E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .\32xl\:focus\:from-deep-orange-700:focus {
    --tw-gradient-from: #E64A19;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .\32xl\:focus\:from-deep-orange-800:focus {
    --tw-gradient-from: #D84315;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .\32xl\:focus\:from-deep-orange-900:focus {
    --tw-gradient-from: #BF360C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .\32xl\:focus\:from-deep-orange-default:focus {
    --tw-gradient-from: #FF5722;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .\32xl\:focus\:from-deep-orange-A100:focus {
    --tw-gradient-from: #FF9E80;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .\32xl\:focus\:from-deep-orange-A200:focus {
    --tw-gradient-from: #FF6E40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .\32xl\:focus\:from-deep-orange-A400:focus {
    --tw-gradient-from: #FF3D00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .\32xl\:focus\:from-deep-orange-A700:focus {
    --tw-gradient-from: #DD2C00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .\32xl\:focus\:from-light-green-50:focus {
    --tw-gradient-from: #F1F8E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .\32xl\:focus\:from-light-green-100:focus {
    --tw-gradient-from: #DCEDC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .\32xl\:focus\:from-light-green-200:focus {
    --tw-gradient-from: #C5E1A5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .\32xl\:focus\:from-light-green-300:focus {
    --tw-gradient-from: #AED581;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .\32xl\:focus\:from-light-green-400:focus {
    --tw-gradient-from: #9CCC65;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .\32xl\:focus\:from-light-green-500:focus {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .\32xl\:focus\:from-light-green-600:focus {
    --tw-gradient-from: #7CB342;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .\32xl\:focus\:from-light-green-700:focus {
    --tw-gradient-from: #689F38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .\32xl\:focus\:from-light-green-800:focus {
    --tw-gradient-from: #558B2F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .\32xl\:focus\:from-light-green-900:focus {
    --tw-gradient-from: #33691E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .\32xl\:focus\:from-light-green-default:focus {
    --tw-gradient-from: #8BC34A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .\32xl\:focus\:from-light-green-A100:focus {
    --tw-gradient-from: #CCFF90;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .\32xl\:focus\:from-light-green-A200:focus {
    --tw-gradient-from: #B2FF59;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .\32xl\:focus\:from-light-green-A400:focus {
    --tw-gradient-from: #76FF03;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .\32xl\:focus\:from-light-green-A700:focus {
    --tw-gradient-from: #64DD17;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .\32xl\:focus\:from-teal-50:focus {
    --tw-gradient-from: #E0F2F1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .\32xl\:focus\:from-teal-100:focus {
    --tw-gradient-from: #B2DFDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .\32xl\:focus\:from-teal-200:focus {
    --tw-gradient-from: #80CBC4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .\32xl\:focus\:from-teal-300:focus {
    --tw-gradient-from: #4DB6AC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .\32xl\:focus\:from-teal-400:focus {
    --tw-gradient-from: #26A69A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .\32xl\:focus\:from-teal-500:focus {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .\32xl\:focus\:from-teal-600:focus {
    --tw-gradient-from: #00897B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .\32xl\:focus\:from-teal-700:focus {
    --tw-gradient-from: #00796B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .\32xl\:focus\:from-teal-800:focus {
    --tw-gradient-from: #00695C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .\32xl\:focus\:from-teal-900:focus {
    --tw-gradient-from: #004D40;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .\32xl\:focus\:from-teal-default:focus {
    --tw-gradient-from: #009688;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .\32xl\:focus\:from-teal-A100:focus {
    --tw-gradient-from: #A7FFEB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .\32xl\:focus\:from-teal-A200:focus {
    --tw-gradient-from: #64FFDA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .\32xl\:focus\:from-teal-A400:focus {
    --tw-gradient-from: #1DE9B6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .\32xl\:focus\:from-teal-A700:focus {
    --tw-gradient-from: #00BFA5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .\32xl\:focus\:from-light-blue-50:focus {
    --tw-gradient-from: #E1F5FE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .\32xl\:focus\:from-light-blue-100:focus {
    --tw-gradient-from: #B3E5FC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .\32xl\:focus\:from-light-blue-200:focus {
    --tw-gradient-from: #81D4FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .\32xl\:focus\:from-light-blue-300:focus {
    --tw-gradient-from: #4FC3F7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .\32xl\:focus\:from-light-blue-400:focus {
    --tw-gradient-from: #29B6F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .\32xl\:focus\:from-light-blue-500:focus {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .\32xl\:focus\:from-light-blue-600:focus {
    --tw-gradient-from: #039BE5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .\32xl\:focus\:from-light-blue-700:focus {
    --tw-gradient-from: #0288D1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .\32xl\:focus\:from-light-blue-800:focus {
    --tw-gradient-from: #0277BD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .\32xl\:focus\:from-light-blue-900:focus {
    --tw-gradient-from: #01579B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .\32xl\:focus\:from-light-blue-default:focus {
    --tw-gradient-from: #03A9F4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .\32xl\:focus\:from-light-blue-A100:focus {
    --tw-gradient-from: #80D8FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .\32xl\:focus\:from-light-blue-A200:focus {
    --tw-gradient-from: #40C4FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .\32xl\:focus\:from-light-blue-A400:focus {
    --tw-gradient-from: #00B0FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .\32xl\:focus\:from-light-blue-A700:focus {
    --tw-gradient-from: #0091EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .\32xl\:focus\:from-deep-purple-50:focus {
    --tw-gradient-from: #EDE7F6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .\32xl\:focus\:from-deep-purple-100:focus {
    --tw-gradient-from: #D1C4E9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .\32xl\:focus\:from-deep-purple-200:focus {
    --tw-gradient-from: #B39DDB;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .\32xl\:focus\:from-deep-purple-300:focus {
    --tw-gradient-from: #9575CD;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .\32xl\:focus\:from-deep-purple-400:focus {
    --tw-gradient-from: #7E57C2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .\32xl\:focus\:from-deep-purple-500:focus {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .\32xl\:focus\:from-deep-purple-600:focus {
    --tw-gradient-from: #5E35B1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .\32xl\:focus\:from-deep-purple-700:focus {
    --tw-gradient-from: #512DA8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .\32xl\:focus\:from-deep-purple-800:focus {
    --tw-gradient-from: #4527A0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .\32xl\:focus\:from-deep-purple-900:focus {
    --tw-gradient-from: #311B92;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .\32xl\:focus\:from-deep-purple-default:focus {
    --tw-gradient-from: #673AB7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .\32xl\:focus\:from-deep-purple-A100:focus {
    --tw-gradient-from: #B388FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .\32xl\:focus\:from-deep-purple-A200:focus {
    --tw-gradient-from: #7C4DFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .\32xl\:focus\:from-deep-purple-A400:focus {
    --tw-gradient-from: #651FFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .\32xl\:focus\:from-deep-purple-A700:focus {
    --tw-gradient-from: #6200EA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .\32xl\:focus\:from-lime-50:focus {
    --tw-gradient-from: #F9FBE7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .\32xl\:focus\:from-lime-100:focus {
    --tw-gradient-from: #F0F4C3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .\32xl\:focus\:from-lime-200:focus {
    --tw-gradient-from: #E6EE9C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .\32xl\:focus\:from-lime-300:focus {
    --tw-gradient-from: #DCE775;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .\32xl\:focus\:from-lime-400:focus {
    --tw-gradient-from: #D4E157;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .\32xl\:focus\:from-lime-500:focus {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .\32xl\:focus\:from-lime-600:focus {
    --tw-gradient-from: #C0CA33;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .\32xl\:focus\:from-lime-700:focus {
    --tw-gradient-from: #AFB42B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .\32xl\:focus\:from-lime-800:focus {
    --tw-gradient-from: #9E9D24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .\32xl\:focus\:from-lime-900:focus {
    --tw-gradient-from: #827717;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .\32xl\:focus\:from-lime-default:focus {
    --tw-gradient-from: #CDDC39;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .\32xl\:focus\:from-lime-A100:focus {
    --tw-gradient-from: #F4FF81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .\32xl\:focus\:from-lime-A200:focus {
    --tw-gradient-from: #EEFF41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .\32xl\:focus\:from-lime-A400:focus {
    --tw-gradient-from: #C6FF00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .\32xl\:focus\:from-lime-A700:focus {
    --tw-gradient-from: #AEEA00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .\32xl\:focus\:from-amber-50:focus {
    --tw-gradient-from: #FFF8E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .\32xl\:focus\:from-amber-100:focus {
    --tw-gradient-from: #FFECB3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .\32xl\:focus\:from-amber-200:focus {
    --tw-gradient-from: #FFE082;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .\32xl\:focus\:from-amber-300:focus {
    --tw-gradient-from: #FFD54F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .\32xl\:focus\:from-amber-400:focus {
    --tw-gradient-from: #FFCA28;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .\32xl\:focus\:from-amber-500:focus {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .\32xl\:focus\:from-amber-600:focus {
    --tw-gradient-from: #FFB300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .\32xl\:focus\:from-amber-700:focus {
    --tw-gradient-from: #FFA000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .\32xl\:focus\:from-amber-800:focus {
    --tw-gradient-from: #FF8F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .\32xl\:focus\:from-amber-900:focus {
    --tw-gradient-from: #FF6F00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .\32xl\:focus\:from-amber-default:focus {
    --tw-gradient-from: #FFC107;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .\32xl\:focus\:from-amber-A100:focus {
    --tw-gradient-from: #FFE57F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .\32xl\:focus\:from-amber-A200:focus {
    --tw-gradient-from: #FFD740;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .\32xl\:focus\:from-amber-A400:focus {
    --tw-gradient-from: #FFC400;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .\32xl\:focus\:from-amber-A700:focus {
    --tw-gradient-from: #FFAB00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .\32xl\:focus\:from-brown-50:focus {
    --tw-gradient-from: #EFEBE9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .\32xl\:focus\:from-brown-100:focus {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .\32xl\:focus\:from-brown-200:focus {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .\32xl\:focus\:from-brown-300:focus {
    --tw-gradient-from: #A1887F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .\32xl\:focus\:from-brown-400:focus {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .\32xl\:focus\:from-brown-500:focus {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .\32xl\:focus\:from-brown-600:focus {
    --tw-gradient-from: #6D4C41;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .\32xl\:focus\:from-brown-700:focus {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .\32xl\:focus\:from-brown-800:focus {
    --tw-gradient-from: #4E342E;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .\32xl\:focus\:from-brown-900:focus {
    --tw-gradient-from: #3E2723;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .\32xl\:focus\:from-brown-default:focus {
    --tw-gradient-from: #795548;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .\32xl\:focus\:from-brown-A100:focus {
    --tw-gradient-from: #D7CCC8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .\32xl\:focus\:from-brown-A200:focus {
    --tw-gradient-from: #BCAAA4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .\32xl\:focus\:from-brown-A400:focus {
    --tw-gradient-from: #8D6E63;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .\32xl\:focus\:from-brown-A700:focus {
    --tw-gradient-from: #5D4037;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .\32xl\:focus\:from-blue-gray-50:focus {
    --tw-gradient-from: #ECEFF1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .\32xl\:focus\:from-blue-gray-100:focus {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .\32xl\:focus\:from-blue-gray-200:focus {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .\32xl\:focus\:from-blue-gray-300:focus {
    --tw-gradient-from: #90A4AE;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .\32xl\:focus\:from-blue-gray-400:focus {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .\32xl\:focus\:from-blue-gray-500:focus {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .\32xl\:focus\:from-blue-gray-600:focus {
    --tw-gradient-from: #546E7A;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .\32xl\:focus\:from-blue-gray-700:focus {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .\32xl\:focus\:from-blue-gray-800:focus {
    --tw-gradient-from: #37474F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .\32xl\:focus\:from-blue-gray-900:focus {
    --tw-gradient-from: #263238;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .\32xl\:focus\:from-blue-gray-default:focus {
    --tw-gradient-from: #607D8B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .\32xl\:focus\:from-blue-gray-A100:focus {
    --tw-gradient-from: #CFD8DC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .\32xl\:focus\:from-blue-gray-A200:focus {
    --tw-gradient-from: #B0BEC5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .\32xl\:focus\:from-blue-gray-A400:focus {
    --tw-gradient-from: #78909C;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .\32xl\:focus\:from-blue-gray-A700:focus {
    --tw-gradient-from: #455A64;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .\32xl\:focus\:from-cyan-50:focus {
    --tw-gradient-from: #E0F7FA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .\32xl\:focus\:from-cyan-100:focus {
    --tw-gradient-from: #B2EBF2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .\32xl\:focus\:from-cyan-200:focus {
    --tw-gradient-from: #80DEEA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .\32xl\:focus\:from-cyan-300:focus {
    --tw-gradient-from: #4DD0E1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .\32xl\:focus\:from-cyan-400:focus {
    --tw-gradient-from: #26C6DA;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .\32xl\:focus\:from-cyan-500:focus {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .\32xl\:focus\:from-cyan-600:focus {
    --tw-gradient-from: #00ACC1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .\32xl\:focus\:from-cyan-700:focus {
    --tw-gradient-from: #0097A7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .\32xl\:focus\:from-cyan-800:focus {
    --tw-gradient-from: #00838F;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .\32xl\:focus\:from-cyan-900:focus {
    --tw-gradient-from: #006064;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .\32xl\:focus\:from-cyan-default:focus {
    --tw-gradient-from: #00BCD4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .\32xl\:focus\:from-cyan-A100:focus {
    --tw-gradient-from: #84FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .\32xl\:focus\:from-cyan-A200:focus {
    --tw-gradient-from: #18FFFF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .\32xl\:focus\:from-cyan-A400:focus {
    --tw-gradient-from: #00E5FF;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .\32xl\:focus\:from-cyan-A700:focus {
    --tw-gradient-from: #00B8D4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .\32xl\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .\32xl\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .\32xl\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .\32xl\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .\32xl\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .\32xl\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .\32xl\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .\32xl\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .\32xl\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .\32xl\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .\32xl\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .\32xl\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .\32xl\:via-gray-default {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:via-gray-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .\32xl\:via-gray-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .\32xl\:via-gray-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .\32xl\:via-gray-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .\32xl\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .\32xl\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .\32xl\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .\32xl\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .\32xl\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .\32xl\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .\32xl\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .\32xl\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .\32xl\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .\32xl\:via-red-default {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .\32xl\:via-red-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .\32xl\:via-red-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .\32xl\:via-red-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .\32xl\:via-red-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .\32xl\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .\32xl\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .\32xl\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .\32xl\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .\32xl\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .\32xl\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .\32xl\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .\32xl\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .\32xl\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .\32xl\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .\32xl\:via-yellow-default {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .\32xl\:via-yellow-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .\32xl\:via-yellow-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .\32xl\:via-yellow-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .\32xl\:via-yellow-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .\32xl\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .\32xl\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .\32xl\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .\32xl\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .\32xl\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .\32xl\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .\32xl\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .\32xl\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .\32xl\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .\32xl\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .\32xl\:via-green-default {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .\32xl\:via-green-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .\32xl\:via-green-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .\32xl\:via-green-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .\32xl\:via-green-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .\32xl\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .\32xl\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .\32xl\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .\32xl\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .\32xl\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .\32xl\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .\32xl\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .\32xl\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .\32xl\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .\32xl\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .\32xl\:via-blue-default {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .\32xl\:via-blue-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .\32xl\:via-blue-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .\32xl\:via-blue-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .\32xl\:via-blue-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .\32xl\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .\32xl\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .\32xl\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .\32xl\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .\32xl\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .\32xl\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .\32xl\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .\32xl\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .\32xl\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .\32xl\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .\32xl\:via-indigo-default {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .\32xl\:via-indigo-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .\32xl\:via-indigo-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .\32xl\:via-indigo-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .\32xl\:via-indigo-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .\32xl\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .\32xl\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .\32xl\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .\32xl\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .\32xl\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .\32xl\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .\32xl\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .\32xl\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .\32xl\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .\32xl\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .\32xl\:via-purple-default {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .\32xl\:via-purple-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .\32xl\:via-purple-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .\32xl\:via-purple-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .\32xl\:via-purple-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .\32xl\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .\32xl\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .\32xl\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .\32xl\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .\32xl\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .\32xl\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .\32xl\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .\32xl\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .\32xl\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .\32xl\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .\32xl\:via-pink-default {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .\32xl\:via-pink-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .\32xl\:via-pink-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .\32xl\:via-pink-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .\32xl\:via-pink-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .\32xl\:via-grey-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .\32xl\:via-grey-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .\32xl\:via-grey-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .\32xl\:via-grey-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .\32xl\:via-grey-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .\32xl\:via-grey-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:via-grey-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .\32xl\:via-grey-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:via-grey-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .\32xl\:via-grey-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .\32xl\:via-grey-default {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:via-grey-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .\32xl\:via-grey-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .\32xl\:via-grey-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .\32xl\:via-grey-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:via-orange-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .\32xl\:via-orange-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .\32xl\:via-orange-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .\32xl\:via-orange-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .\32xl\:via-orange-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .\32xl\:via-orange-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .\32xl\:via-orange-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .\32xl\:via-orange-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .\32xl\:via-orange-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .\32xl\:via-orange-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .\32xl\:via-orange-default {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .\32xl\:via-orange-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .\32xl\:via-orange-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .\32xl\:via-orange-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .\32xl\:via-orange-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .\32xl\:via-deep-orange-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .\32xl\:via-deep-orange-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .\32xl\:via-deep-orange-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .\32xl\:via-deep-orange-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .\32xl\:via-deep-orange-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .\32xl\:via-deep-orange-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .\32xl\:via-deep-orange-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .\32xl\:via-deep-orange-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .\32xl\:via-deep-orange-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .\32xl\:via-deep-orange-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .\32xl\:via-deep-orange-default {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .\32xl\:via-deep-orange-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .\32xl\:via-deep-orange-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .\32xl\:via-deep-orange-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .\32xl\:via-deep-orange-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .\32xl\:via-light-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .\32xl\:via-light-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .\32xl\:via-light-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .\32xl\:via-light-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .\32xl\:via-light-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .\32xl\:via-light-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .\32xl\:via-light-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .\32xl\:via-light-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .\32xl\:via-light-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .\32xl\:via-light-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .\32xl\:via-light-green-default {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .\32xl\:via-light-green-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .\32xl\:via-light-green-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .\32xl\:via-light-green-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .\32xl\:via-light-green-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .\32xl\:via-teal-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .\32xl\:via-teal-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .\32xl\:via-teal-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .\32xl\:via-teal-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .\32xl\:via-teal-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .\32xl\:via-teal-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .\32xl\:via-teal-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .\32xl\:via-teal-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .\32xl\:via-teal-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .\32xl\:via-teal-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .\32xl\:via-teal-default {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .\32xl\:via-teal-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .\32xl\:via-teal-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .\32xl\:via-teal-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .\32xl\:via-teal-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .\32xl\:via-light-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .\32xl\:via-light-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .\32xl\:via-light-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .\32xl\:via-light-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .\32xl\:via-light-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .\32xl\:via-light-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .\32xl\:via-light-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .\32xl\:via-light-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .\32xl\:via-light-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .\32xl\:via-light-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .\32xl\:via-light-blue-default {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .\32xl\:via-light-blue-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .\32xl\:via-light-blue-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .\32xl\:via-light-blue-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .\32xl\:via-light-blue-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .\32xl\:via-deep-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .\32xl\:via-deep-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .\32xl\:via-deep-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .\32xl\:via-deep-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .\32xl\:via-deep-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .\32xl\:via-deep-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .\32xl\:via-deep-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .\32xl\:via-deep-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .\32xl\:via-deep-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .\32xl\:via-deep-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .\32xl\:via-deep-purple-default {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .\32xl\:via-deep-purple-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .\32xl\:via-deep-purple-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .\32xl\:via-deep-purple-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .\32xl\:via-deep-purple-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .\32xl\:via-lime-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .\32xl\:via-lime-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .\32xl\:via-lime-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .\32xl\:via-lime-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .\32xl\:via-lime-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .\32xl\:via-lime-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .\32xl\:via-lime-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .\32xl\:via-lime-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .\32xl\:via-lime-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .\32xl\:via-lime-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .\32xl\:via-lime-default {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .\32xl\:via-lime-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .\32xl\:via-lime-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .\32xl\:via-lime-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .\32xl\:via-lime-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .\32xl\:via-amber-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .\32xl\:via-amber-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .\32xl\:via-amber-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .\32xl\:via-amber-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .\32xl\:via-amber-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .\32xl\:via-amber-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .\32xl\:via-amber-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .\32xl\:via-amber-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .\32xl\:via-amber-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .\32xl\:via-amber-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .\32xl\:via-amber-default {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .\32xl\:via-amber-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .\32xl\:via-amber-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .\32xl\:via-amber-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .\32xl\:via-amber-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .\32xl\:via-brown-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .\32xl\:via-brown-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .\32xl\:via-brown-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .\32xl\:via-brown-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .\32xl\:via-brown-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .\32xl\:via-brown-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .\32xl\:via-brown-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .\32xl\:via-brown-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .\32xl\:via-brown-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .\32xl\:via-brown-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .\32xl\:via-brown-default {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .\32xl\:via-brown-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .\32xl\:via-brown-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .\32xl\:via-brown-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .\32xl\:via-brown-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .\32xl\:via-blue-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .\32xl\:via-blue-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .\32xl\:via-blue-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .\32xl\:via-blue-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .\32xl\:via-blue-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .\32xl\:via-blue-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .\32xl\:via-blue-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .\32xl\:via-blue-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .\32xl\:via-blue-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .\32xl\:via-blue-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .\32xl\:via-blue-gray-default {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .\32xl\:via-blue-gray-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .\32xl\:via-blue-gray-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .\32xl\:via-blue-gray-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .\32xl\:via-blue-gray-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .\32xl\:via-cyan-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .\32xl\:via-cyan-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .\32xl\:via-cyan-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .\32xl\:via-cyan-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .\32xl\:via-cyan-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .\32xl\:via-cyan-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .\32xl\:via-cyan-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .\32xl\:via-cyan-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .\32xl\:via-cyan-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .\32xl\:via-cyan-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .\32xl\:via-cyan-default {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .\32xl\:via-cyan-A100 {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .\32xl\:via-cyan-A200 {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .\32xl\:via-cyan-A400 {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .\32xl\:via-cyan-A700 {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .\32xl\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .\32xl\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .\32xl\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .\32xl\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .\32xl\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .\32xl\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .\32xl\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .\32xl\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .\32xl\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .\32xl\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .\32xl\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .\32xl\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .\32xl\:hover\:via-gray-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:hover\:via-gray-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .\32xl\:hover\:via-gray-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .\32xl\:hover\:via-gray-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .\32xl\:hover\:via-gray-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .\32xl\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .\32xl\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .\32xl\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .\32xl\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .\32xl\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .\32xl\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .\32xl\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .\32xl\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .\32xl\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .\32xl\:hover\:via-red-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .\32xl\:hover\:via-red-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .\32xl\:hover\:via-red-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .\32xl\:hover\:via-red-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .\32xl\:hover\:via-red-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .\32xl\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .\32xl\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .\32xl\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .\32xl\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .\32xl\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .\32xl\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .\32xl\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .\32xl\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .\32xl\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .\32xl\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .\32xl\:hover\:via-yellow-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .\32xl\:hover\:via-yellow-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .\32xl\:hover\:via-yellow-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .\32xl\:hover\:via-yellow-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .\32xl\:hover\:via-yellow-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .\32xl\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .\32xl\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .\32xl\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .\32xl\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .\32xl\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .\32xl\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .\32xl\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .\32xl\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .\32xl\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .\32xl\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .\32xl\:hover\:via-green-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .\32xl\:hover\:via-green-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .\32xl\:hover\:via-green-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .\32xl\:hover\:via-green-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .\32xl\:hover\:via-green-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .\32xl\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .\32xl\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .\32xl\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .\32xl\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .\32xl\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .\32xl\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .\32xl\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .\32xl\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .\32xl\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .\32xl\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .\32xl\:hover\:via-blue-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .\32xl\:hover\:via-blue-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .\32xl\:hover\:via-blue-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .\32xl\:hover\:via-blue-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .\32xl\:hover\:via-blue-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .\32xl\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .\32xl\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .\32xl\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .\32xl\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .\32xl\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .\32xl\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .\32xl\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .\32xl\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .\32xl\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .\32xl\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .\32xl\:hover\:via-indigo-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .\32xl\:hover\:via-indigo-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .\32xl\:hover\:via-indigo-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .\32xl\:hover\:via-indigo-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .\32xl\:hover\:via-indigo-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .\32xl\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .\32xl\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .\32xl\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .\32xl\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .\32xl\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .\32xl\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .\32xl\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .\32xl\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .\32xl\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .\32xl\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .\32xl\:hover\:via-purple-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .\32xl\:hover\:via-purple-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .\32xl\:hover\:via-purple-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .\32xl\:hover\:via-purple-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .\32xl\:hover\:via-purple-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .\32xl\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .\32xl\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .\32xl\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .\32xl\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .\32xl\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .\32xl\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .\32xl\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .\32xl\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .\32xl\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .\32xl\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .\32xl\:hover\:via-pink-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .\32xl\:hover\:via-pink-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .\32xl\:hover\:via-pink-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .\32xl\:hover\:via-pink-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .\32xl\:hover\:via-pink-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .\32xl\:hover\:via-grey-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .\32xl\:hover\:via-grey-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .\32xl\:hover\:via-grey-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .\32xl\:hover\:via-grey-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .\32xl\:hover\:via-grey-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .\32xl\:hover\:via-grey-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:hover\:via-grey-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .\32xl\:hover\:via-grey-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:hover\:via-grey-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .\32xl\:hover\:via-grey-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .\32xl\:hover\:via-grey-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:hover\:via-grey-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .\32xl\:hover\:via-grey-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .\32xl\:hover\:via-grey-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .\32xl\:hover\:via-grey-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:hover\:via-orange-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .\32xl\:hover\:via-orange-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .\32xl\:hover\:via-orange-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .\32xl\:hover\:via-orange-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .\32xl\:hover\:via-orange-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .\32xl\:hover\:via-orange-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .\32xl\:hover\:via-orange-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .\32xl\:hover\:via-orange-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .\32xl\:hover\:via-orange-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .\32xl\:hover\:via-orange-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .\32xl\:hover\:via-orange-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .\32xl\:hover\:via-orange-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .\32xl\:hover\:via-orange-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .\32xl\:hover\:via-orange-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .\32xl\:hover\:via-orange-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .\32xl\:hover\:via-deep-orange-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .\32xl\:hover\:via-deep-orange-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .\32xl\:hover\:via-deep-orange-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .\32xl\:hover\:via-deep-orange-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .\32xl\:hover\:via-deep-orange-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .\32xl\:hover\:via-deep-orange-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .\32xl\:hover\:via-deep-orange-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .\32xl\:hover\:via-deep-orange-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .\32xl\:hover\:via-deep-orange-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .\32xl\:hover\:via-deep-orange-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .\32xl\:hover\:via-deep-orange-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .\32xl\:hover\:via-deep-orange-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .\32xl\:hover\:via-deep-orange-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .\32xl\:hover\:via-deep-orange-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .\32xl\:hover\:via-deep-orange-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .\32xl\:hover\:via-light-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .\32xl\:hover\:via-light-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .\32xl\:hover\:via-light-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .\32xl\:hover\:via-light-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .\32xl\:hover\:via-light-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .\32xl\:hover\:via-light-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .\32xl\:hover\:via-light-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .\32xl\:hover\:via-light-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .\32xl\:hover\:via-light-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .\32xl\:hover\:via-light-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .\32xl\:hover\:via-light-green-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .\32xl\:hover\:via-light-green-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .\32xl\:hover\:via-light-green-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .\32xl\:hover\:via-light-green-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .\32xl\:hover\:via-light-green-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .\32xl\:hover\:via-teal-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .\32xl\:hover\:via-teal-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .\32xl\:hover\:via-teal-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .\32xl\:hover\:via-teal-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .\32xl\:hover\:via-teal-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .\32xl\:hover\:via-teal-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .\32xl\:hover\:via-teal-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .\32xl\:hover\:via-teal-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .\32xl\:hover\:via-teal-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .\32xl\:hover\:via-teal-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .\32xl\:hover\:via-teal-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .\32xl\:hover\:via-teal-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .\32xl\:hover\:via-teal-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .\32xl\:hover\:via-teal-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .\32xl\:hover\:via-teal-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .\32xl\:hover\:via-light-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .\32xl\:hover\:via-light-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .\32xl\:hover\:via-light-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .\32xl\:hover\:via-light-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .\32xl\:hover\:via-light-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .\32xl\:hover\:via-light-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .\32xl\:hover\:via-light-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .\32xl\:hover\:via-light-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .\32xl\:hover\:via-light-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .\32xl\:hover\:via-light-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .\32xl\:hover\:via-light-blue-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .\32xl\:hover\:via-light-blue-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .\32xl\:hover\:via-light-blue-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .\32xl\:hover\:via-light-blue-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .\32xl\:hover\:via-light-blue-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .\32xl\:hover\:via-deep-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .\32xl\:hover\:via-deep-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .\32xl\:hover\:via-deep-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .\32xl\:hover\:via-deep-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .\32xl\:hover\:via-deep-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .\32xl\:hover\:via-deep-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .\32xl\:hover\:via-deep-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .\32xl\:hover\:via-deep-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .\32xl\:hover\:via-deep-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .\32xl\:hover\:via-deep-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .\32xl\:hover\:via-deep-purple-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .\32xl\:hover\:via-deep-purple-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .\32xl\:hover\:via-deep-purple-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .\32xl\:hover\:via-deep-purple-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .\32xl\:hover\:via-deep-purple-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .\32xl\:hover\:via-lime-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .\32xl\:hover\:via-lime-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .\32xl\:hover\:via-lime-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .\32xl\:hover\:via-lime-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .\32xl\:hover\:via-lime-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .\32xl\:hover\:via-lime-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .\32xl\:hover\:via-lime-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .\32xl\:hover\:via-lime-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .\32xl\:hover\:via-lime-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .\32xl\:hover\:via-lime-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .\32xl\:hover\:via-lime-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .\32xl\:hover\:via-lime-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .\32xl\:hover\:via-lime-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .\32xl\:hover\:via-lime-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .\32xl\:hover\:via-lime-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .\32xl\:hover\:via-amber-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .\32xl\:hover\:via-amber-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .\32xl\:hover\:via-amber-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .\32xl\:hover\:via-amber-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .\32xl\:hover\:via-amber-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .\32xl\:hover\:via-amber-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .\32xl\:hover\:via-amber-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .\32xl\:hover\:via-amber-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .\32xl\:hover\:via-amber-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .\32xl\:hover\:via-amber-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .\32xl\:hover\:via-amber-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .\32xl\:hover\:via-amber-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .\32xl\:hover\:via-amber-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .\32xl\:hover\:via-amber-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .\32xl\:hover\:via-amber-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .\32xl\:hover\:via-brown-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .\32xl\:hover\:via-brown-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .\32xl\:hover\:via-brown-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .\32xl\:hover\:via-brown-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .\32xl\:hover\:via-brown-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .\32xl\:hover\:via-brown-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .\32xl\:hover\:via-brown-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .\32xl\:hover\:via-brown-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .\32xl\:hover\:via-brown-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .\32xl\:hover\:via-brown-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .\32xl\:hover\:via-brown-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .\32xl\:hover\:via-brown-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .\32xl\:hover\:via-brown-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .\32xl\:hover\:via-brown-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .\32xl\:hover\:via-brown-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .\32xl\:hover\:via-blue-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .\32xl\:hover\:via-blue-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .\32xl\:hover\:via-blue-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .\32xl\:hover\:via-blue-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .\32xl\:hover\:via-blue-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .\32xl\:hover\:via-blue-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .\32xl\:hover\:via-blue-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .\32xl\:hover\:via-blue-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .\32xl\:hover\:via-blue-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .\32xl\:hover\:via-blue-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .\32xl\:hover\:via-blue-gray-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .\32xl\:hover\:via-blue-gray-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .\32xl\:hover\:via-blue-gray-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .\32xl\:hover\:via-blue-gray-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .\32xl\:hover\:via-blue-gray-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .\32xl\:hover\:via-cyan-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .\32xl\:hover\:via-cyan-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .\32xl\:hover\:via-cyan-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .\32xl\:hover\:via-cyan-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .\32xl\:hover\:via-cyan-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .\32xl\:hover\:via-cyan-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .\32xl\:hover\:via-cyan-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .\32xl\:hover\:via-cyan-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .\32xl\:hover\:via-cyan-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .\32xl\:hover\:via-cyan-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .\32xl\:hover\:via-cyan-default:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .\32xl\:hover\:via-cyan-A100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .\32xl\:hover\:via-cyan-A200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .\32xl\:hover\:via-cyan-A400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .\32xl\:hover\:via-cyan-A700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .\32xl\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .\32xl\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .\32xl\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #22292F, var(--tw-gradient-to, rgba(34, 41, 47, 0))
  }

  .\32xl\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .\32xl\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .\32xl\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .\32xl\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .\32xl\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .\32xl\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .\32xl\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .\32xl\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .\32xl\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .\32xl\:focus\:via-gray-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:focus\:via-gray-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .\32xl\:focus\:via-gray-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .\32xl\:focus\:via-gray-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .\32xl\:focus\:via-gray-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEBEE, var(--tw-gradient-to, rgba(255, 235, 238, 0))
  }

  .\32xl\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCDD2, var(--tw-gradient-to, rgba(255, 205, 210, 0))
  }

  .\32xl\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF9A9A, var(--tw-gradient-to, rgba(239, 154, 154, 0))
  }

  .\32xl\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E57373, var(--tw-gradient-to, rgba(229, 115, 115, 0))
  }

  .\32xl\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF5350, var(--tw-gradient-to, rgba(239, 83, 80, 0))
  }

  .\32xl\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .\32xl\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E53935, var(--tw-gradient-to, rgba(229, 57, 53, 0))
  }

  .\32xl\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D32F2F, var(--tw-gradient-to, rgba(211, 47, 47, 0))
  }

  .\32xl\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C62828, var(--tw-gradient-to, rgba(198, 40, 40, 0))
  }

  .\32xl\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B71C1C, var(--tw-gradient-to, rgba(183, 28, 28, 0))
  }

  .\32xl\:focus\:via-red-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F44336, var(--tw-gradient-to, rgba(244, 67, 54, 0))
  }

  .\32xl\:focus\:via-red-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A80, var(--tw-gradient-to, rgba(255, 138, 128, 0))
  }

  .\32xl\:focus\:via-red-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5252, var(--tw-gradient-to, rgba(255, 82, 82, 0))
  }

  .\32xl\:focus\:via-red-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF1744, var(--tw-gradient-to, rgba(255, 23, 68, 0))
  }

  .\32xl\:focus\:via-red-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D50000, var(--tw-gradient-to, rgba(213, 0, 0, 0))
  }

  .\32xl\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFDE7, var(--tw-gradient-to, rgba(255, 253, 231, 0))
  }

  .\32xl\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF9C4, var(--tw-gradient-to, rgba(255, 249, 196, 0))
  }

  .\32xl\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF59D, var(--tw-gradient-to, rgba(255, 245, 157, 0))
  }

  .\32xl\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF176, var(--tw-gradient-to, rgba(255, 241, 118, 0))
  }

  .\32xl\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEE58, var(--tw-gradient-to, rgba(255, 238, 88, 0))
  }

  .\32xl\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .\32xl\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FDD835, var(--tw-gradient-to, rgba(253, 216, 53, 0))
  }

  .\32xl\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FBC02D, var(--tw-gradient-to, rgba(251, 192, 45, 0))
  }

  .\32xl\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F9A825, var(--tw-gradient-to, rgba(249, 168, 37, 0))
  }

  .\32xl\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F57F17, var(--tw-gradient-to, rgba(245, 127, 23, 0))
  }

  .\32xl\:focus\:via-yellow-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEB3B, var(--tw-gradient-to, rgba(255, 235, 59, 0))
  }

  .\32xl\:focus\:via-yellow-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF8D, var(--tw-gradient-to, rgba(255, 255, 141, 0))
  }

  .\32xl\:focus\:via-yellow-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFFF00, var(--tw-gradient-to, rgba(255, 255, 0, 0))
  }

  .\32xl\:focus\:via-yellow-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFEA00, var(--tw-gradient-to, rgba(255, 234, 0, 0))
  }

  .\32xl\:focus\:via-yellow-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD600, var(--tw-gradient-to, rgba(255, 214, 0, 0))
  }

  .\32xl\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E8F5E9, var(--tw-gradient-to, rgba(232, 245, 233, 0))
  }

  .\32xl\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C8E6C9, var(--tw-gradient-to, rgba(200, 230, 201, 0))
  }

  .\32xl\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A5D6A7, var(--tw-gradient-to, rgba(165, 214, 167, 0))
  }

  .\32xl\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #81C784, var(--tw-gradient-to, rgba(129, 199, 132, 0))
  }

  .\32xl\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #66BB6A, var(--tw-gradient-to, rgba(102, 187, 106, 0))
  }

  .\32xl\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .\32xl\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #43A047, var(--tw-gradient-to, rgba(67, 160, 71, 0))
  }

  .\32xl\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #388E3C, var(--tw-gradient-to, rgba(56, 142, 60, 0))
  }

  .\32xl\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2E7D32, var(--tw-gradient-to, rgba(46, 125, 50, 0))
  }

  .\32xl\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1B5E20, var(--tw-gradient-to, rgba(27, 94, 32, 0))
  }

  .\32xl\:focus\:via-green-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4CAF50, var(--tw-gradient-to, rgba(76, 175, 80, 0))
  }

  .\32xl\:focus\:via-green-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B9F6CA, var(--tw-gradient-to, rgba(185, 246, 202, 0))
  }

  .\32xl\:focus\:via-green-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #69F0AE, var(--tw-gradient-to, rgba(105, 240, 174, 0))
  }

  .\32xl\:focus\:via-green-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00E676, var(--tw-gradient-to, rgba(0, 230, 118, 0))
  }

  .\32xl\:focus\:via-green-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00C853, var(--tw-gradient-to, rgba(0, 200, 83, 0))
  }

  .\32xl\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E3F2FD, var(--tw-gradient-to, rgba(227, 242, 253, 0))
  }

  .\32xl\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BBDEFB, var(--tw-gradient-to, rgba(187, 222, 251, 0))
  }

  .\32xl\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #90CAF9, var(--tw-gradient-to, rgba(144, 202, 249, 0))
  }

  .\32xl\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64B5F6, var(--tw-gradient-to, rgba(100, 181, 246, 0))
  }

  .\32xl\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #42A5F5, var(--tw-gradient-to, rgba(66, 165, 245, 0))
  }

  .\32xl\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .\32xl\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1E88E5, var(--tw-gradient-to, rgba(30, 136, 229, 0))
  }

  .\32xl\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1976D2, var(--tw-gradient-to, rgba(25, 118, 210, 0))
  }

  .\32xl\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1565C0, var(--tw-gradient-to, rgba(21, 101, 192, 0))
  }

  .\32xl\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0D47A1, var(--tw-gradient-to, rgba(13, 71, 161, 0))
  }

  .\32xl\:focus\:via-blue-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2196F3, var(--tw-gradient-to, rgba(33, 150, 243, 0))
  }

  .\32xl\:focus\:via-blue-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #82B1FF, var(--tw-gradient-to, rgba(130, 177, 255, 0))
  }

  .\32xl\:focus\:via-blue-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #448AFF, var(--tw-gradient-to, rgba(68, 138, 255, 0))
  }

  .\32xl\:focus\:via-blue-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2979FF, var(--tw-gradient-to, rgba(41, 121, 255, 0))
  }

  .\32xl\:focus\:via-blue-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2962FF, var(--tw-gradient-to, rgba(41, 98, 255, 0))
  }

  .\32xl\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E8EAF6, var(--tw-gradient-to, rgba(232, 234, 246, 0))
  }

  .\32xl\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C5CAE9, var(--tw-gradient-to, rgba(197, 202, 233, 0))
  }

  .\32xl\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9FA8DA, var(--tw-gradient-to, rgba(159, 168, 218, 0))
  }

  .\32xl\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7986CB, var(--tw-gradient-to, rgba(121, 134, 203, 0))
  }

  .\32xl\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5C6BC0, var(--tw-gradient-to, rgba(92, 107, 192, 0))
  }

  .\32xl\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .\32xl\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3949AB, var(--tw-gradient-to, rgba(57, 73, 171, 0))
  }

  .\32xl\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303F9F, var(--tw-gradient-to, rgba(48, 63, 159, 0))
  }

  .\32xl\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #283593, var(--tw-gradient-to, rgba(40, 53, 147, 0))
  }

  .\32xl\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1A237E, var(--tw-gradient-to, rgba(26, 35, 126, 0))
  }

  .\32xl\:focus\:via-indigo-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3F51B5, var(--tw-gradient-to, rgba(63, 81, 181, 0))
  }

  .\32xl\:focus\:via-indigo-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8C9EFF, var(--tw-gradient-to, rgba(140, 158, 255, 0))
  }

  .\32xl\:focus\:via-indigo-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #536DFE, var(--tw-gradient-to, rgba(83, 109, 254, 0))
  }

  .\32xl\:focus\:via-indigo-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3D5AFE, var(--tw-gradient-to, rgba(61, 90, 254, 0))
  }

  .\32xl\:focus\:via-indigo-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #304FFE, var(--tw-gradient-to, rgba(48, 79, 254, 0))
  }

  .\32xl\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F3E5F5, var(--tw-gradient-to, rgba(243, 229, 245, 0))
  }

  .\32xl\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E1BEE7, var(--tw-gradient-to, rgba(225, 190, 231, 0))
  }

  .\32xl\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CE93D8, var(--tw-gradient-to, rgba(206, 147, 216, 0))
  }

  .\32xl\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BA68C8, var(--tw-gradient-to, rgba(186, 104, 200, 0))
  }

  .\32xl\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AB47BC, var(--tw-gradient-to, rgba(171, 71, 188, 0))
  }

  .\32xl\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .\32xl\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8E24AA, var(--tw-gradient-to, rgba(142, 36, 170, 0))
  }

  .\32xl\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7B1FA2, var(--tw-gradient-to, rgba(123, 31, 162, 0))
  }

  .\32xl\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6A1B9A, var(--tw-gradient-to, rgba(106, 27, 154, 0))
  }

  .\32xl\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4A148C, var(--tw-gradient-to, rgba(74, 20, 140, 0))
  }

  .\32xl\:focus\:via-purple-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9C27B0, var(--tw-gradient-to, rgba(156, 39, 176, 0))
  }

  .\32xl\:focus\:via-purple-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EA80FC, var(--tw-gradient-to, rgba(234, 128, 252, 0))
  }

  .\32xl\:focus\:via-purple-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E040FB, var(--tw-gradient-to, rgba(224, 64, 251, 0))
  }

  .\32xl\:focus\:via-purple-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D500F9, var(--tw-gradient-to, rgba(213, 0, 249, 0))
  }

  .\32xl\:focus\:via-purple-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AA00FF, var(--tw-gradient-to, rgba(170, 0, 255, 0))
  }

  .\32xl\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FCE4EC, var(--tw-gradient-to, rgba(252, 228, 236, 0))
  }

  .\32xl\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F8BBD0, var(--tw-gradient-to, rgba(248, 187, 208, 0))
  }

  .\32xl\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F48FB1, var(--tw-gradient-to, rgba(244, 143, 177, 0))
  }

  .\32xl\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F06292, var(--tw-gradient-to, rgba(240, 98, 146, 0))
  }

  .\32xl\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EC407A, var(--tw-gradient-to, rgba(236, 64, 122, 0))
  }

  .\32xl\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .\32xl\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D81B60, var(--tw-gradient-to, rgba(216, 27, 96, 0))
  }

  .\32xl\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C2185B, var(--tw-gradient-to, rgba(194, 24, 91, 0))
  }

  .\32xl\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AD1457, var(--tw-gradient-to, rgba(173, 20, 87, 0))
  }

  .\32xl\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #880E4F, var(--tw-gradient-to, rgba(136, 14, 79, 0))
  }

  .\32xl\:focus\:via-pink-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E91E63, var(--tw-gradient-to, rgba(233, 30, 99, 0))
  }

  .\32xl\:focus\:via-pink-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF80AB, var(--tw-gradient-to, rgba(255, 128, 171, 0))
  }

  .\32xl\:focus\:via-pink-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF4081, var(--tw-gradient-to, rgba(255, 64, 129, 0))
  }

  .\32xl\:focus\:via-pink-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F50057, var(--tw-gradient-to, rgba(245, 0, 87, 0))
  }

  .\32xl\:focus\:via-pink-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C51162, var(--tw-gradient-to, rgba(197, 17, 98, 0))
  }

  .\32xl\:focus\:via-grey-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FAFAFA, var(--tw-gradient-to, rgba(250, 250, 250, 0))
  }

  .\32xl\:focus\:via-grey-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F5F5F5, var(--tw-gradient-to, rgba(245, 245, 245, 0))
  }

  .\32xl\:focus\:via-grey-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEEEEE, var(--tw-gradient-to, rgba(238, 238, 238, 0))
  }

  .\32xl\:focus\:via-grey-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0E0E0, var(--tw-gradient-to, rgba(224, 224, 224, 0))
  }

  .\32xl\:focus\:via-grey-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BDBDBD, var(--tw-gradient-to, rgba(189, 189, 189, 0))
  }

  .\32xl\:focus\:via-grey-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:focus\:via-grey-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #757575, var(--tw-gradient-to, rgba(117, 117, 117, 0))
  }

  .\32xl\:focus\:via-grey-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:focus\:via-grey-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #424242, var(--tw-gradient-to, rgba(66, 66, 66, 0))
  }

  .\32xl\:focus\:via-grey-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0))
  }

  .\32xl\:focus\:via-grey-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9E9E, var(--tw-gradient-to, rgba(158, 158, 158, 0))
  }

  .\32xl\:focus\:via-grey-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D5D5D5, var(--tw-gradient-to, rgba(213, 213, 213, 0))
  }

  .\32xl\:focus\:via-grey-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AAAAAA, var(--tw-gradient-to, rgba(170, 170, 170, 0))
  }

  .\32xl\:focus\:via-grey-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #303030, var(--tw-gradient-to, rgba(48, 48, 48, 0))
  }

  .\32xl\:focus\:via-grey-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #616161, var(--tw-gradient-to, rgba(97, 97, 97, 0))
  }

  .\32xl\:focus\:via-orange-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF3E0, var(--tw-gradient-to, rgba(255, 243, 224, 0))
  }

  .\32xl\:focus\:via-orange-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE0B2, var(--tw-gradient-to, rgba(255, 224, 178, 0))
  }

  .\32xl\:focus\:via-orange-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCC80, var(--tw-gradient-to, rgba(255, 204, 128, 0))
  }

  .\32xl\:focus\:via-orange-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB74D, var(--tw-gradient-to, rgba(255, 183, 77, 0))
  }

  .\32xl\:focus\:via-orange-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA726, var(--tw-gradient-to, rgba(255, 167, 38, 0))
  }

  .\32xl\:focus\:via-orange-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .\32xl\:focus\:via-orange-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FB8C00, var(--tw-gradient-to, rgba(251, 140, 0, 0))
  }

  .\32xl\:focus\:via-orange-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F57C00, var(--tw-gradient-to, rgba(245, 124, 0, 0))
  }

  .\32xl\:focus\:via-orange-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EF6C00, var(--tw-gradient-to, rgba(239, 108, 0, 0))
  }

  .\32xl\:focus\:via-orange-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E65100, var(--tw-gradient-to, rgba(230, 81, 0, 0))
  }

  .\32xl\:focus\:via-orange-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9800, var(--tw-gradient-to, rgba(255, 152, 0, 0))
  }

  .\32xl\:focus\:via-orange-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD180, var(--tw-gradient-to, rgba(255, 209, 128, 0))
  }

  .\32xl\:focus\:via-orange-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB40, var(--tw-gradient-to, rgba(255, 171, 64, 0))
  }

  .\32xl\:focus\:via-orange-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9100, var(--tw-gradient-to, rgba(255, 145, 0, 0))
  }

  .\32xl\:focus\:via-orange-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6D00, var(--tw-gradient-to, rgba(255, 109, 0, 0))
  }

  .\32xl\:focus\:via-deep-orange-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FBE9E7, var(--tw-gradient-to, rgba(251, 233, 231, 0))
  }

  .\32xl\:focus\:via-deep-orange-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCCBC, var(--tw-gradient-to, rgba(255, 204, 188, 0))
  }

  .\32xl\:focus\:via-deep-orange-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB91, var(--tw-gradient-to, rgba(255, 171, 145, 0))
  }

  .\32xl\:focus\:via-deep-orange-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8A65, var(--tw-gradient-to, rgba(255, 138, 101, 0))
  }

  .\32xl\:focus\:via-deep-orange-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF7043, var(--tw-gradient-to, rgba(255, 112, 67, 0))
  }

  .\32xl\:focus\:via-deep-orange-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .\32xl\:focus\:via-deep-orange-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F4511E, var(--tw-gradient-to, rgba(244, 81, 30, 0))
  }

  .\32xl\:focus\:via-deep-orange-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E64A19, var(--tw-gradient-to, rgba(230, 74, 25, 0))
  }

  .\32xl\:focus\:via-deep-orange-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D84315, var(--tw-gradient-to, rgba(216, 67, 21, 0))
  }

  .\32xl\:focus\:via-deep-orange-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BF360C, var(--tw-gradient-to, rgba(191, 54, 12, 0))
  }

  .\32xl\:focus\:via-deep-orange-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF5722, var(--tw-gradient-to, rgba(255, 87, 34, 0))
  }

  .\32xl\:focus\:via-deep-orange-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF9E80, var(--tw-gradient-to, rgba(255, 158, 128, 0))
  }

  .\32xl\:focus\:via-deep-orange-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6E40, var(--tw-gradient-to, rgba(255, 110, 64, 0))
  }

  .\32xl\:focus\:via-deep-orange-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF3D00, var(--tw-gradient-to, rgba(255, 61, 0, 0))
  }

  .\32xl\:focus\:via-deep-orange-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DD2C00, var(--tw-gradient-to, rgba(221, 44, 0, 0))
  }

  .\32xl\:focus\:via-light-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F1F8E9, var(--tw-gradient-to, rgba(241, 248, 233, 0))
  }

  .\32xl\:focus\:via-light-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DCEDC8, var(--tw-gradient-to, rgba(220, 237, 200, 0))
  }

  .\32xl\:focus\:via-light-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C5E1A5, var(--tw-gradient-to, rgba(197, 225, 165, 0))
  }

  .\32xl\:focus\:via-light-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AED581, var(--tw-gradient-to, rgba(174, 213, 129, 0))
  }

  .\32xl\:focus\:via-light-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9CCC65, var(--tw-gradient-to, rgba(156, 204, 101, 0))
  }

  .\32xl\:focus\:via-light-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .\32xl\:focus\:via-light-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7CB342, var(--tw-gradient-to, rgba(124, 179, 66, 0))
  }

  .\32xl\:focus\:via-light-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #689F38, var(--tw-gradient-to, rgba(104, 159, 56, 0))
  }

  .\32xl\:focus\:via-light-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #558B2F, var(--tw-gradient-to, rgba(85, 139, 47, 0))
  }

  .\32xl\:focus\:via-light-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #33691E, var(--tw-gradient-to, rgba(51, 105, 30, 0))
  }

  .\32xl\:focus\:via-light-green-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8BC34A, var(--tw-gradient-to, rgba(139, 195, 74, 0))
  }

  .\32xl\:focus\:via-light-green-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CCFF90, var(--tw-gradient-to, rgba(204, 255, 144, 0))
  }

  .\32xl\:focus\:via-light-green-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2FF59, var(--tw-gradient-to, rgba(178, 255, 89, 0))
  }

  .\32xl\:focus\:via-light-green-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #76FF03, var(--tw-gradient-to, rgba(118, 255, 3, 0))
  }

  .\32xl\:focus\:via-light-green-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64DD17, var(--tw-gradient-to, rgba(100, 221, 23, 0))
  }

  .\32xl\:focus\:via-teal-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F2F1, var(--tw-gradient-to, rgba(224, 242, 241, 0))
  }

  .\32xl\:focus\:via-teal-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2DFDB, var(--tw-gradient-to, rgba(178, 223, 219, 0))
  }

  .\32xl\:focus\:via-teal-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80CBC4, var(--tw-gradient-to, rgba(128, 203, 196, 0))
  }

  .\32xl\:focus\:via-teal-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4DB6AC, var(--tw-gradient-to, rgba(77, 182, 172, 0))
  }

  .\32xl\:focus\:via-teal-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #26A69A, var(--tw-gradient-to, rgba(38, 166, 154, 0))
  }

  .\32xl\:focus\:via-teal-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .\32xl\:focus\:via-teal-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00897B, var(--tw-gradient-to, rgba(0, 137, 123, 0))
  }

  .\32xl\:focus\:via-teal-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00796B, var(--tw-gradient-to, rgba(0, 121, 107, 0))
  }

  .\32xl\:focus\:via-teal-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00695C, var(--tw-gradient-to, rgba(0, 105, 92, 0))
  }

  .\32xl\:focus\:via-teal-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #004D40, var(--tw-gradient-to, rgba(0, 77, 64, 0))
  }

  .\32xl\:focus\:via-teal-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #009688, var(--tw-gradient-to, rgba(0, 150, 136, 0))
  }

  .\32xl\:focus\:via-teal-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A7FFEB, var(--tw-gradient-to, rgba(167, 255, 235, 0))
  }

  .\32xl\:focus\:via-teal-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #64FFDA, var(--tw-gradient-to, rgba(100, 255, 218, 0))
  }

  .\32xl\:focus\:via-teal-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1DE9B6, var(--tw-gradient-to, rgba(29, 233, 182, 0))
  }

  .\32xl\:focus\:via-teal-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BFA5, var(--tw-gradient-to, rgba(0, 191, 165, 0))
  }

  .\32xl\:focus\:via-light-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E1F5FE, var(--tw-gradient-to, rgba(225, 245, 254, 0))
  }

  .\32xl\:focus\:via-light-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B3E5FC, var(--tw-gradient-to, rgba(179, 229, 252, 0))
  }

  .\32xl\:focus\:via-light-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #81D4FA, var(--tw-gradient-to, rgba(129, 212, 250, 0))
  }

  .\32xl\:focus\:via-light-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4FC3F7, var(--tw-gradient-to, rgba(79, 195, 247, 0))
  }

  .\32xl\:focus\:via-light-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #29B6F6, var(--tw-gradient-to, rgba(41, 182, 246, 0))
  }

  .\32xl\:focus\:via-light-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .\32xl\:focus\:via-light-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #039BE5, var(--tw-gradient-to, rgba(3, 155, 229, 0))
  }

  .\32xl\:focus\:via-light-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0288D1, var(--tw-gradient-to, rgba(2, 136, 209, 0))
  }

  .\32xl\:focus\:via-light-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0277BD, var(--tw-gradient-to, rgba(2, 119, 189, 0))
  }

  .\32xl\:focus\:via-light-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #01579B, var(--tw-gradient-to, rgba(1, 87, 155, 0))
  }

  .\32xl\:focus\:via-light-blue-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #03A9F4, var(--tw-gradient-to, rgba(3, 169, 244, 0))
  }

  .\32xl\:focus\:via-light-blue-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80D8FF, var(--tw-gradient-to, rgba(128, 216, 255, 0))
  }

  .\32xl\:focus\:via-light-blue-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #40C4FF, var(--tw-gradient-to, rgba(64, 196, 255, 0))
  }

  .\32xl\:focus\:via-light-blue-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00B0FF, var(--tw-gradient-to, rgba(0, 176, 255, 0))
  }

  .\32xl\:focus\:via-light-blue-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0091EA, var(--tw-gradient-to, rgba(0, 145, 234, 0))
  }

  .\32xl\:focus\:via-deep-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EDE7F6, var(--tw-gradient-to, rgba(237, 231, 246, 0))
  }

  .\32xl\:focus\:via-deep-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D1C4E9, var(--tw-gradient-to, rgba(209, 196, 233, 0))
  }

  .\32xl\:focus\:via-deep-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B39DDB, var(--tw-gradient-to, rgba(179, 157, 219, 0))
  }

  .\32xl\:focus\:via-deep-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9575CD, var(--tw-gradient-to, rgba(149, 117, 205, 0))
  }

  .\32xl\:focus\:via-deep-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7E57C2, var(--tw-gradient-to, rgba(126, 87, 194, 0))
  }

  .\32xl\:focus\:via-deep-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .\32xl\:focus\:via-deep-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5E35B1, var(--tw-gradient-to, rgba(94, 53, 177, 0))
  }

  .\32xl\:focus\:via-deep-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #512DA8, var(--tw-gradient-to, rgba(81, 45, 168, 0))
  }

  .\32xl\:focus\:via-deep-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4527A0, var(--tw-gradient-to, rgba(69, 39, 160, 0))
  }

  .\32xl\:focus\:via-deep-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #311B92, var(--tw-gradient-to, rgba(49, 27, 146, 0))
  }

  .\32xl\:focus\:via-deep-purple-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #673AB7, var(--tw-gradient-to, rgba(103, 58, 183, 0))
  }

  .\32xl\:focus\:via-deep-purple-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B388FF, var(--tw-gradient-to, rgba(179, 136, 255, 0))
  }

  .\32xl\:focus\:via-deep-purple-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7C4DFF, var(--tw-gradient-to, rgba(124, 77, 255, 0))
  }

  .\32xl\:focus\:via-deep-purple-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #651FFF, var(--tw-gradient-to, rgba(101, 31, 255, 0))
  }

  .\32xl\:focus\:via-deep-purple-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6200EA, var(--tw-gradient-to, rgba(98, 0, 234, 0))
  }

  .\32xl\:focus\:via-lime-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F9FBE7, var(--tw-gradient-to, rgba(249, 251, 231, 0))
  }

  .\32xl\:focus\:via-lime-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F0F4C3, var(--tw-gradient-to, rgba(240, 244, 195, 0))
  }

  .\32xl\:focus\:via-lime-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E6EE9C, var(--tw-gradient-to, rgba(230, 238, 156, 0))
  }

  .\32xl\:focus\:via-lime-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #DCE775, var(--tw-gradient-to, rgba(220, 231, 117, 0))
  }

  .\32xl\:focus\:via-lime-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D4E157, var(--tw-gradient-to, rgba(212, 225, 87, 0))
  }

  .\32xl\:focus\:via-lime-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .\32xl\:focus\:via-lime-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C0CA33, var(--tw-gradient-to, rgba(192, 202, 51, 0))
  }

  .\32xl\:focus\:via-lime-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AFB42B, var(--tw-gradient-to, rgba(175, 180, 43, 0))
  }

  .\32xl\:focus\:via-lime-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9E9D24, var(--tw-gradient-to, rgba(158, 157, 36, 0))
  }

  .\32xl\:focus\:via-lime-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #827717, var(--tw-gradient-to, rgba(130, 119, 23, 0))
  }

  .\32xl\:focus\:via-lime-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CDDC39, var(--tw-gradient-to, rgba(205, 220, 57, 0))
  }

  .\32xl\:focus\:via-lime-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #F4FF81, var(--tw-gradient-to, rgba(244, 255, 129, 0))
  }

  .\32xl\:focus\:via-lime-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EEFF41, var(--tw-gradient-to, rgba(238, 255, 65, 0))
  }

  .\32xl\:focus\:via-lime-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #C6FF00, var(--tw-gradient-to, rgba(198, 255, 0, 0))
  }

  .\32xl\:focus\:via-lime-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #AEEA00, var(--tw-gradient-to, rgba(174, 234, 0, 0))
  }

  .\32xl\:focus\:via-amber-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFF8E1, var(--tw-gradient-to, rgba(255, 248, 225, 0))
  }

  .\32xl\:focus\:via-amber-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFECB3, var(--tw-gradient-to, rgba(255, 236, 179, 0))
  }

  .\32xl\:focus\:via-amber-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE082, var(--tw-gradient-to, rgba(255, 224, 130, 0))
  }

  .\32xl\:focus\:via-amber-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD54F, var(--tw-gradient-to, rgba(255, 213, 79, 0))
  }

  .\32xl\:focus\:via-amber-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFCA28, var(--tw-gradient-to, rgba(255, 202, 40, 0))
  }

  .\32xl\:focus\:via-amber-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .\32xl\:focus\:via-amber-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFB300, var(--tw-gradient-to, rgba(255, 179, 0, 0))
  }

  .\32xl\:focus\:via-amber-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFA000, var(--tw-gradient-to, rgba(255, 160, 0, 0))
  }

  .\32xl\:focus\:via-amber-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF8F00, var(--tw-gradient-to, rgba(255, 143, 0, 0))
  }

  .\32xl\:focus\:via-amber-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FF6F00, var(--tw-gradient-to, rgba(255, 111, 0, 0))
  }

  .\32xl\:focus\:via-amber-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC107, var(--tw-gradient-to, rgba(255, 193, 7, 0))
  }

  .\32xl\:focus\:via-amber-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFE57F, var(--tw-gradient-to, rgba(255, 229, 127, 0))
  }

  .\32xl\:focus\:via-amber-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFD740, var(--tw-gradient-to, rgba(255, 215, 64, 0))
  }

  .\32xl\:focus\:via-amber-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFC400, var(--tw-gradient-to, rgba(255, 196, 0, 0))
  }

  .\32xl\:focus\:via-amber-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #FFAB00, var(--tw-gradient-to, rgba(255, 171, 0, 0))
  }

  .\32xl\:focus\:via-brown-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #EFEBE9, var(--tw-gradient-to, rgba(239, 235, 233, 0))
  }

  .\32xl\:focus\:via-brown-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .\32xl\:focus\:via-brown-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .\32xl\:focus\:via-brown-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #A1887F, var(--tw-gradient-to, rgba(161, 136, 127, 0))
  }

  .\32xl\:focus\:via-brown-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .\32xl\:focus\:via-brown-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .\32xl\:focus\:via-brown-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6D4C41, var(--tw-gradient-to, rgba(109, 76, 65, 0))
  }

  .\32xl\:focus\:via-brown-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .\32xl\:focus\:via-brown-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4E342E, var(--tw-gradient-to, rgba(78, 52, 46, 0))
  }

  .\32xl\:focus\:via-brown-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3E2723, var(--tw-gradient-to, rgba(62, 39, 35, 0))
  }

  .\32xl\:focus\:via-brown-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #795548, var(--tw-gradient-to, rgba(121, 85, 72, 0))
  }

  .\32xl\:focus\:via-brown-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #D7CCC8, var(--tw-gradient-to, rgba(215, 204, 200, 0))
  }

  .\32xl\:focus\:via-brown-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #BCAAA4, var(--tw-gradient-to, rgba(188, 170, 164, 0))
  }

  .\32xl\:focus\:via-brown-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8D6E63, var(--tw-gradient-to, rgba(141, 110, 99, 0))
  }

  .\32xl\:focus\:via-brown-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5D4037, var(--tw-gradient-to, rgba(93, 64, 55, 0))
  }

  .\32xl\:focus\:via-blue-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ECEFF1, var(--tw-gradient-to, rgba(236, 239, 241, 0))
  }

  .\32xl\:focus\:via-blue-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .\32xl\:focus\:via-blue-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .\32xl\:focus\:via-blue-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #90A4AE, var(--tw-gradient-to, rgba(144, 164, 174, 0))
  }

  .\32xl\:focus\:via-blue-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .\32xl\:focus\:via-blue-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .\32xl\:focus\:via-blue-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #546E7A, var(--tw-gradient-to, rgba(84, 110, 122, 0))
  }

  .\32xl\:focus\:via-blue-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .\32xl\:focus\:via-blue-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #37474F, var(--tw-gradient-to, rgba(55, 71, 79, 0))
  }

  .\32xl\:focus\:via-blue-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #263238, var(--tw-gradient-to, rgba(38, 50, 56, 0))
  }

  .\32xl\:focus\:via-blue-gray-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #607D8B, var(--tw-gradient-to, rgba(96, 125, 139, 0))
  }

  .\32xl\:focus\:via-blue-gray-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #CFD8DC, var(--tw-gradient-to, rgba(207, 216, 220, 0))
  }

  .\32xl\:focus\:via-blue-gray-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B0BEC5, var(--tw-gradient-to, rgba(176, 190, 197, 0))
  }

  .\32xl\:focus\:via-blue-gray-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78909C, var(--tw-gradient-to, rgba(120, 144, 156, 0))
  }

  .\32xl\:focus\:via-blue-gray-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #455A64, var(--tw-gradient-to, rgba(69, 90, 100, 0))
  }

  .\32xl\:focus\:via-cyan-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #E0F7FA, var(--tw-gradient-to, rgba(224, 247, 250, 0))
  }

  .\32xl\:focus\:via-cyan-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #B2EBF2, var(--tw-gradient-to, rgba(178, 235, 242, 0))
  }

  .\32xl\:focus\:via-cyan-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #80DEEA, var(--tw-gradient-to, rgba(128, 222, 234, 0))
  }

  .\32xl\:focus\:via-cyan-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4DD0E1, var(--tw-gradient-to, rgba(77, 208, 225, 0))
  }

  .\32xl\:focus\:via-cyan-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #26C6DA, var(--tw-gradient-to, rgba(38, 198, 218, 0))
  }

  .\32xl\:focus\:via-cyan-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .\32xl\:focus\:via-cyan-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00ACC1, var(--tw-gradient-to, rgba(0, 172, 193, 0))
  }

  .\32xl\:focus\:via-cyan-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0097A7, var(--tw-gradient-to, rgba(0, 151, 167, 0))
  }

  .\32xl\:focus\:via-cyan-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00838F, var(--tw-gradient-to, rgba(0, 131, 143, 0))
  }

  .\32xl\:focus\:via-cyan-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #006064, var(--tw-gradient-to, rgba(0, 96, 100, 0))
  }

  .\32xl\:focus\:via-cyan-default:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00BCD4, var(--tw-gradient-to, rgba(0, 188, 212, 0))
  }

  .\32xl\:focus\:via-cyan-A100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #84FFFF, var(--tw-gradient-to, rgba(132, 255, 255, 0))
  }

  .\32xl\:focus\:via-cyan-A200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #18FFFF, var(--tw-gradient-to, rgba(24, 255, 255, 0))
  }

  .\32xl\:focus\:via-cyan-A400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00E5FF, var(--tw-gradient-to, rgba(0, 229, 255, 0))
  }

  .\32xl\:focus\:via-cyan-A700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #00B8D4, var(--tw-gradient-to, rgba(0, 184, 212, 0))
  }

  .\32xl\:to-transparent {
    --tw-gradient-to: transparent
  }

  .\32xl\:to-current {
    --tw-gradient-to: currentColor
  }

  .\32xl\:to-black {
    --tw-gradient-to: #22292F
  }

  .\32xl\:to-white {
    --tw-gradient-to: #FFF
  }

  .\32xl\:to-gray-50 {
    --tw-gradient-to: #FAFAFA
  }

  .\32xl\:to-gray-100 {
    --tw-gradient-to: #F5F5F5
  }

  .\32xl\:to-gray-200 {
    --tw-gradient-to: #EEEEEE
  }

  .\32xl\:to-gray-300 {
    --tw-gradient-to: #E0E0E0
  }

  .\32xl\:to-gray-400 {
    --tw-gradient-to: #BDBDBD
  }

  .\32xl\:to-gray-500 {
    --tw-gradient-to: #9E9E9E
  }

  .\32xl\:to-gray-600 {
    --tw-gradient-to: #757575
  }

  .\32xl\:to-gray-700 {
    --tw-gradient-to: #616161
  }

  .\32xl\:to-gray-800 {
    --tw-gradient-to: #424242
  }

  .\32xl\:to-gray-900 {
    --tw-gradient-to: #212121
  }

  .\32xl\:to-gray-default {
    --tw-gradient-to: #9E9E9E
  }

  .\32xl\:to-gray-A100 {
    --tw-gradient-to: #D5D5D5
  }

  .\32xl\:to-gray-A200 {
    --tw-gradient-to: #AAAAAA
  }

  .\32xl\:to-gray-A400 {
    --tw-gradient-to: #303030
  }

  .\32xl\:to-gray-A700 {
    --tw-gradient-to: #616161
  }

  .\32xl\:to-red-50 {
    --tw-gradient-to: #FFEBEE
  }

  .\32xl\:to-red-100 {
    --tw-gradient-to: #FFCDD2
  }

  .\32xl\:to-red-200 {
    --tw-gradient-to: #EF9A9A
  }

  .\32xl\:to-red-300 {
    --tw-gradient-to: #E57373
  }

  .\32xl\:to-red-400 {
    --tw-gradient-to: #EF5350
  }

  .\32xl\:to-red-500 {
    --tw-gradient-to: #F44336
  }

  .\32xl\:to-red-600 {
    --tw-gradient-to: #E53935
  }

  .\32xl\:to-red-700 {
    --tw-gradient-to: #D32F2F
  }

  .\32xl\:to-red-800 {
    --tw-gradient-to: #C62828
  }

  .\32xl\:to-red-900 {
    --tw-gradient-to: #B71C1C
  }

  .\32xl\:to-red-default {
    --tw-gradient-to: #F44336
  }

  .\32xl\:to-red-A100 {
    --tw-gradient-to: #FF8A80
  }

  .\32xl\:to-red-A200 {
    --tw-gradient-to: #FF5252
  }

  .\32xl\:to-red-A400 {
    --tw-gradient-to: #FF1744
  }

  .\32xl\:to-red-A700 {
    --tw-gradient-to: #D50000
  }

  .\32xl\:to-yellow-50 {
    --tw-gradient-to: #FFFDE7
  }

  .\32xl\:to-yellow-100 {
    --tw-gradient-to: #FFF9C4
  }

  .\32xl\:to-yellow-200 {
    --tw-gradient-to: #FFF59D
  }

  .\32xl\:to-yellow-300 {
    --tw-gradient-to: #FFF176
  }

  .\32xl\:to-yellow-400 {
    --tw-gradient-to: #FFEE58
  }

  .\32xl\:to-yellow-500 {
    --tw-gradient-to: #FFEB3B
  }

  .\32xl\:to-yellow-600 {
    --tw-gradient-to: #FDD835
  }

  .\32xl\:to-yellow-700 {
    --tw-gradient-to: #FBC02D
  }

  .\32xl\:to-yellow-800 {
    --tw-gradient-to: #F9A825
  }

  .\32xl\:to-yellow-900 {
    --tw-gradient-to: #F57F17
  }

  .\32xl\:to-yellow-default {
    --tw-gradient-to: #FFEB3B
  }

  .\32xl\:to-yellow-A100 {
    --tw-gradient-to: #FFFF8D
  }

  .\32xl\:to-yellow-A200 {
    --tw-gradient-to: #FFFF00
  }

  .\32xl\:to-yellow-A400 {
    --tw-gradient-to: #FFEA00
  }

  .\32xl\:to-yellow-A700 {
    --tw-gradient-to: #FFD600
  }

  .\32xl\:to-green-50 {
    --tw-gradient-to: #E8F5E9
  }

  .\32xl\:to-green-100 {
    --tw-gradient-to: #C8E6C9
  }

  .\32xl\:to-green-200 {
    --tw-gradient-to: #A5D6A7
  }

  .\32xl\:to-green-300 {
    --tw-gradient-to: #81C784
  }

  .\32xl\:to-green-400 {
    --tw-gradient-to: #66BB6A
  }

  .\32xl\:to-green-500 {
    --tw-gradient-to: #4CAF50
  }

  .\32xl\:to-green-600 {
    --tw-gradient-to: #43A047
  }

  .\32xl\:to-green-700 {
    --tw-gradient-to: #388E3C
  }

  .\32xl\:to-green-800 {
    --tw-gradient-to: #2E7D32
  }

  .\32xl\:to-green-900 {
    --tw-gradient-to: #1B5E20
  }

  .\32xl\:to-green-default {
    --tw-gradient-to: #4CAF50
  }

  .\32xl\:to-green-A100 {
    --tw-gradient-to: #B9F6CA
  }

  .\32xl\:to-green-A200 {
    --tw-gradient-to: #69F0AE
  }

  .\32xl\:to-green-A400 {
    --tw-gradient-to: #00E676
  }

  .\32xl\:to-green-A700 {
    --tw-gradient-to: #00C853
  }

  .\32xl\:to-blue-50 {
    --tw-gradient-to: #E3F2FD
  }

  .\32xl\:to-blue-100 {
    --tw-gradient-to: #BBDEFB
  }

  .\32xl\:to-blue-200 {
    --tw-gradient-to: #90CAF9
  }

  .\32xl\:to-blue-300 {
    --tw-gradient-to: #64B5F6
  }

  .\32xl\:to-blue-400 {
    --tw-gradient-to: #42A5F5
  }

  .\32xl\:to-blue-500 {
    --tw-gradient-to: #2196F3
  }

  .\32xl\:to-blue-600 {
    --tw-gradient-to: #1E88E5
  }

  .\32xl\:to-blue-700 {
    --tw-gradient-to: #1976D2
  }

  .\32xl\:to-blue-800 {
    --tw-gradient-to: #1565C0
  }

  .\32xl\:to-blue-900 {
    --tw-gradient-to: #0D47A1
  }

  .\32xl\:to-blue-default {
    --tw-gradient-to: #2196F3
  }

  .\32xl\:to-blue-A100 {
    --tw-gradient-to: #82B1FF
  }

  .\32xl\:to-blue-A200 {
    --tw-gradient-to: #448AFF
  }

  .\32xl\:to-blue-A400 {
    --tw-gradient-to: #2979FF
  }

  .\32xl\:to-blue-A700 {
    --tw-gradient-to: #2962FF
  }

  .\32xl\:to-indigo-50 {
    --tw-gradient-to: #E8EAF6
  }

  .\32xl\:to-indigo-100 {
    --tw-gradient-to: #C5CAE9
  }

  .\32xl\:to-indigo-200 {
    --tw-gradient-to: #9FA8DA
  }

  .\32xl\:to-indigo-300 {
    --tw-gradient-to: #7986CB
  }

  .\32xl\:to-indigo-400 {
    --tw-gradient-to: #5C6BC0
  }

  .\32xl\:to-indigo-500 {
    --tw-gradient-to: #3F51B5
  }

  .\32xl\:to-indigo-600 {
    --tw-gradient-to: #3949AB
  }

  .\32xl\:to-indigo-700 {
    --tw-gradient-to: #303F9F
  }

  .\32xl\:to-indigo-800 {
    --tw-gradient-to: #283593
  }

  .\32xl\:to-indigo-900 {
    --tw-gradient-to: #1A237E
  }

  .\32xl\:to-indigo-default {
    --tw-gradient-to: #3F51B5
  }

  .\32xl\:to-indigo-A100 {
    --tw-gradient-to: #8C9EFF
  }

  .\32xl\:to-indigo-A200 {
    --tw-gradient-to: #536DFE
  }

  .\32xl\:to-indigo-A400 {
    --tw-gradient-to: #3D5AFE
  }

  .\32xl\:to-indigo-A700 {
    --tw-gradient-to: #304FFE
  }

  .\32xl\:to-purple-50 {
    --tw-gradient-to: #F3E5F5
  }

  .\32xl\:to-purple-100 {
    --tw-gradient-to: #E1BEE7
  }

  .\32xl\:to-purple-200 {
    --tw-gradient-to: #CE93D8
  }

  .\32xl\:to-purple-300 {
    --tw-gradient-to: #BA68C8
  }

  .\32xl\:to-purple-400 {
    --tw-gradient-to: #AB47BC
  }

  .\32xl\:to-purple-500 {
    --tw-gradient-to: #9C27B0
  }

  .\32xl\:to-purple-600 {
    --tw-gradient-to: #8E24AA
  }

  .\32xl\:to-purple-700 {
    --tw-gradient-to: #7B1FA2
  }

  .\32xl\:to-purple-800 {
    --tw-gradient-to: #6A1B9A
  }

  .\32xl\:to-purple-900 {
    --tw-gradient-to: #4A148C
  }

  .\32xl\:to-purple-default {
    --tw-gradient-to: #9C27B0
  }

  .\32xl\:to-purple-A100 {
    --tw-gradient-to: #EA80FC
  }

  .\32xl\:to-purple-A200 {
    --tw-gradient-to: #E040FB
  }

  .\32xl\:to-purple-A400 {
    --tw-gradient-to: #D500F9
  }

  .\32xl\:to-purple-A700 {
    --tw-gradient-to: #AA00FF
  }

  .\32xl\:to-pink-50 {
    --tw-gradient-to: #FCE4EC
  }

  .\32xl\:to-pink-100 {
    --tw-gradient-to: #F8BBD0
  }

  .\32xl\:to-pink-200 {
    --tw-gradient-to: #F48FB1
  }

  .\32xl\:to-pink-300 {
    --tw-gradient-to: #F06292
  }

  .\32xl\:to-pink-400 {
    --tw-gradient-to: #EC407A
  }

  .\32xl\:to-pink-500 {
    --tw-gradient-to: #E91E63
  }

  .\32xl\:to-pink-600 {
    --tw-gradient-to: #D81B60
  }

  .\32xl\:to-pink-700 {
    --tw-gradient-to: #C2185B
  }

  .\32xl\:to-pink-800 {
    --tw-gradient-to: #AD1457
  }

  .\32xl\:to-pink-900 {
    --tw-gradient-to: #880E4F
  }

  .\32xl\:to-pink-default {
    --tw-gradient-to: #E91E63
  }

  .\32xl\:to-pink-A100 {
    --tw-gradient-to: #FF80AB
  }

  .\32xl\:to-pink-A200 {
    --tw-gradient-to: #FF4081
  }

  .\32xl\:to-pink-A400 {
    --tw-gradient-to: #F50057
  }

  .\32xl\:to-pink-A700 {
    --tw-gradient-to: #C51162
  }

  .\32xl\:to-grey-50 {
    --tw-gradient-to: #FAFAFA
  }

  .\32xl\:to-grey-100 {
    --tw-gradient-to: #F5F5F5
  }

  .\32xl\:to-grey-200 {
    --tw-gradient-to: #EEEEEE
  }

  .\32xl\:to-grey-300 {
    --tw-gradient-to: #E0E0E0
  }

  .\32xl\:to-grey-400 {
    --tw-gradient-to: #BDBDBD
  }

  .\32xl\:to-grey-500 {
    --tw-gradient-to: #9E9E9E
  }

  .\32xl\:to-grey-600 {
    --tw-gradient-to: #757575
  }

  .\32xl\:to-grey-700 {
    --tw-gradient-to: #616161
  }

  .\32xl\:to-grey-800 {
    --tw-gradient-to: #424242
  }

  .\32xl\:to-grey-900 {
    --tw-gradient-to: #212121
  }

  .\32xl\:to-grey-default {
    --tw-gradient-to: #9E9E9E
  }

  .\32xl\:to-grey-A100 {
    --tw-gradient-to: #D5D5D5
  }

  .\32xl\:to-grey-A200 {
    --tw-gradient-to: #AAAAAA
  }

  .\32xl\:to-grey-A400 {
    --tw-gradient-to: #303030
  }

  .\32xl\:to-grey-A700 {
    --tw-gradient-to: #616161
  }

  .\32xl\:to-orange-50 {
    --tw-gradient-to: #FFF3E0
  }

  .\32xl\:to-orange-100 {
    --tw-gradient-to: #FFE0B2
  }

  .\32xl\:to-orange-200 {
    --tw-gradient-to: #FFCC80
  }

  .\32xl\:to-orange-300 {
    --tw-gradient-to: #FFB74D
  }

  .\32xl\:to-orange-400 {
    --tw-gradient-to: #FFA726
  }

  .\32xl\:to-orange-500 {
    --tw-gradient-to: #FF9800
  }

  .\32xl\:to-orange-600 {
    --tw-gradient-to: #FB8C00
  }

  .\32xl\:to-orange-700 {
    --tw-gradient-to: #F57C00
  }

  .\32xl\:to-orange-800 {
    --tw-gradient-to: #EF6C00
  }

  .\32xl\:to-orange-900 {
    --tw-gradient-to: #E65100
  }

  .\32xl\:to-orange-default {
    --tw-gradient-to: #FF9800
  }

  .\32xl\:to-orange-A100 {
    --tw-gradient-to: #FFD180
  }

  .\32xl\:to-orange-A200 {
    --tw-gradient-to: #FFAB40
  }

  .\32xl\:to-orange-A400 {
    --tw-gradient-to: #FF9100
  }

  .\32xl\:to-orange-A700 {
    --tw-gradient-to: #FF6D00
  }

  .\32xl\:to-deep-orange-50 {
    --tw-gradient-to: #FBE9E7
  }

  .\32xl\:to-deep-orange-100 {
    --tw-gradient-to: #FFCCBC
  }

  .\32xl\:to-deep-orange-200 {
    --tw-gradient-to: #FFAB91
  }

  .\32xl\:to-deep-orange-300 {
    --tw-gradient-to: #FF8A65
  }

  .\32xl\:to-deep-orange-400 {
    --tw-gradient-to: #FF7043
  }

  .\32xl\:to-deep-orange-500 {
    --tw-gradient-to: #FF5722
  }

  .\32xl\:to-deep-orange-600 {
    --tw-gradient-to: #F4511E
  }

  .\32xl\:to-deep-orange-700 {
    --tw-gradient-to: #E64A19
  }

  .\32xl\:to-deep-orange-800 {
    --tw-gradient-to: #D84315
  }

  .\32xl\:to-deep-orange-900 {
    --tw-gradient-to: #BF360C
  }

  .\32xl\:to-deep-orange-default {
    --tw-gradient-to: #FF5722
  }

  .\32xl\:to-deep-orange-A100 {
    --tw-gradient-to: #FF9E80
  }

  .\32xl\:to-deep-orange-A200 {
    --tw-gradient-to: #FF6E40
  }

  .\32xl\:to-deep-orange-A400 {
    --tw-gradient-to: #FF3D00
  }

  .\32xl\:to-deep-orange-A700 {
    --tw-gradient-to: #DD2C00
  }

  .\32xl\:to-light-green-50 {
    --tw-gradient-to: #F1F8E9
  }

  .\32xl\:to-light-green-100 {
    --tw-gradient-to: #DCEDC8
  }

  .\32xl\:to-light-green-200 {
    --tw-gradient-to: #C5E1A5
  }

  .\32xl\:to-light-green-300 {
    --tw-gradient-to: #AED581
  }

  .\32xl\:to-light-green-400 {
    --tw-gradient-to: #9CCC65
  }

  .\32xl\:to-light-green-500 {
    --tw-gradient-to: #8BC34A
  }

  .\32xl\:to-light-green-600 {
    --tw-gradient-to: #7CB342
  }

  .\32xl\:to-light-green-700 {
    --tw-gradient-to: #689F38
  }

  .\32xl\:to-light-green-800 {
    --tw-gradient-to: #558B2F
  }

  .\32xl\:to-light-green-900 {
    --tw-gradient-to: #33691E
  }

  .\32xl\:to-light-green-default {
    --tw-gradient-to: #8BC34A
  }

  .\32xl\:to-light-green-A100 {
    --tw-gradient-to: #CCFF90
  }

  .\32xl\:to-light-green-A200 {
    --tw-gradient-to: #B2FF59
  }

  .\32xl\:to-light-green-A400 {
    --tw-gradient-to: #76FF03
  }

  .\32xl\:to-light-green-A700 {
    --tw-gradient-to: #64DD17
  }

  .\32xl\:to-teal-50 {
    --tw-gradient-to: #E0F2F1
  }

  .\32xl\:to-teal-100 {
    --tw-gradient-to: #B2DFDB
  }

  .\32xl\:to-teal-200 {
    --tw-gradient-to: #80CBC4
  }

  .\32xl\:to-teal-300 {
    --tw-gradient-to: #4DB6AC
  }

  .\32xl\:to-teal-400 {
    --tw-gradient-to: #26A69A
  }

  .\32xl\:to-teal-500 {
    --tw-gradient-to: #009688
  }

  .\32xl\:to-teal-600 {
    --tw-gradient-to: #00897B
  }

  .\32xl\:to-teal-700 {
    --tw-gradient-to: #00796B
  }

  .\32xl\:to-teal-800 {
    --tw-gradient-to: #00695C
  }

  .\32xl\:to-teal-900 {
    --tw-gradient-to: #004D40
  }

  .\32xl\:to-teal-default {
    --tw-gradient-to: #009688
  }

  .\32xl\:to-teal-A100 {
    --tw-gradient-to: #A7FFEB
  }

  .\32xl\:to-teal-A200 {
    --tw-gradient-to: #64FFDA
  }

  .\32xl\:to-teal-A400 {
    --tw-gradient-to: #1DE9B6
  }

  .\32xl\:to-teal-A700 {
    --tw-gradient-to: #00BFA5
  }

  .\32xl\:to-light-blue-50 {
    --tw-gradient-to: #E1F5FE
  }

  .\32xl\:to-light-blue-100 {
    --tw-gradient-to: #B3E5FC
  }

  .\32xl\:to-light-blue-200 {
    --tw-gradient-to: #81D4FA
  }

  .\32xl\:to-light-blue-300 {
    --tw-gradient-to: #4FC3F7
  }

  .\32xl\:to-light-blue-400 {
    --tw-gradient-to: #29B6F6
  }

  .\32xl\:to-light-blue-500 {
    --tw-gradient-to: #03A9F4
  }

  .\32xl\:to-light-blue-600 {
    --tw-gradient-to: #039BE5
  }

  .\32xl\:to-light-blue-700 {
    --tw-gradient-to: #0288D1
  }

  .\32xl\:to-light-blue-800 {
    --tw-gradient-to: #0277BD
  }

  .\32xl\:to-light-blue-900 {
    --tw-gradient-to: #01579B
  }

  .\32xl\:to-light-blue-default {
    --tw-gradient-to: #03A9F4
  }

  .\32xl\:to-light-blue-A100 {
    --tw-gradient-to: #80D8FF
  }

  .\32xl\:to-light-blue-A200 {
    --tw-gradient-to: #40C4FF
  }

  .\32xl\:to-light-blue-A400 {
    --tw-gradient-to: #00B0FF
  }

  .\32xl\:to-light-blue-A700 {
    --tw-gradient-to: #0091EA
  }

  .\32xl\:to-deep-purple-50 {
    --tw-gradient-to: #EDE7F6
  }

  .\32xl\:to-deep-purple-100 {
    --tw-gradient-to: #D1C4E9
  }

  .\32xl\:to-deep-purple-200 {
    --tw-gradient-to: #B39DDB
  }

  .\32xl\:to-deep-purple-300 {
    --tw-gradient-to: #9575CD
  }

  .\32xl\:to-deep-purple-400 {
    --tw-gradient-to: #7E57C2
  }

  .\32xl\:to-deep-purple-500 {
    --tw-gradient-to: #673AB7
  }

  .\32xl\:to-deep-purple-600 {
    --tw-gradient-to: #5E35B1
  }

  .\32xl\:to-deep-purple-700 {
    --tw-gradient-to: #512DA8
  }

  .\32xl\:to-deep-purple-800 {
    --tw-gradient-to: #4527A0
  }

  .\32xl\:to-deep-purple-900 {
    --tw-gradient-to: #311B92
  }

  .\32xl\:to-deep-purple-default {
    --tw-gradient-to: #673AB7
  }

  .\32xl\:to-deep-purple-A100 {
    --tw-gradient-to: #B388FF
  }

  .\32xl\:to-deep-purple-A200 {
    --tw-gradient-to: #7C4DFF
  }

  .\32xl\:to-deep-purple-A400 {
    --tw-gradient-to: #651FFF
  }

  .\32xl\:to-deep-purple-A700 {
    --tw-gradient-to: #6200EA
  }

  .\32xl\:to-lime-50 {
    --tw-gradient-to: #F9FBE7
  }

  .\32xl\:to-lime-100 {
    --tw-gradient-to: #F0F4C3
  }

  .\32xl\:to-lime-200 {
    --tw-gradient-to: #E6EE9C
  }

  .\32xl\:to-lime-300 {
    --tw-gradient-to: #DCE775
  }

  .\32xl\:to-lime-400 {
    --tw-gradient-to: #D4E157
  }

  .\32xl\:to-lime-500 {
    --tw-gradient-to: #CDDC39
  }

  .\32xl\:to-lime-600 {
    --tw-gradient-to: #C0CA33
  }

  .\32xl\:to-lime-700 {
    --tw-gradient-to: #AFB42B
  }

  .\32xl\:to-lime-800 {
    --tw-gradient-to: #9E9D24
  }

  .\32xl\:to-lime-900 {
    --tw-gradient-to: #827717
  }

  .\32xl\:to-lime-default {
    --tw-gradient-to: #CDDC39
  }

  .\32xl\:to-lime-A100 {
    --tw-gradient-to: #F4FF81
  }

  .\32xl\:to-lime-A200 {
    --tw-gradient-to: #EEFF41
  }

  .\32xl\:to-lime-A400 {
    --tw-gradient-to: #C6FF00
  }

  .\32xl\:to-lime-A700 {
    --tw-gradient-to: #AEEA00
  }

  .\32xl\:to-amber-50 {
    --tw-gradient-to: #FFF8E1
  }

  .\32xl\:to-amber-100 {
    --tw-gradient-to: #FFECB3
  }

  .\32xl\:to-amber-200 {
    --tw-gradient-to: #FFE082
  }

  .\32xl\:to-amber-300 {
    --tw-gradient-to: #FFD54F
  }

  .\32xl\:to-amber-400 {
    --tw-gradient-to: #FFCA28
  }

  .\32xl\:to-amber-500 {
    --tw-gradient-to: #FFC107
  }

  .\32xl\:to-amber-600 {
    --tw-gradient-to: #FFB300
  }

  .\32xl\:to-amber-700 {
    --tw-gradient-to: #FFA000
  }

  .\32xl\:to-amber-800 {
    --tw-gradient-to: #FF8F00
  }

  .\32xl\:to-amber-900 {
    --tw-gradient-to: #FF6F00
  }

  .\32xl\:to-amber-default {
    --tw-gradient-to: #FFC107
  }

  .\32xl\:to-amber-A100 {
    --tw-gradient-to: #FFE57F
  }

  .\32xl\:to-amber-A200 {
    --tw-gradient-to: #FFD740
  }

  .\32xl\:to-amber-A400 {
    --tw-gradient-to: #FFC400
  }

  .\32xl\:to-amber-A700 {
    --tw-gradient-to: #FFAB00
  }

  .\32xl\:to-brown-50 {
    --tw-gradient-to: #EFEBE9
  }

  .\32xl\:to-brown-100 {
    --tw-gradient-to: #D7CCC8
  }

  .\32xl\:to-brown-200 {
    --tw-gradient-to: #BCAAA4
  }

  .\32xl\:to-brown-300 {
    --tw-gradient-to: #A1887F
  }

  .\32xl\:to-brown-400 {
    --tw-gradient-to: #8D6E63
  }

  .\32xl\:to-brown-500 {
    --tw-gradient-to: #795548
  }

  .\32xl\:to-brown-600 {
    --tw-gradient-to: #6D4C41
  }

  .\32xl\:to-brown-700 {
    --tw-gradient-to: #5D4037
  }

  .\32xl\:to-brown-800 {
    --tw-gradient-to: #4E342E
  }

  .\32xl\:to-brown-900 {
    --tw-gradient-to: #3E2723
  }

  .\32xl\:to-brown-default {
    --tw-gradient-to: #795548
  }

  .\32xl\:to-brown-A100 {
    --tw-gradient-to: #D7CCC8
  }

  .\32xl\:to-brown-A200 {
    --tw-gradient-to: #BCAAA4
  }

  .\32xl\:to-brown-A400 {
    --tw-gradient-to: #8D6E63
  }

  .\32xl\:to-brown-A700 {
    --tw-gradient-to: #5D4037
  }

  .\32xl\:to-blue-gray-50 {
    --tw-gradient-to: #ECEFF1
  }

  .\32xl\:to-blue-gray-100 {
    --tw-gradient-to: #CFD8DC
  }

  .\32xl\:to-blue-gray-200 {
    --tw-gradient-to: #B0BEC5
  }

  .\32xl\:to-blue-gray-300 {
    --tw-gradient-to: #90A4AE
  }

  .\32xl\:to-blue-gray-400 {
    --tw-gradient-to: #78909C
  }

  .\32xl\:to-blue-gray-500 {
    --tw-gradient-to: #607D8B
  }

  .\32xl\:to-blue-gray-600 {
    --tw-gradient-to: #546E7A
  }

  .\32xl\:to-blue-gray-700 {
    --tw-gradient-to: #455A64
  }

  .\32xl\:to-blue-gray-800 {
    --tw-gradient-to: #37474F
  }

  .\32xl\:to-blue-gray-900 {
    --tw-gradient-to: #263238
  }

  .\32xl\:to-blue-gray-default {
    --tw-gradient-to: #607D8B
  }

  .\32xl\:to-blue-gray-A100 {
    --tw-gradient-to: #CFD8DC
  }

  .\32xl\:to-blue-gray-A200 {
    --tw-gradient-to: #B0BEC5
  }

  .\32xl\:to-blue-gray-A400 {
    --tw-gradient-to: #78909C
  }

  .\32xl\:to-blue-gray-A700 {
    --tw-gradient-to: #455A64
  }

  .\32xl\:to-cyan-50 {
    --tw-gradient-to: #E0F7FA
  }

  .\32xl\:to-cyan-100 {
    --tw-gradient-to: #B2EBF2
  }

  .\32xl\:to-cyan-200 {
    --tw-gradient-to: #80DEEA
  }

  .\32xl\:to-cyan-300 {
    --tw-gradient-to: #4DD0E1
  }

  .\32xl\:to-cyan-400 {
    --tw-gradient-to: #26C6DA
  }

  .\32xl\:to-cyan-500 {
    --tw-gradient-to: #00BCD4
  }

  .\32xl\:to-cyan-600 {
    --tw-gradient-to: #00ACC1
  }

  .\32xl\:to-cyan-700 {
    --tw-gradient-to: #0097A7
  }

  .\32xl\:to-cyan-800 {
    --tw-gradient-to: #00838F
  }

  .\32xl\:to-cyan-900 {
    --tw-gradient-to: #006064
  }

  .\32xl\:to-cyan-default {
    --tw-gradient-to: #00BCD4
  }

  .\32xl\:to-cyan-A100 {
    --tw-gradient-to: #84FFFF
  }

  .\32xl\:to-cyan-A200 {
    --tw-gradient-to: #18FFFF
  }

  .\32xl\:to-cyan-A400 {
    --tw-gradient-to: #00E5FF
  }

  .\32xl\:to-cyan-A700 {
    --tw-gradient-to: #00B8D4
  }

  .\32xl\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent
  }

  .\32xl\:hover\:to-current:hover {
    --tw-gradient-to: currentColor
  }

  .\32xl\:hover\:to-black:hover {
    --tw-gradient-to: #22292F
  }

  .\32xl\:hover\:to-white:hover {
    --tw-gradient-to: #FFF
  }

  .\32xl\:hover\:to-gray-50:hover {
    --tw-gradient-to: #FAFAFA
  }

  .\32xl\:hover\:to-gray-100:hover {
    --tw-gradient-to: #F5F5F5
  }

  .\32xl\:hover\:to-gray-200:hover {
    --tw-gradient-to: #EEEEEE
  }

  .\32xl\:hover\:to-gray-300:hover {
    --tw-gradient-to: #E0E0E0
  }

  .\32xl\:hover\:to-gray-400:hover {
    --tw-gradient-to: #BDBDBD
  }

  .\32xl\:hover\:to-gray-500:hover {
    --tw-gradient-to: #9E9E9E
  }

  .\32xl\:hover\:to-gray-600:hover {
    --tw-gradient-to: #757575
  }

  .\32xl\:hover\:to-gray-700:hover {
    --tw-gradient-to: #616161
  }

  .\32xl\:hover\:to-gray-800:hover {
    --tw-gradient-to: #424242
  }

  .\32xl\:hover\:to-gray-900:hover {
    --tw-gradient-to: #212121
  }

  .\32xl\:hover\:to-gray-default:hover {
    --tw-gradient-to: #9E9E9E
  }

  .\32xl\:hover\:to-gray-A100:hover {
    --tw-gradient-to: #D5D5D5
  }

  .\32xl\:hover\:to-gray-A200:hover {
    --tw-gradient-to: #AAAAAA
  }

  .\32xl\:hover\:to-gray-A400:hover {
    --tw-gradient-to: #303030
  }

  .\32xl\:hover\:to-gray-A700:hover {
    --tw-gradient-to: #616161
  }

  .\32xl\:hover\:to-red-50:hover {
    --tw-gradient-to: #FFEBEE
  }

  .\32xl\:hover\:to-red-100:hover {
    --tw-gradient-to: #FFCDD2
  }

  .\32xl\:hover\:to-red-200:hover {
    --tw-gradient-to: #EF9A9A
  }

  .\32xl\:hover\:to-red-300:hover {
    --tw-gradient-to: #E57373
  }

  .\32xl\:hover\:to-red-400:hover {
    --tw-gradient-to: #EF5350
  }

  .\32xl\:hover\:to-red-500:hover {
    --tw-gradient-to: #F44336
  }

  .\32xl\:hover\:to-red-600:hover {
    --tw-gradient-to: #E53935
  }

  .\32xl\:hover\:to-red-700:hover {
    --tw-gradient-to: #D32F2F
  }

  .\32xl\:hover\:to-red-800:hover {
    --tw-gradient-to: #C62828
  }

  .\32xl\:hover\:to-red-900:hover {
    --tw-gradient-to: #B71C1C
  }

  .\32xl\:hover\:to-red-default:hover {
    --tw-gradient-to: #F44336
  }

  .\32xl\:hover\:to-red-A100:hover {
    --tw-gradient-to: #FF8A80
  }

  .\32xl\:hover\:to-red-A200:hover {
    --tw-gradient-to: #FF5252
  }

  .\32xl\:hover\:to-red-A400:hover {
    --tw-gradient-to: #FF1744
  }

  .\32xl\:hover\:to-red-A700:hover {
    --tw-gradient-to: #D50000
  }

  .\32xl\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #FFFDE7
  }

  .\32xl\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #FFF9C4
  }

  .\32xl\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #FFF59D
  }

  .\32xl\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #FFF176
  }

  .\32xl\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #FFEE58
  }

  .\32xl\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #FFEB3B
  }

  .\32xl\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #FDD835
  }

  .\32xl\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #FBC02D
  }

  .\32xl\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #F9A825
  }

  .\32xl\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #F57F17
  }

  .\32xl\:hover\:to-yellow-default:hover {
    --tw-gradient-to: #FFEB3B
  }

  .\32xl\:hover\:to-yellow-A100:hover {
    --tw-gradient-to: #FFFF8D
  }

  .\32xl\:hover\:to-yellow-A200:hover {
    --tw-gradient-to: #FFFF00
  }

  .\32xl\:hover\:to-yellow-A400:hover {
    --tw-gradient-to: #FFEA00
  }

  .\32xl\:hover\:to-yellow-A700:hover {
    --tw-gradient-to: #FFD600
  }

  .\32xl\:hover\:to-green-50:hover {
    --tw-gradient-to: #E8F5E9
  }

  .\32xl\:hover\:to-green-100:hover {
    --tw-gradient-to: #C8E6C9
  }

  .\32xl\:hover\:to-green-200:hover {
    --tw-gradient-to: #A5D6A7
  }

  .\32xl\:hover\:to-green-300:hover {
    --tw-gradient-to: #81C784
  }

  .\32xl\:hover\:to-green-400:hover {
    --tw-gradient-to: #66BB6A
  }

  .\32xl\:hover\:to-green-500:hover {
    --tw-gradient-to: #4CAF50
  }

  .\32xl\:hover\:to-green-600:hover {
    --tw-gradient-to: #43A047
  }

  .\32xl\:hover\:to-green-700:hover {
    --tw-gradient-to: #388E3C
  }

  .\32xl\:hover\:to-green-800:hover {
    --tw-gradient-to: #2E7D32
  }

  .\32xl\:hover\:to-green-900:hover {
    --tw-gradient-to: #1B5E20
  }

  .\32xl\:hover\:to-green-default:hover {
    --tw-gradient-to: #4CAF50
  }

  .\32xl\:hover\:to-green-A100:hover {
    --tw-gradient-to: #B9F6CA
  }

  .\32xl\:hover\:to-green-A200:hover {
    --tw-gradient-to: #69F0AE
  }

  .\32xl\:hover\:to-green-A400:hover {
    --tw-gradient-to: #00E676
  }

  .\32xl\:hover\:to-green-A700:hover {
    --tw-gradient-to: #00C853
  }

  .\32xl\:hover\:to-blue-50:hover {
    --tw-gradient-to: #E3F2FD
  }

  .\32xl\:hover\:to-blue-100:hover {
    --tw-gradient-to: #BBDEFB
  }

  .\32xl\:hover\:to-blue-200:hover {
    --tw-gradient-to: #90CAF9
  }

  .\32xl\:hover\:to-blue-300:hover {
    --tw-gradient-to: #64B5F6
  }

  .\32xl\:hover\:to-blue-400:hover {
    --tw-gradient-to: #42A5F5
  }

  .\32xl\:hover\:to-blue-500:hover {
    --tw-gradient-to: #2196F3
  }

  .\32xl\:hover\:to-blue-600:hover {
    --tw-gradient-to: #1E88E5
  }

  .\32xl\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1976D2
  }

  .\32xl\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1565C0
  }

  .\32xl\:hover\:to-blue-900:hover {
    --tw-gradient-to: #0D47A1
  }

  .\32xl\:hover\:to-blue-default:hover {
    --tw-gradient-to: #2196F3
  }

  .\32xl\:hover\:to-blue-A100:hover {
    --tw-gradient-to: #82B1FF
  }

  .\32xl\:hover\:to-blue-A200:hover {
    --tw-gradient-to: #448AFF
  }

  .\32xl\:hover\:to-blue-A400:hover {
    --tw-gradient-to: #2979FF
  }

  .\32xl\:hover\:to-blue-A700:hover {
    --tw-gradient-to: #2962FF
  }

  .\32xl\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #E8EAF6
  }

  .\32xl\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #C5CAE9
  }

  .\32xl\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #9FA8DA
  }

  .\32xl\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #7986CB
  }

  .\32xl\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #5C6BC0
  }

  .\32xl\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #3F51B5
  }

  .\32xl\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #3949AB
  }

  .\32xl\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #303F9F
  }

  .\32xl\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #283593
  }

  .\32xl\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #1A237E
  }

  .\32xl\:hover\:to-indigo-default:hover {
    --tw-gradient-to: #3F51B5
  }

  .\32xl\:hover\:to-indigo-A100:hover {
    --tw-gradient-to: #8C9EFF
  }

  .\32xl\:hover\:to-indigo-A200:hover {
    --tw-gradient-to: #536DFE
  }

  .\32xl\:hover\:to-indigo-A400:hover {
    --tw-gradient-to: #3D5AFE
  }

  .\32xl\:hover\:to-indigo-A700:hover {
    --tw-gradient-to: #304FFE
  }

  .\32xl\:hover\:to-purple-50:hover {
    --tw-gradient-to: #F3E5F5
  }

  .\32xl\:hover\:to-purple-100:hover {
    --tw-gradient-to: #E1BEE7
  }

  .\32xl\:hover\:to-purple-200:hover {
    --tw-gradient-to: #CE93D8
  }

  .\32xl\:hover\:to-purple-300:hover {
    --tw-gradient-to: #BA68C8
  }

  .\32xl\:hover\:to-purple-400:hover {
    --tw-gradient-to: #AB47BC
  }

  .\32xl\:hover\:to-purple-500:hover {
    --tw-gradient-to: #9C27B0
  }

  .\32xl\:hover\:to-purple-600:hover {
    --tw-gradient-to: #8E24AA
  }

  .\32xl\:hover\:to-purple-700:hover {
    --tw-gradient-to: #7B1FA2
  }

  .\32xl\:hover\:to-purple-800:hover {
    --tw-gradient-to: #6A1B9A
  }

  .\32xl\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4A148C
  }

  .\32xl\:hover\:to-purple-default:hover {
    --tw-gradient-to: #9C27B0
  }

  .\32xl\:hover\:to-purple-A100:hover {
    --tw-gradient-to: #EA80FC
  }

  .\32xl\:hover\:to-purple-A200:hover {
    --tw-gradient-to: #E040FB
  }

  .\32xl\:hover\:to-purple-A400:hover {
    --tw-gradient-to: #D500F9
  }

  .\32xl\:hover\:to-purple-A700:hover {
    --tw-gradient-to: #AA00FF
  }

  .\32xl\:hover\:to-pink-50:hover {
    --tw-gradient-to: #FCE4EC
  }

  .\32xl\:hover\:to-pink-100:hover {
    --tw-gradient-to: #F8BBD0
  }

  .\32xl\:hover\:to-pink-200:hover {
    --tw-gradient-to: #F48FB1
  }

  .\32xl\:hover\:to-pink-300:hover {
    --tw-gradient-to: #F06292
  }

  .\32xl\:hover\:to-pink-400:hover {
    --tw-gradient-to: #EC407A
  }

  .\32xl\:hover\:to-pink-500:hover {
    --tw-gradient-to: #E91E63
  }

  .\32xl\:hover\:to-pink-600:hover {
    --tw-gradient-to: #D81B60
  }

  .\32xl\:hover\:to-pink-700:hover {
    --tw-gradient-to: #C2185B
  }

  .\32xl\:hover\:to-pink-800:hover {
    --tw-gradient-to: #AD1457
  }

  .\32xl\:hover\:to-pink-900:hover {
    --tw-gradient-to: #880E4F
  }

  .\32xl\:hover\:to-pink-default:hover {
    --tw-gradient-to: #E91E63
  }

  .\32xl\:hover\:to-pink-A100:hover {
    --tw-gradient-to: #FF80AB
  }

  .\32xl\:hover\:to-pink-A200:hover {
    --tw-gradient-to: #FF4081
  }

  .\32xl\:hover\:to-pink-A400:hover {
    --tw-gradient-to: #F50057
  }

  .\32xl\:hover\:to-pink-A700:hover {
    --tw-gradient-to: #C51162
  }

  .\32xl\:hover\:to-grey-50:hover {
    --tw-gradient-to: #FAFAFA
  }

  .\32xl\:hover\:to-grey-100:hover {
    --tw-gradient-to: #F5F5F5
  }

  .\32xl\:hover\:to-grey-200:hover {
    --tw-gradient-to: #EEEEEE
  }

  .\32xl\:hover\:to-grey-300:hover {
    --tw-gradient-to: #E0E0E0
  }

  .\32xl\:hover\:to-grey-400:hover {
    --tw-gradient-to: #BDBDBD
  }

  .\32xl\:hover\:to-grey-500:hover {
    --tw-gradient-to: #9E9E9E
  }

  .\32xl\:hover\:to-grey-600:hover {
    --tw-gradient-to: #757575
  }

  .\32xl\:hover\:to-grey-700:hover {
    --tw-gradient-to: #616161
  }

  .\32xl\:hover\:to-grey-800:hover {
    --tw-gradient-to: #424242
  }

  .\32xl\:hover\:to-grey-900:hover {
    --tw-gradient-to: #212121
  }

  .\32xl\:hover\:to-grey-default:hover {
    --tw-gradient-to: #9E9E9E
  }

  .\32xl\:hover\:to-grey-A100:hover {
    --tw-gradient-to: #D5D5D5
  }

  .\32xl\:hover\:to-grey-A200:hover {
    --tw-gradient-to: #AAAAAA
  }

  .\32xl\:hover\:to-grey-A400:hover {
    --tw-gradient-to: #303030
  }

  .\32xl\:hover\:to-grey-A700:hover {
    --tw-gradient-to: #616161
  }

  .\32xl\:hover\:to-orange-50:hover {
    --tw-gradient-to: #FFF3E0
  }

  .\32xl\:hover\:to-orange-100:hover {
    --tw-gradient-to: #FFE0B2
  }

  .\32xl\:hover\:to-orange-200:hover {
    --tw-gradient-to: #FFCC80
  }

  .\32xl\:hover\:to-orange-300:hover {
    --tw-gradient-to: #FFB74D
  }

  .\32xl\:hover\:to-orange-400:hover {
    --tw-gradient-to: #FFA726
  }

  .\32xl\:hover\:to-orange-500:hover {
    --tw-gradient-to: #FF9800
  }

  .\32xl\:hover\:to-orange-600:hover {
    --tw-gradient-to: #FB8C00
  }

  .\32xl\:hover\:to-orange-700:hover {
    --tw-gradient-to: #F57C00
  }

  .\32xl\:hover\:to-orange-800:hover {
    --tw-gradient-to: #EF6C00
  }

  .\32xl\:hover\:to-orange-900:hover {
    --tw-gradient-to: #E65100
  }

  .\32xl\:hover\:to-orange-default:hover {
    --tw-gradient-to: #FF9800
  }

  .\32xl\:hover\:to-orange-A100:hover {
    --tw-gradient-to: #FFD180
  }

  .\32xl\:hover\:to-orange-A200:hover {
    --tw-gradient-to: #FFAB40
  }

  .\32xl\:hover\:to-orange-A400:hover {
    --tw-gradient-to: #FF9100
  }

  .\32xl\:hover\:to-orange-A700:hover {
    --tw-gradient-to: #FF6D00
  }

  .\32xl\:hover\:to-deep-orange-50:hover {
    --tw-gradient-to: #FBE9E7
  }

  .\32xl\:hover\:to-deep-orange-100:hover {
    --tw-gradient-to: #FFCCBC
  }

  .\32xl\:hover\:to-deep-orange-200:hover {
    --tw-gradient-to: #FFAB91
  }

  .\32xl\:hover\:to-deep-orange-300:hover {
    --tw-gradient-to: #FF8A65
  }

  .\32xl\:hover\:to-deep-orange-400:hover {
    --tw-gradient-to: #FF7043
  }

  .\32xl\:hover\:to-deep-orange-500:hover {
    --tw-gradient-to: #FF5722
  }

  .\32xl\:hover\:to-deep-orange-600:hover {
    --tw-gradient-to: #F4511E
  }

  .\32xl\:hover\:to-deep-orange-700:hover {
    --tw-gradient-to: #E64A19
  }

  .\32xl\:hover\:to-deep-orange-800:hover {
    --tw-gradient-to: #D84315
  }

  .\32xl\:hover\:to-deep-orange-900:hover {
    --tw-gradient-to: #BF360C
  }

  .\32xl\:hover\:to-deep-orange-default:hover {
    --tw-gradient-to: #FF5722
  }

  .\32xl\:hover\:to-deep-orange-A100:hover {
    --tw-gradient-to: #FF9E80
  }

  .\32xl\:hover\:to-deep-orange-A200:hover {
    --tw-gradient-to: #FF6E40
  }

  .\32xl\:hover\:to-deep-orange-A400:hover {
    --tw-gradient-to: #FF3D00
  }

  .\32xl\:hover\:to-deep-orange-A700:hover {
    --tw-gradient-to: #DD2C00
  }

  .\32xl\:hover\:to-light-green-50:hover {
    --tw-gradient-to: #F1F8E9
  }

  .\32xl\:hover\:to-light-green-100:hover {
    --tw-gradient-to: #DCEDC8
  }

  .\32xl\:hover\:to-light-green-200:hover {
    --tw-gradient-to: #C5E1A5
  }

  .\32xl\:hover\:to-light-green-300:hover {
    --tw-gradient-to: #AED581
  }

  .\32xl\:hover\:to-light-green-400:hover {
    --tw-gradient-to: #9CCC65
  }

  .\32xl\:hover\:to-light-green-500:hover {
    --tw-gradient-to: #8BC34A
  }

  .\32xl\:hover\:to-light-green-600:hover {
    --tw-gradient-to: #7CB342
  }

  .\32xl\:hover\:to-light-green-700:hover {
    --tw-gradient-to: #689F38
  }

  .\32xl\:hover\:to-light-green-800:hover {
    --tw-gradient-to: #558B2F
  }

  .\32xl\:hover\:to-light-green-900:hover {
    --tw-gradient-to: #33691E
  }

  .\32xl\:hover\:to-light-green-default:hover {
    --tw-gradient-to: #8BC34A
  }

  .\32xl\:hover\:to-light-green-A100:hover {
    --tw-gradient-to: #CCFF90
  }

  .\32xl\:hover\:to-light-green-A200:hover {
    --tw-gradient-to: #B2FF59
  }

  .\32xl\:hover\:to-light-green-A400:hover {
    --tw-gradient-to: #76FF03
  }

  .\32xl\:hover\:to-light-green-A700:hover {
    --tw-gradient-to: #64DD17
  }

  .\32xl\:hover\:to-teal-50:hover {
    --tw-gradient-to: #E0F2F1
  }

  .\32xl\:hover\:to-teal-100:hover {
    --tw-gradient-to: #B2DFDB
  }

  .\32xl\:hover\:to-teal-200:hover {
    --tw-gradient-to: #80CBC4
  }

  .\32xl\:hover\:to-teal-300:hover {
    --tw-gradient-to: #4DB6AC
  }

  .\32xl\:hover\:to-teal-400:hover {
    --tw-gradient-to: #26A69A
  }

  .\32xl\:hover\:to-teal-500:hover {
    --tw-gradient-to: #009688
  }

  .\32xl\:hover\:to-teal-600:hover {
    --tw-gradient-to: #00897B
  }

  .\32xl\:hover\:to-teal-700:hover {
    --tw-gradient-to: #00796B
  }

  .\32xl\:hover\:to-teal-800:hover {
    --tw-gradient-to: #00695C
  }

  .\32xl\:hover\:to-teal-900:hover {
    --tw-gradient-to: #004D40
  }

  .\32xl\:hover\:to-teal-default:hover {
    --tw-gradient-to: #009688
  }

  .\32xl\:hover\:to-teal-A100:hover {
    --tw-gradient-to: #A7FFEB
  }

  .\32xl\:hover\:to-teal-A200:hover {
    --tw-gradient-to: #64FFDA
  }

  .\32xl\:hover\:to-teal-A400:hover {
    --tw-gradient-to: #1DE9B6
  }

  .\32xl\:hover\:to-teal-A700:hover {
    --tw-gradient-to: #00BFA5
  }

  .\32xl\:hover\:to-light-blue-50:hover {
    --tw-gradient-to: #E1F5FE
  }

  .\32xl\:hover\:to-light-blue-100:hover {
    --tw-gradient-to: #B3E5FC
  }

  .\32xl\:hover\:to-light-blue-200:hover {
    --tw-gradient-to: #81D4FA
  }

  .\32xl\:hover\:to-light-blue-300:hover {
    --tw-gradient-to: #4FC3F7
  }

  .\32xl\:hover\:to-light-blue-400:hover {
    --tw-gradient-to: #29B6F6
  }

  .\32xl\:hover\:to-light-blue-500:hover {
    --tw-gradient-to: #03A9F4
  }

  .\32xl\:hover\:to-light-blue-600:hover {
    --tw-gradient-to: #039BE5
  }

  .\32xl\:hover\:to-light-blue-700:hover {
    --tw-gradient-to: #0288D1
  }

  .\32xl\:hover\:to-light-blue-800:hover {
    --tw-gradient-to: #0277BD
  }

  .\32xl\:hover\:to-light-blue-900:hover {
    --tw-gradient-to: #01579B
  }

  .\32xl\:hover\:to-light-blue-default:hover {
    --tw-gradient-to: #03A9F4
  }

  .\32xl\:hover\:to-light-blue-A100:hover {
    --tw-gradient-to: #80D8FF
  }

  .\32xl\:hover\:to-light-blue-A200:hover {
    --tw-gradient-to: #40C4FF
  }

  .\32xl\:hover\:to-light-blue-A400:hover {
    --tw-gradient-to: #00B0FF
  }

  .\32xl\:hover\:to-light-blue-A700:hover {
    --tw-gradient-to: #0091EA
  }

  .\32xl\:hover\:to-deep-purple-50:hover {
    --tw-gradient-to: #EDE7F6
  }

  .\32xl\:hover\:to-deep-purple-100:hover {
    --tw-gradient-to: #D1C4E9
  }

  .\32xl\:hover\:to-deep-purple-200:hover {
    --tw-gradient-to: #B39DDB
  }

  .\32xl\:hover\:to-deep-purple-300:hover {
    --tw-gradient-to: #9575CD
  }

  .\32xl\:hover\:to-deep-purple-400:hover {
    --tw-gradient-to: #7E57C2
  }

  .\32xl\:hover\:to-deep-purple-500:hover {
    --tw-gradient-to: #673AB7
  }

  .\32xl\:hover\:to-deep-purple-600:hover {
    --tw-gradient-to: #5E35B1
  }

  .\32xl\:hover\:to-deep-purple-700:hover {
    --tw-gradient-to: #512DA8
  }

  .\32xl\:hover\:to-deep-purple-800:hover {
    --tw-gradient-to: #4527A0
  }

  .\32xl\:hover\:to-deep-purple-900:hover {
    --tw-gradient-to: #311B92
  }

  .\32xl\:hover\:to-deep-purple-default:hover {
    --tw-gradient-to: #673AB7
  }

  .\32xl\:hover\:to-deep-purple-A100:hover {
    --tw-gradient-to: #B388FF
  }

  .\32xl\:hover\:to-deep-purple-A200:hover {
    --tw-gradient-to: #7C4DFF
  }

  .\32xl\:hover\:to-deep-purple-A400:hover {
    --tw-gradient-to: #651FFF
  }

  .\32xl\:hover\:to-deep-purple-A700:hover {
    --tw-gradient-to: #6200EA
  }

  .\32xl\:hover\:to-lime-50:hover {
    --tw-gradient-to: #F9FBE7
  }

  .\32xl\:hover\:to-lime-100:hover {
    --tw-gradient-to: #F0F4C3
  }

  .\32xl\:hover\:to-lime-200:hover {
    --tw-gradient-to: #E6EE9C
  }

  .\32xl\:hover\:to-lime-300:hover {
    --tw-gradient-to: #DCE775
  }

  .\32xl\:hover\:to-lime-400:hover {
    --tw-gradient-to: #D4E157
  }

  .\32xl\:hover\:to-lime-500:hover {
    --tw-gradient-to: #CDDC39
  }

  .\32xl\:hover\:to-lime-600:hover {
    --tw-gradient-to: #C0CA33
  }

  .\32xl\:hover\:to-lime-700:hover {
    --tw-gradient-to: #AFB42B
  }

  .\32xl\:hover\:to-lime-800:hover {
    --tw-gradient-to: #9E9D24
  }

  .\32xl\:hover\:to-lime-900:hover {
    --tw-gradient-to: #827717
  }

  .\32xl\:hover\:to-lime-default:hover {
    --tw-gradient-to: #CDDC39
  }

  .\32xl\:hover\:to-lime-A100:hover {
    --tw-gradient-to: #F4FF81
  }

  .\32xl\:hover\:to-lime-A200:hover {
    --tw-gradient-to: #EEFF41
  }

  .\32xl\:hover\:to-lime-A400:hover {
    --tw-gradient-to: #C6FF00
  }

  .\32xl\:hover\:to-lime-A700:hover {
    --tw-gradient-to: #AEEA00
  }

  .\32xl\:hover\:to-amber-50:hover {
    --tw-gradient-to: #FFF8E1
  }

  .\32xl\:hover\:to-amber-100:hover {
    --tw-gradient-to: #FFECB3
  }

  .\32xl\:hover\:to-amber-200:hover {
    --tw-gradient-to: #FFE082
  }

  .\32xl\:hover\:to-amber-300:hover {
    --tw-gradient-to: #FFD54F
  }

  .\32xl\:hover\:to-amber-400:hover {
    --tw-gradient-to: #FFCA28
  }

  .\32xl\:hover\:to-amber-500:hover {
    --tw-gradient-to: #FFC107
  }

  .\32xl\:hover\:to-amber-600:hover {
    --tw-gradient-to: #FFB300
  }

  .\32xl\:hover\:to-amber-700:hover {
    --tw-gradient-to: #FFA000
  }

  .\32xl\:hover\:to-amber-800:hover {
    --tw-gradient-to: #FF8F00
  }

  .\32xl\:hover\:to-amber-900:hover {
    --tw-gradient-to: #FF6F00
  }

  .\32xl\:hover\:to-amber-default:hover {
    --tw-gradient-to: #FFC107
  }

  .\32xl\:hover\:to-amber-A100:hover {
    --tw-gradient-to: #FFE57F
  }

  .\32xl\:hover\:to-amber-A200:hover {
    --tw-gradient-to: #FFD740
  }

  .\32xl\:hover\:to-amber-A400:hover {
    --tw-gradient-to: #FFC400
  }

  .\32xl\:hover\:to-amber-A700:hover {
    --tw-gradient-to: #FFAB00
  }

  .\32xl\:hover\:to-brown-50:hover {
    --tw-gradient-to: #EFEBE9
  }

  .\32xl\:hover\:to-brown-100:hover {
    --tw-gradient-to: #D7CCC8
  }

  .\32xl\:hover\:to-brown-200:hover {
    --tw-gradient-to: #BCAAA4
  }

  .\32xl\:hover\:to-brown-300:hover {
    --tw-gradient-to: #A1887F
  }

  .\32xl\:hover\:to-brown-400:hover {
    --tw-gradient-to: #8D6E63
  }

  .\32xl\:hover\:to-brown-500:hover {
    --tw-gradient-to: #795548
  }

  .\32xl\:hover\:to-brown-600:hover {
    --tw-gradient-to: #6D4C41
  }

  .\32xl\:hover\:to-brown-700:hover {
    --tw-gradient-to: #5D4037
  }

  .\32xl\:hover\:to-brown-800:hover {
    --tw-gradient-to: #4E342E
  }

  .\32xl\:hover\:to-brown-900:hover {
    --tw-gradient-to: #3E2723
  }

  .\32xl\:hover\:to-brown-default:hover {
    --tw-gradient-to: #795548
  }

  .\32xl\:hover\:to-brown-A100:hover {
    --tw-gradient-to: #D7CCC8
  }

  .\32xl\:hover\:to-brown-A200:hover {
    --tw-gradient-to: #BCAAA4
  }

  .\32xl\:hover\:to-brown-A400:hover {
    --tw-gradient-to: #8D6E63
  }

  .\32xl\:hover\:to-brown-A700:hover {
    --tw-gradient-to: #5D4037
  }

  .\32xl\:hover\:to-blue-gray-50:hover {
    --tw-gradient-to: #ECEFF1
  }

  .\32xl\:hover\:to-blue-gray-100:hover {
    --tw-gradient-to: #CFD8DC
  }

  .\32xl\:hover\:to-blue-gray-200:hover {
    --tw-gradient-to: #B0BEC5
  }

  .\32xl\:hover\:to-blue-gray-300:hover {
    --tw-gradient-to: #90A4AE
  }

  .\32xl\:hover\:to-blue-gray-400:hover {
    --tw-gradient-to: #78909C
  }

  .\32xl\:hover\:to-blue-gray-500:hover {
    --tw-gradient-to: #607D8B
  }

  .\32xl\:hover\:to-blue-gray-600:hover {
    --tw-gradient-to: #546E7A
  }

  .\32xl\:hover\:to-blue-gray-700:hover {
    --tw-gradient-to: #455A64
  }

  .\32xl\:hover\:to-blue-gray-800:hover {
    --tw-gradient-to: #37474F
  }

  .\32xl\:hover\:to-blue-gray-900:hover {
    --tw-gradient-to: #263238
  }

  .\32xl\:hover\:to-blue-gray-default:hover {
    --tw-gradient-to: #607D8B
  }

  .\32xl\:hover\:to-blue-gray-A100:hover {
    --tw-gradient-to: #CFD8DC
  }

  .\32xl\:hover\:to-blue-gray-A200:hover {
    --tw-gradient-to: #B0BEC5
  }

  .\32xl\:hover\:to-blue-gray-A400:hover {
    --tw-gradient-to: #78909C
  }

  .\32xl\:hover\:to-blue-gray-A700:hover {
    --tw-gradient-to: #455A64
  }

  .\32xl\:hover\:to-cyan-50:hover {
    --tw-gradient-to: #E0F7FA
  }

  .\32xl\:hover\:to-cyan-100:hover {
    --tw-gradient-to: #B2EBF2
  }

  .\32xl\:hover\:to-cyan-200:hover {
    --tw-gradient-to: #80DEEA
  }

  .\32xl\:hover\:to-cyan-300:hover {
    --tw-gradient-to: #4DD0E1
  }

  .\32xl\:hover\:to-cyan-400:hover {
    --tw-gradient-to: #26C6DA
  }

  .\32xl\:hover\:to-cyan-500:hover {
    --tw-gradient-to: #00BCD4
  }

  .\32xl\:hover\:to-cyan-600:hover {
    --tw-gradient-to: #00ACC1
  }

  .\32xl\:hover\:to-cyan-700:hover {
    --tw-gradient-to: #0097A7
  }

  .\32xl\:hover\:to-cyan-800:hover {
    --tw-gradient-to: #00838F
  }

  .\32xl\:hover\:to-cyan-900:hover {
    --tw-gradient-to: #006064
  }

  .\32xl\:hover\:to-cyan-default:hover {
    --tw-gradient-to: #00BCD4
  }

  .\32xl\:hover\:to-cyan-A100:hover {
    --tw-gradient-to: #84FFFF
  }

  .\32xl\:hover\:to-cyan-A200:hover {
    --tw-gradient-to: #18FFFF
  }

  .\32xl\:hover\:to-cyan-A400:hover {
    --tw-gradient-to: #00E5FF
  }

  .\32xl\:hover\:to-cyan-A700:hover {
    --tw-gradient-to: #00B8D4
  }

  .\32xl\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent
  }

  .\32xl\:focus\:to-current:focus {
    --tw-gradient-to: currentColor
  }

  .\32xl\:focus\:to-black:focus {
    --tw-gradient-to: #22292F
  }

  .\32xl\:focus\:to-white:focus {
    --tw-gradient-to: #FFF
  }

  .\32xl\:focus\:to-gray-50:focus {
    --tw-gradient-to: #FAFAFA
  }

  .\32xl\:focus\:to-gray-100:focus {
    --tw-gradient-to: #F5F5F5
  }

  .\32xl\:focus\:to-gray-200:focus {
    --tw-gradient-to: #EEEEEE
  }

  .\32xl\:focus\:to-gray-300:focus {
    --tw-gradient-to: #E0E0E0
  }

  .\32xl\:focus\:to-gray-400:focus {
    --tw-gradient-to: #BDBDBD
  }

  .\32xl\:focus\:to-gray-500:focus {
    --tw-gradient-to: #9E9E9E
  }

  .\32xl\:focus\:to-gray-600:focus {
    --tw-gradient-to: #757575
  }

  .\32xl\:focus\:to-gray-700:focus {
    --tw-gradient-to: #616161
  }

  .\32xl\:focus\:to-gray-800:focus {
    --tw-gradient-to: #424242
  }

  .\32xl\:focus\:to-gray-900:focus {
    --tw-gradient-to: #212121
  }

  .\32xl\:focus\:to-gray-default:focus {
    --tw-gradient-to: #9E9E9E
  }

  .\32xl\:focus\:to-gray-A100:focus {
    --tw-gradient-to: #D5D5D5
  }

  .\32xl\:focus\:to-gray-A200:focus {
    --tw-gradient-to: #AAAAAA
  }

  .\32xl\:focus\:to-gray-A400:focus {
    --tw-gradient-to: #303030
  }

  .\32xl\:focus\:to-gray-A700:focus {
    --tw-gradient-to: #616161
  }

  .\32xl\:focus\:to-red-50:focus {
    --tw-gradient-to: #FFEBEE
  }

  .\32xl\:focus\:to-red-100:focus {
    --tw-gradient-to: #FFCDD2
  }

  .\32xl\:focus\:to-red-200:focus {
    --tw-gradient-to: #EF9A9A
  }

  .\32xl\:focus\:to-red-300:focus {
    --tw-gradient-to: #E57373
  }

  .\32xl\:focus\:to-red-400:focus {
    --tw-gradient-to: #EF5350
  }

  .\32xl\:focus\:to-red-500:focus {
    --tw-gradient-to: #F44336
  }

  .\32xl\:focus\:to-red-600:focus {
    --tw-gradient-to: #E53935
  }

  .\32xl\:focus\:to-red-700:focus {
    --tw-gradient-to: #D32F2F
  }

  .\32xl\:focus\:to-red-800:focus {
    --tw-gradient-to: #C62828
  }

  .\32xl\:focus\:to-red-900:focus {
    --tw-gradient-to: #B71C1C
  }

  .\32xl\:focus\:to-red-default:focus {
    --tw-gradient-to: #F44336
  }

  .\32xl\:focus\:to-red-A100:focus {
    --tw-gradient-to: #FF8A80
  }

  .\32xl\:focus\:to-red-A200:focus {
    --tw-gradient-to: #FF5252
  }

  .\32xl\:focus\:to-red-A400:focus {
    --tw-gradient-to: #FF1744
  }

  .\32xl\:focus\:to-red-A700:focus {
    --tw-gradient-to: #D50000
  }

  .\32xl\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #FFFDE7
  }

  .\32xl\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #FFF9C4
  }

  .\32xl\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #FFF59D
  }

  .\32xl\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #FFF176
  }

  .\32xl\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #FFEE58
  }

  .\32xl\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #FFEB3B
  }

  .\32xl\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #FDD835
  }

  .\32xl\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #FBC02D
  }

  .\32xl\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #F9A825
  }

  .\32xl\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #F57F17
  }

  .\32xl\:focus\:to-yellow-default:focus {
    --tw-gradient-to: #FFEB3B
  }

  .\32xl\:focus\:to-yellow-A100:focus {
    --tw-gradient-to: #FFFF8D
  }

  .\32xl\:focus\:to-yellow-A200:focus {
    --tw-gradient-to: #FFFF00
  }

  .\32xl\:focus\:to-yellow-A400:focus {
    --tw-gradient-to: #FFEA00
  }

  .\32xl\:focus\:to-yellow-A700:focus {
    --tw-gradient-to: #FFD600
  }

  .\32xl\:focus\:to-green-50:focus {
    --tw-gradient-to: #E8F5E9
  }

  .\32xl\:focus\:to-green-100:focus {
    --tw-gradient-to: #C8E6C9
  }

  .\32xl\:focus\:to-green-200:focus {
    --tw-gradient-to: #A5D6A7
  }

  .\32xl\:focus\:to-green-300:focus {
    --tw-gradient-to: #81C784
  }

  .\32xl\:focus\:to-green-400:focus {
    --tw-gradient-to: #66BB6A
  }

  .\32xl\:focus\:to-green-500:focus {
    --tw-gradient-to: #4CAF50
  }

  .\32xl\:focus\:to-green-600:focus {
    --tw-gradient-to: #43A047
  }

  .\32xl\:focus\:to-green-700:focus {
    --tw-gradient-to: #388E3C
  }

  .\32xl\:focus\:to-green-800:focus {
    --tw-gradient-to: #2E7D32
  }

  .\32xl\:focus\:to-green-900:focus {
    --tw-gradient-to: #1B5E20
  }

  .\32xl\:focus\:to-green-default:focus {
    --tw-gradient-to: #4CAF50
  }

  .\32xl\:focus\:to-green-A100:focus {
    --tw-gradient-to: #B9F6CA
  }

  .\32xl\:focus\:to-green-A200:focus {
    --tw-gradient-to: #69F0AE
  }

  .\32xl\:focus\:to-green-A400:focus {
    --tw-gradient-to: #00E676
  }

  .\32xl\:focus\:to-green-A700:focus {
    --tw-gradient-to: #00C853
  }

  .\32xl\:focus\:to-blue-50:focus {
    --tw-gradient-to: #E3F2FD
  }

  .\32xl\:focus\:to-blue-100:focus {
    --tw-gradient-to: #BBDEFB
  }

  .\32xl\:focus\:to-blue-200:focus {
    --tw-gradient-to: #90CAF9
  }

  .\32xl\:focus\:to-blue-300:focus {
    --tw-gradient-to: #64B5F6
  }

  .\32xl\:focus\:to-blue-400:focus {
    --tw-gradient-to: #42A5F5
  }

  .\32xl\:focus\:to-blue-500:focus {
    --tw-gradient-to: #2196F3
  }

  .\32xl\:focus\:to-blue-600:focus {
    --tw-gradient-to: #1E88E5
  }

  .\32xl\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1976D2
  }

  .\32xl\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1565C0
  }

  .\32xl\:focus\:to-blue-900:focus {
    --tw-gradient-to: #0D47A1
  }

  .\32xl\:focus\:to-blue-default:focus {
    --tw-gradient-to: #2196F3
  }

  .\32xl\:focus\:to-blue-A100:focus {
    --tw-gradient-to: #82B1FF
  }

  .\32xl\:focus\:to-blue-A200:focus {
    --tw-gradient-to: #448AFF
  }

  .\32xl\:focus\:to-blue-A400:focus {
    --tw-gradient-to: #2979FF
  }

  .\32xl\:focus\:to-blue-A700:focus {
    --tw-gradient-to: #2962FF
  }

  .\32xl\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #E8EAF6
  }

  .\32xl\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #C5CAE9
  }

  .\32xl\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #9FA8DA
  }

  .\32xl\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #7986CB
  }

  .\32xl\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #5C6BC0
  }

  .\32xl\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #3F51B5
  }

  .\32xl\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #3949AB
  }

  .\32xl\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #303F9F
  }

  .\32xl\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #283593
  }

  .\32xl\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #1A237E
  }

  .\32xl\:focus\:to-indigo-default:focus {
    --tw-gradient-to: #3F51B5
  }

  .\32xl\:focus\:to-indigo-A100:focus {
    --tw-gradient-to: #8C9EFF
  }

  .\32xl\:focus\:to-indigo-A200:focus {
    --tw-gradient-to: #536DFE
  }

  .\32xl\:focus\:to-indigo-A400:focus {
    --tw-gradient-to: #3D5AFE
  }

  .\32xl\:focus\:to-indigo-A700:focus {
    --tw-gradient-to: #304FFE
  }

  .\32xl\:focus\:to-purple-50:focus {
    --tw-gradient-to: #F3E5F5
  }

  .\32xl\:focus\:to-purple-100:focus {
    --tw-gradient-to: #E1BEE7
  }

  .\32xl\:focus\:to-purple-200:focus {
    --tw-gradient-to: #CE93D8
  }

  .\32xl\:focus\:to-purple-300:focus {
    --tw-gradient-to: #BA68C8
  }

  .\32xl\:focus\:to-purple-400:focus {
    --tw-gradient-to: #AB47BC
  }

  .\32xl\:focus\:to-purple-500:focus {
    --tw-gradient-to: #9C27B0
  }

  .\32xl\:focus\:to-purple-600:focus {
    --tw-gradient-to: #8E24AA
  }

  .\32xl\:focus\:to-purple-700:focus {
    --tw-gradient-to: #7B1FA2
  }

  .\32xl\:focus\:to-purple-800:focus {
    --tw-gradient-to: #6A1B9A
  }

  .\32xl\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4A148C
  }

  .\32xl\:focus\:to-purple-default:focus {
    --tw-gradient-to: #9C27B0
  }

  .\32xl\:focus\:to-purple-A100:focus {
    --tw-gradient-to: #EA80FC
  }

  .\32xl\:focus\:to-purple-A200:focus {
    --tw-gradient-to: #E040FB
  }

  .\32xl\:focus\:to-purple-A400:focus {
    --tw-gradient-to: #D500F9
  }

  .\32xl\:focus\:to-purple-A700:focus {
    --tw-gradient-to: #AA00FF
  }

  .\32xl\:focus\:to-pink-50:focus {
    --tw-gradient-to: #FCE4EC
  }

  .\32xl\:focus\:to-pink-100:focus {
    --tw-gradient-to: #F8BBD0
  }

  .\32xl\:focus\:to-pink-200:focus {
    --tw-gradient-to: #F48FB1
  }

  .\32xl\:focus\:to-pink-300:focus {
    --tw-gradient-to: #F06292
  }

  .\32xl\:focus\:to-pink-400:focus {
    --tw-gradient-to: #EC407A
  }

  .\32xl\:focus\:to-pink-500:focus {
    --tw-gradient-to: #E91E63
  }

  .\32xl\:focus\:to-pink-600:focus {
    --tw-gradient-to: #D81B60
  }

  .\32xl\:focus\:to-pink-700:focus {
    --tw-gradient-to: #C2185B
  }

  .\32xl\:focus\:to-pink-800:focus {
    --tw-gradient-to: #AD1457
  }

  .\32xl\:focus\:to-pink-900:focus {
    --tw-gradient-to: #880E4F
  }

  .\32xl\:focus\:to-pink-default:focus {
    --tw-gradient-to: #E91E63
  }

  .\32xl\:focus\:to-pink-A100:focus {
    --tw-gradient-to: #FF80AB
  }

  .\32xl\:focus\:to-pink-A200:focus {
    --tw-gradient-to: #FF4081
  }

  .\32xl\:focus\:to-pink-A400:focus {
    --tw-gradient-to: #F50057
  }

  .\32xl\:focus\:to-pink-A700:focus {
    --tw-gradient-to: #C51162
  }

  .\32xl\:focus\:to-grey-50:focus {
    --tw-gradient-to: #FAFAFA
  }

  .\32xl\:focus\:to-grey-100:focus {
    --tw-gradient-to: #F5F5F5
  }

  .\32xl\:focus\:to-grey-200:focus {
    --tw-gradient-to: #EEEEEE
  }

  .\32xl\:focus\:to-grey-300:focus {
    --tw-gradient-to: #E0E0E0
  }

  .\32xl\:focus\:to-grey-400:focus {
    --tw-gradient-to: #BDBDBD
  }

  .\32xl\:focus\:to-grey-500:focus {
    --tw-gradient-to: #9E9E9E
  }

  .\32xl\:focus\:to-grey-600:focus {
    --tw-gradient-to: #757575
  }

  .\32xl\:focus\:to-grey-700:focus {
    --tw-gradient-to: #616161
  }

  .\32xl\:focus\:to-grey-800:focus {
    --tw-gradient-to: #424242
  }

  .\32xl\:focus\:to-grey-900:focus {
    --tw-gradient-to: #212121
  }

  .\32xl\:focus\:to-grey-default:focus {
    --tw-gradient-to: #9E9E9E
  }

  .\32xl\:focus\:to-grey-A100:focus {
    --tw-gradient-to: #D5D5D5
  }

  .\32xl\:focus\:to-grey-A200:focus {
    --tw-gradient-to: #AAAAAA
  }

  .\32xl\:focus\:to-grey-A400:focus {
    --tw-gradient-to: #303030
  }

  .\32xl\:focus\:to-grey-A700:focus {
    --tw-gradient-to: #616161
  }

  .\32xl\:focus\:to-orange-50:focus {
    --tw-gradient-to: #FFF3E0
  }

  .\32xl\:focus\:to-orange-100:focus {
    --tw-gradient-to: #FFE0B2
  }

  .\32xl\:focus\:to-orange-200:focus {
    --tw-gradient-to: #FFCC80
  }

  .\32xl\:focus\:to-orange-300:focus {
    --tw-gradient-to: #FFB74D
  }

  .\32xl\:focus\:to-orange-400:focus {
    --tw-gradient-to: #FFA726
  }

  .\32xl\:focus\:to-orange-500:focus {
    --tw-gradient-to: #FF9800
  }

  .\32xl\:focus\:to-orange-600:focus {
    --tw-gradient-to: #FB8C00
  }

  .\32xl\:focus\:to-orange-700:focus {
    --tw-gradient-to: #F57C00
  }

  .\32xl\:focus\:to-orange-800:focus {
    --tw-gradient-to: #EF6C00
  }

  .\32xl\:focus\:to-orange-900:focus {
    --tw-gradient-to: #E65100
  }

  .\32xl\:focus\:to-orange-default:focus {
    --tw-gradient-to: #FF9800
  }

  .\32xl\:focus\:to-orange-A100:focus {
    --tw-gradient-to: #FFD180
  }

  .\32xl\:focus\:to-orange-A200:focus {
    --tw-gradient-to: #FFAB40
  }

  .\32xl\:focus\:to-orange-A400:focus {
    --tw-gradient-to: #FF9100
  }

  .\32xl\:focus\:to-orange-A700:focus {
    --tw-gradient-to: #FF6D00
  }

  .\32xl\:focus\:to-deep-orange-50:focus {
    --tw-gradient-to: #FBE9E7
  }

  .\32xl\:focus\:to-deep-orange-100:focus {
    --tw-gradient-to: #FFCCBC
  }

  .\32xl\:focus\:to-deep-orange-200:focus {
    --tw-gradient-to: #FFAB91
  }

  .\32xl\:focus\:to-deep-orange-300:focus {
    --tw-gradient-to: #FF8A65
  }

  .\32xl\:focus\:to-deep-orange-400:focus {
    --tw-gradient-to: #FF7043
  }

  .\32xl\:focus\:to-deep-orange-500:focus {
    --tw-gradient-to: #FF5722
  }

  .\32xl\:focus\:to-deep-orange-600:focus {
    --tw-gradient-to: #F4511E
  }

  .\32xl\:focus\:to-deep-orange-700:focus {
    --tw-gradient-to: #E64A19
  }

  .\32xl\:focus\:to-deep-orange-800:focus {
    --tw-gradient-to: #D84315
  }

  .\32xl\:focus\:to-deep-orange-900:focus {
    --tw-gradient-to: #BF360C
  }

  .\32xl\:focus\:to-deep-orange-default:focus {
    --tw-gradient-to: #FF5722
  }

  .\32xl\:focus\:to-deep-orange-A100:focus {
    --tw-gradient-to: #FF9E80
  }

  .\32xl\:focus\:to-deep-orange-A200:focus {
    --tw-gradient-to: #FF6E40
  }

  .\32xl\:focus\:to-deep-orange-A400:focus {
    --tw-gradient-to: #FF3D00
  }

  .\32xl\:focus\:to-deep-orange-A700:focus {
    --tw-gradient-to: #DD2C00
  }

  .\32xl\:focus\:to-light-green-50:focus {
    --tw-gradient-to: #F1F8E9
  }

  .\32xl\:focus\:to-light-green-100:focus {
    --tw-gradient-to: #DCEDC8
  }

  .\32xl\:focus\:to-light-green-200:focus {
    --tw-gradient-to: #C5E1A5
  }

  .\32xl\:focus\:to-light-green-300:focus {
    --tw-gradient-to: #AED581
  }

  .\32xl\:focus\:to-light-green-400:focus {
    --tw-gradient-to: #9CCC65
  }

  .\32xl\:focus\:to-light-green-500:focus {
    --tw-gradient-to: #8BC34A
  }

  .\32xl\:focus\:to-light-green-600:focus {
    --tw-gradient-to: #7CB342
  }

  .\32xl\:focus\:to-light-green-700:focus {
    --tw-gradient-to: #689F38
  }

  .\32xl\:focus\:to-light-green-800:focus {
    --tw-gradient-to: #558B2F
  }

  .\32xl\:focus\:to-light-green-900:focus {
    --tw-gradient-to: #33691E
  }

  .\32xl\:focus\:to-light-green-default:focus {
    --tw-gradient-to: #8BC34A
  }

  .\32xl\:focus\:to-light-green-A100:focus {
    --tw-gradient-to: #CCFF90
  }

  .\32xl\:focus\:to-light-green-A200:focus {
    --tw-gradient-to: #B2FF59
  }

  .\32xl\:focus\:to-light-green-A400:focus {
    --tw-gradient-to: #76FF03
  }

  .\32xl\:focus\:to-light-green-A700:focus {
    --tw-gradient-to: #64DD17
  }

  .\32xl\:focus\:to-teal-50:focus {
    --tw-gradient-to: #E0F2F1
  }

  .\32xl\:focus\:to-teal-100:focus {
    --tw-gradient-to: #B2DFDB
  }

  .\32xl\:focus\:to-teal-200:focus {
    --tw-gradient-to: #80CBC4
  }

  .\32xl\:focus\:to-teal-300:focus {
    --tw-gradient-to: #4DB6AC
  }

  .\32xl\:focus\:to-teal-400:focus {
    --tw-gradient-to: #26A69A
  }

  .\32xl\:focus\:to-teal-500:focus {
    --tw-gradient-to: #009688
  }

  .\32xl\:focus\:to-teal-600:focus {
    --tw-gradient-to: #00897B
  }

  .\32xl\:focus\:to-teal-700:focus {
    --tw-gradient-to: #00796B
  }

  .\32xl\:focus\:to-teal-800:focus {
    --tw-gradient-to: #00695C
  }

  .\32xl\:focus\:to-teal-900:focus {
    --tw-gradient-to: #004D40
  }

  .\32xl\:focus\:to-teal-default:focus {
    --tw-gradient-to: #009688
  }

  .\32xl\:focus\:to-teal-A100:focus {
    --tw-gradient-to: #A7FFEB
  }

  .\32xl\:focus\:to-teal-A200:focus {
    --tw-gradient-to: #64FFDA
  }

  .\32xl\:focus\:to-teal-A400:focus {
    --tw-gradient-to: #1DE9B6
  }

  .\32xl\:focus\:to-teal-A700:focus {
    --tw-gradient-to: #00BFA5
  }

  .\32xl\:focus\:to-light-blue-50:focus {
    --tw-gradient-to: #E1F5FE
  }

  .\32xl\:focus\:to-light-blue-100:focus {
    --tw-gradient-to: #B3E5FC
  }

  .\32xl\:focus\:to-light-blue-200:focus {
    --tw-gradient-to: #81D4FA
  }

  .\32xl\:focus\:to-light-blue-300:focus {
    --tw-gradient-to: #4FC3F7
  }

  .\32xl\:focus\:to-light-blue-400:focus {
    --tw-gradient-to: #29B6F6
  }

  .\32xl\:focus\:to-light-blue-500:focus {
    --tw-gradient-to: #03A9F4
  }

  .\32xl\:focus\:to-light-blue-600:focus {
    --tw-gradient-to: #039BE5
  }

  .\32xl\:focus\:to-light-blue-700:focus {
    --tw-gradient-to: #0288D1
  }

  .\32xl\:focus\:to-light-blue-800:focus {
    --tw-gradient-to: #0277BD
  }

  .\32xl\:focus\:to-light-blue-900:focus {
    --tw-gradient-to: #01579B
  }

  .\32xl\:focus\:to-light-blue-default:focus {
    --tw-gradient-to: #03A9F4
  }

  .\32xl\:focus\:to-light-blue-A100:focus {
    --tw-gradient-to: #80D8FF
  }

  .\32xl\:focus\:to-light-blue-A200:focus {
    --tw-gradient-to: #40C4FF
  }

  .\32xl\:focus\:to-light-blue-A400:focus {
    --tw-gradient-to: #00B0FF
  }

  .\32xl\:focus\:to-light-blue-A700:focus {
    --tw-gradient-to: #0091EA
  }

  .\32xl\:focus\:to-deep-purple-50:focus {
    --tw-gradient-to: #EDE7F6
  }

  .\32xl\:focus\:to-deep-purple-100:focus {
    --tw-gradient-to: #D1C4E9
  }

  .\32xl\:focus\:to-deep-purple-200:focus {
    --tw-gradient-to: #B39DDB
  }

  .\32xl\:focus\:to-deep-purple-300:focus {
    --tw-gradient-to: #9575CD
  }

  .\32xl\:focus\:to-deep-purple-400:focus {
    --tw-gradient-to: #7E57C2
  }

  .\32xl\:focus\:to-deep-purple-500:focus {
    --tw-gradient-to: #673AB7
  }

  .\32xl\:focus\:to-deep-purple-600:focus {
    --tw-gradient-to: #5E35B1
  }

  .\32xl\:focus\:to-deep-purple-700:focus {
    --tw-gradient-to: #512DA8
  }

  .\32xl\:focus\:to-deep-purple-800:focus {
    --tw-gradient-to: #4527A0
  }

  .\32xl\:focus\:to-deep-purple-900:focus {
    --tw-gradient-to: #311B92
  }

  .\32xl\:focus\:to-deep-purple-default:focus {
    --tw-gradient-to: #673AB7
  }

  .\32xl\:focus\:to-deep-purple-A100:focus {
    --tw-gradient-to: #B388FF
  }

  .\32xl\:focus\:to-deep-purple-A200:focus {
    --tw-gradient-to: #7C4DFF
  }

  .\32xl\:focus\:to-deep-purple-A400:focus {
    --tw-gradient-to: #651FFF
  }

  .\32xl\:focus\:to-deep-purple-A700:focus {
    --tw-gradient-to: #6200EA
  }

  .\32xl\:focus\:to-lime-50:focus {
    --tw-gradient-to: #F9FBE7
  }

  .\32xl\:focus\:to-lime-100:focus {
    --tw-gradient-to: #F0F4C3
  }

  .\32xl\:focus\:to-lime-200:focus {
    --tw-gradient-to: #E6EE9C
  }

  .\32xl\:focus\:to-lime-300:focus {
    --tw-gradient-to: #DCE775
  }

  .\32xl\:focus\:to-lime-400:focus {
    --tw-gradient-to: #D4E157
  }

  .\32xl\:focus\:to-lime-500:focus {
    --tw-gradient-to: #CDDC39
  }

  .\32xl\:focus\:to-lime-600:focus {
    --tw-gradient-to: #C0CA33
  }

  .\32xl\:focus\:to-lime-700:focus {
    --tw-gradient-to: #AFB42B
  }

  .\32xl\:focus\:to-lime-800:focus {
    --tw-gradient-to: #9E9D24
  }

  .\32xl\:focus\:to-lime-900:focus {
    --tw-gradient-to: #827717
  }

  .\32xl\:focus\:to-lime-default:focus {
    --tw-gradient-to: #CDDC39
  }

  .\32xl\:focus\:to-lime-A100:focus {
    --tw-gradient-to: #F4FF81
  }

  .\32xl\:focus\:to-lime-A200:focus {
    --tw-gradient-to: #EEFF41
  }

  .\32xl\:focus\:to-lime-A400:focus {
    --tw-gradient-to: #C6FF00
  }

  .\32xl\:focus\:to-lime-A700:focus {
    --tw-gradient-to: #AEEA00
  }

  .\32xl\:focus\:to-amber-50:focus {
    --tw-gradient-to: #FFF8E1
  }

  .\32xl\:focus\:to-amber-100:focus {
    --tw-gradient-to: #FFECB3
  }

  .\32xl\:focus\:to-amber-200:focus {
    --tw-gradient-to: #FFE082
  }

  .\32xl\:focus\:to-amber-300:focus {
    --tw-gradient-to: #FFD54F
  }

  .\32xl\:focus\:to-amber-400:focus {
    --tw-gradient-to: #FFCA28
  }

  .\32xl\:focus\:to-amber-500:focus {
    --tw-gradient-to: #FFC107
  }

  .\32xl\:focus\:to-amber-600:focus {
    --tw-gradient-to: #FFB300
  }

  .\32xl\:focus\:to-amber-700:focus {
    --tw-gradient-to: #FFA000
  }

  .\32xl\:focus\:to-amber-800:focus {
    --tw-gradient-to: #FF8F00
  }

  .\32xl\:focus\:to-amber-900:focus {
    --tw-gradient-to: #FF6F00
  }

  .\32xl\:focus\:to-amber-default:focus {
    --tw-gradient-to: #FFC107
  }

  .\32xl\:focus\:to-amber-A100:focus {
    --tw-gradient-to: #FFE57F
  }

  .\32xl\:focus\:to-amber-A200:focus {
    --tw-gradient-to: #FFD740
  }

  .\32xl\:focus\:to-amber-A400:focus {
    --tw-gradient-to: #FFC400
  }

  .\32xl\:focus\:to-amber-A700:focus {
    --tw-gradient-to: #FFAB00
  }

  .\32xl\:focus\:to-brown-50:focus {
    --tw-gradient-to: #EFEBE9
  }

  .\32xl\:focus\:to-brown-100:focus {
    --tw-gradient-to: #D7CCC8
  }

  .\32xl\:focus\:to-brown-200:focus {
    --tw-gradient-to: #BCAAA4
  }

  .\32xl\:focus\:to-brown-300:focus {
    --tw-gradient-to: #A1887F
  }

  .\32xl\:focus\:to-brown-400:focus {
    --tw-gradient-to: #8D6E63
  }

  .\32xl\:focus\:to-brown-500:focus {
    --tw-gradient-to: #795548
  }

  .\32xl\:focus\:to-brown-600:focus {
    --tw-gradient-to: #6D4C41
  }

  .\32xl\:focus\:to-brown-700:focus {
    --tw-gradient-to: #5D4037
  }

  .\32xl\:focus\:to-brown-800:focus {
    --tw-gradient-to: #4E342E
  }

  .\32xl\:focus\:to-brown-900:focus {
    --tw-gradient-to: #3E2723
  }

  .\32xl\:focus\:to-brown-default:focus {
    --tw-gradient-to: #795548
  }

  .\32xl\:focus\:to-brown-A100:focus {
    --tw-gradient-to: #D7CCC8
  }

  .\32xl\:focus\:to-brown-A200:focus {
    --tw-gradient-to: #BCAAA4
  }

  .\32xl\:focus\:to-brown-A400:focus {
    --tw-gradient-to: #8D6E63
  }

  .\32xl\:focus\:to-brown-A700:focus {
    --tw-gradient-to: #5D4037
  }

  .\32xl\:focus\:to-blue-gray-50:focus {
    --tw-gradient-to: #ECEFF1
  }

  .\32xl\:focus\:to-blue-gray-100:focus {
    --tw-gradient-to: #CFD8DC
  }

  .\32xl\:focus\:to-blue-gray-200:focus {
    --tw-gradient-to: #B0BEC5
  }

  .\32xl\:focus\:to-blue-gray-300:focus {
    --tw-gradient-to: #90A4AE
  }

  .\32xl\:focus\:to-blue-gray-400:focus {
    --tw-gradient-to: #78909C
  }

  .\32xl\:focus\:to-blue-gray-500:focus {
    --tw-gradient-to: #607D8B
  }

  .\32xl\:focus\:to-blue-gray-600:focus {
    --tw-gradient-to: #546E7A
  }

  .\32xl\:focus\:to-blue-gray-700:focus {
    --tw-gradient-to: #455A64
  }

  .\32xl\:focus\:to-blue-gray-800:focus {
    --tw-gradient-to: #37474F
  }

  .\32xl\:focus\:to-blue-gray-900:focus {
    --tw-gradient-to: #263238
  }

  .\32xl\:focus\:to-blue-gray-default:focus {
    --tw-gradient-to: #607D8B
  }

  .\32xl\:focus\:to-blue-gray-A100:focus {
    --tw-gradient-to: #CFD8DC
  }

  .\32xl\:focus\:to-blue-gray-A200:focus {
    --tw-gradient-to: #B0BEC5
  }

  .\32xl\:focus\:to-blue-gray-A400:focus {
    --tw-gradient-to: #78909C
  }

  .\32xl\:focus\:to-blue-gray-A700:focus {
    --tw-gradient-to: #455A64
  }

  .\32xl\:focus\:to-cyan-50:focus {
    --tw-gradient-to: #E0F7FA
  }

  .\32xl\:focus\:to-cyan-100:focus {
    --tw-gradient-to: #B2EBF2
  }

  .\32xl\:focus\:to-cyan-200:focus {
    --tw-gradient-to: #80DEEA
  }

  .\32xl\:focus\:to-cyan-300:focus {
    --tw-gradient-to: #4DD0E1
  }

  .\32xl\:focus\:to-cyan-400:focus {
    --tw-gradient-to: #26C6DA
  }

  .\32xl\:focus\:to-cyan-500:focus {
    --tw-gradient-to: #00BCD4
  }

  .\32xl\:focus\:to-cyan-600:focus {
    --tw-gradient-to: #00ACC1
  }

  .\32xl\:focus\:to-cyan-700:focus {
    --tw-gradient-to: #0097A7
  }

  .\32xl\:focus\:to-cyan-800:focus {
    --tw-gradient-to: #00838F
  }

  .\32xl\:focus\:to-cyan-900:focus {
    --tw-gradient-to: #006064
  }

  .\32xl\:focus\:to-cyan-default:focus {
    --tw-gradient-to: #00BCD4
  }

  .\32xl\:focus\:to-cyan-A100:focus {
    --tw-gradient-to: #84FFFF
  }

  .\32xl\:focus\:to-cyan-A200:focus {
    --tw-gradient-to: #18FFFF
  }

  .\32xl\:focus\:to-cyan-A400:focus {
    --tw-gradient-to: #00E5FF
  }

  .\32xl\:focus\:to-cyan-A700:focus {
    --tw-gradient-to: #00B8D4
  }

  .\32xl\:decoration-slice {
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice
  }

  .\32xl\:decoration-clone {
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone
  }

  .\32xl\:bg-auto {
    background-size: auto
  }

  .\32xl\:bg-cover {
    background-size: cover
  }

  .\32xl\:bg-contain {
    background-size: contain
  }

  .\32xl\:bg-fixed {
    background-attachment: fixed
  }

  .\32xl\:bg-local {
    background-attachment: local
  }

  .\32xl\:bg-scroll {
    background-attachment: scroll
  }

  .\32xl\:bg-clip-border {
    background-clip: border-box
  }

  .\32xl\:bg-clip-padding {
    background-clip: padding-box
  }

  .\32xl\:bg-clip-content {
    background-clip: content-box
  }

  .\32xl\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text
  }

  .\32xl\:bg-bottom {
    background-position: bottom
  }

  .\32xl\:bg-center {
    background-position: center
  }

  .\32xl\:bg-left {
    background-position: left
  }

  .\32xl\:bg-left-bottom {
    background-position: left bottom
  }

  .\32xl\:bg-left-top {
    background-position: left top
  }

  .\32xl\:bg-right {
    background-position: right
  }

  .\32xl\:bg-right-bottom {
    background-position: right bottom
  }

  .\32xl\:bg-right-top {
    background-position: right top
  }

  .\32xl\:bg-top {
    background-position: top
  }

  .\32xl\:bg-repeat {
    background-repeat: repeat
  }

  .\32xl\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .\32xl\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .\32xl\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .\32xl\:bg-repeat-round {
    background-repeat: round
  }

  .\32xl\:bg-repeat-space {
    background-repeat: space
  }

  .\32xl\:bg-origin-border {
    background-origin: border-box
  }

  .\32xl\:bg-origin-padding {
    background-origin: padding-box
  }

  .\32xl\:bg-origin-content {
    background-origin: content-box
  }

  .\32xl\:fill-current {
    fill: currentColor
  }

  .\32xl\:stroke-current {
    stroke: currentColor
  }

  .\32xl\:stroke-0 {
    stroke-width: 0
  }

  .\32xl\:stroke-1 {
    stroke-width: 1
  }

  .\32xl\:stroke-2 {
    stroke-width: 2
  }

  .\32xl\:object-contain {
    object-fit: contain
  }

  .\32xl\:object-cover {
    object-fit: cover
  }

  .\32xl\:object-fill {
    object-fit: fill
  }

  .\32xl\:object-none {
    object-fit: none
  }

  .\32xl\:object-scale-down {
    object-fit: scale-down
  }

  .\32xl\:object-bottom {
    object-position: bottom
  }

  .\32xl\:object-center {
    object-position: center
  }

  .\32xl\:object-left {
    object-position: left
  }

  .\32xl\:object-left-bottom {
    object-position: left bottom
  }

  .\32xl\:object-left-top {
    object-position: left top
  }

  .\32xl\:object-right {
    object-position: right
  }

  .\32xl\:object-right-bottom {
    object-position: right bottom
  }

  .\32xl\:object-right-top {
    object-position: right top
  }

  .\32xl\:object-top {
    object-position: top
  }

  .\32xl\:p-0 {
    padding: 0
  }

  .\32xl\:p-1 {
    padding: 0.25rem
  }

  .\32xl\:p-2 {
    padding: 0.5rem
  }

  .\32xl\:p-3 {
    padding: 0.75rem
  }

  .\32xl\:p-4 {
    padding: 0.4rem
  }

  .\32xl\:p-5 {
    padding: 1.25rem
  }

  .\32xl\:p-6 {
    padding: 0.6rem
  }

  .\32xl\:p-7 {
    padding: 1.75rem
  }

  .\32xl\:p-8 {
    padding: 0.8rem
  }

  .\32xl\:p-9 {
    padding: 2.25rem
  }

  .\32xl\:p-10 {
    padding: 2.5rem
  }

  .\32xl\:p-11 {
    padding: 2.75rem
  }

  .\32xl\:p-12 {
    padding: 1.2rem
  }

  .\32xl\:p-14 {
    padding: 3.5rem
  }

  .\32xl\:p-16 {
    padding: 1.6rem
  }

  .\32xl\:p-20 {
    padding: 2rem
  }

  .\32xl\:p-24 {
    padding: 2.4rem
  }

  .\32xl\:p-28 {
    padding: 2.8rem
  }

  .\32xl\:p-32 {
    padding: 3.2rem
  }

  .\32xl\:p-36 {
    padding: 3.6rem
  }

  .\32xl\:p-40 {
    padding: 4rem
  }

  .\32xl\:p-44 {
    padding: 4.4rem
  }

  .\32xl\:p-48 {
    padding: 4.8rem
  }

  .\32xl\:p-52 {
    padding: 5.2rem
  }

  .\32xl\:p-56 {
    padding: 5.6rem
  }

  .\32xl\:p-60 {
    padding: 6rem
  }

  .\32xl\:p-64 {
    padding: 6.4rem
  }

  .\32xl\:p-68 {
    padding: 6.8rem
  }

  .\32xl\:p-72 {
    padding: 7.2rem
  }

  .\32xl\:p-76 {
    padding: 7.6rem
  }

  .\32xl\:p-80 {
    padding: 8rem
  }

  .\32xl\:p-84 {
    padding: 8.4rem
  }

  .\32xl\:p-88 {
    padding: 8.8rem
  }

  .\32xl\:p-92 {
    padding: 9.2rem
  }

  .\32xl\:p-96 {
    padding: 9.6rem
  }

  .\32xl\:p-128 {
    padding: 12.8rem
  }

  .\32xl\:p-136 {
    padding: 13.6rem
  }

  .\32xl\:p-160 {
    padding: 16rem
  }

  .\32xl\:p-192 {
    padding: 19.2rem
  }

  .\32xl\:p-200 {
    padding: 20rem
  }

  .\32xl\:p-208 {
    padding: 20.8rem
  }

  .\32xl\:p-216 {
    padding: 21.6rem
  }

  .\32xl\:p-224 {
    padding: 22.4rem
  }

  .\32xl\:p-256 {
    padding: 25.6rem
  }

  .\32xl\:p-288 {
    padding: 28.8rem
  }

  .\32xl\:p-320 {
    padding: 32rem
  }

  .\32xl\:p-360 {
    padding: 36rem
  }

  .\32xl\:p-384 {
    padding: 38.4rem
  }

  .\32xl\:p-400 {
    padding: 40rem
  }

  .\32xl\:p-512 {
    padding: 51.2rem
  }

  .\32xl\:p-640 {
    padding: 64rem
  }

  .\32xl\:p-px {
    padding: 1px
  }

  .\32xl\:p-0\.5 {
    padding: 0.125rem
  }

  .\32xl\:p-1\.5 {
    padding: 0.375rem
  }

  .\32xl\:p-2\.5 {
    padding: 0.625rem
  }

  .\32xl\:p-3\.5 {
    padding: 0.875rem
  }

  .\32xl\:p-xs {
    padding: 32rem
  }

  .\32xl\:p-sm {
    padding: 48rem
  }

  .\32xl\:p-md {
    padding: 64rem
  }

  .\32xl\:p-lg {
    padding: 80rem
  }

  .\32xl\:p-xl {
    padding: 96rem
  }

  .\32xl\:p-2xl {
    padding: 112rem
  }

  .\32xl\:p-3xl {
    padding: 128rem
  }

  .\32xl\:p-4xl {
    padding: 144rem
  }

  .\32xl\:p-5xl {
    padding: 160rem
  }

  .\32xl\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .\32xl\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .\32xl\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .\32xl\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  .\32xl\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  .\32xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .\32xl\:px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem
  }

  .\32xl\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem
  }

  .\32xl\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  .\32xl\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem
  }

  .\32xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .\32xl\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem
  }

  .\32xl\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .\32xl\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem
  }

  .\32xl\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  .\32xl\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .\32xl\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .\32xl\:px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem
  }

  .\32xl\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  .\32xl\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  .\32xl\:px-40 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .\32xl\:px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem
  }

  .\32xl\:px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem
  }

  .\32xl\:px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem
  }

  .\32xl\:px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem
  }

  .\32xl\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .\32xl\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  .\32xl\:px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem
  }

  .\32xl\:px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem
  }

  .\32xl\:px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem
  }

  .\32xl\:px-80 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .\32xl\:px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem
  }

  .\32xl\:px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem
  }

  .\32xl\:px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem
  }

  .\32xl\:px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem
  }

  .\32xl\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  .\32xl\:px-136 {
    padding-left: 13.6rem;
    padding-right: 13.6rem
  }

  .\32xl\:px-160 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .\32xl\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  .\32xl\:px-200 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  .\32xl\:px-208 {
    padding-left: 20.8rem;
    padding-right: 20.8rem
  }

  .\32xl\:px-216 {
    padding-left: 21.6rem;
    padding-right: 21.6rem
  }

  .\32xl\:px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem
  }

  .\32xl\:px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem
  }

  .\32xl\:px-288 {
    padding-left: 28.8rem;
    padding-right: 28.8rem
  }

  .\32xl\:px-320 {
    padding-left: 32rem;
    padding-right: 32rem
  }

  .\32xl\:px-360 {
    padding-left: 36rem;
    padding-right: 36rem
  }

  .\32xl\:px-384 {
    padding-left: 38.4rem;
    padding-right: 38.4rem
  }

  .\32xl\:px-400 {
    padding-left: 40rem;
    padding-right: 40rem
  }

  .\32xl\:px-512 {
    padding-left: 51.2rem;
    padding-right: 51.2rem
  }

  .\32xl\:px-640 {
    padding-left: 64rem;
    padding-right: 64rem
  }

  .\32xl\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .\32xl\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem
  }

  .\32xl\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem
  }

  .\32xl\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem
  }

  .\32xl\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem
  }

  .\32xl\:px-xs {
    padding-left: 32rem;
    padding-right: 32rem
  }

  .\32xl\:px-sm {
    padding-left: 48rem;
    padding-right: 48rem
  }

  .\32xl\:px-md {
    padding-left: 64rem;
    padding-right: 64rem
  }

  .\32xl\:px-lg {
    padding-left: 80rem;
    padding-right: 80rem
  }

  .\32xl\:px-xl {
    padding-left: 96rem;
    padding-right: 96rem
  }

  .\32xl\:px-2xl {
    padding-left: 112rem;
    padding-right: 112rem
  }

  .\32xl\:px-3xl {
    padding-left: 128rem;
    padding-right: 128rem
  }

  .\32xl\:px-4xl {
    padding-left: 144rem;
    padding-right: 144rem
  }

  .\32xl\:px-5xl {
    padding-left: 160rem;
    padding-right: 160rem
  }

  .\32xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .\32xl\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .\32xl\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .\32xl\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  .\32xl\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  .\32xl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .\32xl\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  .\32xl\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem
  }

  .\32xl\:py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
  }

  .\32xl\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem
  }

  .\32xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .\32xl\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem
  }

  .\32xl\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  .\32xl\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem
  }

  .\32xl\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  .\32xl\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .\32xl\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  .\32xl\:py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem
  }

  .\32xl\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  .\32xl\:py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem
  }

  .\32xl\:py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .\32xl\:py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem
  }

  .\32xl\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  .\32xl\:py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem
  }

  .\32xl\:py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem
  }

  .\32xl\:py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .\32xl\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  .\32xl\:py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem
  }

  .\32xl\:py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem
  }

  .\32xl\:py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem
  }

  .\32xl\:py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .\32xl\:py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem
  }

  .\32xl\:py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem
  }

  .\32xl\:py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem
  }

  .\32xl\:py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem
  }

  .\32xl\:py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem
  }

  .\32xl\:py-136 {
    padding-top: 13.6rem;
    padding-bottom: 13.6rem
  }

  .\32xl\:py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .\32xl\:py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem
  }

  .\32xl\:py-200 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  .\32xl\:py-208 {
    padding-top: 20.8rem;
    padding-bottom: 20.8rem
  }

  .\32xl\:py-216 {
    padding-top: 21.6rem;
    padding-bottom: 21.6rem
  }

  .\32xl\:py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem
  }

  .\32xl\:py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem
  }

  .\32xl\:py-288 {
    padding-top: 28.8rem;
    padding-bottom: 28.8rem
  }

  .\32xl\:py-320 {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  .\32xl\:py-360 {
    padding-top: 36rem;
    padding-bottom: 36rem
  }

  .\32xl\:py-384 {
    padding-top: 38.4rem;
    padding-bottom: 38.4rem
  }

  .\32xl\:py-400 {
    padding-top: 40rem;
    padding-bottom: 40rem
  }

  .\32xl\:py-512 {
    padding-top: 51.2rem;
    padding-bottom: 51.2rem
  }

  .\32xl\:py-640 {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  .\32xl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .\32xl\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem
  }

  .\32xl\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem
  }

  .\32xl\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem
  }

  .\32xl\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem
  }

  .\32xl\:py-xs {
    padding-top: 32rem;
    padding-bottom: 32rem
  }

  .\32xl\:py-sm {
    padding-top: 48rem;
    padding-bottom: 48rem
  }

  .\32xl\:py-md {
    padding-top: 64rem;
    padding-bottom: 64rem
  }

  .\32xl\:py-lg {
    padding-top: 80rem;
    padding-bottom: 80rem
  }

  .\32xl\:py-xl {
    padding-top: 96rem;
    padding-bottom: 96rem
  }

  .\32xl\:py-2xl {
    padding-top: 112rem;
    padding-bottom: 112rem
  }

  .\32xl\:py-3xl {
    padding-top: 128rem;
    padding-bottom: 128rem
  }

  .\32xl\:py-4xl {
    padding-top: 144rem;
    padding-bottom: 144rem
  }

  .\32xl\:py-5xl {
    padding-top: 160rem;
    padding-bottom: 160rem
  }

  .\32xl\:pt-0 {
    padding-top: 0
  }

  .\32xl\:pt-1 {
    padding-top: 0.25rem
  }

  .\32xl\:pt-2 {
    padding-top: 0.5rem
  }

  .\32xl\:pt-3 {
    padding-top: 0.75rem
  }

  .\32xl\:pt-4 {
    padding-top: 0.4rem
  }

  .\32xl\:pt-5 {
    padding-top: 1.25rem
  }

  .\32xl\:pt-6 {
    padding-top: 0.6rem
  }

  .\32xl\:pt-7 {
    padding-top: 1.75rem
  }

  .\32xl\:pt-8 {
    padding-top: 0.8rem
  }

  .\32xl\:pt-9 {
    padding-top: 2.25rem
  }

  .\32xl\:pt-10 {
    padding-top: 2.5rem
  }

  .\32xl\:pt-11 {
    padding-top: 2.75rem
  }

  .\32xl\:pt-12 {
    padding-top: 1.2rem
  }

  .\32xl\:pt-14 {
    padding-top: 3.5rem
  }

  .\32xl\:pt-16 {
    padding-top: 1.6rem
  }

  .\32xl\:pt-20 {
    padding-top: 2rem
  }

  .\32xl\:pt-24 {
    padding-top: 2.4rem
  }

  .\32xl\:pt-28 {
    padding-top: 2.8rem
  }

  .\32xl\:pt-32 {
    padding-top: 3.2rem
  }

  .\32xl\:pt-36 {
    padding-top: 3.6rem
  }

  .\32xl\:pt-40 {
    padding-top: 4rem
  }

  .\32xl\:pt-44 {
    padding-top: 4.4rem
  }

  .\32xl\:pt-48 {
    padding-top: 4.8rem
  }

  .\32xl\:pt-52 {
    padding-top: 5.2rem
  }

  .\32xl\:pt-56 {
    padding-top: 5.6rem
  }

  .\32xl\:pt-60 {
    padding-top: 6rem
  }

  .\32xl\:pt-64 {
    padding-top: 6.4rem
  }

  .\32xl\:pt-68 {
    padding-top: 6.8rem
  }

  .\32xl\:pt-72 {
    padding-top: 7.2rem
  }

  .\32xl\:pt-76 {
    padding-top: 7.6rem
  }

  .\32xl\:pt-80 {
    padding-top: 8rem
  }

  .\32xl\:pt-84 {
    padding-top: 8.4rem
  }

  .\32xl\:pt-88 {
    padding-top: 8.8rem
  }

  .\32xl\:pt-92 {
    padding-top: 9.2rem
  }

  .\32xl\:pt-96 {
    padding-top: 9.6rem
  }

  .\32xl\:pt-128 {
    padding-top: 12.8rem
  }

  .\32xl\:pt-136 {
    padding-top: 13.6rem
  }

  .\32xl\:pt-160 {
    padding-top: 16rem
  }

  .\32xl\:pt-192 {
    padding-top: 19.2rem
  }

  .\32xl\:pt-200 {
    padding-top: 20rem
  }

  .\32xl\:pt-208 {
    padding-top: 20.8rem
  }

  .\32xl\:pt-216 {
    padding-top: 21.6rem
  }

  .\32xl\:pt-224 {
    padding-top: 22.4rem
  }

  .\32xl\:pt-256 {
    padding-top: 25.6rem
  }

  .\32xl\:pt-288 {
    padding-top: 28.8rem
  }

  .\32xl\:pt-320 {
    padding-top: 32rem
  }

  .\32xl\:pt-360 {
    padding-top: 36rem
  }

  .\32xl\:pt-384 {
    padding-top: 38.4rem
  }

  .\32xl\:pt-400 {
    padding-top: 40rem
  }

  .\32xl\:pt-512 {
    padding-top: 51.2rem
  }

  .\32xl\:pt-640 {
    padding-top: 64rem
  }

  .\32xl\:pt-px {
    padding-top: 1px
  }

  .\32xl\:pt-0\.5 {
    padding-top: 0.125rem
  }

  .\32xl\:pt-1\.5 {
    padding-top: 0.375rem
  }

  .\32xl\:pt-2\.5 {
    padding-top: 0.625rem
  }

  .\32xl\:pt-3\.5 {
    padding-top: 0.875rem
  }

  .\32xl\:pt-xs {
    padding-top: 32rem
  }

  .\32xl\:pt-sm {
    padding-top: 48rem
  }

  .\32xl\:pt-md {
    padding-top: 64rem
  }

  .\32xl\:pt-lg {
    padding-top: 80rem
  }

  .\32xl\:pt-xl {
    padding-top: 96rem
  }

  .\32xl\:pt-2xl {
    padding-top: 112rem
  }

  .\32xl\:pt-3xl {
    padding-top: 128rem
  }

  .\32xl\:pt-4xl {
    padding-top: 144rem
  }

  .\32xl\:pt-5xl {
    padding-top: 160rem
  }

  .\32xl\:pr-0 {
    padding-right: 0
  }

  .\32xl\:pr-1 {
    padding-right: 0.25rem
  }

  .\32xl\:pr-2 {
    padding-right: 0.5rem
  }

  .\32xl\:pr-3 {
    padding-right: 0.75rem
  }

  .\32xl\:pr-4 {
    padding-right: 0.4rem
  }

  .\32xl\:pr-5 {
    padding-right: 1.25rem
  }

  .\32xl\:pr-6 {
    padding-right: 0.6rem
  }

  .\32xl\:pr-7 {
    padding-right: 1.75rem
  }

  .\32xl\:pr-8 {
    padding-right: 0.8rem
  }

  .\32xl\:pr-9 {
    padding-right: 2.25rem
  }

  .\32xl\:pr-10 {
    padding-right: 2.5rem
  }

  .\32xl\:pr-11 {
    padding-right: 2.75rem
  }

  .\32xl\:pr-12 {
    padding-right: 1.2rem
  }

  .\32xl\:pr-14 {
    padding-right: 3.5rem
  }

  .\32xl\:pr-16 {
    padding-right: 1.6rem
  }

  .\32xl\:pr-20 {
    padding-right: 2rem
  }

  .\32xl\:pr-24 {
    padding-right: 2.4rem
  }

  .\32xl\:pr-28 {
    padding-right: 2.8rem
  }

  .\32xl\:pr-32 {
    padding-right: 3.2rem
  }

  .\32xl\:pr-36 {
    padding-right: 3.6rem
  }

  .\32xl\:pr-40 {
    padding-right: 4rem
  }

  .\32xl\:pr-44 {
    padding-right: 4.4rem
  }

  .\32xl\:pr-48 {
    padding-right: 4.8rem
  }

  .\32xl\:pr-52 {
    padding-right: 5.2rem
  }

  .\32xl\:pr-56 {
    padding-right: 5.6rem
  }

  .\32xl\:pr-60 {
    padding-right: 6rem
  }

  .\32xl\:pr-64 {
    padding-right: 6.4rem
  }

  .\32xl\:pr-68 {
    padding-right: 6.8rem
  }

  .\32xl\:pr-72 {
    padding-right: 7.2rem
  }

  .\32xl\:pr-76 {
    padding-right: 7.6rem
  }

  .\32xl\:pr-80 {
    padding-right: 8rem
  }

  .\32xl\:pr-84 {
    padding-right: 8.4rem
  }

  .\32xl\:pr-88 {
    padding-right: 8.8rem
  }

  .\32xl\:pr-92 {
    padding-right: 9.2rem
  }

  .\32xl\:pr-96 {
    padding-right: 9.6rem
  }

  .\32xl\:pr-128 {
    padding-right: 12.8rem
  }

  .\32xl\:pr-136 {
    padding-right: 13.6rem
  }

  .\32xl\:pr-160 {
    padding-right: 16rem
  }

  .\32xl\:pr-192 {
    padding-right: 19.2rem
  }

  .\32xl\:pr-200 {
    padding-right: 20rem
  }

  .\32xl\:pr-208 {
    padding-right: 20.8rem
  }

  .\32xl\:pr-216 {
    padding-right: 21.6rem
  }

  .\32xl\:pr-224 {
    padding-right: 22.4rem
  }

  .\32xl\:pr-256 {
    padding-right: 25.6rem
  }

  .\32xl\:pr-288 {
    padding-right: 28.8rem
  }

  .\32xl\:pr-320 {
    padding-right: 32rem
  }

  .\32xl\:pr-360 {
    padding-right: 36rem
  }

  .\32xl\:pr-384 {
    padding-right: 38.4rem
  }

  .\32xl\:pr-400 {
    padding-right: 40rem
  }

  .\32xl\:pr-512 {
    padding-right: 51.2rem
  }

  .\32xl\:pr-640 {
    padding-right: 64rem
  }

  .\32xl\:pr-px {
    padding-right: 1px
  }

  .\32xl\:pr-0\.5 {
    padding-right: 0.125rem
  }

  .\32xl\:pr-1\.5 {
    padding-right: 0.375rem
  }

  .\32xl\:pr-2\.5 {
    padding-right: 0.625rem
  }

  .\32xl\:pr-3\.5 {
    padding-right: 0.875rem
  }

  .\32xl\:pr-xs {
    padding-right: 32rem
  }

  .\32xl\:pr-sm {
    padding-right: 48rem
  }

  .\32xl\:pr-md {
    padding-right: 64rem
  }

  .\32xl\:pr-lg {
    padding-right: 80rem
  }

  .\32xl\:pr-xl {
    padding-right: 96rem
  }

  .\32xl\:pr-2xl {
    padding-right: 112rem
  }

  .\32xl\:pr-3xl {
    padding-right: 128rem
  }

  .\32xl\:pr-4xl {
    padding-right: 144rem
  }

  .\32xl\:pr-5xl {
    padding-right: 160rem
  }

  .\32xl\:pb-0 {
    padding-bottom: 0
  }

  .\32xl\:pb-1 {
    padding-bottom: 0.25rem
  }

  .\32xl\:pb-2 {
    padding-bottom: 0.5rem
  }

  .\32xl\:pb-3 {
    padding-bottom: 0.75rem
  }

  .\32xl\:pb-4 {
    padding-bottom: 0.4rem
  }

  .\32xl\:pb-5 {
    padding-bottom: 1.25rem
  }

  .\32xl\:pb-6 {
    padding-bottom: 0.6rem
  }

  .\32xl\:pb-7 {
    padding-bottom: 1.75rem
  }

  .\32xl\:pb-8 {
    padding-bottom: 0.8rem
  }

  .\32xl\:pb-9 {
    padding-bottom: 2.25rem
  }

  .\32xl\:pb-10 {
    padding-bottom: 2.5rem
  }

  .\32xl\:pb-11 {
    padding-bottom: 2.75rem
  }

  .\32xl\:pb-12 {
    padding-bottom: 1.2rem
  }

  .\32xl\:pb-14 {
    padding-bottom: 3.5rem
  }

  .\32xl\:pb-16 {
    padding-bottom: 1.6rem
  }

  .\32xl\:pb-20 {
    padding-bottom: 2rem
  }

  .\32xl\:pb-24 {
    padding-bottom: 2.4rem
  }

  .\32xl\:pb-28 {
    padding-bottom: 2.8rem
  }

  .\32xl\:pb-32 {
    padding-bottom: 3.2rem
  }

  .\32xl\:pb-36 {
    padding-bottom: 3.6rem
  }

  .\32xl\:pb-40 {
    padding-bottom: 4rem
  }

  .\32xl\:pb-44 {
    padding-bottom: 4.4rem
  }

  .\32xl\:pb-48 {
    padding-bottom: 4.8rem
  }

  .\32xl\:pb-52 {
    padding-bottom: 5.2rem
  }

  .\32xl\:pb-56 {
    padding-bottom: 5.6rem
  }

  .\32xl\:pb-60 {
    padding-bottom: 6rem
  }

  .\32xl\:pb-64 {
    padding-bottom: 6.4rem
  }

  .\32xl\:pb-68 {
    padding-bottom: 6.8rem
  }

  .\32xl\:pb-72 {
    padding-bottom: 7.2rem
  }

  .\32xl\:pb-76 {
    padding-bottom: 7.6rem
  }

  .\32xl\:pb-80 {
    padding-bottom: 8rem
  }

  .\32xl\:pb-84 {
    padding-bottom: 8.4rem
  }

  .\32xl\:pb-88 {
    padding-bottom: 8.8rem
  }

  .\32xl\:pb-92 {
    padding-bottom: 9.2rem
  }

  .\32xl\:pb-96 {
    padding-bottom: 9.6rem
  }

  .\32xl\:pb-128 {
    padding-bottom: 12.8rem
  }

  .\32xl\:pb-136 {
    padding-bottom: 13.6rem
  }

  .\32xl\:pb-160 {
    padding-bottom: 16rem
  }

  .\32xl\:pb-192 {
    padding-bottom: 19.2rem
  }

  .\32xl\:pb-200 {
    padding-bottom: 20rem
  }

  .\32xl\:pb-208 {
    padding-bottom: 20.8rem
  }

  .\32xl\:pb-216 {
    padding-bottom: 21.6rem
  }

  .\32xl\:pb-224 {
    padding-bottom: 22.4rem
  }

  .\32xl\:pb-256 {
    padding-bottom: 25.6rem
  }

  .\32xl\:pb-288 {
    padding-bottom: 28.8rem
  }

  .\32xl\:pb-320 {
    padding-bottom: 32rem
  }

  .\32xl\:pb-360 {
    padding-bottom: 36rem
  }

  .\32xl\:pb-384 {
    padding-bottom: 38.4rem
  }

  .\32xl\:pb-400 {
    padding-bottom: 40rem
  }

  .\32xl\:pb-512 {
    padding-bottom: 51.2rem
  }

  .\32xl\:pb-640 {
    padding-bottom: 64rem
  }

  .\32xl\:pb-px {
    padding-bottom: 1px
  }

  .\32xl\:pb-0\.5 {
    padding-bottom: 0.125rem
  }

  .\32xl\:pb-1\.5 {
    padding-bottom: 0.375rem
  }

  .\32xl\:pb-2\.5 {
    padding-bottom: 0.625rem
  }

  .\32xl\:pb-3\.5 {
    padding-bottom: 0.875rem
  }

  .\32xl\:pb-xs {
    padding-bottom: 32rem
  }

  .\32xl\:pb-sm {
    padding-bottom: 48rem
  }

  .\32xl\:pb-md {
    padding-bottom: 64rem
  }

  .\32xl\:pb-lg {
    padding-bottom: 80rem
  }

  .\32xl\:pb-xl {
    padding-bottom: 96rem
  }

  .\32xl\:pb-2xl {
    padding-bottom: 112rem
  }

  .\32xl\:pb-3xl {
    padding-bottom: 128rem
  }

  .\32xl\:pb-4xl {
    padding-bottom: 144rem
  }

  .\32xl\:pb-5xl {
    padding-bottom: 160rem
  }

  .\32xl\:pl-0 {
    padding-left: 0
  }

  .\32xl\:pl-1 {
    padding-left: 0.25rem
  }

  .\32xl\:pl-2 {
    padding-left: 0.5rem
  }

  .\32xl\:pl-3 {
    padding-left: 0.75rem
  }

  .\32xl\:pl-4 {
    padding-left: 0.4rem
  }

  .\32xl\:pl-5 {
    padding-left: 1.25rem
  }

  .\32xl\:pl-6 {
    padding-left: 0.6rem
  }

  .\32xl\:pl-7 {
    padding-left: 1.75rem
  }

  .\32xl\:pl-8 {
    padding-left: 0.8rem
  }

  .\32xl\:pl-9 {
    padding-left: 2.25rem
  }

  .\32xl\:pl-10 {
    padding-left: 2.5rem
  }

  .\32xl\:pl-11 {
    padding-left: 2.75rem
  }

  .\32xl\:pl-12 {
    padding-left: 1.2rem
  }

  .\32xl\:pl-14 {
    padding-left: 3.5rem
  }

  .\32xl\:pl-16 {
    padding-left: 1.6rem
  }

  .\32xl\:pl-20 {
    padding-left: 2rem
  }

  .\32xl\:pl-24 {
    padding-left: 2.4rem
  }

  .\32xl\:pl-28 {
    padding-left: 2.8rem
  }

  .\32xl\:pl-32 {
    padding-left: 3.2rem
  }

  .\32xl\:pl-36 {
    padding-left: 3.6rem
  }

  .\32xl\:pl-40 {
    padding-left: 4rem
  }

  .\32xl\:pl-44 {
    padding-left: 4.4rem
  }

  .\32xl\:pl-48 {
    padding-left: 4.8rem
  }

  .\32xl\:pl-52 {
    padding-left: 5.2rem
  }

  .\32xl\:pl-56 {
    padding-left: 5.6rem
  }

  .\32xl\:pl-60 {
    padding-left: 6rem
  }

  .\32xl\:pl-64 {
    padding-left: 6.4rem
  }

  .\32xl\:pl-68 {
    padding-left: 6.8rem
  }

  .\32xl\:pl-72 {
    padding-left: 7.2rem
  }

  .\32xl\:pl-76 {
    padding-left: 7.6rem
  }

  .\32xl\:pl-80 {
    padding-left: 8rem
  }

  .\32xl\:pl-84 {
    padding-left: 8.4rem
  }

  .\32xl\:pl-88 {
    padding-left: 8.8rem
  }

  .\32xl\:pl-92 {
    padding-left: 9.2rem
  }

  .\32xl\:pl-96 {
    padding-left: 9.6rem
  }

  .\32xl\:pl-128 {
    padding-left: 12.8rem
  }

  .\32xl\:pl-136 {
    padding-left: 13.6rem
  }

  .\32xl\:pl-160 {
    padding-left: 16rem
  }

  .\32xl\:pl-192 {
    padding-left: 19.2rem
  }

  .\32xl\:pl-200 {
    padding-left: 20rem
  }

  .\32xl\:pl-208 {
    padding-left: 20.8rem
  }

  .\32xl\:pl-216 {
    padding-left: 21.6rem
  }

  .\32xl\:pl-224 {
    padding-left: 22.4rem
  }

  .\32xl\:pl-256 {
    padding-left: 25.6rem
  }

  .\32xl\:pl-288 {
    padding-left: 28.8rem
  }

  .\32xl\:pl-320 {
    padding-left: 32rem
  }

  .\32xl\:pl-360 {
    padding-left: 36rem
  }

  .\32xl\:pl-384 {
    padding-left: 38.4rem
  }

  .\32xl\:pl-400 {
    padding-left: 40rem
  }

  .\32xl\:pl-512 {
    padding-left: 51.2rem
  }

  .\32xl\:pl-640 {
    padding-left: 64rem
  }

  .\32xl\:pl-px {
    padding-left: 1px
  }

  .\32xl\:pl-0\.5 {
    padding-left: 0.125rem
  }

  .\32xl\:pl-1\.5 {
    padding-left: 0.375rem
  }

  .\32xl\:pl-2\.5 {
    padding-left: 0.625rem
  }

  .\32xl\:pl-3\.5 {
    padding-left: 0.875rem
  }

  .\32xl\:pl-xs {
    padding-left: 32rem
  }

  .\32xl\:pl-sm {
    padding-left: 48rem
  }

  .\32xl\:pl-md {
    padding-left: 64rem
  }

  .\32xl\:pl-lg {
    padding-left: 80rem
  }

  .\32xl\:pl-xl {
    padding-left: 96rem
  }

  .\32xl\:pl-2xl {
    padding-left: 112rem
  }

  .\32xl\:pl-3xl {
    padding-left: 128rem
  }

  .\32xl\:pl-4xl {
    padding-left: 144rem
  }

  .\32xl\:pl-5xl {
    padding-left: 160rem
  }

  .\32xl\:text-left {
    text-align: left
  }

  .\32xl\:text-center {
    text-align: center
  }

  .\32xl\:text-right {
    text-align: right
  }

  .\32xl\:text-justify {
    text-align: justify
  }

  .\32xl\:align-baseline {
    vertical-align: baseline
  }

  .\32xl\:align-top {
    vertical-align: top
  }

  .\32xl\:align-middle {
    vertical-align: middle
  }

  .\32xl\:align-bottom {
    vertical-align: bottom
  }

  .\32xl\:align-text-top {
    vertical-align: text-top
  }

  .\32xl\:align-text-bottom {
    vertical-align: text-bottom
  }

  .\32xl\:font-sans {
    font-family: Muli, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .\32xl\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .\32xl\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  .\32xl\:text-10 {
    font-size: 1rem
  }

  .\32xl\:text-11 {
    font-size: 1.1rem
  }

  .\32xl\:text-12 {
    font-size: 1.2rem
  }

  .\32xl\:text-13 {
    font-size: 1.3rem
  }

  .\32xl\:text-14 {
    font-size: 1.4rem
  }

  .\32xl\:text-15 {
    font-size: 1.5rem
  }

  .\32xl\:text-16 {
    font-size: 1.6rem
  }

  .\32xl\:text-17 {
    font-size: 1.7rem
  }

  .\32xl\:text-18 {
    font-size: 1.8rem
  }

  .\32xl\:text-19 {
    font-size: 1.9rem
  }

  .\32xl\:text-20 {
    font-size: 2rem
  }

  .\32xl\:text-24 {
    font-size: 2.4rem
  }

  .\32xl\:text-28 {
    font-size: 2.8rem
  }

  .\32xl\:text-32 {
    font-size: 3.2rem
  }

  .\32xl\:text-36 {
    font-size: 3.6rem
  }

  .\32xl\:text-40 {
    font-size: 4rem
  }

  .\32xl\:text-44 {
    font-size: 4.4rem
  }

  .\32xl\:text-48 {
    font-size: 4.8rem
  }

  .\32xl\:text-52 {
    font-size: 5.2rem
  }

  .\32xl\:text-56 {
    font-size: 5.6rem
  }

  .\32xl\:text-60 {
    font-size: 6rem
  }

  .\32xl\:text-64 {
    font-size: 6.4rem
  }

  .\32xl\:text-68 {
    font-size: 6.8rem
  }

  .\32xl\:text-72 {
    font-size: 7.2rem
  }

  .\32xl\:text-96 {
    font-size: 9.6rem
  }

  .\32xl\:text-128 {
    font-size: 12.8rem
  }

  .\32xl\:text-xs {
    font-size: 1.2rem
  }

  .\32xl\:text-sm {
    font-size: 2.4rem
  }

  .\32xl\:text-base {
    font-size: 1.6rem
  }

  .\32xl\:text-lg {
    font-size: 1.8rem
  }

  .\32xl\:text-xl {
    font-size: 2rem
  }

  .\32xl\:text-2xl {
    font-size: 2.4rem
  }

  .\32xl\:text-3xl {
    font-size: 3rem
  }

  .\32xl\:text-4xl {
    font-size: 3.6rem
  }

  .\32xl\:text-5xl {
    font-size: 4.8rem
  }

  .\32xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1
  }

  .\32xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1
  }

  .\32xl\:text-8xl {
    font-size: 6rem;
    line-height: 1
  }

  .\32xl\:text-9xl {
    font-size: 8rem;
    line-height: 1
  }

  .\32xl\:font-100 {
    font-weight: 100
  }

  .\32xl\:font-200 {
    font-weight: 200
  }

  .\32xl\:font-300 {
    font-weight: 300
  }

  .\32xl\:font-400 {
    font-weight: 400
  }

  .\32xl\:font-500 {
    font-weight: 500
  }

  .\32xl\:font-600 {
    font-weight: 600
  }

  .\32xl\:font-700 {
    font-weight: 700
  }

  .\32xl\:font-800 {
    font-weight: 800
  }

  .\32xl\:font-900 {
    font-weight: 900
  }

  .\32xl\:font-thin {
    font-weight: 200
  }

  .\32xl\:font-extralight {
    font-weight: 200
  }

  .\32xl\:font-light {
    font-weight: 300
  }

  .\32xl\:font-normal {
    font-weight: 400
  }

  .\32xl\:font-medium {
    font-weight: 500
  }

  .\32xl\:font-semibold {
    font-weight: 600
  }

  .\32xl\:font-bold {
    font-weight: 700
  }

  .\32xl\:font-extrabold {
    font-weight: 800
  }

  .\32xl\:font-black {
    font-weight: 900
  }

  .\32xl\:font-hairline {
    font-weight: 100
  }

  .\32xl\:hover\:font-100:hover {
    font-weight: 100
  }

  .\32xl\:hover\:font-200:hover {
    font-weight: 200
  }

  .\32xl\:hover\:font-300:hover {
    font-weight: 300
  }

  .\32xl\:hover\:font-400:hover {
    font-weight: 400
  }

  .\32xl\:hover\:font-500:hover {
    font-weight: 500
  }

  .\32xl\:hover\:font-600:hover {
    font-weight: 600
  }

  .\32xl\:hover\:font-700:hover {
    font-weight: 700
  }

  .\32xl\:hover\:font-800:hover {
    font-weight: 800
  }

  .\32xl\:hover\:font-900:hover {
    font-weight: 900
  }

  .\32xl\:hover\:font-thin:hover {
    font-weight: 200
  }

  .\32xl\:hover\:font-extralight:hover {
    font-weight: 200
  }

  .\32xl\:hover\:font-light:hover {
    font-weight: 300
  }

  .\32xl\:hover\:font-normal:hover {
    font-weight: 400
  }

  .\32xl\:hover\:font-medium:hover {
    font-weight: 500
  }

  .\32xl\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .\32xl\:hover\:font-bold:hover {
    font-weight: 700
  }

  .\32xl\:hover\:font-extrabold:hover {
    font-weight: 800
  }

  .\32xl\:hover\:font-black:hover {
    font-weight: 900
  }

  .\32xl\:hover\:font-hairline:hover {
    font-weight: 100
  }

  .\32xl\:focus\:font-100:focus {
    font-weight: 100
  }

  .\32xl\:focus\:font-200:focus {
    font-weight: 200
  }

  .\32xl\:focus\:font-300:focus {
    font-weight: 300
  }

  .\32xl\:focus\:font-400:focus {
    font-weight: 400
  }

  .\32xl\:focus\:font-500:focus {
    font-weight: 500
  }

  .\32xl\:focus\:font-600:focus {
    font-weight: 600
  }

  .\32xl\:focus\:font-700:focus {
    font-weight: 700
  }

  .\32xl\:focus\:font-800:focus {
    font-weight: 800
  }

  .\32xl\:focus\:font-900:focus {
    font-weight: 900
  }

  .\32xl\:focus\:font-thin:focus {
    font-weight: 200
  }

  .\32xl\:focus\:font-extralight:focus {
    font-weight: 200
  }

  .\32xl\:focus\:font-light:focus {
    font-weight: 300
  }

  .\32xl\:focus\:font-normal:focus {
    font-weight: 400
  }

  .\32xl\:focus\:font-medium:focus {
    font-weight: 500
  }

  .\32xl\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .\32xl\:focus\:font-bold:focus {
    font-weight: 700
  }

  .\32xl\:focus\:font-extrabold:focus {
    font-weight: 800
  }

  .\32xl\:focus\:font-black:focus {
    font-weight: 900
  }

  .\32xl\:focus\:font-hairline:focus {
    font-weight: 100
  }

  .\32xl\:uppercase {
    text-transform: uppercase
  }

  .\32xl\:lowercase {
    text-transform: lowercase
  }

  .\32xl\:capitalize {
    text-transform: capitalize
  }

  .\32xl\:normal-case {
    text-transform: none
  }

  .\32xl\:italic {
    font-style: italic
  }

  .\32xl\:not-italic {
    font-style: normal
  }

  .\32xl\:hover\:italic:hover {
    font-style: italic
  }

  .\32xl\:hover\:not-italic:hover {
    font-style: normal
  }

  .\32xl\:focus\:italic:focus {
    font-style: italic
  }

  .\32xl\:focus\:not-italic:focus {
    font-style: normal
  }

  .\32xl\:ordinal, .\32xl\:slashed-zero, .\32xl\:lining-nums, .\32xl\:oldstyle-nums, .\32xl\:proportional-nums, .\32xl\:tabular-nums, .\32xl\:diagonal-fractions, .\32xl\:stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  .\32xl\:normal-nums {
    font-variant-numeric: normal
  }

  .\32xl\:ordinal {
    --tw-ordinal: ordinal
  }

  .\32xl\:slashed-zero {
    --tw-slashed-zero: slashed-zero
  }

  .\32xl\:lining-nums {
    --tw-numeric-figure: lining-nums
  }

  .\32xl\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums
  }

  .\32xl\:proportional-nums {
    --tw-numeric-spacing: proportional-nums
  }

  .\32xl\:tabular-nums {
    --tw-numeric-spacing: tabular-nums
  }

  .\32xl\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions
  }

  .\32xl\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions
  }

  .\32xl\:leading-3 {
    line-height: .75rem
  }

  .\32xl\:leading-4 {
    line-height: 1rem
  }

  .\32xl\:leading-5 {
    line-height: 1.25rem
  }

  .\32xl\:leading-6 {
    line-height: 1.5rem
  }

  .\32xl\:leading-7 {
    line-height: 1.75rem
  }

  .\32xl\:leading-8 {
    line-height: 2rem
  }

  .\32xl\:leading-9 {
    line-height: 2.25rem
  }

  .\32xl\:leading-10 {
    line-height: 2.5rem
  }

  .\32xl\:leading-none {
    line-height: 1
  }

  .\32xl\:leading-tight {
    line-height: 1.25
  }

  .\32xl\:leading-snug {
    line-height: 1.375
  }

  .\32xl\:leading-normal {
    line-height: 1.5
  }

  .\32xl\:leading-relaxed {
    line-height: 1.625
  }

  .\32xl\:leading-loose {
    line-height: 2
  }

  .\32xl\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .\32xl\:tracking-tight {
    letter-spacing: -0.025em
  }

  .\32xl\:tracking-normal {
    letter-spacing: 0
  }

  .\32xl\:tracking-wide {
    letter-spacing: 0.025em
  }

  .\32xl\:tracking-wider {
    letter-spacing: 0.05em
  }

  .\32xl\:tracking-widest {
    letter-spacing: 0.1em
  }

  .\32xl\:text-transparent {
    color: transparent
  }

  .\32xl\:text-current {
    color: currentColor
  }

  .\32xl\:text-black {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .\32xl\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .\32xl\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .\32xl\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .\32xl\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .\32xl\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .\32xl\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .\32xl\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .\32xl\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .\32xl\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .\32xl\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .\32xl\:text-gray-default {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .\32xl\:text-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .\32xl\:text-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .\32xl\:text-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .\32xl\:text-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .\32xl\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .\32xl\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .\32xl\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .\32xl\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .\32xl\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .\32xl\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .\32xl\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .\32xl\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .\32xl\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .\32xl\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .\32xl\:text-red-default {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .\32xl\:text-red-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .\32xl\:text-red-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .\32xl\:text-red-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .\32xl\:text-red-A700 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .\32xl\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .\32xl\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .\32xl\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .\32xl\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .\32xl\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .\32xl\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .\32xl\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .\32xl\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .\32xl\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .\32xl\:text-yellow-default {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .\32xl\:text-yellow-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .\32xl\:text-yellow-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-yellow-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-yellow-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .\32xl\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .\32xl\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .\32xl\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .\32xl\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .\32xl\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .\32xl\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .\32xl\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .\32xl\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .\32xl\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .\32xl\:text-green-default {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .\32xl\:text-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .\32xl\:text-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .\32xl\:text-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .\32xl\:text-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-default {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .\32xl\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .\32xl\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .\32xl\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .\32xl\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .\32xl\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .\32xl\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .\32xl\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .\32xl\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .\32xl\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .\32xl\:text-indigo-default {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .\32xl\:text-indigo-A100 {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-indigo-A200 {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .\32xl\:text-indigo-A400 {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .\32xl\:text-indigo-A700 {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .\32xl\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .\32xl\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .\32xl\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .\32xl\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .\32xl\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .\32xl\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .\32xl\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .\32xl\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .\32xl\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .\32xl\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .\32xl\:text-purple-default {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .\32xl\:text-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .\32xl\:text-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .\32xl\:text-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .\32xl\:text-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .\32xl\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .\32xl\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .\32xl\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .\32xl\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .\32xl\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .\32xl\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .\32xl\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .\32xl\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .\32xl\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .\32xl\:text-pink-default {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .\32xl\:text-pink-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .\32xl\:text-pink-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .\32xl\:text-pink-A400 {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .\32xl\:text-pink-A700 {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .\32xl\:text-grey-50 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .\32xl\:text-grey-100 {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .\32xl\:text-grey-200 {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .\32xl\:text-grey-300 {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .\32xl\:text-grey-400 {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .\32xl\:text-grey-500 {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .\32xl\:text-grey-600 {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .\32xl\:text-grey-700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .\32xl\:text-grey-800 {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .\32xl\:text-grey-900 {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .\32xl\:text-grey-default {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .\32xl\:text-grey-A100 {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .\32xl\:text-grey-A200 {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .\32xl\:text-grey-A400 {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .\32xl\:text-grey-A700 {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .\32xl\:text-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .\32xl\:text-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .\32xl\:text-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .\32xl\:text-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .\32xl\:text-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .\32xl\:text-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-orange-default {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .\32xl\:text-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .\32xl\:text-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-orange-50 {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-orange-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-orange-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-orange-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-orange-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-orange-600 {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-orange-800 {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-orange-900 {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-orange-default {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-orange-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-orange-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-orange-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-orange-A700 {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-light-green-50 {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .\32xl\:text-light-green-100 {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .\32xl\:text-light-green-200 {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .\32xl\:text-light-green-300 {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .\32xl\:text-light-green-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .\32xl\:text-light-green-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .\32xl\:text-light-green-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .\32xl\:text-light-green-700 {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .\32xl\:text-light-green-800 {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .\32xl\:text-light-green-900 {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .\32xl\:text-light-green-default {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .\32xl\:text-light-green-A100 {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .\32xl\:text-light-green-A200 {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .\32xl\:text-light-green-A400 {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .\32xl\:text-light-green-A700 {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .\32xl\:text-teal-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .\32xl\:text-teal-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .\32xl\:text-teal-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .\32xl\:text-teal-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .\32xl\:text-teal-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .\32xl\:text-teal-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .\32xl\:text-teal-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .\32xl\:text-teal-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .\32xl\:text-teal-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .\32xl\:text-teal-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .\32xl\:text-teal-default {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .\32xl\:text-teal-A100 {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .\32xl\:text-teal-A200 {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .\32xl\:text-teal-A400 {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .\32xl\:text-teal-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .\32xl\:text-light-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .\32xl\:text-light-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .\32xl\:text-light-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .\32xl\:text-light-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .\32xl\:text-light-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .\32xl\:text-light-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .\32xl\:text-light-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .\32xl\:text-light-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .\32xl\:text-light-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .\32xl\:text-light-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .\32xl\:text-light-blue-default {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .\32xl\:text-light-blue-A100 {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-light-blue-A200 {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-light-blue-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-light-blue-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-purple-default {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-purple-A100 {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-purple-A200 {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-purple-A400 {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-deep-purple-A700 {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .\32xl\:text-lime-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .\32xl\:text-lime-100 {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .\32xl\:text-lime-200 {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .\32xl\:text-lime-300 {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .\32xl\:text-lime-400 {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .\32xl\:text-lime-500 {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .\32xl\:text-lime-600 {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .\32xl\:text-lime-700 {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .\32xl\:text-lime-800 {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .\32xl\:text-lime-900 {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .\32xl\:text-lime-default {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .\32xl\:text-lime-A100 {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .\32xl\:text-lime-A200 {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .\32xl\:text-lime-A400 {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-lime-A700 {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-amber-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .\32xl\:text-amber-100 {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .\32xl\:text-amber-200 {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .\32xl\:text-amber-300 {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .\32xl\:text-amber-400 {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .\32xl\:text-amber-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .\32xl\:text-amber-600 {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-amber-700 {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-amber-800 {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-amber-900 {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-amber-default {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .\32xl\:text-amber-A100 {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .\32xl\:text-amber-A200 {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .\32xl\:text-amber-A400 {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-amber-A700 {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .\32xl\:text-brown-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .\32xl\:text-brown-100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .\32xl\:text-brown-200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .\32xl\:text-brown-300 {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .\32xl\:text-brown-400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .\32xl\:text-brown-500 {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .\32xl\:text-brown-600 {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .\32xl\:text-brown-700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .\32xl\:text-brown-800 {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .\32xl\:text-brown-900 {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .\32xl\:text-brown-default {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .\32xl\:text-brown-A100 {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .\32xl\:text-brown-A200 {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .\32xl\:text-brown-A400 {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .\32xl\:text-brown-A700 {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-gray-default {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-gray-A100 {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-gray-A200 {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-gray-A400 {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .\32xl\:text-blue-gray-A700 {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .\32xl\:text-cyan-50 {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .\32xl\:text-cyan-100 {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .\32xl\:text-cyan-200 {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .\32xl\:text-cyan-300 {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .\32xl\:text-cyan-400 {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .\32xl\:text-cyan-500 {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .\32xl\:text-cyan-600 {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .\32xl\:text-cyan-700 {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .\32xl\:text-cyan-800 {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .\32xl\:text-cyan-900 {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .\32xl\:text-cyan-default {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .\32xl\:text-cyan-A100 {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-cyan-A200 {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-cyan-A400 {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .\32xl\:text-cyan-A700 {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-transparent:hover {
    color: transparent
  }

  .\32xl\:hover\:text-current:hover {
    color: currentColor
  }

  .\32xl\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-gray-default:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-gray-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-gray-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-gray-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-gray-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-red-default:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-red-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-red-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-red-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-red-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-yellow-default:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-yellow-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-yellow-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-yellow-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-yellow-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-green-default:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-green-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-green-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-green-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-green-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-default:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-indigo-default:hover {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-indigo-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-indigo-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-indigo-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-indigo-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-purple-default:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-purple-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-purple-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-purple-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-purple-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-pink-default:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-pink-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-pink-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-pink-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-pink-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-grey-50:hover {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-grey-100:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-grey-200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-grey-300:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-grey-400:hover {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-grey-500:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-grey-600:hover {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-grey-700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-grey-800:hover {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-grey-900:hover {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-grey-default:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-grey-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-grey-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-grey-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-grey-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-orange-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-orange-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-orange-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-orange-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-orange-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-orange-600:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-orange-700:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-orange-800:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-orange-900:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-orange-default:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-orange-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-orange-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-orange-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-orange-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-orange-50:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-orange-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-orange-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-orange-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-orange-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-orange-600:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-orange-700:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-orange-800:hover {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-orange-900:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-orange-default:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-orange-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-orange-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-orange-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-orange-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-green-default:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-green-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-green-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-green-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-green-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-teal-50:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-teal-100:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-teal-200:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-teal-300:hover {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-teal-400:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-teal-500:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-teal-600:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-teal-700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-teal-800:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-teal-900:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-teal-default:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-teal-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-teal-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-teal-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-teal-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-blue-default:hover {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-blue-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-blue-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-blue-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-light-blue-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-purple-default:hover {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-purple-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-purple-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-purple-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-deep-purple-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-lime-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-lime-100:hover {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-lime-200:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-lime-300:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-lime-400:hover {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-lime-500:hover {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-lime-600:hover {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-lime-700:hover {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-lime-800:hover {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-lime-900:hover {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-lime-default:hover {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-lime-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-lime-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-lime-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-lime-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-amber-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-amber-100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-amber-200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-amber-300:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-amber-400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-amber-500:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-amber-600:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-amber-700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-amber-800:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-amber-900:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-amber-default:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-amber-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-amber-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-amber-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-amber-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-brown-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-brown-100:hover {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-brown-200:hover {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-brown-300:hover {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-brown-400:hover {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-brown-500:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-brown-600:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-brown-700:hover {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-brown-800:hover {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-brown-900:hover {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-brown-default:hover {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-brown-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-brown-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-brown-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-brown-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-gray-default:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-gray-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-gray-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-gray-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-blue-gray-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-cyan-50:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-cyan-100:hover {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-cyan-200:hover {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-cyan-300:hover {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-cyan-400:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-cyan-500:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-cyan-600:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-cyan-700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-cyan-800:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-cyan-900:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-cyan-default:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-cyan-A100:hover {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-cyan-A200:hover {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-cyan-A400:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .\32xl\:hover\:text-cyan-A700:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-transparent:focus {
    color: transparent
  }

  .\32xl\:focus\:text-current:focus {
    color: currentColor
  }

  .\32xl\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-gray-default:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-gray-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-gray-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-gray-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-gray-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-red-default:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-red-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-red-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-red-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-red-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-yellow-default:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-yellow-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-yellow-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-yellow-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-yellow-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-green-default:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-green-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-green-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-green-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-green-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-default:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-indigo-default:focus {
    --tw-text-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-indigo-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-indigo-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-indigo-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-indigo-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-purple-default:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-purple-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-purple-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-purple-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-purple-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-pink-default:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-pink-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-pink-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-pink-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-pink-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-grey-50:focus {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-grey-100:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-grey-200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-grey-300:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-grey-400:focus {
    --tw-text-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-grey-500:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-grey-600:focus {
    --tw-text-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-grey-700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-grey-800:focus {
    --tw-text-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-grey-900:focus {
    --tw-text-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-grey-default:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-grey-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-grey-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-grey-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-grey-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-orange-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-orange-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-orange-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-orange-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-orange-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-orange-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-orange-600:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-orange-700:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-orange-800:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-orange-900:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-orange-default:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-orange-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-orange-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-orange-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-orange-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-orange-50:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-orange-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-orange-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-orange-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-orange-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-orange-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-orange-600:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-orange-700:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-orange-800:focus {
    --tw-text-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-orange-900:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-orange-default:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-orange-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-orange-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-orange-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-orange-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-green-default:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-green-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-green-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-green-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-green-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-teal-50:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-teal-100:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-teal-200:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-teal-300:focus {
    --tw-text-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-teal-400:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-teal-500:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-teal-600:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-teal-700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-teal-800:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-teal-900:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-teal-default:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-teal-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-teal-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-teal-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-teal-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-blue-default:focus {
    --tw-text-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-blue-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-blue-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-blue-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-light-blue-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-purple-default:focus {
    --tw-text-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-purple-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-purple-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-purple-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-deep-purple-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-lime-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-lime-100:focus {
    --tw-text-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-lime-200:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-lime-300:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-lime-400:focus {
    --tw-text-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-lime-500:focus {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-lime-600:focus {
    --tw-text-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-lime-700:focus {
    --tw-text-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-lime-800:focus {
    --tw-text-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-lime-900:focus {
    --tw-text-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-lime-default:focus {
    --tw-text-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-lime-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-lime-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-lime-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-lime-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-amber-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-amber-100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-amber-200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-amber-300:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-amber-400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-amber-500:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-amber-600:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-amber-700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-amber-800:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-amber-900:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-amber-default:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-amber-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-amber-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-amber-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-amber-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-brown-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-brown-100:focus {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-brown-200:focus {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-brown-300:focus {
    --tw-text-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-brown-400:focus {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-brown-500:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-brown-600:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-brown-700:focus {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-brown-800:focus {
    --tw-text-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-brown-900:focus {
    --tw-text-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-brown-default:focus {
    --tw-text-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-brown-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-brown-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-brown-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-brown-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-gray-default:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-gray-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-gray-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-gray-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-blue-gray-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-cyan-50:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-cyan-100:focus {
    --tw-text-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-cyan-200:focus {
    --tw-text-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-cyan-300:focus {
    --tw-text-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-cyan-400:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-cyan-500:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-cyan-600:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-cyan-700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-cyan-800:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-cyan-900:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-cyan-default:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-cyan-A100:focus {
    --tw-text-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-cyan-A200:focus {
    --tw-text-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-cyan-A400:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-text-opacity))
  }

  .\32xl\:focus\:text-cyan-A700:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-text-opacity))
  }

  .\32xl\:text-opacity-0 {
    --tw-text-opacity: 0
  }

  .\32xl\:text-opacity-5 {
    --tw-text-opacity: 0.05
  }

  .\32xl\:text-opacity-10 {
    --tw-text-opacity: 0.1
  }

  .\32xl\:text-opacity-20 {
    --tw-text-opacity: 0.2
  }

  .\32xl\:text-opacity-25 {
    --tw-text-opacity: 0.25
  }

  .\32xl\:text-opacity-30 {
    --tw-text-opacity: 0.3
  }

  .\32xl\:text-opacity-40 {
    --tw-text-opacity: 0.4
  }

  .\32xl\:text-opacity-50 {
    --tw-text-opacity: 0.5
  }

  .\32xl\:text-opacity-60 {
    --tw-text-opacity: 0.6
  }

  .\32xl\:text-opacity-70 {
    --tw-text-opacity: 0.7
  }

  .\32xl\:text-opacity-75 {
    --tw-text-opacity: 0.75
  }

  .\32xl\:text-opacity-80 {
    --tw-text-opacity: 0.8
  }

  .\32xl\:text-opacity-90 {
    --tw-text-opacity: 0.9
  }

  .\32xl\:text-opacity-95 {
    --tw-text-opacity: 0.95
  }

  .\32xl\:text-opacity-100 {
    --tw-text-opacity: 1
  }

  .group:hover .\32xl\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0
  }

  .group:hover .\32xl\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05
  }

  .group:hover .\32xl\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1
  }

  .group:hover .\32xl\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2
  }

  .group:hover .\32xl\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25
  }

  .group:hover .\32xl\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3
  }

  .group:hover .\32xl\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4
  }

  .group:hover .\32xl\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5
  }

  .group:hover .\32xl\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6
  }

  .group:hover .\32xl\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7
  }

  .group:hover .\32xl\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75
  }

  .group:hover .\32xl\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8
  }

  .group:hover .\32xl\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9
  }

  .group:hover .\32xl\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95
  }

  .group:hover .\32xl\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1
  }

  .\32xl\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0
  }

  .\32xl\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05
  }

  .\32xl\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1
  }

  .\32xl\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2
  }

  .\32xl\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25
  }

  .\32xl\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3
  }

  .\32xl\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4
  }

  .\32xl\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5
  }

  .\32xl\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6
  }

  .\32xl\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7
  }

  .\32xl\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75
  }

  .\32xl\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8
  }

  .\32xl\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9
  }

  .\32xl\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95
  }

  .\32xl\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1
  }

  .\32xl\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0
  }

  .\32xl\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05
  }

  .\32xl\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1
  }

  .\32xl\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2
  }

  .\32xl\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25
  }

  .\32xl\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3
  }

  .\32xl\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4
  }

  .\32xl\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5
  }

  .\32xl\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6
  }

  .\32xl\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7
  }

  .\32xl\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75
  }

  .\32xl\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8
  }

  .\32xl\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9
  }

  .\32xl\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95
  }

  .\32xl\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1
  }

  .\32xl\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0
  }

  .\32xl\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05
  }

  .\32xl\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1
  }

  .\32xl\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2
  }

  .\32xl\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25
  }

  .\32xl\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3
  }

  .\32xl\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4
  }

  .\32xl\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5
  }

  .\32xl\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6
  }

  .\32xl\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7
  }

  .\32xl\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75
  }

  .\32xl\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8
  }

  .\32xl\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9
  }

  .\32xl\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95
  }

  .\32xl\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1
  }

  .\32xl\:underline {
    text-decoration: underline
  }

  .\32xl\:line-through {
    text-decoration: line-through
  }

  .\32xl\:no-underline {
    text-decoration: none
  }

  .\32xl\:hover\:underline:hover {
    text-decoration: underline
  }

  .\32xl\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .\32xl\:hover\:no-underline:hover {
    text-decoration: none
  }

  .\32xl\:focus\:underline:focus {
    text-decoration: underline
  }

  .\32xl\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .\32xl\:focus\:no-underline:focus {
    text-decoration: none
  }

  .\32xl\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .\32xl\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .\32xl\:placeholder-transparent:-ms-input-placeholder {
    color: transparent
  }

  .\32xl\:placeholder-transparent::placeholder {
    color: transparent
  }

  .\32xl\:placeholder-current:-ms-input-placeholder {
    color: currentColor
  }

  .\32xl\:placeholder-current::placeholder {
    color: currentColor
  }

  .\32xl\:placeholder-black:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-gray-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-red-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-yellow-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-green-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-indigo-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-purple-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-pink-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-grey-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-orange-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-orange-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-green-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-teal-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-light-blue-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-deep-purple-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-lime-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-amber-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-brown-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-blue-gray-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-default:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-default::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-A100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-A100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-A200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-A200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-A400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-A400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-A700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-cyan-A700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent
  }

  .\32xl\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent
  }

  .\32xl\:focus\:placeholder-current:focus:-ms-input-placeholder {
    color: currentColor
  }

  .\32xl\:focus\:placeholder-current:focus::placeholder {
    color: currentColor
  }

  .\32xl\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(34, 41, 47, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-gray-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 238, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 205, 210, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 154, 154, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 115, 115, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 83, 80, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 57, 53, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(211, 47, 47, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 40, 40, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 28, 28, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 67, 54, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 82, 82, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 23, 68, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-red-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 253, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 249, 196, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 245, 157, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 241, 118, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 238, 88, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 216, 53, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 192, 45, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 37, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 127, 23, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 235, 59, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 141, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 234, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-yellow-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 214, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 245, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(200, 230, 201, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 214, 167, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 199, 132, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(102, 187, 106, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 160, 71, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(56, 142, 60, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 125, 50, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 94, 32, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 175, 80, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 246, 202, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(105, 240, 174, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 230, 118, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-green-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 200, 83, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(227, 242, 253, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(187, 222, 251, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 202, 249, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 181, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 165, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 136, 229, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(25, 118, 210, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(21, 101, 192, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(13, 71, 161, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 150, 243, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 177, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(68, 138, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 121, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 98, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(232, 234, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 202, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(159, 168, 218, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 134, 203, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(92, 107, 192, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(57, 73, 171, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 63, 159, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(40, 53, 147, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(26, 35, 126, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(63, 81, 181, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(140, 158, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(83, 109, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(61, 90, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-indigo-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 79, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 229, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 190, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(206, 147, 216, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(186, 104, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(171, 71, 188, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(142, 36, 170, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(123, 31, 162, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(106, 27, 154, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(74, 20, 140, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 39, 176, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 128, 252, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 64, 251, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 0, 249, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-purple-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 0, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 228, 236, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 187, 208, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 143, 177, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 98, 146, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 64, 122, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 27, 96, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(194, 24, 91, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(173, 20, 87, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(136, 14, 79, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 30, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 128, 171, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 64, 129, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 0, 87, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-pink-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 17, 98, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(250, 250, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 245, 245, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 238, 238, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 224, 224, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(189, 189, 189, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(117, 117, 117, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(66, 66, 66, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 158, 158, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(213, 213, 213, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 170, 170, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(48, 48, 48, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-grey-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 97, 97, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 243, 224, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 178, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 183, 77, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 167, 38, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 140, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 124, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 108, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 81, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 152, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 209, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 145, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-orange-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 109, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 233, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 204, 188, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 145, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 138, 101, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 112, 67, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 81, 30, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 74, 25, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(216, 67, 21, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 54, 12, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 87, 34, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 158, 128, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 110, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 61, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-orange-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 44, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(241, 248, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 237, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(197, 225, 165, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 213, 129, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 204, 101, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 179, 66, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(104, 159, 56, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(85, 139, 47, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(51, 105, 30, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 195, 74, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 255, 144, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 255, 89, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(118, 255, 3, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-green-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 221, 23, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 242, 241, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 223, 219, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 203, 196, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 182, 172, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 166, 154, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 137, 123, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 121, 107, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 105, 92, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 77, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 150, 136, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 255, 235, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(100, 255, 218, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 233, 182, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-teal-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 191, 165, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(225, 245, 254, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 229, 252, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 212, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 195, 247, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(41, 182, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 155, 229, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 136, 209, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(2, 119, 189, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(1, 87, 155, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(3, 169, 244, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 216, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(64, 196, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 176, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-light-blue-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 145, 234, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 231, 246, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 196, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 157, 219, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(149, 117, 205, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(126, 87, 194, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(94, 53, 177, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(81, 45, 168, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 39, 160, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 27, 146, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(103, 58, 183, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(179, 136, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 77, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(101, 31, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-deep-purple-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(98, 0, 234, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 251, 231, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(240, 244, 195, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 238, 156, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 231, 117, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(212, 225, 87, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(192, 202, 51, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(175, 180, 43, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(158, 157, 36, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(130, 119, 23, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(205, 220, 57, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 255, 129, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 255, 65, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(198, 255, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-lime-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(174, 234, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 248, 225, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 236, 179, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 224, 130, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 213, 79, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 202, 40, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 179, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 160, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 143, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 111, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 193, 7, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 229, 127, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 215, 64, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 196, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-amber-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 171, 0, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 235, 233, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(161, 136, 127, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 76, 65, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(78, 52, 46, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(62, 39, 35, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(121, 85, 72, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(215, 204, 200, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(188, 170, 164, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(141, 110, 99, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-brown-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(93, 64, 55, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 239, 241, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(144, 164, 174, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(84, 110, 122, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 71, 79, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 50, 56, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 125, 139, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(207, 216, 220, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(176, 190, 197, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 144, 156, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-blue-gray-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(69, 90, 100, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 247, 250, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(178, 235, 242, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(128, 222, 234, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(77, 208, 225, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 198, 218, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 172, 193, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 151, 167, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 131, 143, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 96, 100, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-default:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-default:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 188, 212, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-A100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-A100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(132, 255, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-A200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-A200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(24, 255, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-A400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-A400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 229, 255, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-A700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
  }

  .\32xl\:focus\:placeholder-cyan-A700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 184, 212, var(--tw-placeholder-opacity))
  }

  .\32xl\:placeholder-opacity-0:-ms-input-placeholder {
    --tw-placeholder-opacity: 0
  }

  .\32xl\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0
  }

  .\32xl\:placeholder-opacity-5:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05
  }

  .\32xl\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05
  }

  .\32xl\:placeholder-opacity-10:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1
  }

  .\32xl\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1
  }

  .\32xl\:placeholder-opacity-20:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2
  }

  .\32xl\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2
  }

  .\32xl\:placeholder-opacity-25:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25
  }

  .\32xl\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25
  }

  .\32xl\:placeholder-opacity-30:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3
  }

  .\32xl\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3
  }

  .\32xl\:placeholder-opacity-40:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4
  }

  .\32xl\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4
  }

  .\32xl\:placeholder-opacity-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5
  }

  .\32xl\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5
  }

  .\32xl\:placeholder-opacity-60:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6
  }

  .\32xl\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6
  }

  .\32xl\:placeholder-opacity-70:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7
  }

  .\32xl\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7
  }

  .\32xl\:placeholder-opacity-75:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75
  }

  .\32xl\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75
  }

  .\32xl\:placeholder-opacity-80:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8
  }

  .\32xl\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8
  }

  .\32xl\:placeholder-opacity-90:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9
  }

  .\32xl\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9
  }

  .\32xl\:placeholder-opacity-95:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95
  }

  .\32xl\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95
  }

  .\32xl\:placeholder-opacity-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1
  }

  .\32xl\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1
  }

  .\32xl\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0
  }

  .\32xl\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0
  }

  .\32xl\:focus\:placeholder-opacity-5:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05
  }

  .\32xl\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05
  }

  .\32xl\:focus\:placeholder-opacity-10:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1
  }

  .\32xl\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1
  }

  .\32xl\:focus\:placeholder-opacity-20:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2
  }

  .\32xl\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2
  }

  .\32xl\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25
  }

  .\32xl\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25
  }

  .\32xl\:focus\:placeholder-opacity-30:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3
  }

  .\32xl\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3
  }

  .\32xl\:focus\:placeholder-opacity-40:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4
  }

  .\32xl\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4
  }

  .\32xl\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5
  }

  .\32xl\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5
  }

  .\32xl\:focus\:placeholder-opacity-60:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6
  }

  .\32xl\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6
  }

  .\32xl\:focus\:placeholder-opacity-70:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7
  }

  .\32xl\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7
  }

  .\32xl\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75
  }

  .\32xl\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75
  }

  .\32xl\:focus\:placeholder-opacity-80:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8
  }

  .\32xl\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8
  }

  .\32xl\:focus\:placeholder-opacity-90:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9
  }

  .\32xl\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9
  }

  .\32xl\:focus\:placeholder-opacity-95:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95
  }

  .\32xl\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95
  }

  .\32xl\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1
  }

  .\32xl\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1
  }

  .\32xl\:opacity-0 {
    opacity: 0
  }

  .\32xl\:opacity-5 {
    opacity: 0.05
  }

  .\32xl\:opacity-10 {
    opacity: 0.1
  }

  .\32xl\:opacity-20 {
    opacity: 0.2
  }

  .\32xl\:opacity-25 {
    opacity: 0.25
  }

  .\32xl\:opacity-30 {
    opacity: 0.3
  }

  .\32xl\:opacity-40 {
    opacity: 0.4
  }

  .\32xl\:opacity-50 {
    opacity: 0.5
  }

  .\32xl\:opacity-60 {
    opacity: 0.6
  }

  .\32xl\:opacity-70 {
    opacity: 0.7
  }

  .\32xl\:opacity-75 {
    opacity: 0.75
  }

  .\32xl\:opacity-80 {
    opacity: 0.8
  }

  .\32xl\:opacity-90 {
    opacity: 0.9
  }

  .\32xl\:opacity-95 {
    opacity: 0.95
  }

  .\32xl\:opacity-100 {
    opacity: 1
  }

  .\32xl\:bg-blend-normal {
    background-blend-mode: normal
  }

  .\32xl\:bg-blend-multiply {
    background-blend-mode: multiply
  }

  .\32xl\:bg-blend-screen {
    background-blend-mode: screen
  }

  .\32xl\:bg-blend-overlay {
    background-blend-mode: overlay
  }

  .\32xl\:bg-blend-darken {
    background-blend-mode: darken
  }

  .\32xl\:bg-blend-lighten {
    background-blend-mode: lighten
  }

  .\32xl\:bg-blend-color-dodge {
    background-blend-mode: color-dodge
  }

  .\32xl\:bg-blend-color-burn {
    background-blend-mode: color-burn
  }

  .\32xl\:bg-blend-hard-light {
    background-blend-mode: hard-light
  }

  .\32xl\:bg-blend-soft-light {
    background-blend-mode: soft-light
  }

  .\32xl\:bg-blend-difference {
    background-blend-mode: difference
  }

  .\32xl\:bg-blend-exclusion {
    background-blend-mode: exclusion
  }

  .\32xl\:bg-blend-hue {
    background-blend-mode: hue
  }

  .\32xl\:bg-blend-saturation {
    background-blend-mode: saturation
  }

  .\32xl\:bg-blend-color {
    background-blend-mode: color
  }

  .\32xl\:bg-blend-luminosity {
    background-blend-mode: luminosity
  }

  .\32xl\:mix-blend-normal {
    mix-blend-mode: normal
  }

  .\32xl\:mix-blend-multiply {
    mix-blend-mode: multiply
  }

  .\32xl\:mix-blend-screen {
    mix-blend-mode: screen
  }

  .\32xl\:mix-blend-overlay {
    mix-blend-mode: overlay
  }

  .\32xl\:mix-blend-darken {
    mix-blend-mode: darken
  }

  .\32xl\:mix-blend-lighten {
    mix-blend-mode: lighten
  }

  .\32xl\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge
  }

  .\32xl\:mix-blend-color-burn {
    mix-blend-mode: color-burn
  }

  .\32xl\:mix-blend-hard-light {
    mix-blend-mode: hard-light
  }

  .\32xl\:mix-blend-soft-light {
    mix-blend-mode: soft-light
  }

  .\32xl\:mix-blend-difference {
    mix-blend-mode: difference
  }

  .\32xl\:mix-blend-exclusion {
    mix-blend-mode: exclusion
  }

  .\32xl\:mix-blend-hue {
    mix-blend-mode: hue
  }

  .\32xl\:mix-blend-saturation {
    mix-blend-mode: saturation
  }

  .\32xl\:mix-blend-color {
    mix-blend-mode: color
  }

  .\32xl\:mix-blend-luminosity {
    mix-blend-mode: luminosity
  }

  .\32xl\:shadow-0 {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-1 {
    --tw-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-2 {
    --tw-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-3 {
    --tw-shadow: 0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-4 {
    --tw-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-5 {
    --tw-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-6 {
    --tw-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-7 {
    --tw-shadow: 0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-8 {
    --tw-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-9 {
    --tw-shadow: 0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-10 {
    --tw-shadow: 0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-11 {
    --tw-shadow: 0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-12 {
    --tw-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-13 {
    --tw-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-14 {
    --tw-shadow: 0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-15 {
    --tw-shadow: 0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-16 {
    --tw-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-17 {
    --tw-shadow: 0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-18 {
    --tw-shadow: 0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-19 {
    --tw-shadow: 0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-20 {
    --tw-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-21 {
    --tw-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-22 {
    --tw-shadow: 0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-23 {
    --tw-shadow: 0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-24 {
    --tw-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-md {
    --tw-shadow: 0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-lg {
    --tw-shadow: 0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-default {
    --tw-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:shadow-outline {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-0:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-1:hover {
    --tw-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-2:hover {
    --tw-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-3:hover {
    --tw-shadow: 0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-4:hover {
    --tw-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-5:hover {
    --tw-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-6:hover {
    --tw-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-7:hover {
    --tw-shadow: 0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-8:hover {
    --tw-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-9:hover {
    --tw-shadow: 0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-10:hover {
    --tw-shadow: 0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-11:hover {
    --tw-shadow: 0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-12:hover {
    --tw-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-13:hover {
    --tw-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-14:hover {
    --tw-shadow: 0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-15:hover {
    --tw-shadow: 0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-16:hover {
    --tw-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-17:hover {
    --tw-shadow: 0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-18:hover {
    --tw-shadow: 0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-19:hover {
    --tw-shadow: 0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-20:hover {
    --tw-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-21:hover {
    --tw-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-22:hover {
    --tw-shadow: 0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-23:hover {
    --tw-shadow: 0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-24:hover {
    --tw-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-lg:hover {
    --tw-shadow: 0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-default:hover {
    --tw-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:hover\:shadow-outline:hover {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-0:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-1:focus {
    --tw-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-2:focus {
    --tw-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-3:focus {
    --tw-shadow: 0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-4:focus {
    --tw-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-5:focus {
    --tw-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-6:focus {
    --tw-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-7:focus {
    --tw-shadow: 0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-8:focus {
    --tw-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-9:focus {
    --tw-shadow: 0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-10:focus {
    --tw-shadow: 0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-11:focus {
    --tw-shadow: 0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-12:focus {
    --tw-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-13:focus {
    --tw-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-14:focus {
    --tw-shadow: 0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-15:focus {
    --tw-shadow: 0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-16:focus {
    --tw-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-17:focus {
    --tw-shadow: 0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-18:focus {
    --tw-shadow: 0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-19:focus {
    --tw-shadow: 0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-20:focus {
    --tw-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-21:focus {
    --tw-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-22:focus {
    --tw-shadow: 0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-23:focus {
    --tw-shadow: 0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-24:focus {
    --tw-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-lg:focus {
    --tw-shadow: 0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-default:focus {
    --tw-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:focus\:shadow-outline:focus {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .\32xl\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .\32xl\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .\32xl\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .\32xl\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .\32xl\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .\32xl\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .\32xl\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .\32xl\:ring-inset {
    --tw-ring-inset: inset
  }

  .\32xl\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset
  }

  .\32xl\:focus\:ring-inset:focus {
    --tw-ring-inset: inset
  }

  .\32xl\:ring-transparent {
    --tw-ring-color: transparent
  }

  .\32xl\:ring-current {
    --tw-ring-color: currentColor
  }

  .\32xl\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .\32xl\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .\32xl\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .\32xl\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .\32xl\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .\32xl\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .\32xl\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .\32xl\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .\32xl\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .\32xl\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .\32xl\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .\32xl\:ring-gray-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .\32xl\:ring-gray-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .\32xl\:ring-gray-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .\32xl\:ring-gray-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .\32xl\:ring-gray-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .\32xl\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .\32xl\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .\32xl\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .\32xl\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .\32xl\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .\32xl\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .\32xl\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .\32xl\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .\32xl\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .\32xl\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .\32xl\:ring-red-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .\32xl\:ring-red-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .\32xl\:ring-red-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .\32xl\:ring-red-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .\32xl\:ring-red-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .\32xl\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .\32xl\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .\32xl\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .\32xl\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .\32xl\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .\32xl\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .\32xl\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .\32xl\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .\32xl\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .\32xl\:ring-yellow-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .\32xl\:ring-yellow-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .\32xl\:ring-yellow-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-yellow-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-yellow-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .\32xl\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .\32xl\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .\32xl\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .\32xl\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .\32xl\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .\32xl\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .\32xl\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .\32xl\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .\32xl\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .\32xl\:ring-green-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .\32xl\:ring-green-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .\32xl\:ring-green-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .\32xl\:ring-green-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .\32xl\:ring-green-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .\32xl\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .\32xl\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .\32xl\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .\32xl\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .\32xl\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .\32xl\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .\32xl\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .\32xl\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .\32xl\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .\32xl\:ring-indigo-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .\32xl\:ring-indigo-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-indigo-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .\32xl\:ring-indigo-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .\32xl\:ring-indigo-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .\32xl\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .\32xl\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .\32xl\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .\32xl\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .\32xl\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .\32xl\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .\32xl\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .\32xl\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .\32xl\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .\32xl\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .\32xl\:ring-purple-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .\32xl\:ring-purple-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .\32xl\:ring-purple-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .\32xl\:ring-purple-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .\32xl\:ring-purple-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .\32xl\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .\32xl\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .\32xl\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .\32xl\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .\32xl\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .\32xl\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .\32xl\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .\32xl\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .\32xl\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .\32xl\:ring-pink-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .\32xl\:ring-pink-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .\32xl\:ring-pink-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .\32xl\:ring-pink-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .\32xl\:ring-pink-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .\32xl\:ring-grey-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .\32xl\:ring-grey-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .\32xl\:ring-grey-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .\32xl\:ring-grey-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .\32xl\:ring-grey-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .\32xl\:ring-grey-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .\32xl\:ring-grey-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .\32xl\:ring-grey-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .\32xl\:ring-grey-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .\32xl\:ring-grey-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .\32xl\:ring-grey-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .\32xl\:ring-grey-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .\32xl\:ring-grey-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .\32xl\:ring-grey-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .\32xl\:ring-grey-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .\32xl\:ring-orange-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .\32xl\:ring-orange-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .\32xl\:ring-orange-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .\32xl\:ring-orange-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .\32xl\:ring-orange-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .\32xl\:ring-orange-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-orange-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-orange-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-orange-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-orange-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-orange-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-orange-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .\32xl\:ring-orange-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .\32xl\:ring-orange-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-orange-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-orange-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-orange-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-orange-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-orange-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-orange-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-orange-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-orange-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-orange-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-orange-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-orange-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-orange-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-orange-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-orange-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-orange-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-orange-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-green-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-green-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-green-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-green-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-green-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .\32xl\:ring-teal-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .\32xl\:ring-teal-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .\32xl\:ring-teal-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .\32xl\:ring-teal-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .\32xl\:ring-teal-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .\32xl\:ring-teal-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .\32xl\:ring-teal-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .\32xl\:ring-teal-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .\32xl\:ring-teal-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .\32xl\:ring-teal-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .\32xl\:ring-teal-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .\32xl\:ring-teal-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .\32xl\:ring-teal-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .\32xl\:ring-teal-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .\32xl\:ring-teal-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-blue-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-blue-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-blue-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-blue-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-light-blue-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-purple-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-purple-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-purple-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-purple-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-deep-purple-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .\32xl\:ring-lime-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .\32xl\:ring-lime-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .\32xl\:ring-lime-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .\32xl\:ring-lime-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .\32xl\:ring-lime-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .\32xl\:ring-lime-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .\32xl\:ring-lime-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .\32xl\:ring-lime-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .\32xl\:ring-lime-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .\32xl\:ring-lime-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .\32xl\:ring-lime-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .\32xl\:ring-lime-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .\32xl\:ring-lime-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .\32xl\:ring-lime-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-lime-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-amber-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .\32xl\:ring-amber-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .\32xl\:ring-amber-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .\32xl\:ring-amber-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .\32xl\:ring-amber-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .\32xl\:ring-amber-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .\32xl\:ring-amber-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-amber-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-amber-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-amber-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-amber-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .\32xl\:ring-amber-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .\32xl\:ring-amber-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .\32xl\:ring-amber-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-amber-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .\32xl\:ring-brown-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .\32xl\:ring-brown-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .\32xl\:ring-brown-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .\32xl\:ring-brown-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .\32xl\:ring-brown-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .\32xl\:ring-brown-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .\32xl\:ring-brown-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .\32xl\:ring-brown-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .\32xl\:ring-brown-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .\32xl\:ring-brown-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .\32xl\:ring-brown-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .\32xl\:ring-brown-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .\32xl\:ring-brown-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .\32xl\:ring-brown-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .\32xl\:ring-brown-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-gray-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-gray-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-gray-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-gray-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .\32xl\:ring-blue-gray-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .\32xl\:ring-cyan-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .\32xl\:ring-cyan-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .\32xl\:ring-cyan-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .\32xl\:ring-cyan-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .\32xl\:ring-cyan-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .\32xl\:ring-cyan-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .\32xl\:ring-cyan-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .\32xl\:ring-cyan-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .\32xl\:ring-cyan-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .\32xl\:ring-cyan-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .\32xl\:ring-cyan-default {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .\32xl\:ring-cyan-A100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-cyan-A200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-cyan-A400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .\32xl\:ring-cyan-A700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent
  }

  .\32xl\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor
  }

  .\32xl\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-gray-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-gray-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-gray-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-gray-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-gray-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-red-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-red-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-red-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-red-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-red-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-yellow-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-yellow-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-yellow-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-yellow-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-yellow-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-green-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-green-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-green-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-green-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-green-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-indigo-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-indigo-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-indigo-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-indigo-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-indigo-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-purple-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-purple-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-purple-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-purple-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-purple-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-pink-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-pink-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-pink-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-pink-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-pink-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-grey-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-grey-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-grey-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-grey-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-grey-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-grey-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-grey-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-grey-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-grey-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-grey-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-grey-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-grey-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-grey-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-grey-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-grey-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-orange-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-orange-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-orange-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-orange-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-orange-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-orange-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-orange-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-orange-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-orange-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-orange-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-orange-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-orange-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-orange-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-orange-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-orange-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-orange-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-orange-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-orange-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-orange-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-orange-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-orange-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-orange-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-orange-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-orange-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-orange-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-orange-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-orange-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-orange-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-orange-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-orange-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-green-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-green-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-green-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-green-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-green-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-teal-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-teal-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-teal-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-teal-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-teal-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-teal-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-teal-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-teal-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-teal-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-teal-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-teal-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-teal-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-teal-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-teal-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-teal-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-blue-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-blue-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-blue-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-blue-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-light-blue-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-purple-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-purple-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-purple-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-purple-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-deep-purple-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-lime-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-lime-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-lime-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-lime-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-lime-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-lime-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-lime-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-lime-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-lime-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-lime-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-lime-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-lime-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-lime-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-lime-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-lime-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-amber-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-amber-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-amber-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-amber-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-amber-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-amber-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-amber-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-amber-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-amber-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-amber-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-amber-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-amber-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-amber-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-amber-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-amber-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-brown-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-brown-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-brown-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-brown-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-brown-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-brown-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-brown-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-brown-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-brown-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-brown-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-brown-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-brown-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-brown-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-brown-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-brown-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-gray-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-gray-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-gray-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-gray-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-blue-gray-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-cyan-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-cyan-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-cyan-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-cyan-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-cyan-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-cyan-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-cyan-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-cyan-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-cyan-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-cyan-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-cyan-default:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-cyan-A100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-cyan-A200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-cyan-A400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus-within\:ring-cyan-A700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent
  }

  .\32xl\:focus\:ring-current:focus {
    --tw-ring-color: currentColor
  }

  .\32xl\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(34, 41, 47, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-gray-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-gray-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-gray-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-gray-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-gray-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 238, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 205, 210, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 154, 154, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 115, 115, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 83, 80, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 57, 53, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(211, 47, 47, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 40, 40, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(183, 28, 28, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-red-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-red-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 128, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-red-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 82, 82, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-red-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 23, 68, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-red-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 253, 231, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 249, 196, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 245, 157, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 241, 118, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 238, 88, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 216, 53, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 192, 45, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 37, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 127, 23, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-yellow-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 235, 59, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-yellow-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 141, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-yellow-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-yellow-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 234, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-yellow-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 214, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 245, 233, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(200, 230, 201, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 214, 167, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 199, 132, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(102, 187, 106, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 160, 71, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(56, 142, 60, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 125, 50, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(27, 94, 32, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-green-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 175, 80, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-green-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 246, 202, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-green-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(105, 240, 174, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-green-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 230, 118, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-green-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 200, 83, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 242, 253, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(187, 222, 251, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 202, 249, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 181, 246, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 165, 245, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 136, 229, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 118, 210, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(21, 101, 192, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(13, 71, 161, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 150, 243, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 177, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(68, 138, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 121, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 98, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(232, 234, 246, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 202, 233, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(159, 168, 218, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 134, 203, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(92, 107, 192, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 73, 171, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 63, 159, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(40, 53, 147, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 35, 126, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-indigo-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(63, 81, 181, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-indigo-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(140, 158, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-indigo-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(83, 109, 254, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-indigo-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(61, 90, 254, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-indigo-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 79, 254, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 229, 245, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 190, 231, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(206, 147, 216, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(186, 104, 200, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(171, 71, 188, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 36, 170, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(123, 31, 162, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(106, 27, 154, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 20, 140, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-purple-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 39, 176, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-purple-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(234, 128, 252, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-purple-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 64, 251, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-purple-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 0, 249, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-purple-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 0, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 228, 236, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 187, 208, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 143, 177, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 98, 146, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 64, 122, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 27, 96, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(194, 24, 91, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(173, 20, 87, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(136, 14, 79, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-pink-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(233, 30, 99, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-pink-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 128, 171, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-pink-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 64, 129, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-pink-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 0, 87, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-pink-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 17, 98, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-grey-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-grey-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-grey-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 238, 238, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-grey-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 224, 224, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-grey-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(189, 189, 189, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-grey-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-grey-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(117, 117, 117, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-grey-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-grey-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 66, 66, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-grey-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-grey-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 158, 158, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-grey-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(213, 213, 213, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-grey-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(170, 170, 170, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-grey-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(48, 48, 48, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-grey-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 97, 97, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-orange-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 243, 224, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-orange-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 178, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-orange-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 128, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-orange-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 183, 77, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-orange-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 167, 38, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-orange-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-orange-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 140, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-orange-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 124, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-orange-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 108, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-orange-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 81, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-orange-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 152, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-orange-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 209, 128, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-orange-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 64, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-orange-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 145, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-orange-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 109, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-orange-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 233, 231, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-orange-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 204, 188, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-orange-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 145, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-orange-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 138, 101, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-orange-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 112, 67, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-orange-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-orange-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 81, 30, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-orange-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 74, 25, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-orange-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(216, 67, 21, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-orange-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 54, 12, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-orange-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 87, 34, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-orange-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 158, 128, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-orange-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 110, 64, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-orange-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 61, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-orange-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 44, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(241, 248, 233, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 237, 200, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(197, 225, 165, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 213, 129, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 204, 101, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 179, 66, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(104, 159, 56, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(85, 139, 47, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(51, 105, 30, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-green-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 195, 74, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-green-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 255, 144, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-green-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 255, 89, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-green-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(118, 255, 3, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-green-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 221, 23, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-teal-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 242, 241, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-teal-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 223, 219, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-teal-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 203, 196, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-teal-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 182, 172, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-teal-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 166, 154, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-teal-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-teal-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 137, 123, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-teal-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 121, 107, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-teal-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 105, 92, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-teal-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 77, 64, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-teal-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 150, 136, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-teal-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 255, 235, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-teal-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(100, 255, 218, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-teal-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 233, 182, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-teal-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 191, 165, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(225, 245, 254, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 229, 252, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 212, 250, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 195, 247, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(41, 182, 246, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 155, 229, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 136, 209, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(2, 119, 189, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(1, 87, 155, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-blue-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(3, 169, 244, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-blue-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 216, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-blue-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(64, 196, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-blue-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-light-blue-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 145, 234, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 231, 246, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 196, 233, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 157, 219, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(149, 117, 205, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(126, 87, 194, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(94, 53, 177, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(81, 45, 168, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 39, 160, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 27, 146, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-purple-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(103, 58, 183, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-purple-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(179, 136, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-purple-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 77, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-purple-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(101, 31, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-deep-purple-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(98, 0, 234, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-lime-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 251, 231, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-lime-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(240, 244, 195, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-lime-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(230, 238, 156, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-lime-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 231, 117, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-lime-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(212, 225, 87, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-lime-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-lime-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(192, 202, 51, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-lime-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(175, 180, 43, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-lime-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(158, 157, 36, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-lime-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(130, 119, 23, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-lime-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(205, 220, 57, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-lime-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 255, 129, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-lime-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 255, 65, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-lime-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(198, 255, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-lime-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(174, 234, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-amber-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 248, 225, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-amber-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 236, 179, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-amber-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 224, 130, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-amber-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 213, 79, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-amber-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 202, 40, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-amber-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-amber-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 179, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-amber-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 160, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-amber-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 143, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-amber-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 111, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-amber-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 193, 7, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-amber-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 229, 127, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-amber-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 215, 64, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-amber-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 196, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-amber-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 171, 0, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-brown-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 235, 233, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-brown-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-brown-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-brown-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(161, 136, 127, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-brown-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-brown-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-brown-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 76, 65, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-brown-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-brown-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(78, 52, 46, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-brown-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(62, 39, 35, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-brown-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(121, 85, 72, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-brown-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(215, 204, 200, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-brown-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(188, 170, 164, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-brown-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(141, 110, 99, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-brown-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(93, 64, 55, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 239, 241, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 164, 174, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(84, 110, 122, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 71, 79, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 50, 56, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-gray-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 125, 139, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-gray-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(207, 216, 220, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-gray-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(176, 190, 197, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-gray-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 144, 156, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-blue-gray-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(69, 90, 100, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-cyan-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 247, 250, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-cyan-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(178, 235, 242, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-cyan-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(128, 222, 234, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-cyan-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(77, 208, 225, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-cyan-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(38, 198, 218, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-cyan-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-cyan-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 172, 193, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-cyan-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 151, 167, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-cyan-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 131, 143, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-cyan-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 96, 100, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-cyan-default:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 188, 212, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-cyan-A100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(132, 255, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-cyan-A200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(24, 255, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-cyan-A400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 229, 255, var(--tw-ring-opacity))
  }

  .\32xl\:focus\:ring-cyan-A700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 184, 212, var(--tw-ring-opacity))
  }

  .\32xl\:ring-opacity-0 {
    --tw-ring-opacity: 0
  }

  .\32xl\:ring-opacity-5 {
    --tw-ring-opacity: 0.05
  }

  .\32xl\:ring-opacity-10 {
    --tw-ring-opacity: 0.1
  }

  .\32xl\:ring-opacity-20 {
    --tw-ring-opacity: 0.2
  }

  .\32xl\:ring-opacity-25 {
    --tw-ring-opacity: 0.25
  }

  .\32xl\:ring-opacity-30 {
    --tw-ring-opacity: 0.3
  }

  .\32xl\:ring-opacity-40 {
    --tw-ring-opacity: 0.4
  }

  .\32xl\:ring-opacity-50 {
    --tw-ring-opacity: 0.5
  }

  .\32xl\:ring-opacity-60 {
    --tw-ring-opacity: 0.6
  }

  .\32xl\:ring-opacity-70 {
    --tw-ring-opacity: 0.7
  }

  .\32xl\:ring-opacity-75 {
    --tw-ring-opacity: 0.75
  }

  .\32xl\:ring-opacity-80 {
    --tw-ring-opacity: 0.8
  }

  .\32xl\:ring-opacity-90 {
    --tw-ring-opacity: 0.9
  }

  .\32xl\:ring-opacity-95 {
    --tw-ring-opacity: 0.95
  }

  .\32xl\:ring-opacity-100 {
    --tw-ring-opacity: 1
  }

  .\32xl\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0
  }

  .\32xl\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05
  }

  .\32xl\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1
  }

  .\32xl\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2
  }

  .\32xl\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25
  }

  .\32xl\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3
  }

  .\32xl\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4
  }

  .\32xl\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5
  }

  .\32xl\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6
  }

  .\32xl\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7
  }

  .\32xl\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75
  }

  .\32xl\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8
  }

  .\32xl\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9
  }

  .\32xl\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95
  }

  .\32xl\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1
  }

  .\32xl\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0
  }

  .\32xl\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05
  }

  .\32xl\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1
  }

  .\32xl\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2
  }

  .\32xl\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25
  }

  .\32xl\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3
  }

  .\32xl\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4
  }

  .\32xl\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5
  }

  .\32xl\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6
  }

  .\32xl\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7
  }

  .\32xl\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75
  }

  .\32xl\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8
  }

  .\32xl\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9
  }

  .\32xl\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95
  }

  .\32xl\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1
  }

  .\32xl\:ring-offset-0 {
    --tw-ring-offset-width: 0px
  }

  .\32xl\:ring-offset-1 {
    --tw-ring-offset-width: 1px
  }

  .\32xl\:ring-offset-2 {
    --tw-ring-offset-width: 2px
  }

  .\32xl\:ring-offset-4 {
    --tw-ring-offset-width: 4px
  }

  .\32xl\:ring-offset-8 {
    --tw-ring-offset-width: 8px
  }

  .\32xl\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px
  }

  .\32xl\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px
  }

  .\32xl\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px
  }

  .\32xl\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px
  }

  .\32xl\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px
  }

  .\32xl\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px
  }

  .\32xl\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px
  }

  .\32xl\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px
  }

  .\32xl\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px
  }

  .\32xl\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px
  }

  .\32xl\:ring-offset-transparent {
    --tw-ring-offset-color: transparent
  }

  .\32xl\:ring-offset-current {
    --tw-ring-offset-color: currentColor
  }

  .\32xl\:ring-offset-black {
    --tw-ring-offset-color: #22292F
  }

  .\32xl\:ring-offset-white {
    --tw-ring-offset-color: #FFF
  }

  .\32xl\:ring-offset-gray-50 {
    --tw-ring-offset-color: #FAFAFA
  }

  .\32xl\:ring-offset-gray-100 {
    --tw-ring-offset-color: #F5F5F5
  }

  .\32xl\:ring-offset-gray-200 {
    --tw-ring-offset-color: #EEEEEE
  }

  .\32xl\:ring-offset-gray-300 {
    --tw-ring-offset-color: #E0E0E0
  }

  .\32xl\:ring-offset-gray-400 {
    --tw-ring-offset-color: #BDBDBD
  }

  .\32xl\:ring-offset-gray-500 {
    --tw-ring-offset-color: #9E9E9E
  }

  .\32xl\:ring-offset-gray-600 {
    --tw-ring-offset-color: #757575
  }

  .\32xl\:ring-offset-gray-700 {
    --tw-ring-offset-color: #616161
  }

  .\32xl\:ring-offset-gray-800 {
    --tw-ring-offset-color: #424242
  }

  .\32xl\:ring-offset-gray-900 {
    --tw-ring-offset-color: #212121
  }

  .\32xl\:ring-offset-gray-default {
    --tw-ring-offset-color: #9E9E9E
  }

  .\32xl\:ring-offset-gray-A100 {
    --tw-ring-offset-color: #D5D5D5
  }

  .\32xl\:ring-offset-gray-A200 {
    --tw-ring-offset-color: #AAAAAA
  }

  .\32xl\:ring-offset-gray-A400 {
    --tw-ring-offset-color: #303030
  }

  .\32xl\:ring-offset-gray-A700 {
    --tw-ring-offset-color: #616161
  }

  .\32xl\:ring-offset-red-50 {
    --tw-ring-offset-color: #FFEBEE
  }

  .\32xl\:ring-offset-red-100 {
    --tw-ring-offset-color: #FFCDD2
  }

  .\32xl\:ring-offset-red-200 {
    --tw-ring-offset-color: #EF9A9A
  }

  .\32xl\:ring-offset-red-300 {
    --tw-ring-offset-color: #E57373
  }

  .\32xl\:ring-offset-red-400 {
    --tw-ring-offset-color: #EF5350
  }

  .\32xl\:ring-offset-red-500 {
    --tw-ring-offset-color: #F44336
  }

  .\32xl\:ring-offset-red-600 {
    --tw-ring-offset-color: #E53935
  }

  .\32xl\:ring-offset-red-700 {
    --tw-ring-offset-color: #D32F2F
  }

  .\32xl\:ring-offset-red-800 {
    --tw-ring-offset-color: #C62828
  }

  .\32xl\:ring-offset-red-900 {
    --tw-ring-offset-color: #B71C1C
  }

  .\32xl\:ring-offset-red-default {
    --tw-ring-offset-color: #F44336
  }

  .\32xl\:ring-offset-red-A100 {
    --tw-ring-offset-color: #FF8A80
  }

  .\32xl\:ring-offset-red-A200 {
    --tw-ring-offset-color: #FF5252
  }

  .\32xl\:ring-offset-red-A400 {
    --tw-ring-offset-color: #FF1744
  }

  .\32xl\:ring-offset-red-A700 {
    --tw-ring-offset-color: #D50000
  }

  .\32xl\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #FFFDE7
  }

  .\32xl\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #FFF9C4
  }

  .\32xl\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #FFF59D
  }

  .\32xl\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #FFF176
  }

  .\32xl\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #FFEE58
  }

  .\32xl\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #FFEB3B
  }

  .\32xl\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #FDD835
  }

  .\32xl\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #FBC02D
  }

  .\32xl\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #F9A825
  }

  .\32xl\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #F57F17
  }

  .\32xl\:ring-offset-yellow-default {
    --tw-ring-offset-color: #FFEB3B
  }

  .\32xl\:ring-offset-yellow-A100 {
    --tw-ring-offset-color: #FFFF8D
  }

  .\32xl\:ring-offset-yellow-A200 {
    --tw-ring-offset-color: #FFFF00
  }

  .\32xl\:ring-offset-yellow-A400 {
    --tw-ring-offset-color: #FFEA00
  }

  .\32xl\:ring-offset-yellow-A700 {
    --tw-ring-offset-color: #FFD600
  }

  .\32xl\:ring-offset-green-50 {
    --tw-ring-offset-color: #E8F5E9
  }

  .\32xl\:ring-offset-green-100 {
    --tw-ring-offset-color: #C8E6C9
  }

  .\32xl\:ring-offset-green-200 {
    --tw-ring-offset-color: #A5D6A7
  }

  .\32xl\:ring-offset-green-300 {
    --tw-ring-offset-color: #81C784
  }

  .\32xl\:ring-offset-green-400 {
    --tw-ring-offset-color: #66BB6A
  }

  .\32xl\:ring-offset-green-500 {
    --tw-ring-offset-color: #4CAF50
  }

  .\32xl\:ring-offset-green-600 {
    --tw-ring-offset-color: #43A047
  }

  .\32xl\:ring-offset-green-700 {
    --tw-ring-offset-color: #388E3C
  }

  .\32xl\:ring-offset-green-800 {
    --tw-ring-offset-color: #2E7D32
  }

  .\32xl\:ring-offset-green-900 {
    --tw-ring-offset-color: #1B5E20
  }

  .\32xl\:ring-offset-green-default {
    --tw-ring-offset-color: #4CAF50
  }

  .\32xl\:ring-offset-green-A100 {
    --tw-ring-offset-color: #B9F6CA
  }

  .\32xl\:ring-offset-green-A200 {
    --tw-ring-offset-color: #69F0AE
  }

  .\32xl\:ring-offset-green-A400 {
    --tw-ring-offset-color: #00E676
  }

  .\32xl\:ring-offset-green-A700 {
    --tw-ring-offset-color: #00C853
  }

  .\32xl\:ring-offset-blue-50 {
    --tw-ring-offset-color: #E3F2FD
  }

  .\32xl\:ring-offset-blue-100 {
    --tw-ring-offset-color: #BBDEFB
  }

  .\32xl\:ring-offset-blue-200 {
    --tw-ring-offset-color: #90CAF9
  }

  .\32xl\:ring-offset-blue-300 {
    --tw-ring-offset-color: #64B5F6
  }

  .\32xl\:ring-offset-blue-400 {
    --tw-ring-offset-color: #42A5F5
  }

  .\32xl\:ring-offset-blue-500 {
    --tw-ring-offset-color: #2196F3
  }

  .\32xl\:ring-offset-blue-600 {
    --tw-ring-offset-color: #1E88E5
  }

  .\32xl\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1976D2
  }

  .\32xl\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1565C0
  }

  .\32xl\:ring-offset-blue-900 {
    --tw-ring-offset-color: #0D47A1
  }

  .\32xl\:ring-offset-blue-default {
    --tw-ring-offset-color: #2196F3
  }

  .\32xl\:ring-offset-blue-A100 {
    --tw-ring-offset-color: #82B1FF
  }

  .\32xl\:ring-offset-blue-A200 {
    --tw-ring-offset-color: #448AFF
  }

  .\32xl\:ring-offset-blue-A400 {
    --tw-ring-offset-color: #2979FF
  }

  .\32xl\:ring-offset-blue-A700 {
    --tw-ring-offset-color: #2962FF
  }

  .\32xl\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #E8EAF6
  }

  .\32xl\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #C5CAE9
  }

  .\32xl\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #9FA8DA
  }

  .\32xl\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #7986CB
  }

  .\32xl\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #5C6BC0
  }

  .\32xl\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #3F51B5
  }

  .\32xl\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #3949AB
  }

  .\32xl\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #303F9F
  }

  .\32xl\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #283593
  }

  .\32xl\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #1A237E
  }

  .\32xl\:ring-offset-indigo-default {
    --tw-ring-offset-color: #3F51B5
  }

  .\32xl\:ring-offset-indigo-A100 {
    --tw-ring-offset-color: #8C9EFF
  }

  .\32xl\:ring-offset-indigo-A200 {
    --tw-ring-offset-color: #536DFE
  }

  .\32xl\:ring-offset-indigo-A400 {
    --tw-ring-offset-color: #3D5AFE
  }

  .\32xl\:ring-offset-indigo-A700 {
    --tw-ring-offset-color: #304FFE
  }

  .\32xl\:ring-offset-purple-50 {
    --tw-ring-offset-color: #F3E5F5
  }

  .\32xl\:ring-offset-purple-100 {
    --tw-ring-offset-color: #E1BEE7
  }

  .\32xl\:ring-offset-purple-200 {
    --tw-ring-offset-color: #CE93D8
  }

  .\32xl\:ring-offset-purple-300 {
    --tw-ring-offset-color: #BA68C8
  }

  .\32xl\:ring-offset-purple-400 {
    --tw-ring-offset-color: #AB47BC
  }

  .\32xl\:ring-offset-purple-500 {
    --tw-ring-offset-color: #9C27B0
  }

  .\32xl\:ring-offset-purple-600 {
    --tw-ring-offset-color: #8E24AA
  }

  .\32xl\:ring-offset-purple-700 {
    --tw-ring-offset-color: #7B1FA2
  }

  .\32xl\:ring-offset-purple-800 {
    --tw-ring-offset-color: #6A1B9A
  }

  .\32xl\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4A148C
  }

  .\32xl\:ring-offset-purple-default {
    --tw-ring-offset-color: #9C27B0
  }

  .\32xl\:ring-offset-purple-A100 {
    --tw-ring-offset-color: #EA80FC
  }

  .\32xl\:ring-offset-purple-A200 {
    --tw-ring-offset-color: #E040FB
  }

  .\32xl\:ring-offset-purple-A400 {
    --tw-ring-offset-color: #D500F9
  }

  .\32xl\:ring-offset-purple-A700 {
    --tw-ring-offset-color: #AA00FF
  }

  .\32xl\:ring-offset-pink-50 {
    --tw-ring-offset-color: #FCE4EC
  }

  .\32xl\:ring-offset-pink-100 {
    --tw-ring-offset-color: #F8BBD0
  }

  .\32xl\:ring-offset-pink-200 {
    --tw-ring-offset-color: #F48FB1
  }

  .\32xl\:ring-offset-pink-300 {
    --tw-ring-offset-color: #F06292
  }

  .\32xl\:ring-offset-pink-400 {
    --tw-ring-offset-color: #EC407A
  }

  .\32xl\:ring-offset-pink-500 {
    --tw-ring-offset-color: #E91E63
  }

  .\32xl\:ring-offset-pink-600 {
    --tw-ring-offset-color: #D81B60
  }

  .\32xl\:ring-offset-pink-700 {
    --tw-ring-offset-color: #C2185B
  }

  .\32xl\:ring-offset-pink-800 {
    --tw-ring-offset-color: #AD1457
  }

  .\32xl\:ring-offset-pink-900 {
    --tw-ring-offset-color: #880E4F
  }

  .\32xl\:ring-offset-pink-default {
    --tw-ring-offset-color: #E91E63
  }

  .\32xl\:ring-offset-pink-A100 {
    --tw-ring-offset-color: #FF80AB
  }

  .\32xl\:ring-offset-pink-A200 {
    --tw-ring-offset-color: #FF4081
  }

  .\32xl\:ring-offset-pink-A400 {
    --tw-ring-offset-color: #F50057
  }

  .\32xl\:ring-offset-pink-A700 {
    --tw-ring-offset-color: #C51162
  }

  .\32xl\:ring-offset-grey-50 {
    --tw-ring-offset-color: #FAFAFA
  }

  .\32xl\:ring-offset-grey-100 {
    --tw-ring-offset-color: #F5F5F5
  }

  .\32xl\:ring-offset-grey-200 {
    --tw-ring-offset-color: #EEEEEE
  }

  .\32xl\:ring-offset-grey-300 {
    --tw-ring-offset-color: #E0E0E0
  }

  .\32xl\:ring-offset-grey-400 {
    --tw-ring-offset-color: #BDBDBD
  }

  .\32xl\:ring-offset-grey-500 {
    --tw-ring-offset-color: #9E9E9E
  }

  .\32xl\:ring-offset-grey-600 {
    --tw-ring-offset-color: #757575
  }

  .\32xl\:ring-offset-grey-700 {
    --tw-ring-offset-color: #616161
  }

  .\32xl\:ring-offset-grey-800 {
    --tw-ring-offset-color: #424242
  }

  .\32xl\:ring-offset-grey-900 {
    --tw-ring-offset-color: #212121
  }

  .\32xl\:ring-offset-grey-default {
    --tw-ring-offset-color: #9E9E9E
  }

  .\32xl\:ring-offset-grey-A100 {
    --tw-ring-offset-color: #D5D5D5
  }

  .\32xl\:ring-offset-grey-A200 {
    --tw-ring-offset-color: #AAAAAA
  }

  .\32xl\:ring-offset-grey-A400 {
    --tw-ring-offset-color: #303030
  }

  .\32xl\:ring-offset-grey-A700 {
    --tw-ring-offset-color: #616161
  }

  .\32xl\:ring-offset-orange-50 {
    --tw-ring-offset-color: #FFF3E0
  }

  .\32xl\:ring-offset-orange-100 {
    --tw-ring-offset-color: #FFE0B2
  }

  .\32xl\:ring-offset-orange-200 {
    --tw-ring-offset-color: #FFCC80
  }

  .\32xl\:ring-offset-orange-300 {
    --tw-ring-offset-color: #FFB74D
  }

  .\32xl\:ring-offset-orange-400 {
    --tw-ring-offset-color: #FFA726
  }

  .\32xl\:ring-offset-orange-500 {
    --tw-ring-offset-color: #FF9800
  }

  .\32xl\:ring-offset-orange-600 {
    --tw-ring-offset-color: #FB8C00
  }

  .\32xl\:ring-offset-orange-700 {
    --tw-ring-offset-color: #F57C00
  }

  .\32xl\:ring-offset-orange-800 {
    --tw-ring-offset-color: #EF6C00
  }

  .\32xl\:ring-offset-orange-900 {
    --tw-ring-offset-color: #E65100
  }

  .\32xl\:ring-offset-orange-default {
    --tw-ring-offset-color: #FF9800
  }

  .\32xl\:ring-offset-orange-A100 {
    --tw-ring-offset-color: #FFD180
  }

  .\32xl\:ring-offset-orange-A200 {
    --tw-ring-offset-color: #FFAB40
  }

  .\32xl\:ring-offset-orange-A400 {
    --tw-ring-offset-color: #FF9100
  }

  .\32xl\:ring-offset-orange-A700 {
    --tw-ring-offset-color: #FF6D00
  }

  .\32xl\:ring-offset-deep-orange-50 {
    --tw-ring-offset-color: #FBE9E7
  }

  .\32xl\:ring-offset-deep-orange-100 {
    --tw-ring-offset-color: #FFCCBC
  }

  .\32xl\:ring-offset-deep-orange-200 {
    --tw-ring-offset-color: #FFAB91
  }

  .\32xl\:ring-offset-deep-orange-300 {
    --tw-ring-offset-color: #FF8A65
  }

  .\32xl\:ring-offset-deep-orange-400 {
    --tw-ring-offset-color: #FF7043
  }

  .\32xl\:ring-offset-deep-orange-500 {
    --tw-ring-offset-color: #FF5722
  }

  .\32xl\:ring-offset-deep-orange-600 {
    --tw-ring-offset-color: #F4511E
  }

  .\32xl\:ring-offset-deep-orange-700 {
    --tw-ring-offset-color: #E64A19
  }

  .\32xl\:ring-offset-deep-orange-800 {
    --tw-ring-offset-color: #D84315
  }

  .\32xl\:ring-offset-deep-orange-900 {
    --tw-ring-offset-color: #BF360C
  }

  .\32xl\:ring-offset-deep-orange-default {
    --tw-ring-offset-color: #FF5722
  }

  .\32xl\:ring-offset-deep-orange-A100 {
    --tw-ring-offset-color: #FF9E80
  }

  .\32xl\:ring-offset-deep-orange-A200 {
    --tw-ring-offset-color: #FF6E40
  }

  .\32xl\:ring-offset-deep-orange-A400 {
    --tw-ring-offset-color: #FF3D00
  }

  .\32xl\:ring-offset-deep-orange-A700 {
    --tw-ring-offset-color: #DD2C00
  }

  .\32xl\:ring-offset-light-green-50 {
    --tw-ring-offset-color: #F1F8E9
  }

  .\32xl\:ring-offset-light-green-100 {
    --tw-ring-offset-color: #DCEDC8
  }

  .\32xl\:ring-offset-light-green-200 {
    --tw-ring-offset-color: #C5E1A5
  }

  .\32xl\:ring-offset-light-green-300 {
    --tw-ring-offset-color: #AED581
  }

  .\32xl\:ring-offset-light-green-400 {
    --tw-ring-offset-color: #9CCC65
  }

  .\32xl\:ring-offset-light-green-500 {
    --tw-ring-offset-color: #8BC34A
  }

  .\32xl\:ring-offset-light-green-600 {
    --tw-ring-offset-color: #7CB342
  }

  .\32xl\:ring-offset-light-green-700 {
    --tw-ring-offset-color: #689F38
  }

  .\32xl\:ring-offset-light-green-800 {
    --tw-ring-offset-color: #558B2F
  }

  .\32xl\:ring-offset-light-green-900 {
    --tw-ring-offset-color: #33691E
  }

  .\32xl\:ring-offset-light-green-default {
    --tw-ring-offset-color: #8BC34A
  }

  .\32xl\:ring-offset-light-green-A100 {
    --tw-ring-offset-color: #CCFF90
  }

  .\32xl\:ring-offset-light-green-A200 {
    --tw-ring-offset-color: #B2FF59
  }

  .\32xl\:ring-offset-light-green-A400 {
    --tw-ring-offset-color: #76FF03
  }

  .\32xl\:ring-offset-light-green-A700 {
    --tw-ring-offset-color: #64DD17
  }

  .\32xl\:ring-offset-teal-50 {
    --tw-ring-offset-color: #E0F2F1
  }

  .\32xl\:ring-offset-teal-100 {
    --tw-ring-offset-color: #B2DFDB
  }

  .\32xl\:ring-offset-teal-200 {
    --tw-ring-offset-color: #80CBC4
  }

  .\32xl\:ring-offset-teal-300 {
    --tw-ring-offset-color: #4DB6AC
  }

  .\32xl\:ring-offset-teal-400 {
    --tw-ring-offset-color: #26A69A
  }

  .\32xl\:ring-offset-teal-500 {
    --tw-ring-offset-color: #009688
  }

  .\32xl\:ring-offset-teal-600 {
    --tw-ring-offset-color: #00897B
  }

  .\32xl\:ring-offset-teal-700 {
    --tw-ring-offset-color: #00796B
  }

  .\32xl\:ring-offset-teal-800 {
    --tw-ring-offset-color: #00695C
  }

  .\32xl\:ring-offset-teal-900 {
    --tw-ring-offset-color: #004D40
  }

  .\32xl\:ring-offset-teal-default {
    --tw-ring-offset-color: #009688
  }

  .\32xl\:ring-offset-teal-A100 {
    --tw-ring-offset-color: #A7FFEB
  }

  .\32xl\:ring-offset-teal-A200 {
    --tw-ring-offset-color: #64FFDA
  }

  .\32xl\:ring-offset-teal-A400 {
    --tw-ring-offset-color: #1DE9B6
  }

  .\32xl\:ring-offset-teal-A700 {
    --tw-ring-offset-color: #00BFA5
  }

  .\32xl\:ring-offset-light-blue-50 {
    --tw-ring-offset-color: #E1F5FE
  }

  .\32xl\:ring-offset-light-blue-100 {
    --tw-ring-offset-color: #B3E5FC
  }

  .\32xl\:ring-offset-light-blue-200 {
    --tw-ring-offset-color: #81D4FA
  }

  .\32xl\:ring-offset-light-blue-300 {
    --tw-ring-offset-color: #4FC3F7
  }

  .\32xl\:ring-offset-light-blue-400 {
    --tw-ring-offset-color: #29B6F6
  }

  .\32xl\:ring-offset-light-blue-500 {
    --tw-ring-offset-color: #03A9F4
  }

  .\32xl\:ring-offset-light-blue-600 {
    --tw-ring-offset-color: #039BE5
  }

  .\32xl\:ring-offset-light-blue-700 {
    --tw-ring-offset-color: #0288D1
  }

  .\32xl\:ring-offset-light-blue-800 {
    --tw-ring-offset-color: #0277BD
  }

  .\32xl\:ring-offset-light-blue-900 {
    --tw-ring-offset-color: #01579B
  }

  .\32xl\:ring-offset-light-blue-default {
    --tw-ring-offset-color: #03A9F4
  }

  .\32xl\:ring-offset-light-blue-A100 {
    --tw-ring-offset-color: #80D8FF
  }

  .\32xl\:ring-offset-light-blue-A200 {
    --tw-ring-offset-color: #40C4FF
  }

  .\32xl\:ring-offset-light-blue-A400 {
    --tw-ring-offset-color: #00B0FF
  }

  .\32xl\:ring-offset-light-blue-A700 {
    --tw-ring-offset-color: #0091EA
  }

  .\32xl\:ring-offset-deep-purple-50 {
    --tw-ring-offset-color: #EDE7F6
  }

  .\32xl\:ring-offset-deep-purple-100 {
    --tw-ring-offset-color: #D1C4E9
  }

  .\32xl\:ring-offset-deep-purple-200 {
    --tw-ring-offset-color: #B39DDB
  }

  .\32xl\:ring-offset-deep-purple-300 {
    --tw-ring-offset-color: #9575CD
  }

  .\32xl\:ring-offset-deep-purple-400 {
    --tw-ring-offset-color: #7E57C2
  }

  .\32xl\:ring-offset-deep-purple-500 {
    --tw-ring-offset-color: #673AB7
  }

  .\32xl\:ring-offset-deep-purple-600 {
    --tw-ring-offset-color: #5E35B1
  }

  .\32xl\:ring-offset-deep-purple-700 {
    --tw-ring-offset-color: #512DA8
  }

  .\32xl\:ring-offset-deep-purple-800 {
    --tw-ring-offset-color: #4527A0
  }

  .\32xl\:ring-offset-deep-purple-900 {
    --tw-ring-offset-color: #311B92
  }

  .\32xl\:ring-offset-deep-purple-default {
    --tw-ring-offset-color: #673AB7
  }

  .\32xl\:ring-offset-deep-purple-A100 {
    --tw-ring-offset-color: #B388FF
  }

  .\32xl\:ring-offset-deep-purple-A200 {
    --tw-ring-offset-color: #7C4DFF
  }

  .\32xl\:ring-offset-deep-purple-A400 {
    --tw-ring-offset-color: #651FFF
  }

  .\32xl\:ring-offset-deep-purple-A700 {
    --tw-ring-offset-color: #6200EA
  }

  .\32xl\:ring-offset-lime-50 {
    --tw-ring-offset-color: #F9FBE7
  }

  .\32xl\:ring-offset-lime-100 {
    --tw-ring-offset-color: #F0F4C3
  }

  .\32xl\:ring-offset-lime-200 {
    --tw-ring-offset-color: #E6EE9C
  }

  .\32xl\:ring-offset-lime-300 {
    --tw-ring-offset-color: #DCE775
  }

  .\32xl\:ring-offset-lime-400 {
    --tw-ring-offset-color: #D4E157
  }

  .\32xl\:ring-offset-lime-500 {
    --tw-ring-offset-color: #CDDC39
  }

  .\32xl\:ring-offset-lime-600 {
    --tw-ring-offset-color: #C0CA33
  }

  .\32xl\:ring-offset-lime-700 {
    --tw-ring-offset-color: #AFB42B
  }

  .\32xl\:ring-offset-lime-800 {
    --tw-ring-offset-color: #9E9D24
  }

  .\32xl\:ring-offset-lime-900 {
    --tw-ring-offset-color: #827717
  }

  .\32xl\:ring-offset-lime-default {
    --tw-ring-offset-color: #CDDC39
  }

  .\32xl\:ring-offset-lime-A100 {
    --tw-ring-offset-color: #F4FF81
  }

  .\32xl\:ring-offset-lime-A200 {
    --tw-ring-offset-color: #EEFF41
  }

  .\32xl\:ring-offset-lime-A400 {
    --tw-ring-offset-color: #C6FF00
  }

  .\32xl\:ring-offset-lime-A700 {
    --tw-ring-offset-color: #AEEA00
  }

  .\32xl\:ring-offset-amber-50 {
    --tw-ring-offset-color: #FFF8E1
  }

  .\32xl\:ring-offset-amber-100 {
    --tw-ring-offset-color: #FFECB3
  }

  .\32xl\:ring-offset-amber-200 {
    --tw-ring-offset-color: #FFE082
  }

  .\32xl\:ring-offset-amber-300 {
    --tw-ring-offset-color: #FFD54F
  }

  .\32xl\:ring-offset-amber-400 {
    --tw-ring-offset-color: #FFCA28
  }

  .\32xl\:ring-offset-amber-500 {
    --tw-ring-offset-color: #FFC107
  }

  .\32xl\:ring-offset-amber-600 {
    --tw-ring-offset-color: #FFB300
  }

  .\32xl\:ring-offset-amber-700 {
    --tw-ring-offset-color: #FFA000
  }

  .\32xl\:ring-offset-amber-800 {
    --tw-ring-offset-color: #FF8F00
  }

  .\32xl\:ring-offset-amber-900 {
    --tw-ring-offset-color: #FF6F00
  }

  .\32xl\:ring-offset-amber-default {
    --tw-ring-offset-color: #FFC107
  }

  .\32xl\:ring-offset-amber-A100 {
    --tw-ring-offset-color: #FFE57F
  }

  .\32xl\:ring-offset-amber-A200 {
    --tw-ring-offset-color: #FFD740
  }

  .\32xl\:ring-offset-amber-A400 {
    --tw-ring-offset-color: #FFC400
  }

  .\32xl\:ring-offset-amber-A700 {
    --tw-ring-offset-color: #FFAB00
  }

  .\32xl\:ring-offset-brown-50 {
    --tw-ring-offset-color: #EFEBE9
  }

  .\32xl\:ring-offset-brown-100 {
    --tw-ring-offset-color: #D7CCC8
  }

  .\32xl\:ring-offset-brown-200 {
    --tw-ring-offset-color: #BCAAA4
  }

  .\32xl\:ring-offset-brown-300 {
    --tw-ring-offset-color: #A1887F
  }

  .\32xl\:ring-offset-brown-400 {
    --tw-ring-offset-color: #8D6E63
  }

  .\32xl\:ring-offset-brown-500 {
    --tw-ring-offset-color: #795548
  }

  .\32xl\:ring-offset-brown-600 {
    --tw-ring-offset-color: #6D4C41
  }

  .\32xl\:ring-offset-brown-700 {
    --tw-ring-offset-color: #5D4037
  }

  .\32xl\:ring-offset-brown-800 {
    --tw-ring-offset-color: #4E342E
  }

  .\32xl\:ring-offset-brown-900 {
    --tw-ring-offset-color: #3E2723
  }

  .\32xl\:ring-offset-brown-default {
    --tw-ring-offset-color: #795548
  }

  .\32xl\:ring-offset-brown-A100 {
    --tw-ring-offset-color: #D7CCC8
  }

  .\32xl\:ring-offset-brown-A200 {
    --tw-ring-offset-color: #BCAAA4
  }

  .\32xl\:ring-offset-brown-A400 {
    --tw-ring-offset-color: #8D6E63
  }

  .\32xl\:ring-offset-brown-A700 {
    --tw-ring-offset-color: #5D4037
  }

  .\32xl\:ring-offset-blue-gray-50 {
    --tw-ring-offset-color: #ECEFF1
  }

  .\32xl\:ring-offset-blue-gray-100 {
    --tw-ring-offset-color: #CFD8DC
  }

  .\32xl\:ring-offset-blue-gray-200 {
    --tw-ring-offset-color: #B0BEC5
  }

  .\32xl\:ring-offset-blue-gray-300 {
    --tw-ring-offset-color: #90A4AE
  }

  .\32xl\:ring-offset-blue-gray-400 {
    --tw-ring-offset-color: #78909C
  }

  .\32xl\:ring-offset-blue-gray-500 {
    --tw-ring-offset-color: #607D8B
  }

  .\32xl\:ring-offset-blue-gray-600 {
    --tw-ring-offset-color: #546E7A
  }

  .\32xl\:ring-offset-blue-gray-700 {
    --tw-ring-offset-color: #455A64
  }

  .\32xl\:ring-offset-blue-gray-800 {
    --tw-ring-offset-color: #37474F
  }

  .\32xl\:ring-offset-blue-gray-900 {
    --tw-ring-offset-color: #263238
  }

  .\32xl\:ring-offset-blue-gray-default {
    --tw-ring-offset-color: #607D8B
  }

  .\32xl\:ring-offset-blue-gray-A100 {
    --tw-ring-offset-color: #CFD8DC
  }

  .\32xl\:ring-offset-blue-gray-A200 {
    --tw-ring-offset-color: #B0BEC5
  }

  .\32xl\:ring-offset-blue-gray-A400 {
    --tw-ring-offset-color: #78909C
  }

  .\32xl\:ring-offset-blue-gray-A700 {
    --tw-ring-offset-color: #455A64
  }

  .\32xl\:ring-offset-cyan-50 {
    --tw-ring-offset-color: #E0F7FA
  }

  .\32xl\:ring-offset-cyan-100 {
    --tw-ring-offset-color: #B2EBF2
  }

  .\32xl\:ring-offset-cyan-200 {
    --tw-ring-offset-color: #80DEEA
  }

  .\32xl\:ring-offset-cyan-300 {
    --tw-ring-offset-color: #4DD0E1
  }

  .\32xl\:ring-offset-cyan-400 {
    --tw-ring-offset-color: #26C6DA
  }

  .\32xl\:ring-offset-cyan-500 {
    --tw-ring-offset-color: #00BCD4
  }

  .\32xl\:ring-offset-cyan-600 {
    --tw-ring-offset-color: #00ACC1
  }

  .\32xl\:ring-offset-cyan-700 {
    --tw-ring-offset-color: #0097A7
  }

  .\32xl\:ring-offset-cyan-800 {
    --tw-ring-offset-color: #00838F
  }

  .\32xl\:ring-offset-cyan-900 {
    --tw-ring-offset-color: #006064
  }

  .\32xl\:ring-offset-cyan-default {
    --tw-ring-offset-color: #00BCD4
  }

  .\32xl\:ring-offset-cyan-A100 {
    --tw-ring-offset-color: #84FFFF
  }

  .\32xl\:ring-offset-cyan-A200 {
    --tw-ring-offset-color: #18FFFF
  }

  .\32xl\:ring-offset-cyan-A400 {
    --tw-ring-offset-color: #00E5FF
  }

  .\32xl\:ring-offset-cyan-A700 {
    --tw-ring-offset-color: #00B8D4
  }

  .\32xl\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent
  }

  .\32xl\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor
  }

  .\32xl\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #22292F
  }

  .\32xl\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #FFF
  }

  .\32xl\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #FAFAFA
  }

  .\32xl\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #F5F5F5
  }

  .\32xl\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #EEEEEE
  }

  .\32xl\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #E0E0E0
  }

  .\32xl\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #BDBDBD
  }

  .\32xl\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .\32xl\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #757575
  }

  .\32xl\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .\32xl\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #424242
  }

  .\32xl\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #212121
  }

  .\32xl\:focus-within\:ring-offset-gray-default:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .\32xl\:focus-within\:ring-offset-gray-A100:focus-within {
    --tw-ring-offset-color: #D5D5D5
  }

  .\32xl\:focus-within\:ring-offset-gray-A200:focus-within {
    --tw-ring-offset-color: #AAAAAA
  }

  .\32xl\:focus-within\:ring-offset-gray-A400:focus-within {
    --tw-ring-offset-color: #303030
  }

  .\32xl\:focus-within\:ring-offset-gray-A700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .\32xl\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #FFEBEE
  }

  .\32xl\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #FFCDD2
  }

  .\32xl\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #EF9A9A
  }

  .\32xl\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #E57373
  }

  .\32xl\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #EF5350
  }

  .\32xl\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #F44336
  }

  .\32xl\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #E53935
  }

  .\32xl\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #D32F2F
  }

  .\32xl\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #C62828
  }

  .\32xl\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #B71C1C
  }

  .\32xl\:focus-within\:ring-offset-red-default:focus-within {
    --tw-ring-offset-color: #F44336
  }

  .\32xl\:focus-within\:ring-offset-red-A100:focus-within {
    --tw-ring-offset-color: #FF8A80
  }

  .\32xl\:focus-within\:ring-offset-red-A200:focus-within {
    --tw-ring-offset-color: #FF5252
  }

  .\32xl\:focus-within\:ring-offset-red-A400:focus-within {
    --tw-ring-offset-color: #FF1744
  }

  .\32xl\:focus-within\:ring-offset-red-A700:focus-within {
    --tw-ring-offset-color: #D50000
  }

  .\32xl\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #FFFDE7
  }

  .\32xl\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #FFF9C4
  }

  .\32xl\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #FFF59D
  }

  .\32xl\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #FFF176
  }

  .\32xl\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #FFEE58
  }

  .\32xl\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #FFEB3B
  }

  .\32xl\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #FDD835
  }

  .\32xl\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #FBC02D
  }

  .\32xl\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #F9A825
  }

  .\32xl\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #F57F17
  }

  .\32xl\:focus-within\:ring-offset-yellow-default:focus-within {
    --tw-ring-offset-color: #FFEB3B
  }

  .\32xl\:focus-within\:ring-offset-yellow-A100:focus-within {
    --tw-ring-offset-color: #FFFF8D
  }

  .\32xl\:focus-within\:ring-offset-yellow-A200:focus-within {
    --tw-ring-offset-color: #FFFF00
  }

  .\32xl\:focus-within\:ring-offset-yellow-A400:focus-within {
    --tw-ring-offset-color: #FFEA00
  }

  .\32xl\:focus-within\:ring-offset-yellow-A700:focus-within {
    --tw-ring-offset-color: #FFD600
  }

  .\32xl\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #E8F5E9
  }

  .\32xl\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #C8E6C9
  }

  .\32xl\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #A5D6A7
  }

  .\32xl\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #81C784
  }

  .\32xl\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #66BB6A
  }

  .\32xl\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #4CAF50
  }

  .\32xl\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #43A047
  }

  .\32xl\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #388E3C
  }

  .\32xl\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #2E7D32
  }

  .\32xl\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #1B5E20
  }

  .\32xl\:focus-within\:ring-offset-green-default:focus-within {
    --tw-ring-offset-color: #4CAF50
  }

  .\32xl\:focus-within\:ring-offset-green-A100:focus-within {
    --tw-ring-offset-color: #B9F6CA
  }

  .\32xl\:focus-within\:ring-offset-green-A200:focus-within {
    --tw-ring-offset-color: #69F0AE
  }

  .\32xl\:focus-within\:ring-offset-green-A400:focus-within {
    --tw-ring-offset-color: #00E676
  }

  .\32xl\:focus-within\:ring-offset-green-A700:focus-within {
    --tw-ring-offset-color: #00C853
  }

  .\32xl\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #E3F2FD
  }

  .\32xl\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #BBDEFB
  }

  .\32xl\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #90CAF9
  }

  .\32xl\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #64B5F6
  }

  .\32xl\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #42A5F5
  }

  .\32xl\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #2196F3
  }

  .\32xl\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #1E88E5
  }

  .\32xl\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1976D2
  }

  .\32xl\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1565C0
  }

  .\32xl\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #0D47A1
  }

  .\32xl\:focus-within\:ring-offset-blue-default:focus-within {
    --tw-ring-offset-color: #2196F3
  }

  .\32xl\:focus-within\:ring-offset-blue-A100:focus-within {
    --tw-ring-offset-color: #82B1FF
  }

  .\32xl\:focus-within\:ring-offset-blue-A200:focus-within {
    --tw-ring-offset-color: #448AFF
  }

  .\32xl\:focus-within\:ring-offset-blue-A400:focus-within {
    --tw-ring-offset-color: #2979FF
  }

  .\32xl\:focus-within\:ring-offset-blue-A700:focus-within {
    --tw-ring-offset-color: #2962FF
  }

  .\32xl\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #E8EAF6
  }

  .\32xl\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #C5CAE9
  }

  .\32xl\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #9FA8DA
  }

  .\32xl\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #7986CB
  }

  .\32xl\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #5C6BC0
  }

  .\32xl\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #3F51B5
  }

  .\32xl\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #3949AB
  }

  .\32xl\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #303F9F
  }

  .\32xl\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #283593
  }

  .\32xl\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #1A237E
  }

  .\32xl\:focus-within\:ring-offset-indigo-default:focus-within {
    --tw-ring-offset-color: #3F51B5
  }

  .\32xl\:focus-within\:ring-offset-indigo-A100:focus-within {
    --tw-ring-offset-color: #8C9EFF
  }

  .\32xl\:focus-within\:ring-offset-indigo-A200:focus-within {
    --tw-ring-offset-color: #536DFE
  }

  .\32xl\:focus-within\:ring-offset-indigo-A400:focus-within {
    --tw-ring-offset-color: #3D5AFE
  }

  .\32xl\:focus-within\:ring-offset-indigo-A700:focus-within {
    --tw-ring-offset-color: #304FFE
  }

  .\32xl\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #F3E5F5
  }

  .\32xl\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #E1BEE7
  }

  .\32xl\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #CE93D8
  }

  .\32xl\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #BA68C8
  }

  .\32xl\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #AB47BC
  }

  .\32xl\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #9C27B0
  }

  .\32xl\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #8E24AA
  }

  .\32xl\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #7B1FA2
  }

  .\32xl\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #6A1B9A
  }

  .\32xl\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4A148C
  }

  .\32xl\:focus-within\:ring-offset-purple-default:focus-within {
    --tw-ring-offset-color: #9C27B0
  }

  .\32xl\:focus-within\:ring-offset-purple-A100:focus-within {
    --tw-ring-offset-color: #EA80FC
  }

  .\32xl\:focus-within\:ring-offset-purple-A200:focus-within {
    --tw-ring-offset-color: #E040FB
  }

  .\32xl\:focus-within\:ring-offset-purple-A400:focus-within {
    --tw-ring-offset-color: #D500F9
  }

  .\32xl\:focus-within\:ring-offset-purple-A700:focus-within {
    --tw-ring-offset-color: #AA00FF
  }

  .\32xl\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #FCE4EC
  }

  .\32xl\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #F8BBD0
  }

  .\32xl\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #F48FB1
  }

  .\32xl\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #F06292
  }

  .\32xl\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #EC407A
  }

  .\32xl\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #E91E63
  }

  .\32xl\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #D81B60
  }

  .\32xl\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #C2185B
  }

  .\32xl\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #AD1457
  }

  .\32xl\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #880E4F
  }

  .\32xl\:focus-within\:ring-offset-pink-default:focus-within {
    --tw-ring-offset-color: #E91E63
  }

  .\32xl\:focus-within\:ring-offset-pink-A100:focus-within {
    --tw-ring-offset-color: #FF80AB
  }

  .\32xl\:focus-within\:ring-offset-pink-A200:focus-within {
    --tw-ring-offset-color: #FF4081
  }

  .\32xl\:focus-within\:ring-offset-pink-A400:focus-within {
    --tw-ring-offset-color: #F50057
  }

  .\32xl\:focus-within\:ring-offset-pink-A700:focus-within {
    --tw-ring-offset-color: #C51162
  }

  .\32xl\:focus-within\:ring-offset-grey-50:focus-within {
    --tw-ring-offset-color: #FAFAFA
  }

  .\32xl\:focus-within\:ring-offset-grey-100:focus-within {
    --tw-ring-offset-color: #F5F5F5
  }

  .\32xl\:focus-within\:ring-offset-grey-200:focus-within {
    --tw-ring-offset-color: #EEEEEE
  }

  .\32xl\:focus-within\:ring-offset-grey-300:focus-within {
    --tw-ring-offset-color: #E0E0E0
  }

  .\32xl\:focus-within\:ring-offset-grey-400:focus-within {
    --tw-ring-offset-color: #BDBDBD
  }

  .\32xl\:focus-within\:ring-offset-grey-500:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .\32xl\:focus-within\:ring-offset-grey-600:focus-within {
    --tw-ring-offset-color: #757575
  }

  .\32xl\:focus-within\:ring-offset-grey-700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .\32xl\:focus-within\:ring-offset-grey-800:focus-within {
    --tw-ring-offset-color: #424242
  }

  .\32xl\:focus-within\:ring-offset-grey-900:focus-within {
    --tw-ring-offset-color: #212121
  }

  .\32xl\:focus-within\:ring-offset-grey-default:focus-within {
    --tw-ring-offset-color: #9E9E9E
  }

  .\32xl\:focus-within\:ring-offset-grey-A100:focus-within {
    --tw-ring-offset-color: #D5D5D5
  }

  .\32xl\:focus-within\:ring-offset-grey-A200:focus-within {
    --tw-ring-offset-color: #AAAAAA
  }

  .\32xl\:focus-within\:ring-offset-grey-A400:focus-within {
    --tw-ring-offset-color: #303030
  }

  .\32xl\:focus-within\:ring-offset-grey-A700:focus-within {
    --tw-ring-offset-color: #616161
  }

  .\32xl\:focus-within\:ring-offset-orange-50:focus-within {
    --tw-ring-offset-color: #FFF3E0
  }

  .\32xl\:focus-within\:ring-offset-orange-100:focus-within {
    --tw-ring-offset-color: #FFE0B2
  }

  .\32xl\:focus-within\:ring-offset-orange-200:focus-within {
    --tw-ring-offset-color: #FFCC80
  }

  .\32xl\:focus-within\:ring-offset-orange-300:focus-within {
    --tw-ring-offset-color: #FFB74D
  }

  .\32xl\:focus-within\:ring-offset-orange-400:focus-within {
    --tw-ring-offset-color: #FFA726
  }

  .\32xl\:focus-within\:ring-offset-orange-500:focus-within {
    --tw-ring-offset-color: #FF9800
  }

  .\32xl\:focus-within\:ring-offset-orange-600:focus-within {
    --tw-ring-offset-color: #FB8C00
  }

  .\32xl\:focus-within\:ring-offset-orange-700:focus-within {
    --tw-ring-offset-color: #F57C00
  }

  .\32xl\:focus-within\:ring-offset-orange-800:focus-within {
    --tw-ring-offset-color: #EF6C00
  }

  .\32xl\:focus-within\:ring-offset-orange-900:focus-within {
    --tw-ring-offset-color: #E65100
  }

  .\32xl\:focus-within\:ring-offset-orange-default:focus-within {
    --tw-ring-offset-color: #FF9800
  }

  .\32xl\:focus-within\:ring-offset-orange-A100:focus-within {
    --tw-ring-offset-color: #FFD180
  }

  .\32xl\:focus-within\:ring-offset-orange-A200:focus-within {
    --tw-ring-offset-color: #FFAB40
  }

  .\32xl\:focus-within\:ring-offset-orange-A400:focus-within {
    --tw-ring-offset-color: #FF9100
  }

  .\32xl\:focus-within\:ring-offset-orange-A700:focus-within {
    --tw-ring-offset-color: #FF6D00
  }

  .\32xl\:focus-within\:ring-offset-deep-orange-50:focus-within {
    --tw-ring-offset-color: #FBE9E7
  }

  .\32xl\:focus-within\:ring-offset-deep-orange-100:focus-within {
    --tw-ring-offset-color: #FFCCBC
  }

  .\32xl\:focus-within\:ring-offset-deep-orange-200:focus-within {
    --tw-ring-offset-color: #FFAB91
  }

  .\32xl\:focus-within\:ring-offset-deep-orange-300:focus-within {
    --tw-ring-offset-color: #FF8A65
  }

  .\32xl\:focus-within\:ring-offset-deep-orange-400:focus-within {
    --tw-ring-offset-color: #FF7043
  }

  .\32xl\:focus-within\:ring-offset-deep-orange-500:focus-within {
    --tw-ring-offset-color: #FF5722
  }

  .\32xl\:focus-within\:ring-offset-deep-orange-600:focus-within {
    --tw-ring-offset-color: #F4511E
  }

  .\32xl\:focus-within\:ring-offset-deep-orange-700:focus-within {
    --tw-ring-offset-color: #E64A19
  }

  .\32xl\:focus-within\:ring-offset-deep-orange-800:focus-within {
    --tw-ring-offset-color: #D84315
  }

  .\32xl\:focus-within\:ring-offset-deep-orange-900:focus-within {
    --tw-ring-offset-color: #BF360C
  }

  .\32xl\:focus-within\:ring-offset-deep-orange-default:focus-within {
    --tw-ring-offset-color: #FF5722
  }

  .\32xl\:focus-within\:ring-offset-deep-orange-A100:focus-within {
    --tw-ring-offset-color: #FF9E80
  }

  .\32xl\:focus-within\:ring-offset-deep-orange-A200:focus-within {
    --tw-ring-offset-color: #FF6E40
  }

  .\32xl\:focus-within\:ring-offset-deep-orange-A400:focus-within {
    --tw-ring-offset-color: #FF3D00
  }

  .\32xl\:focus-within\:ring-offset-deep-orange-A700:focus-within {
    --tw-ring-offset-color: #DD2C00
  }

  .\32xl\:focus-within\:ring-offset-light-green-50:focus-within {
    --tw-ring-offset-color: #F1F8E9
  }

  .\32xl\:focus-within\:ring-offset-light-green-100:focus-within {
    --tw-ring-offset-color: #DCEDC8
  }

  .\32xl\:focus-within\:ring-offset-light-green-200:focus-within {
    --tw-ring-offset-color: #C5E1A5
  }

  .\32xl\:focus-within\:ring-offset-light-green-300:focus-within {
    --tw-ring-offset-color: #AED581
  }

  .\32xl\:focus-within\:ring-offset-light-green-400:focus-within {
    --tw-ring-offset-color: #9CCC65
  }

  .\32xl\:focus-within\:ring-offset-light-green-500:focus-within {
    --tw-ring-offset-color: #8BC34A
  }

  .\32xl\:focus-within\:ring-offset-light-green-600:focus-within {
    --tw-ring-offset-color: #7CB342
  }

  .\32xl\:focus-within\:ring-offset-light-green-700:focus-within {
    --tw-ring-offset-color: #689F38
  }

  .\32xl\:focus-within\:ring-offset-light-green-800:focus-within {
    --tw-ring-offset-color: #558B2F
  }

  .\32xl\:focus-within\:ring-offset-light-green-900:focus-within {
    --tw-ring-offset-color: #33691E
  }

  .\32xl\:focus-within\:ring-offset-light-green-default:focus-within {
    --tw-ring-offset-color: #8BC34A
  }

  .\32xl\:focus-within\:ring-offset-light-green-A100:focus-within {
    --tw-ring-offset-color: #CCFF90
  }

  .\32xl\:focus-within\:ring-offset-light-green-A200:focus-within {
    --tw-ring-offset-color: #B2FF59
  }

  .\32xl\:focus-within\:ring-offset-light-green-A400:focus-within {
    --tw-ring-offset-color: #76FF03
  }

  .\32xl\:focus-within\:ring-offset-light-green-A700:focus-within {
    --tw-ring-offset-color: #64DD17
  }

  .\32xl\:focus-within\:ring-offset-teal-50:focus-within {
    --tw-ring-offset-color: #E0F2F1
  }

  .\32xl\:focus-within\:ring-offset-teal-100:focus-within {
    --tw-ring-offset-color: #B2DFDB
  }

  .\32xl\:focus-within\:ring-offset-teal-200:focus-within {
    --tw-ring-offset-color: #80CBC4
  }

  .\32xl\:focus-within\:ring-offset-teal-300:focus-within {
    --tw-ring-offset-color: #4DB6AC
  }

  .\32xl\:focus-within\:ring-offset-teal-400:focus-within {
    --tw-ring-offset-color: #26A69A
  }

  .\32xl\:focus-within\:ring-offset-teal-500:focus-within {
    --tw-ring-offset-color: #009688
  }

  .\32xl\:focus-within\:ring-offset-teal-600:focus-within {
    --tw-ring-offset-color: #00897B
  }

  .\32xl\:focus-within\:ring-offset-teal-700:focus-within {
    --tw-ring-offset-color: #00796B
  }

  .\32xl\:focus-within\:ring-offset-teal-800:focus-within {
    --tw-ring-offset-color: #00695C
  }

  .\32xl\:focus-within\:ring-offset-teal-900:focus-within {
    --tw-ring-offset-color: #004D40
  }

  .\32xl\:focus-within\:ring-offset-teal-default:focus-within {
    --tw-ring-offset-color: #009688
  }

  .\32xl\:focus-within\:ring-offset-teal-A100:focus-within {
    --tw-ring-offset-color: #A7FFEB
  }

  .\32xl\:focus-within\:ring-offset-teal-A200:focus-within {
    --tw-ring-offset-color: #64FFDA
  }

  .\32xl\:focus-within\:ring-offset-teal-A400:focus-within {
    --tw-ring-offset-color: #1DE9B6
  }

  .\32xl\:focus-within\:ring-offset-teal-A700:focus-within {
    --tw-ring-offset-color: #00BFA5
  }

  .\32xl\:focus-within\:ring-offset-light-blue-50:focus-within {
    --tw-ring-offset-color: #E1F5FE
  }

  .\32xl\:focus-within\:ring-offset-light-blue-100:focus-within {
    --tw-ring-offset-color: #B3E5FC
  }

  .\32xl\:focus-within\:ring-offset-light-blue-200:focus-within {
    --tw-ring-offset-color: #81D4FA
  }

  .\32xl\:focus-within\:ring-offset-light-blue-300:focus-within {
    --tw-ring-offset-color: #4FC3F7
  }

  .\32xl\:focus-within\:ring-offset-light-blue-400:focus-within {
    --tw-ring-offset-color: #29B6F6
  }

  .\32xl\:focus-within\:ring-offset-light-blue-500:focus-within {
    --tw-ring-offset-color: #03A9F4
  }

  .\32xl\:focus-within\:ring-offset-light-blue-600:focus-within {
    --tw-ring-offset-color: #039BE5
  }

  .\32xl\:focus-within\:ring-offset-light-blue-700:focus-within {
    --tw-ring-offset-color: #0288D1
  }

  .\32xl\:focus-within\:ring-offset-light-blue-800:focus-within {
    --tw-ring-offset-color: #0277BD
  }

  .\32xl\:focus-within\:ring-offset-light-blue-900:focus-within {
    --tw-ring-offset-color: #01579B
  }

  .\32xl\:focus-within\:ring-offset-light-blue-default:focus-within {
    --tw-ring-offset-color: #03A9F4
  }

  .\32xl\:focus-within\:ring-offset-light-blue-A100:focus-within {
    --tw-ring-offset-color: #80D8FF
  }

  .\32xl\:focus-within\:ring-offset-light-blue-A200:focus-within {
    --tw-ring-offset-color: #40C4FF
  }

  .\32xl\:focus-within\:ring-offset-light-blue-A400:focus-within {
    --tw-ring-offset-color: #00B0FF
  }

  .\32xl\:focus-within\:ring-offset-light-blue-A700:focus-within {
    --tw-ring-offset-color: #0091EA
  }

  .\32xl\:focus-within\:ring-offset-deep-purple-50:focus-within {
    --tw-ring-offset-color: #EDE7F6
  }

  .\32xl\:focus-within\:ring-offset-deep-purple-100:focus-within {
    --tw-ring-offset-color: #D1C4E9
  }

  .\32xl\:focus-within\:ring-offset-deep-purple-200:focus-within {
    --tw-ring-offset-color: #B39DDB
  }

  .\32xl\:focus-within\:ring-offset-deep-purple-300:focus-within {
    --tw-ring-offset-color: #9575CD
  }

  .\32xl\:focus-within\:ring-offset-deep-purple-400:focus-within {
    --tw-ring-offset-color: #7E57C2
  }

  .\32xl\:focus-within\:ring-offset-deep-purple-500:focus-within {
    --tw-ring-offset-color: #673AB7
  }

  .\32xl\:focus-within\:ring-offset-deep-purple-600:focus-within {
    --tw-ring-offset-color: #5E35B1
  }

  .\32xl\:focus-within\:ring-offset-deep-purple-700:focus-within {
    --tw-ring-offset-color: #512DA8
  }

  .\32xl\:focus-within\:ring-offset-deep-purple-800:focus-within {
    --tw-ring-offset-color: #4527A0
  }

  .\32xl\:focus-within\:ring-offset-deep-purple-900:focus-within {
    --tw-ring-offset-color: #311B92
  }

  .\32xl\:focus-within\:ring-offset-deep-purple-default:focus-within {
    --tw-ring-offset-color: #673AB7
  }

  .\32xl\:focus-within\:ring-offset-deep-purple-A100:focus-within {
    --tw-ring-offset-color: #B388FF
  }

  .\32xl\:focus-within\:ring-offset-deep-purple-A200:focus-within {
    --tw-ring-offset-color: #7C4DFF
  }

  .\32xl\:focus-within\:ring-offset-deep-purple-A400:focus-within {
    --tw-ring-offset-color: #651FFF
  }

  .\32xl\:focus-within\:ring-offset-deep-purple-A700:focus-within {
    --tw-ring-offset-color: #6200EA
  }

  .\32xl\:focus-within\:ring-offset-lime-50:focus-within {
    --tw-ring-offset-color: #F9FBE7
  }

  .\32xl\:focus-within\:ring-offset-lime-100:focus-within {
    --tw-ring-offset-color: #F0F4C3
  }

  .\32xl\:focus-within\:ring-offset-lime-200:focus-within {
    --tw-ring-offset-color: #E6EE9C
  }

  .\32xl\:focus-within\:ring-offset-lime-300:focus-within {
    --tw-ring-offset-color: #DCE775
  }

  .\32xl\:focus-within\:ring-offset-lime-400:focus-within {
    --tw-ring-offset-color: #D4E157
  }

  .\32xl\:focus-within\:ring-offset-lime-500:focus-within {
    --tw-ring-offset-color: #CDDC39
  }

  .\32xl\:focus-within\:ring-offset-lime-600:focus-within {
    --tw-ring-offset-color: #C0CA33
  }

  .\32xl\:focus-within\:ring-offset-lime-700:focus-within {
    --tw-ring-offset-color: #AFB42B
  }

  .\32xl\:focus-within\:ring-offset-lime-800:focus-within {
    --tw-ring-offset-color: #9E9D24
  }

  .\32xl\:focus-within\:ring-offset-lime-900:focus-within {
    --tw-ring-offset-color: #827717
  }

  .\32xl\:focus-within\:ring-offset-lime-default:focus-within {
    --tw-ring-offset-color: #CDDC39
  }

  .\32xl\:focus-within\:ring-offset-lime-A100:focus-within {
    --tw-ring-offset-color: #F4FF81
  }

  .\32xl\:focus-within\:ring-offset-lime-A200:focus-within {
    --tw-ring-offset-color: #EEFF41
  }

  .\32xl\:focus-within\:ring-offset-lime-A400:focus-within {
    --tw-ring-offset-color: #C6FF00
  }

  .\32xl\:focus-within\:ring-offset-lime-A700:focus-within {
    --tw-ring-offset-color: #AEEA00
  }

  .\32xl\:focus-within\:ring-offset-amber-50:focus-within {
    --tw-ring-offset-color: #FFF8E1
  }

  .\32xl\:focus-within\:ring-offset-amber-100:focus-within {
    --tw-ring-offset-color: #FFECB3
  }

  .\32xl\:focus-within\:ring-offset-amber-200:focus-within {
    --tw-ring-offset-color: #FFE082
  }

  .\32xl\:focus-within\:ring-offset-amber-300:focus-within {
    --tw-ring-offset-color: #FFD54F
  }

  .\32xl\:focus-within\:ring-offset-amber-400:focus-within {
    --tw-ring-offset-color: #FFCA28
  }

  .\32xl\:focus-within\:ring-offset-amber-500:focus-within {
    --tw-ring-offset-color: #FFC107
  }

  .\32xl\:focus-within\:ring-offset-amber-600:focus-within {
    --tw-ring-offset-color: #FFB300
  }

  .\32xl\:focus-within\:ring-offset-amber-700:focus-within {
    --tw-ring-offset-color: #FFA000
  }

  .\32xl\:focus-within\:ring-offset-amber-800:focus-within {
    --tw-ring-offset-color: #FF8F00
  }

  .\32xl\:focus-within\:ring-offset-amber-900:focus-within {
    --tw-ring-offset-color: #FF6F00
  }

  .\32xl\:focus-within\:ring-offset-amber-default:focus-within {
    --tw-ring-offset-color: #FFC107
  }

  .\32xl\:focus-within\:ring-offset-amber-A100:focus-within {
    --tw-ring-offset-color: #FFE57F
  }

  .\32xl\:focus-within\:ring-offset-amber-A200:focus-within {
    --tw-ring-offset-color: #FFD740
  }

  .\32xl\:focus-within\:ring-offset-amber-A400:focus-within {
    --tw-ring-offset-color: #FFC400
  }

  .\32xl\:focus-within\:ring-offset-amber-A700:focus-within {
    --tw-ring-offset-color: #FFAB00
  }

  .\32xl\:focus-within\:ring-offset-brown-50:focus-within {
    --tw-ring-offset-color: #EFEBE9
  }

  .\32xl\:focus-within\:ring-offset-brown-100:focus-within {
    --tw-ring-offset-color: #D7CCC8
  }

  .\32xl\:focus-within\:ring-offset-brown-200:focus-within {
    --tw-ring-offset-color: #BCAAA4
  }

  .\32xl\:focus-within\:ring-offset-brown-300:focus-within {
    --tw-ring-offset-color: #A1887F
  }

  .\32xl\:focus-within\:ring-offset-brown-400:focus-within {
    --tw-ring-offset-color: #8D6E63
  }

  .\32xl\:focus-within\:ring-offset-brown-500:focus-within {
    --tw-ring-offset-color: #795548
  }

  .\32xl\:focus-within\:ring-offset-brown-600:focus-within {
    --tw-ring-offset-color: #6D4C41
  }

  .\32xl\:focus-within\:ring-offset-brown-700:focus-within {
    --tw-ring-offset-color: #5D4037
  }

  .\32xl\:focus-within\:ring-offset-brown-800:focus-within {
    --tw-ring-offset-color: #4E342E
  }

  .\32xl\:focus-within\:ring-offset-brown-900:focus-within {
    --tw-ring-offset-color: #3E2723
  }

  .\32xl\:focus-within\:ring-offset-brown-default:focus-within {
    --tw-ring-offset-color: #795548
  }

  .\32xl\:focus-within\:ring-offset-brown-A100:focus-within {
    --tw-ring-offset-color: #D7CCC8
  }

  .\32xl\:focus-within\:ring-offset-brown-A200:focus-within {
    --tw-ring-offset-color: #BCAAA4
  }

  .\32xl\:focus-within\:ring-offset-brown-A400:focus-within {
    --tw-ring-offset-color: #8D6E63
  }

  .\32xl\:focus-within\:ring-offset-brown-A700:focus-within {
    --tw-ring-offset-color: #5D4037
  }

  .\32xl\:focus-within\:ring-offset-blue-gray-50:focus-within {
    --tw-ring-offset-color: #ECEFF1
  }

  .\32xl\:focus-within\:ring-offset-blue-gray-100:focus-within {
    --tw-ring-offset-color: #CFD8DC
  }

  .\32xl\:focus-within\:ring-offset-blue-gray-200:focus-within {
    --tw-ring-offset-color: #B0BEC5
  }

  .\32xl\:focus-within\:ring-offset-blue-gray-300:focus-within {
    --tw-ring-offset-color: #90A4AE
  }

  .\32xl\:focus-within\:ring-offset-blue-gray-400:focus-within {
    --tw-ring-offset-color: #78909C
  }

  .\32xl\:focus-within\:ring-offset-blue-gray-500:focus-within {
    --tw-ring-offset-color: #607D8B
  }

  .\32xl\:focus-within\:ring-offset-blue-gray-600:focus-within {
    --tw-ring-offset-color: #546E7A
  }

  .\32xl\:focus-within\:ring-offset-blue-gray-700:focus-within {
    --tw-ring-offset-color: #455A64
  }

  .\32xl\:focus-within\:ring-offset-blue-gray-800:focus-within {
    --tw-ring-offset-color: #37474F
  }

  .\32xl\:focus-within\:ring-offset-blue-gray-900:focus-within {
    --tw-ring-offset-color: #263238
  }

  .\32xl\:focus-within\:ring-offset-blue-gray-default:focus-within {
    --tw-ring-offset-color: #607D8B
  }

  .\32xl\:focus-within\:ring-offset-blue-gray-A100:focus-within {
    --tw-ring-offset-color: #CFD8DC
  }

  .\32xl\:focus-within\:ring-offset-blue-gray-A200:focus-within {
    --tw-ring-offset-color: #B0BEC5
  }

  .\32xl\:focus-within\:ring-offset-blue-gray-A400:focus-within {
    --tw-ring-offset-color: #78909C
  }

  .\32xl\:focus-within\:ring-offset-blue-gray-A700:focus-within {
    --tw-ring-offset-color: #455A64
  }

  .\32xl\:focus-within\:ring-offset-cyan-50:focus-within {
    --tw-ring-offset-color: #E0F7FA
  }

  .\32xl\:focus-within\:ring-offset-cyan-100:focus-within {
    --tw-ring-offset-color: #B2EBF2
  }

  .\32xl\:focus-within\:ring-offset-cyan-200:focus-within {
    --tw-ring-offset-color: #80DEEA
  }

  .\32xl\:focus-within\:ring-offset-cyan-300:focus-within {
    --tw-ring-offset-color: #4DD0E1
  }

  .\32xl\:focus-within\:ring-offset-cyan-400:focus-within {
    --tw-ring-offset-color: #26C6DA
  }

  .\32xl\:focus-within\:ring-offset-cyan-500:focus-within {
    --tw-ring-offset-color: #00BCD4
  }

  .\32xl\:focus-within\:ring-offset-cyan-600:focus-within {
    --tw-ring-offset-color: #00ACC1
  }

  .\32xl\:focus-within\:ring-offset-cyan-700:focus-within {
    --tw-ring-offset-color: #0097A7
  }

  .\32xl\:focus-within\:ring-offset-cyan-800:focus-within {
    --tw-ring-offset-color: #00838F
  }

  .\32xl\:focus-within\:ring-offset-cyan-900:focus-within {
    --tw-ring-offset-color: #006064
  }

  .\32xl\:focus-within\:ring-offset-cyan-default:focus-within {
    --tw-ring-offset-color: #00BCD4
  }

  .\32xl\:focus-within\:ring-offset-cyan-A100:focus-within {
    --tw-ring-offset-color: #84FFFF
  }

  .\32xl\:focus-within\:ring-offset-cyan-A200:focus-within {
    --tw-ring-offset-color: #18FFFF
  }

  .\32xl\:focus-within\:ring-offset-cyan-A400:focus-within {
    --tw-ring-offset-color: #00E5FF
  }

  .\32xl\:focus-within\:ring-offset-cyan-A700:focus-within {
    --tw-ring-offset-color: #00B8D4
  }

  .\32xl\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent
  }

  .\32xl\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor
  }

  .\32xl\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #22292F
  }

  .\32xl\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #FFF
  }

  .\32xl\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #FAFAFA
  }

  .\32xl\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #F5F5F5
  }

  .\32xl\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #EEEEEE
  }

  .\32xl\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #E0E0E0
  }

  .\32xl\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #BDBDBD
  }

  .\32xl\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .\32xl\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #757575
  }

  .\32xl\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #616161
  }

  .\32xl\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #424242
  }

  .\32xl\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #212121
  }

  .\32xl\:focus\:ring-offset-gray-default:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .\32xl\:focus\:ring-offset-gray-A100:focus {
    --tw-ring-offset-color: #D5D5D5
  }

  .\32xl\:focus\:ring-offset-gray-A200:focus {
    --tw-ring-offset-color: #AAAAAA
  }

  .\32xl\:focus\:ring-offset-gray-A400:focus {
    --tw-ring-offset-color: #303030
  }

  .\32xl\:focus\:ring-offset-gray-A700:focus {
    --tw-ring-offset-color: #616161
  }

  .\32xl\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #FFEBEE
  }

  .\32xl\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #FFCDD2
  }

  .\32xl\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #EF9A9A
  }

  .\32xl\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #E57373
  }

  .\32xl\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #EF5350
  }

  .\32xl\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #F44336
  }

  .\32xl\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #E53935
  }

  .\32xl\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #D32F2F
  }

  .\32xl\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #C62828
  }

  .\32xl\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #B71C1C
  }

  .\32xl\:focus\:ring-offset-red-default:focus {
    --tw-ring-offset-color: #F44336
  }

  .\32xl\:focus\:ring-offset-red-A100:focus {
    --tw-ring-offset-color: #FF8A80
  }

  .\32xl\:focus\:ring-offset-red-A200:focus {
    --tw-ring-offset-color: #FF5252
  }

  .\32xl\:focus\:ring-offset-red-A400:focus {
    --tw-ring-offset-color: #FF1744
  }

  .\32xl\:focus\:ring-offset-red-A700:focus {
    --tw-ring-offset-color: #D50000
  }

  .\32xl\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #FFFDE7
  }

  .\32xl\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #FFF9C4
  }

  .\32xl\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #FFF59D
  }

  .\32xl\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #FFF176
  }

  .\32xl\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #FFEE58
  }

  .\32xl\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #FFEB3B
  }

  .\32xl\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #FDD835
  }

  .\32xl\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #FBC02D
  }

  .\32xl\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #F9A825
  }

  .\32xl\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #F57F17
  }

  .\32xl\:focus\:ring-offset-yellow-default:focus {
    --tw-ring-offset-color: #FFEB3B
  }

  .\32xl\:focus\:ring-offset-yellow-A100:focus {
    --tw-ring-offset-color: #FFFF8D
  }

  .\32xl\:focus\:ring-offset-yellow-A200:focus {
    --tw-ring-offset-color: #FFFF00
  }

  .\32xl\:focus\:ring-offset-yellow-A400:focus {
    --tw-ring-offset-color: #FFEA00
  }

  .\32xl\:focus\:ring-offset-yellow-A700:focus {
    --tw-ring-offset-color: #FFD600
  }

  .\32xl\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #E8F5E9
  }

  .\32xl\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #C8E6C9
  }

  .\32xl\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #A5D6A7
  }

  .\32xl\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #81C784
  }

  .\32xl\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #66BB6A
  }

  .\32xl\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #4CAF50
  }

  .\32xl\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #43A047
  }

  .\32xl\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #388E3C
  }

  .\32xl\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #2E7D32
  }

  .\32xl\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #1B5E20
  }

  .\32xl\:focus\:ring-offset-green-default:focus {
    --tw-ring-offset-color: #4CAF50
  }

  .\32xl\:focus\:ring-offset-green-A100:focus {
    --tw-ring-offset-color: #B9F6CA
  }

  .\32xl\:focus\:ring-offset-green-A200:focus {
    --tw-ring-offset-color: #69F0AE
  }

  .\32xl\:focus\:ring-offset-green-A400:focus {
    --tw-ring-offset-color: #00E676
  }

  .\32xl\:focus\:ring-offset-green-A700:focus {
    --tw-ring-offset-color: #00C853
  }

  .\32xl\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #E3F2FD
  }

  .\32xl\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #BBDEFB
  }

  .\32xl\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #90CAF9
  }

  .\32xl\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #64B5F6
  }

  .\32xl\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #42A5F5
  }

  .\32xl\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #2196F3
  }

  .\32xl\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #1E88E5
  }

  .\32xl\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1976D2
  }

  .\32xl\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1565C0
  }

  .\32xl\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #0D47A1
  }

  .\32xl\:focus\:ring-offset-blue-default:focus {
    --tw-ring-offset-color: #2196F3
  }

  .\32xl\:focus\:ring-offset-blue-A100:focus {
    --tw-ring-offset-color: #82B1FF
  }

  .\32xl\:focus\:ring-offset-blue-A200:focus {
    --tw-ring-offset-color: #448AFF
  }

  .\32xl\:focus\:ring-offset-blue-A400:focus {
    --tw-ring-offset-color: #2979FF
  }

  .\32xl\:focus\:ring-offset-blue-A700:focus {
    --tw-ring-offset-color: #2962FF
  }

  .\32xl\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #E8EAF6
  }

  .\32xl\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #C5CAE9
  }

  .\32xl\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #9FA8DA
  }

  .\32xl\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #7986CB
  }

  .\32xl\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #5C6BC0
  }

  .\32xl\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #3F51B5
  }

  .\32xl\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #3949AB
  }

  .\32xl\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #303F9F
  }

  .\32xl\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #283593
  }

  .\32xl\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #1A237E
  }

  .\32xl\:focus\:ring-offset-indigo-default:focus {
    --tw-ring-offset-color: #3F51B5
  }

  .\32xl\:focus\:ring-offset-indigo-A100:focus {
    --tw-ring-offset-color: #8C9EFF
  }

  .\32xl\:focus\:ring-offset-indigo-A200:focus {
    --tw-ring-offset-color: #536DFE
  }

  .\32xl\:focus\:ring-offset-indigo-A400:focus {
    --tw-ring-offset-color: #3D5AFE
  }

  .\32xl\:focus\:ring-offset-indigo-A700:focus {
    --tw-ring-offset-color: #304FFE
  }

  .\32xl\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #F3E5F5
  }

  .\32xl\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #E1BEE7
  }

  .\32xl\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #CE93D8
  }

  .\32xl\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #BA68C8
  }

  .\32xl\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #AB47BC
  }

  .\32xl\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #9C27B0
  }

  .\32xl\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #8E24AA
  }

  .\32xl\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #7B1FA2
  }

  .\32xl\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #6A1B9A
  }

  .\32xl\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4A148C
  }

  .\32xl\:focus\:ring-offset-purple-default:focus {
    --tw-ring-offset-color: #9C27B0
  }

  .\32xl\:focus\:ring-offset-purple-A100:focus {
    --tw-ring-offset-color: #EA80FC
  }

  .\32xl\:focus\:ring-offset-purple-A200:focus {
    --tw-ring-offset-color: #E040FB
  }

  .\32xl\:focus\:ring-offset-purple-A400:focus {
    --tw-ring-offset-color: #D500F9
  }

  .\32xl\:focus\:ring-offset-purple-A700:focus {
    --tw-ring-offset-color: #AA00FF
  }

  .\32xl\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #FCE4EC
  }

  .\32xl\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #F8BBD0
  }

  .\32xl\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #F48FB1
  }

  .\32xl\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #F06292
  }

  .\32xl\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #EC407A
  }

  .\32xl\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #E91E63
  }

  .\32xl\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #D81B60
  }

  .\32xl\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #C2185B
  }

  .\32xl\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #AD1457
  }

  .\32xl\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #880E4F
  }

  .\32xl\:focus\:ring-offset-pink-default:focus {
    --tw-ring-offset-color: #E91E63
  }

  .\32xl\:focus\:ring-offset-pink-A100:focus {
    --tw-ring-offset-color: #FF80AB
  }

  .\32xl\:focus\:ring-offset-pink-A200:focus {
    --tw-ring-offset-color: #FF4081
  }

  .\32xl\:focus\:ring-offset-pink-A400:focus {
    --tw-ring-offset-color: #F50057
  }

  .\32xl\:focus\:ring-offset-pink-A700:focus {
    --tw-ring-offset-color: #C51162
  }

  .\32xl\:focus\:ring-offset-grey-50:focus {
    --tw-ring-offset-color: #FAFAFA
  }

  .\32xl\:focus\:ring-offset-grey-100:focus {
    --tw-ring-offset-color: #F5F5F5
  }

  .\32xl\:focus\:ring-offset-grey-200:focus {
    --tw-ring-offset-color: #EEEEEE
  }

  .\32xl\:focus\:ring-offset-grey-300:focus {
    --tw-ring-offset-color: #E0E0E0
  }

  .\32xl\:focus\:ring-offset-grey-400:focus {
    --tw-ring-offset-color: #BDBDBD
  }

  .\32xl\:focus\:ring-offset-grey-500:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .\32xl\:focus\:ring-offset-grey-600:focus {
    --tw-ring-offset-color: #757575
  }

  .\32xl\:focus\:ring-offset-grey-700:focus {
    --tw-ring-offset-color: #616161
  }

  .\32xl\:focus\:ring-offset-grey-800:focus {
    --tw-ring-offset-color: #424242
  }

  .\32xl\:focus\:ring-offset-grey-900:focus {
    --tw-ring-offset-color: #212121
  }

  .\32xl\:focus\:ring-offset-grey-default:focus {
    --tw-ring-offset-color: #9E9E9E
  }

  .\32xl\:focus\:ring-offset-grey-A100:focus {
    --tw-ring-offset-color: #D5D5D5
  }

  .\32xl\:focus\:ring-offset-grey-A200:focus {
    --tw-ring-offset-color: #AAAAAA
  }

  .\32xl\:focus\:ring-offset-grey-A400:focus {
    --tw-ring-offset-color: #303030
  }

  .\32xl\:focus\:ring-offset-grey-A700:focus {
    --tw-ring-offset-color: #616161
  }

  .\32xl\:focus\:ring-offset-orange-50:focus {
    --tw-ring-offset-color: #FFF3E0
  }

  .\32xl\:focus\:ring-offset-orange-100:focus {
    --tw-ring-offset-color: #FFE0B2
  }

  .\32xl\:focus\:ring-offset-orange-200:focus {
    --tw-ring-offset-color: #FFCC80
  }

  .\32xl\:focus\:ring-offset-orange-300:focus {
    --tw-ring-offset-color: #FFB74D
  }

  .\32xl\:focus\:ring-offset-orange-400:focus {
    --tw-ring-offset-color: #FFA726
  }

  .\32xl\:focus\:ring-offset-orange-500:focus {
    --tw-ring-offset-color: #FF9800
  }

  .\32xl\:focus\:ring-offset-orange-600:focus {
    --tw-ring-offset-color: #FB8C00
  }

  .\32xl\:focus\:ring-offset-orange-700:focus {
    --tw-ring-offset-color: #F57C00
  }

  .\32xl\:focus\:ring-offset-orange-800:focus {
    --tw-ring-offset-color: #EF6C00
  }

  .\32xl\:focus\:ring-offset-orange-900:focus {
    --tw-ring-offset-color: #E65100
  }

  .\32xl\:focus\:ring-offset-orange-default:focus {
    --tw-ring-offset-color: #FF9800
  }

  .\32xl\:focus\:ring-offset-orange-A100:focus {
    --tw-ring-offset-color: #FFD180
  }

  .\32xl\:focus\:ring-offset-orange-A200:focus {
    --tw-ring-offset-color: #FFAB40
  }

  .\32xl\:focus\:ring-offset-orange-A400:focus {
    --tw-ring-offset-color: #FF9100
  }

  .\32xl\:focus\:ring-offset-orange-A700:focus {
    --tw-ring-offset-color: #FF6D00
  }

  .\32xl\:focus\:ring-offset-deep-orange-50:focus {
    --tw-ring-offset-color: #FBE9E7
  }

  .\32xl\:focus\:ring-offset-deep-orange-100:focus {
    --tw-ring-offset-color: #FFCCBC
  }

  .\32xl\:focus\:ring-offset-deep-orange-200:focus {
    --tw-ring-offset-color: #FFAB91
  }

  .\32xl\:focus\:ring-offset-deep-orange-300:focus {
    --tw-ring-offset-color: #FF8A65
  }

  .\32xl\:focus\:ring-offset-deep-orange-400:focus {
    --tw-ring-offset-color: #FF7043
  }

  .\32xl\:focus\:ring-offset-deep-orange-500:focus {
    --tw-ring-offset-color: #FF5722
  }

  .\32xl\:focus\:ring-offset-deep-orange-600:focus {
    --tw-ring-offset-color: #F4511E
  }

  .\32xl\:focus\:ring-offset-deep-orange-700:focus {
    --tw-ring-offset-color: #E64A19
  }

  .\32xl\:focus\:ring-offset-deep-orange-800:focus {
    --tw-ring-offset-color: #D84315
  }

  .\32xl\:focus\:ring-offset-deep-orange-900:focus {
    --tw-ring-offset-color: #BF360C
  }

  .\32xl\:focus\:ring-offset-deep-orange-default:focus {
    --tw-ring-offset-color: #FF5722
  }

  .\32xl\:focus\:ring-offset-deep-orange-A100:focus {
    --tw-ring-offset-color: #FF9E80
  }

  .\32xl\:focus\:ring-offset-deep-orange-A200:focus {
    --tw-ring-offset-color: #FF6E40
  }

  .\32xl\:focus\:ring-offset-deep-orange-A400:focus {
    --tw-ring-offset-color: #FF3D00
  }

  .\32xl\:focus\:ring-offset-deep-orange-A700:focus {
    --tw-ring-offset-color: #DD2C00
  }

  .\32xl\:focus\:ring-offset-light-green-50:focus {
    --tw-ring-offset-color: #F1F8E9
  }

  .\32xl\:focus\:ring-offset-light-green-100:focus {
    --tw-ring-offset-color: #DCEDC8
  }

  .\32xl\:focus\:ring-offset-light-green-200:focus {
    --tw-ring-offset-color: #C5E1A5
  }

  .\32xl\:focus\:ring-offset-light-green-300:focus {
    --tw-ring-offset-color: #AED581
  }

  .\32xl\:focus\:ring-offset-light-green-400:focus {
    --tw-ring-offset-color: #9CCC65
  }

  .\32xl\:focus\:ring-offset-light-green-500:focus {
    --tw-ring-offset-color: #8BC34A
  }

  .\32xl\:focus\:ring-offset-light-green-600:focus {
    --tw-ring-offset-color: #7CB342
  }

  .\32xl\:focus\:ring-offset-light-green-700:focus {
    --tw-ring-offset-color: #689F38
  }

  .\32xl\:focus\:ring-offset-light-green-800:focus {
    --tw-ring-offset-color: #558B2F
  }

  .\32xl\:focus\:ring-offset-light-green-900:focus {
    --tw-ring-offset-color: #33691E
  }

  .\32xl\:focus\:ring-offset-light-green-default:focus {
    --tw-ring-offset-color: #8BC34A
  }

  .\32xl\:focus\:ring-offset-light-green-A100:focus {
    --tw-ring-offset-color: #CCFF90
  }

  .\32xl\:focus\:ring-offset-light-green-A200:focus {
    --tw-ring-offset-color: #B2FF59
  }

  .\32xl\:focus\:ring-offset-light-green-A400:focus {
    --tw-ring-offset-color: #76FF03
  }

  .\32xl\:focus\:ring-offset-light-green-A700:focus {
    --tw-ring-offset-color: #64DD17
  }

  .\32xl\:focus\:ring-offset-teal-50:focus {
    --tw-ring-offset-color: #E0F2F1
  }

  .\32xl\:focus\:ring-offset-teal-100:focus {
    --tw-ring-offset-color: #B2DFDB
  }

  .\32xl\:focus\:ring-offset-teal-200:focus {
    --tw-ring-offset-color: #80CBC4
  }

  .\32xl\:focus\:ring-offset-teal-300:focus {
    --tw-ring-offset-color: #4DB6AC
  }

  .\32xl\:focus\:ring-offset-teal-400:focus {
    --tw-ring-offset-color: #26A69A
  }

  .\32xl\:focus\:ring-offset-teal-500:focus {
    --tw-ring-offset-color: #009688
  }

  .\32xl\:focus\:ring-offset-teal-600:focus {
    --tw-ring-offset-color: #00897B
  }

  .\32xl\:focus\:ring-offset-teal-700:focus {
    --tw-ring-offset-color: #00796B
  }

  .\32xl\:focus\:ring-offset-teal-800:focus {
    --tw-ring-offset-color: #00695C
  }

  .\32xl\:focus\:ring-offset-teal-900:focus {
    --tw-ring-offset-color: #004D40
  }

  .\32xl\:focus\:ring-offset-teal-default:focus {
    --tw-ring-offset-color: #009688
  }

  .\32xl\:focus\:ring-offset-teal-A100:focus {
    --tw-ring-offset-color: #A7FFEB
  }

  .\32xl\:focus\:ring-offset-teal-A200:focus {
    --tw-ring-offset-color: #64FFDA
  }

  .\32xl\:focus\:ring-offset-teal-A400:focus {
    --tw-ring-offset-color: #1DE9B6
  }

  .\32xl\:focus\:ring-offset-teal-A700:focus {
    --tw-ring-offset-color: #00BFA5
  }

  .\32xl\:focus\:ring-offset-light-blue-50:focus {
    --tw-ring-offset-color: #E1F5FE
  }

  .\32xl\:focus\:ring-offset-light-blue-100:focus {
    --tw-ring-offset-color: #B3E5FC
  }

  .\32xl\:focus\:ring-offset-light-blue-200:focus {
    --tw-ring-offset-color: #81D4FA
  }

  .\32xl\:focus\:ring-offset-light-blue-300:focus {
    --tw-ring-offset-color: #4FC3F7
  }

  .\32xl\:focus\:ring-offset-light-blue-400:focus {
    --tw-ring-offset-color: #29B6F6
  }

  .\32xl\:focus\:ring-offset-light-blue-500:focus {
    --tw-ring-offset-color: #03A9F4
  }

  .\32xl\:focus\:ring-offset-light-blue-600:focus {
    --tw-ring-offset-color: #039BE5
  }

  .\32xl\:focus\:ring-offset-light-blue-700:focus {
    --tw-ring-offset-color: #0288D1
  }

  .\32xl\:focus\:ring-offset-light-blue-800:focus {
    --tw-ring-offset-color: #0277BD
  }

  .\32xl\:focus\:ring-offset-light-blue-900:focus {
    --tw-ring-offset-color: #01579B
  }

  .\32xl\:focus\:ring-offset-light-blue-default:focus {
    --tw-ring-offset-color: #03A9F4
  }

  .\32xl\:focus\:ring-offset-light-blue-A100:focus {
    --tw-ring-offset-color: #80D8FF
  }

  .\32xl\:focus\:ring-offset-light-blue-A200:focus {
    --tw-ring-offset-color: #40C4FF
  }

  .\32xl\:focus\:ring-offset-light-blue-A400:focus {
    --tw-ring-offset-color: #00B0FF
  }

  .\32xl\:focus\:ring-offset-light-blue-A700:focus {
    --tw-ring-offset-color: #0091EA
  }

  .\32xl\:focus\:ring-offset-deep-purple-50:focus {
    --tw-ring-offset-color: #EDE7F6
  }

  .\32xl\:focus\:ring-offset-deep-purple-100:focus {
    --tw-ring-offset-color: #D1C4E9
  }

  .\32xl\:focus\:ring-offset-deep-purple-200:focus {
    --tw-ring-offset-color: #B39DDB
  }

  .\32xl\:focus\:ring-offset-deep-purple-300:focus {
    --tw-ring-offset-color: #9575CD
  }

  .\32xl\:focus\:ring-offset-deep-purple-400:focus {
    --tw-ring-offset-color: #7E57C2
  }

  .\32xl\:focus\:ring-offset-deep-purple-500:focus {
    --tw-ring-offset-color: #673AB7
  }

  .\32xl\:focus\:ring-offset-deep-purple-600:focus {
    --tw-ring-offset-color: #5E35B1
  }

  .\32xl\:focus\:ring-offset-deep-purple-700:focus {
    --tw-ring-offset-color: #512DA8
  }

  .\32xl\:focus\:ring-offset-deep-purple-800:focus {
    --tw-ring-offset-color: #4527A0
  }

  .\32xl\:focus\:ring-offset-deep-purple-900:focus {
    --tw-ring-offset-color: #311B92
  }

  .\32xl\:focus\:ring-offset-deep-purple-default:focus {
    --tw-ring-offset-color: #673AB7
  }

  .\32xl\:focus\:ring-offset-deep-purple-A100:focus {
    --tw-ring-offset-color: #B388FF
  }

  .\32xl\:focus\:ring-offset-deep-purple-A200:focus {
    --tw-ring-offset-color: #7C4DFF
  }

  .\32xl\:focus\:ring-offset-deep-purple-A400:focus {
    --tw-ring-offset-color: #651FFF
  }

  .\32xl\:focus\:ring-offset-deep-purple-A700:focus {
    --tw-ring-offset-color: #6200EA
  }

  .\32xl\:focus\:ring-offset-lime-50:focus {
    --tw-ring-offset-color: #F9FBE7
  }

  .\32xl\:focus\:ring-offset-lime-100:focus {
    --tw-ring-offset-color: #F0F4C3
  }

  .\32xl\:focus\:ring-offset-lime-200:focus {
    --tw-ring-offset-color: #E6EE9C
  }

  .\32xl\:focus\:ring-offset-lime-300:focus {
    --tw-ring-offset-color: #DCE775
  }

  .\32xl\:focus\:ring-offset-lime-400:focus {
    --tw-ring-offset-color: #D4E157
  }

  .\32xl\:focus\:ring-offset-lime-500:focus {
    --tw-ring-offset-color: #CDDC39
  }

  .\32xl\:focus\:ring-offset-lime-600:focus {
    --tw-ring-offset-color: #C0CA33
  }

  .\32xl\:focus\:ring-offset-lime-700:focus {
    --tw-ring-offset-color: #AFB42B
  }

  .\32xl\:focus\:ring-offset-lime-800:focus {
    --tw-ring-offset-color: #9E9D24
  }

  .\32xl\:focus\:ring-offset-lime-900:focus {
    --tw-ring-offset-color: #827717
  }

  .\32xl\:focus\:ring-offset-lime-default:focus {
    --tw-ring-offset-color: #CDDC39
  }

  .\32xl\:focus\:ring-offset-lime-A100:focus {
    --tw-ring-offset-color: #F4FF81
  }

  .\32xl\:focus\:ring-offset-lime-A200:focus {
    --tw-ring-offset-color: #EEFF41
  }

  .\32xl\:focus\:ring-offset-lime-A400:focus {
    --tw-ring-offset-color: #C6FF00
  }

  .\32xl\:focus\:ring-offset-lime-A700:focus {
    --tw-ring-offset-color: #AEEA00
  }

  .\32xl\:focus\:ring-offset-amber-50:focus {
    --tw-ring-offset-color: #FFF8E1
  }

  .\32xl\:focus\:ring-offset-amber-100:focus {
    --tw-ring-offset-color: #FFECB3
  }

  .\32xl\:focus\:ring-offset-amber-200:focus {
    --tw-ring-offset-color: #FFE082
  }

  .\32xl\:focus\:ring-offset-amber-300:focus {
    --tw-ring-offset-color: #FFD54F
  }

  .\32xl\:focus\:ring-offset-amber-400:focus {
    --tw-ring-offset-color: #FFCA28
  }

  .\32xl\:focus\:ring-offset-amber-500:focus {
    --tw-ring-offset-color: #FFC107
  }

  .\32xl\:focus\:ring-offset-amber-600:focus {
    --tw-ring-offset-color: #FFB300
  }

  .\32xl\:focus\:ring-offset-amber-700:focus {
    --tw-ring-offset-color: #FFA000
  }

  .\32xl\:focus\:ring-offset-amber-800:focus {
    --tw-ring-offset-color: #FF8F00
  }

  .\32xl\:focus\:ring-offset-amber-900:focus {
    --tw-ring-offset-color: #FF6F00
  }

  .\32xl\:focus\:ring-offset-amber-default:focus {
    --tw-ring-offset-color: #FFC107
  }

  .\32xl\:focus\:ring-offset-amber-A100:focus {
    --tw-ring-offset-color: #FFE57F
  }

  .\32xl\:focus\:ring-offset-amber-A200:focus {
    --tw-ring-offset-color: #FFD740
  }

  .\32xl\:focus\:ring-offset-amber-A400:focus {
    --tw-ring-offset-color: #FFC400
  }

  .\32xl\:focus\:ring-offset-amber-A700:focus {
    --tw-ring-offset-color: #FFAB00
  }

  .\32xl\:focus\:ring-offset-brown-50:focus {
    --tw-ring-offset-color: #EFEBE9
  }

  .\32xl\:focus\:ring-offset-brown-100:focus {
    --tw-ring-offset-color: #D7CCC8
  }

  .\32xl\:focus\:ring-offset-brown-200:focus {
    --tw-ring-offset-color: #BCAAA4
  }

  .\32xl\:focus\:ring-offset-brown-300:focus {
    --tw-ring-offset-color: #A1887F
  }

  .\32xl\:focus\:ring-offset-brown-400:focus {
    --tw-ring-offset-color: #8D6E63
  }

  .\32xl\:focus\:ring-offset-brown-500:focus {
    --tw-ring-offset-color: #795548
  }

  .\32xl\:focus\:ring-offset-brown-600:focus {
    --tw-ring-offset-color: #6D4C41
  }

  .\32xl\:focus\:ring-offset-brown-700:focus {
    --tw-ring-offset-color: #5D4037
  }

  .\32xl\:focus\:ring-offset-brown-800:focus {
    --tw-ring-offset-color: #4E342E
  }

  .\32xl\:focus\:ring-offset-brown-900:focus {
    --tw-ring-offset-color: #3E2723
  }

  .\32xl\:focus\:ring-offset-brown-default:focus {
    --tw-ring-offset-color: #795548
  }

  .\32xl\:focus\:ring-offset-brown-A100:focus {
    --tw-ring-offset-color: #D7CCC8
  }

  .\32xl\:focus\:ring-offset-brown-A200:focus {
    --tw-ring-offset-color: #BCAAA4
  }

  .\32xl\:focus\:ring-offset-brown-A400:focus {
    --tw-ring-offset-color: #8D6E63
  }

  .\32xl\:focus\:ring-offset-brown-A700:focus {
    --tw-ring-offset-color: #5D4037
  }

  .\32xl\:focus\:ring-offset-blue-gray-50:focus {
    --tw-ring-offset-color: #ECEFF1
  }

  .\32xl\:focus\:ring-offset-blue-gray-100:focus {
    --tw-ring-offset-color: #CFD8DC
  }

  .\32xl\:focus\:ring-offset-blue-gray-200:focus {
    --tw-ring-offset-color: #B0BEC5
  }

  .\32xl\:focus\:ring-offset-blue-gray-300:focus {
    --tw-ring-offset-color: #90A4AE
  }

  .\32xl\:focus\:ring-offset-blue-gray-400:focus {
    --tw-ring-offset-color: #78909C
  }

  .\32xl\:focus\:ring-offset-blue-gray-500:focus {
    --tw-ring-offset-color: #607D8B
  }

  .\32xl\:focus\:ring-offset-blue-gray-600:focus {
    --tw-ring-offset-color: #546E7A
  }

  .\32xl\:focus\:ring-offset-blue-gray-700:focus {
    --tw-ring-offset-color: #455A64
  }

  .\32xl\:focus\:ring-offset-blue-gray-800:focus {
    --tw-ring-offset-color: #37474F
  }

  .\32xl\:focus\:ring-offset-blue-gray-900:focus {
    --tw-ring-offset-color: #263238
  }

  .\32xl\:focus\:ring-offset-blue-gray-default:focus {
    --tw-ring-offset-color: #607D8B
  }

  .\32xl\:focus\:ring-offset-blue-gray-A100:focus {
    --tw-ring-offset-color: #CFD8DC
  }

  .\32xl\:focus\:ring-offset-blue-gray-A200:focus {
    --tw-ring-offset-color: #B0BEC5
  }

  .\32xl\:focus\:ring-offset-blue-gray-A400:focus {
    --tw-ring-offset-color: #78909C
  }

  .\32xl\:focus\:ring-offset-blue-gray-A700:focus {
    --tw-ring-offset-color: #455A64
  }

  .\32xl\:focus\:ring-offset-cyan-50:focus {
    --tw-ring-offset-color: #E0F7FA
  }

  .\32xl\:focus\:ring-offset-cyan-100:focus {
    --tw-ring-offset-color: #B2EBF2
  }

  .\32xl\:focus\:ring-offset-cyan-200:focus {
    --tw-ring-offset-color: #80DEEA
  }

  .\32xl\:focus\:ring-offset-cyan-300:focus {
    --tw-ring-offset-color: #4DD0E1
  }

  .\32xl\:focus\:ring-offset-cyan-400:focus {
    --tw-ring-offset-color: #26C6DA
  }

  .\32xl\:focus\:ring-offset-cyan-500:focus {
    --tw-ring-offset-color: #00BCD4
  }

  .\32xl\:focus\:ring-offset-cyan-600:focus {
    --tw-ring-offset-color: #00ACC1
  }

  .\32xl\:focus\:ring-offset-cyan-700:focus {
    --tw-ring-offset-color: #0097A7
  }

  .\32xl\:focus\:ring-offset-cyan-800:focus {
    --tw-ring-offset-color: #00838F
  }

  .\32xl\:focus\:ring-offset-cyan-900:focus {
    --tw-ring-offset-color: #006064
  }

  .\32xl\:focus\:ring-offset-cyan-default:focus {
    --tw-ring-offset-color: #00BCD4
  }

  .\32xl\:focus\:ring-offset-cyan-A100:focus {
    --tw-ring-offset-color: #84FFFF
  }

  .\32xl\:focus\:ring-offset-cyan-A200:focus {
    --tw-ring-offset-color: #18FFFF
  }

  .\32xl\:focus\:ring-offset-cyan-A400:focus {
    --tw-ring-offset-color: #00E5FF
  }

  .\32xl\:focus\:ring-offset-cyan-A700:focus {
    --tw-ring-offset-color: #00B8D4
  }

  .\32xl\:filter {
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
  }

  .\32xl\:filter-none {
    filter: none
  }

  .\32xl\:blur-0 {
    --tw-blur: blur(0)
  }

  .\32xl\:blur-none {
    --tw-blur: blur(0)
  }

  .\32xl\:blur-sm {
    --tw-blur: blur(4px)
  }

  .\32xl\:blur {
    --tw-blur: blur(8px)
  }

  .\32xl\:blur-md {
    --tw-blur: blur(12px)
  }

  .\32xl\:blur-lg {
    --tw-blur: blur(16px)
  }

  .\32xl\:blur-xl {
    --tw-blur: blur(24px)
  }

  .\32xl\:blur-2xl {
    --tw-blur: blur(40px)
  }

  .\32xl\:blur-3xl {
    --tw-blur: blur(64px)
  }

  .\32xl\:brightness-0 {
    --tw-brightness: brightness(0)
  }

  .\32xl\:brightness-50 {
    --tw-brightness: brightness(.5)
  }

  .\32xl\:brightness-75 {
    --tw-brightness: brightness(.75)
  }

  .\32xl\:brightness-90 {
    --tw-brightness: brightness(.9)
  }

  .\32xl\:brightness-95 {
    --tw-brightness: brightness(.95)
  }

  .\32xl\:brightness-100 {
    --tw-brightness: brightness(1)
  }

  .\32xl\:brightness-105 {
    --tw-brightness: brightness(1.05)
  }

  .\32xl\:brightness-110 {
    --tw-brightness: brightness(1.1)
  }

  .\32xl\:brightness-125 {
    --tw-brightness: brightness(1.25)
  }

  .\32xl\:brightness-150 {
    --tw-brightness: brightness(1.5)
  }

  .\32xl\:brightness-200 {
    --tw-brightness: brightness(2)
  }

  .\32xl\:contrast-0 {
    --tw-contrast: contrast(0)
  }

  .\32xl\:contrast-50 {
    --tw-contrast: contrast(.5)
  }

  .\32xl\:contrast-75 {
    --tw-contrast: contrast(.75)
  }

  .\32xl\:contrast-100 {
    --tw-contrast: contrast(1)
  }

  .\32xl\:contrast-125 {
    --tw-contrast: contrast(1.25)
  }

  .\32xl\:contrast-150 {
    --tw-contrast: contrast(1.5)
  }

  .\32xl\:contrast-200 {
    --tw-contrast: contrast(2)
  }

  .\32xl\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05))
  }

  .\32xl\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06))
  }

  .\32xl\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06))
  }

  .\32xl\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1))
  }

  .\32xl\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08))
  }

  .\32xl\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15))
  }

  .\32xl\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000)
  }

  .\32xl\:grayscale-0 {
    --tw-grayscale: grayscale(0)
  }

  .\32xl\:grayscale {
    --tw-grayscale: grayscale(100%)
  }

  .\32xl\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg)
  }

  .\32xl\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg)
  }

  .\32xl\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg)
  }

  .\32xl\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg)
  }

  .\32xl\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg)
  }

  .\32xl\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg)
  }

  .\32xl\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg)
  }

  .\32xl\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg)
  }

  .\32xl\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg)
  }

  .\32xl\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg)
  }

  .\32xl\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg)
  }

  .\32xl\:invert-0 {
    --tw-invert: invert(0)
  }

  .\32xl\:invert {
    --tw-invert: invert(100%)
  }

  .\32xl\:saturate-0 {
    --tw-saturate: saturate(0)
  }

  .\32xl\:saturate-50 {
    --tw-saturate: saturate(.5)
  }

  .\32xl\:saturate-100 {
    --tw-saturate: saturate(1)
  }

  .\32xl\:saturate-150 {
    --tw-saturate: saturate(1.5)
  }

  .\32xl\:saturate-200 {
    --tw-saturate: saturate(2)
  }

  .\32xl\:sepia-0 {
    --tw-sepia: sepia(0)
  }

  .\32xl\:sepia {
    --tw-sepia: sepia(100%)
  }

  .\32xl\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
  }

  .\32xl\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
            backdrop-filter: none
  }

  .\32xl\:backdrop-blur-0 {
    --tw-backdrop-blur: blur(0)
  }

  .\32xl\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0)
  }

  .\32xl\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px)
  }

  .\32xl\:backdrop-blur {
    --tw-backdrop-blur: blur(8px)
  }

  .\32xl\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px)
  }

  .\32xl\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px)
  }

  .\32xl\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px)
  }

  .\32xl\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px)
  }

  .\32xl\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px)
  }

  .\32xl\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0)
  }

  .\32xl\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5)
  }

  .\32xl\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75)
  }

  .\32xl\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9)
  }

  .\32xl\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95)
  }

  .\32xl\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1)
  }

  .\32xl\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05)
  }

  .\32xl\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1)
  }

  .\32xl\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25)
  }

  .\32xl\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5)
  }

  .\32xl\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2)
  }

  .\32xl\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0)
  }

  .\32xl\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5)
  }

  .\32xl\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75)
  }

  .\32xl\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1)
  }

  .\32xl\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25)
  }

  .\32xl\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5)
  }

  .\32xl\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2)
  }

  .\32xl\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0)
  }

  .\32xl\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%)
  }

  .\32xl\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg)
  }

  .\32xl\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg)
  }

  .\32xl\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg)
  }

  .\32xl\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg)
  }

  .\32xl\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg)
  }

  .\32xl\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg)
  }

  .\32xl\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg)
  }

  .\32xl\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg)
  }

  .\32xl\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg)
  }

  .\32xl\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg)
  }

  .\32xl\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg)
  }

  .\32xl\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0)
  }

  .\32xl\:backdrop-invert {
    --tw-backdrop-invert: invert(100%)
  }

  .\32xl\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0)
  }

  .\32xl\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05)
  }

  .\32xl\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1)
  }

  .\32xl\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2)
  }

  .\32xl\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25)
  }

  .\32xl\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3)
  }

  .\32xl\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4)
  }

  .\32xl\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5)
  }

  .\32xl\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6)
  }

  .\32xl\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7)
  }

  .\32xl\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75)
  }

  .\32xl\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8)
  }

  .\32xl\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9)
  }

  .\32xl\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95)
  }

  .\32xl\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1)
  }

  .\32xl\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0)
  }

  .\32xl\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5)
  }

  .\32xl\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1)
  }

  .\32xl\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5)
  }

  .\32xl\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2)
  }

  .\32xl\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0)
  }

  .\32xl\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%)
  }

  .\32xl\:transition-none {
    transition-property: none
  }

  .\32xl\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .\32xl\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .\32xl\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .\32xl\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .\32xl\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .\32xl\:transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  .\32xl\:delay-75 {
    transition-delay: 75ms
  }

  .\32xl\:delay-100 {
    transition-delay: 100ms
  }

  .\32xl\:delay-150 {
    transition-delay: 150ms
  }

  .\32xl\:delay-200 {
    transition-delay: 200ms
  }

  .\32xl\:delay-300 {
    transition-delay: 300ms
  }

  .\32xl\:delay-500 {
    transition-delay: 500ms
  }

  .\32xl\:delay-700 {
    transition-delay: 700ms
  }

  .\32xl\:delay-1000 {
    transition-delay: 1000ms
  }

  .\32xl\:duration-75 {
    transition-duration: 75ms
  }

  .\32xl\:duration-100 {
    transition-duration: 100ms
  }

  .\32xl\:duration-150 {
    transition-duration: 150ms
  }

  .\32xl\:duration-200 {
    transition-duration: 200ms
  }

  .\32xl\:duration-300 {
    transition-duration: 300ms
  }

  .\32xl\:duration-500 {
    transition-duration: 500ms
  }

  .\32xl\:duration-700 {
    transition-duration: 700ms
  }

  .\32xl\:duration-1000 {
    transition-duration: 1000ms
  }

  .\32xl\:ease-linear {
    transition-timing-function: linear
  }

  .\32xl\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .\32xl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .\32xl\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }
}
